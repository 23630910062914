import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment';
import { FRONT_DF_1 } from '../../CONST';


const DateTableFilter = ({ filter, onChange, format }) => (
  <Datetime
    locale="es"
    renderInput={props => <input {...props} className="rs-input" />}
    dateFormat={format}
    timeFormat={false}
    value={filter && filter.value}
    onChange={newDate => {
      if (!newDate) {
        onChange(undefined)
      }
      if (moment(newDate, format, true).isValid()) {
        onChange(moment(newDate).format(format))
      }
    }}
    closeOnSelect
  />
);

DateTableFilter.propTypes = {
  filter: PropTypes.shape({
    value: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
};

DateTableFilter.defaultProps = {
  filter: undefined,
  format: FRONT_DF_1,
};

export default DateTableFilter;
