import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'helpers/requests';
import useTableFilters from 'helpers/useTableFilters';
import {
  cellDateOperation,
  cellNumOperation,
  cellOperation,
  headerOperation,
  cellDobleRowOperation,
} from 'helpers/tables';
import { BASE_URL, API } from 'CONST';
import PageContainer from 'components/PageContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import BaseTable from 'components/UI/BaseTable';
import { Col } from 'reactstrap';
import isUserHasProfile from 'helpers/profileValidator';
import { POLICY_WRITE } from 'helpers/profilePermission';
import FilterSelect from '../shared/FilterSelect';

const OportunitiesList = () => {
  const [tiposEstado, setTiposEstado] = useState([]);
  const [tiposMoneda, setTiposMoneda] = useState([]);
  const [onFilteredChange, onFetchData, loading, data] = useTableFilters(
    `${BASE_URL}${API.oportunidades_garantia}`,
  );

  const NO_FILTERED_ID = -1;

  useEffect(() => {
    get(`${BASE_URL}${API.estados_oportunidad}`).then((response) => {
      setTiposEstado(response.results);
    });
    get(`${BASE_URL}${API.monedas}`).then((response) => {
      setTiposMoneda(response);
    });
  }, []);

  const columns = [
    {
      Header: () => headerOperation('Afianzado'),
      id: 'afianzado',
      accessor: (i) => cellDobleRowOperation(i.afianzado.rut, i.afianzado.nombre, 'center'),
      sortable: false,
    },
    {
      Header: () => headerOperation('Monto'),
      id: 'monto',
      accessor: (i) => cellNumOperation(i.monto),
      sortable: true,
      filterable: false,
    },
    {
      Header: () => headerOperation('Moneda'),
      id: 'moneda',
      accessor: (i) => cellOperation((i.moneda && i.moneda.nombre_corto) || '-'),
      Filter: (obj) => (
        <FilterSelect
          filter={obj.filter}
          onFilterChange={obj.onChange}
          options={tiposMoneda.map((elem) => ({ label: elem.nombre_corto, value: elem.id }))}
          defaultOption={{ label: 'Todas', value: NO_FILTERED_ID }}
        />
      ),
      sortable: false,
    },
    {
      Header: () => headerOperation('Estado'),
      id: 'estado',
      accessor: (i) => cellOperation(i.estado.descripcion),
      Filter: (obj) => (
        <FilterSelect
          filter={obj.filter}
          onFilterChange={obj.onChange}
          options={tiposEstado.map((elem) => ({ label: elem.descripcion, value: elem.id }))}
          defaultOption={{ label: 'Todos', value: NO_FILTERED_ID }}
        />
      ),
      minWidth: 150,
      sortable: false,
    },
    {
      Header: () => headerOperation('Fecha de Creación'),
      id: 'creacion_ts',
      accessor: (i) => cellDateOperation(i.creacion_ts),
      sortable: true,
      filterable: false,
    },
    {
      Header: () => headerOperation('Última Modificación'),
      id: 'actualizacion_ts',
      accessor: (i) => cellDateOperation(i.actualizacion_ts),
      sortable: true,
      filterable: false,
    },
    {
      Header: () => headerOperation('Acciones'),
      id: 'acciones',
      accessor: 'id',
      Cell: (row) =>
        cellOperation(
          <div>
            {isUserHasProfile(POLICY_WRITE) && (
              <Link to={`/guarantee/oportunities/${row.value}`} className="table-link">
                <FontAwesomeIcon icon={faEdit} />
              </Link>
            )}
          </div>,
        ),
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <PageContainer
      breadcrumbs={[
        { id: 0, name: 'PÓLIZA', url: '/' },
        { id: 1, name: 'GARANTÍA', url: '/guarantee/policies' },
        { id: 2, name: 'OPORTUNIDADES' },
      ]}
    >
      <div className="table-container">
        <Col className="button-container d-flex justify-content-end pr-0">
          {isUserHasProfile(POLICY_WRITE) && (
            <Link
              className="btn btn-secondary orange mb-2"
              disabled={false}
              to="/guarantee/oportunities/create"
            >
              Crear oportunidad
            </Link>
          )}
        </Col>
        <BaseTable
          columns={columns}
          data={data.data}
          loading={loading}
          filterable
          resizable={false}
          onFetchData={onFetchData}
          onFilteredChange={onFilteredChange}
          pages={data.pages}
          resultCount={data.count}
          showPagination
          style={{ height: '63vh' }}
        />
      </div>
    </PageContainer>
  );
};

export default OportunitiesList;
