import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  clearCreditPolicyForm,
  getOptionsForSelects,
} from '../../../redux-store/actions/creditPolicyActions';
import CreditPolicyBasicInfoStep from './CreditPolicyBasicInfoStep';
import PageContainer from '../../PageContainer';
import CreditPolicyDetailsStep from './CreditPolicyDetailsStep';

import CreditPolicyParticularConditionsStep from './CreditPolicyParticularConditionsStep';
import ClientesYDeudoresStep from './ClientesYDeudoresStep';
import CreditPolicyParameterizedConditionsStep from './CreditPolicyParameterizedConditionsStep';
import { CP_CHANGE_TIPO_ENDOSO } from '../../../redux-store/constants/action-types';
import CreditPolicyFormAccordion from './CreditPolicyFormAccordion';

const CreditPolicyBalanceForm = props => {
  const {
    dispatch,
    tipoEndosoId,
    creditPolicy: { policy },
    match: {
      params: { id, numeroVigencia },
    },
  } = props;

  useEffect(() => {
    dispatch(clearCreditPolicyForm());
    dispatch({ type: CP_CHANGE_TIPO_ENDOSO, payload: tipoEndosoId });
    dispatch(getOptionsForSelects(id, numeroVigencia));
  }, [id]);

  const breadcrumbs = [
    { name: 'CRÉDITO', url: '/credit/policies/' },
    { name: 'PÓLIZAS', url: '/credit/policies/' },
  ];

  if (policy && policy.base && policy.base.nemotecnico) {
    breadcrumbs.push({
      name: policy.base.nemotecnico,
      url: `/credit/policies/detail/${id}`,
    });

    if (policy.vigencia_seleccionada) {
      breadcrumbs.push({
        name: `BALANCE (Vigencia: #${policy.vigencia_seleccionada.numero_vigencia} Endoso: #${policy.vigencia_seleccionada.secuencia_endoso})`,
      });
    }
  }

  return (
    policy && (
      <PageContainer breadcrumbs={breadcrumbs} style={{ padding: '0 20px' }}>
        <CreditPolicyFormAccordion
          sections={[
            {
              name: 'Información Básica',
              component: <CreditPolicyBasicInfoStep {...props} readOnly />,
              isValid: true,
              expansible: true,
            },
            {
              name: 'Detalles',
              component: <CreditPolicyDetailsStep {...props} readOnly />,
              expansible: true,
              isValid: true,
            },
            {
              name: 'Filiales y Deudores Especiales',
              component: <ClientesYDeudoresStep {...props} readOnly />,
              expansible: true,
              isValid: true,
            },
            {
              name: 'Condiciones Particulares',
              component: (
                <CreditPolicyParticularConditionsStep {...props} readOnly />
              ),
              expansible: true,
              isValid: true,
            },
            {
              name: 'Cláusulas Parametrizadas',
              component: (
                <CreditPolicyParameterizedConditionsStep {...props} readOnly />
              ),
              expansible: true,
              isValid: true,
            },
          ]}
        />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 3fr 1fr 1fr 170px',
            gridGap: '20px',
            marginTop: '40px',
          }}
        >
          <Button
            outline
            color="orsan-primary"
            style={{ gridColumn: '4 / 4' }}
            tag={Link}
            to={`/credit/policies/detail/${id}`}
          >
            Volver
          </Button>
        </div>
      </PageContainer>
    )
  );
};

CreditPolicyBalanceForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      numeroVigencia: PropTypes.string,
    }),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  creditPolicy: PropTypes.object.isRequired,
  policies: PropTypes.shape({
    selectedPolicy: PropTypes.shape({
      tiposMoneda: PropTypes.array,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  tipoEndosoId: PropTypes.number.isRequired,
  loginIdToken: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  creditPolicy: state.creditPolicy,
  policies: state.policies,
  ramosFecu: state.conditioned.ramos_fecu,
  loginIdToken: state.login.id_token,
});

export default connect(mapStateToProps)(CreditPolicyBalanceForm);
