/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input } from 'reactstrap';
import Datetime from 'react-datetime';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import moment from 'moment';
import FormSection from '../../UI/FormSection';
import {
  getGastosAdquisicion,
  handleBalanceCalculatedFieldsChange,
  handleFormElementChange,
  handleInicioVigenciaChange,
  handleInicioVigenciaEndosoChange,
  handleSelectChange,
  handleTerminoVigenciaChange,
  handleTextAreaChange,
  handleTipoVigenciaChange,
  getInitialBalanceCalculatedFields,
} from '../../../redux-store/actions/creditPolicyActions';
import IndemnizacionMaximaSection from './details-step-sections/IndemnizacionMaximaSection';
import CostoDeAvisoDeImpagoSection from './details-step-sections/CostoDeAvisoDeImpagoSection';
import FormaDePagoSection from './details-step-sections/FormaDePagoSection';
import TasaCostoDeSeguroSection from './details-step-sections/TasaCostoDeSeguroSection';
import ConfiguracionDeSiniestroSection from './details-step-sections/ConfiguracionDeSiniestroSection';
import PeriodoDeGestionesPropiasSection from './details-step-sections/PeriodoDeGestionesPropiasSection';
import PlazoMaximoDeCreditoSection from './details-step-sections/PlazoMaximoDeCreditoSection';
import ClientesInnominadosSection from './details-step-sections/ClientesInnominadosSection';
import CoberturaSection from './details-step-sections/CoberturaSection';
import MontoMaximoClientesInnominadosSection from './details-step-sections/MontoMaximoClientesInnominadosSection';
import UmbralSection from './details-step-sections/UmbralSection';
import CostoDeProrrogaSection from './details-step-sections/CostoDeProrrogaSection';
import DeduciblesSection from './details-step-sections/DeduciblesSection';
import CostoDeEstudioSection from './details-step-sections/CostoDeEstudioSection';
import {
  BACKEND_DF,
  CP_FS_DETALLES,
  FRONT_DF_2,
  TIPO_ENDOSO_EMISION_ID,
  TIPO_ENDOSO_MODIFICACION_ID,
  TIPO_ENDOSO_RENOVACION_ID,
} from '../../../CONST';
import { CP_SET_SECTION_VALIDATION } from '../../../redux-store/constants/action-types';
import {
  canProrrogate,
  canTerminate,
  getTerminoVigenciaSeleccionada,
  isIntermediarioDirecto,
  isValidComision,
  isValidCreditPolicyDetailsStep,
  isValidTerminoVigencia,
  isValidInicioVigenciaEndoso,
  isValidInicioVigencia,
} from '../../../helpers/validation';
import { getSelectedTipoVigencia, getTiposVigenciaOptions } from '../../../helpers/selects';
import VistoBuenoSection from './helpers/VistoBuenoSection';
import IndemnizacionMaximaInnominadosSection from './details-step-sections/IndemnizacionMaximaInnominadosSection';
import TipoDeProvisionSection from './details-step-sections/TipoDeProvisionSection';
import { roundFloat } from '../../../helpers/helpers';

class CreditPolicyDetailsStep extends Component {
  state = {
    validateInputs: false,
  };

  componentDidMount() {
    const {
      dispatch,
      creditPolicy: {
        form: { data, ultimoEndosoPoliza },
      },
    } = this.props;
    const { ramoFecu, producto, endoso, moneda } = data;
    dispatch(getGastosAdquisicion(ramoFecu, producto, endoso.tipoCobertura));
    if (this.isEndosoRenovacion()) {
      // Solo se puede renovar a partir de la última vigencia, por lo que usamos `ultimoEndosoPoliza`
      const forcedInicioVigencia = moment(ultimoEndosoPoliza.termino_vigencia).add(1, 'days');
      dispatch(handleInicioVigenciaChange(forcedInicioVigencia));
      dispatch(getInitialBalanceCalculatedFields(endoso, moneda.value.nombre_corto));
    }
  }

  componentDidUpdate() {
    const {
      dispatch,
      creditPolicy: {
        form,
        form: { sections },
      },
    } = this.props;
    const isValid = isValidCreditPolicyDetailsStep(form);
    if (isValid !== sections[CP_FS_DETALLES].valid) {
      dispatch({
        type: CP_SET_SECTION_VALIDATION,
        payload: { stepName: CP_FS_DETALLES, isValid },
      });
    }
  }

  setValidateInputs = (validateInputs) => this.setState({ validateInputs });

  isValidInitialDate = (date) => {
    const dt = moment(date, FRONT_DF_2);
    return date.trim().length === 11 && dt.isValid();
  };

  getTipoEndosoId = () => {
    const {
      creditPolicy: {
        form: {
          data: {
            endoso: { tipoEndosoId },
          },
        },
      },
    } = this.props;
    return tipoEndosoId;
  };

  isEndosoEmision = () => {
    return this.getTipoEndosoId() === TIPO_ENDOSO_EMISION_ID;
  };

  isEndosoRenovacion = () => {
    return this.getTipoEndosoId() === TIPO_ENDOSO_RENOVACION_ID;
  };

  isEndosoModificacion = () => {
    return this.getTipoEndosoId() === TIPO_ENDOSO_MODIFICACION_ID;
  };

  endDateValidator = (date) => {
    const {
      creditPolicy: { form },
    } = this.props;
    return isValidTerminoVigencia(form, date);
  };

  inicioVigenciaEndosoValidator = (dt) => {
    const {
      creditPolicy: { form },
    } = this.props;
    return isValidInicioVigenciaEndoso(form, dt);
  };

  inicioVigenciaValidator = (dt) => {
    const {
      creditPolicy: { form },
    } = this.props;
    return isValidInicioVigencia(form, dt);
  };

  handleChange = (name, value) => handleFormElementChange(CP_FS_DETALLES, name, value);

  handleSelect = (selectedOption, action) =>
    handleSelectChange(CP_FS_DETALLES, selectedOption, action);

  handleTextArea = (event) => handleTextAreaChange(CP_FS_DETALLES, event);

  disableTerminoVigencia = () => {
    const {
      creditPolicy: { form },
    } = this.props;
    return !canTerminate(form) && !canProrrogate(form);
  };

  render() {
    const {
      dispatch,
      creditPolicy: {
        form,
        form: { ultimoEndosoVigencia, data, currentPolicyId },
      },
      readOnly,
    } = this.props;
    const { endoso, ramoFecu } = form.data;
    const tipoVigenciaSelectedOption = getSelectedTipoVigencia(form);
    const selectedPlazoMaximo = form.plazosGestionesPropiasDias.find(
      (plazo) => plazo.id === endoso.plazoMaximoCreditoDiasId,
    );
    const invalidComision = !isValidComision(
      endoso.comisionPct,
      form.data.endoso.tipoIntermediario,
    );
    const moneda = form.data.moneda ? form.data.moneda.value.nombre_corto : '';
    const { validateInputs: validate } = this.state;
    const intermediarioDirecto = isIntermediarioDirecto(data.endoso.tipoIntermediario);
    const disableInicioVigenciaEndoso =
      endoso.terminoVigencia &&
      endoso.terminoVigencia.isAfter(getTerminoVigenciaSeleccionada(form));
    const disableTerminoVigencia = this.disableTerminoVigencia();
    const years = tipoVigenciaSelectedOption ? tipoVigenciaSelectedOption.value.years || 1 : 1;
    const months = Math.round(endoso.terminoVigencia.diff(endoso.inicioVigencia, 'months', true));
    return (
      <>
        <div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gridGap: '20px 40px',
            }}
          >
            {this.isEndosoModificacion() && !readOnly && (
              <FormGroup style={{ display: 'grid', alignItems: 'center' }}>
                <label htmlFor="inicio-vigencia">Inicio de vigencia de endoso</label>
                <Datetime
                  closeOnSelect
                  dateFormat="DD-MMM-YYYY"
                  id="inicio-vigencia-endoso"
                  name="endoso.inicioVigenciaEndoso"
                  locale="es"
                  viewDate={endoso.inicioVigenciaEndoso ? endoso.inicioVigenciaEndoso : moment()}
                  onChange={(newDate) => dispatch(handleInicioVigenciaEndosoChange(newDate))}
                  renderInput={(props) => (
                    <Input {...props} invalid={validate && !isValidInicioVigenciaEndoso(form)} />
                  )}
                  timeFormat={false}
                  value={endoso.inicioVigenciaEndoso}
                  inputProps={{ disabled: disableInicioVigenciaEndoso }}
                  isValidDate={this.inicioVigenciaEndosoValidator}
                />
              </FormGroup>
            )}
            <FormGroup style={{ display: 'grid', alignItems: 'center' }}>
              <label htmlFor="inicio-vigencia">Inicio de vigencia de póliza</label>
              <Datetime
                closeOnSelect
                dateFormat="DD-MMM-YYYY"
                id="inicio-vigencia"
                locale="es"
                onChange={(newDate) => dispatch(handleInicioVigenciaChange(newDate))}
                renderInput={(props) => (
                  <Input {...props} invalid={validate && !isValidInicioVigencia(form)} />
                )}
                timeFormat={false}
                value={endoso.inicioVigencia}
                inputProps={{ disabled: !this.isEndosoEmision() || readOnly }}
                isDisabled={readOnly}
              />
            </FormGroup>
            <FormGroup style={{ display: 'grid', alignItems: 'center' }}>
              <label htmlFor="estimacion-ventas">Vigencia</label>
              <Select
                id="tipo-vigencia"
                name="endoso.tipoVigenciaId"
                className="custom-policy-select"
                options={getTiposVigenciaOptions(form)}
                onChange={(selectedOption) =>
                  dispatch(handleTipoVigenciaChange(selectedOption.value))
                }
                value={tipoVigenciaSelectedOption}
                placeholder=""
                isDisabled={endoso.tipoEndosoId === TIPO_ENDOSO_MODIFICACION_ID || readOnly}
              />
            </FormGroup>
            <FormGroup style={{ display: 'grid', alignItems: 'center' }}>
              <label htmlFor="termino-vigencia">Término de vigencia</label>
              <Datetime
                closeOnSelect
                dateFormat="DD-MMM-YYYY"
                id="termino-vigencia"
                locale="es"
                renderInput={(props) => (
                  <Input
                    {...props}
                    invalid={validate && !isValidTerminoVigencia(form, endoso.terminoVigencia)}
                  />
                )}
                timeFormat={false}
                value={endoso.terminoVigencia}
                inputProps={{ disabled: disableTerminoVigencia }}
                onChange={(newDate) =>
                  dispatch(
                    handleTerminoVigenciaChange(
                      moment(ultimoEndosoVigencia.termino_vigencia, BACKEND_DF),
                      newDate,
                      endoso,
                      moneda,
                    ),
                  )
                }
                isValidDate={this.endDateValidator}
                isDisabled={readOnly}
              />
            </FormGroup>
            <div style={{ display: 'grid', alignItems: 'end', gridColumnStart: 1 }}>
              <label htmlFor="moneda">Moneda</label>
              <p id="moneda" className="small-form-value">
                {moneda}
              </p>
            </div>
            <FormGroup style={{ display: 'grid', alignItems: 'end' }}>
              <label htmlFor="estimacion-ventas">Estimación de ventas</label>
              <NumberFormat
                id="estimacion-ventas"
                name="estimacionVentasAmt"
                className={`form-control${
                  validate && !endoso.estimacionVentasAmt ? ' is-invalid' : ''
                }`}
                onValueChange={(values) =>
                  dispatch(
                    handleBalanceCalculatedFieldsChange(
                      'estimacion_ventas_amt',
                      'estimacionVentasAmt',
                      values.floatValue,
                      endoso,
                      moneda,
                    ),
                  )
                }
                thousandSeparator="."
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                allowNegative={false}
                value={endoso.estimacionVentasAmt}
                disabled={readOnly}
              />
            </FormGroup>
            <FormGroup style={{ display: 'grid', alignItems: 'end' }}>
              <label htmlFor="ventas-pct">% Ventas cálculo de prima mínima</label>
              <NumberFormat
                id="ventas-pct"
                name="ventasPct"
                className={`form-control${validate && !endoso.ventasPct ? ' is-invalid' : ''}`}
                onValueChange={(values) =>
                  dispatch(
                    handleBalanceCalculatedFieldsChange(
                      'ventas_pct',
                      'ventasPct',
                      values.floatValue,
                      endoso,
                      moneda,
                    ),
                  )
                }
                decimalSeparator=","
                decimalScale="5"
                fixedDecimalScale
                allowNegative={false}
                isAllowed={(values) => !values.value || values.floatValue <= 100}
                suffix="%"
                value={endoso.ventasPct}
                disabled={readOnly}
              />
            </FormGroup>
            <FormGroup style={{ display: 'grid', alignItems: 'end' }}>
              <label htmlFor="ventas-pct">Comisión</label>
              <NumberFormat
                id="comision"
                name="comisionPct"
                className={`form-control${validate && invalidComision ? ' is-invalid' : ''}`}
                onValueChange={(values) =>
                  dispatch(this.handleChange('endoso.comisionPct', values.floatValue))
                }
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                allowNegative={false}
                isAllowed={(values) => !values.value || values.floatValue <= 100}
                suffix="%"
                value={endoso.comisionPct}
                readOnly={intermediarioDirecto}
                disabled={readOnly}
              />
            </FormGroup>
            <div>
              <label htmlFor="monto-asegurado-estimado">Monto asegurado estimado</label>
              <NumberFormat
                id="monto-asegurado-estimado"
                name="montoAseguradoEstimadoAmt"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                suffix={` ${moneda}`}
                value={endoso.montoAseguradoEstimadoAmt}
                displayType="text"
                renderText={(value) => <p className="small-form-value">{value}</p>}
              />
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                gridGap: '15px',
              }}
            >
              <div>
                <label htmlFor="prima-minima-anual">
                  {years === 1 ? 'Prima mínima anual' : 'Prima mínima total'}
                </label>
                <NumberFormat
                  id="prima-minima-anual"
                  name="primaMinimaAnualAmt"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale="2"
                  fixedDecimalScale
                  suffix={` ${moneda}`}
                  value={roundFloat(endoso.primaMinimaAnualAmt * years)}
                  displayType="text"
                  renderText={(value) => <p className="small-form-value">{value}</p>}
                />
              </div>
              <div>
                <label htmlFor="delta-prima-minima-anual">Cambio en prima</label>
                <NumberFormat
                  id="delta-prima-minima-anual"
                  name="deltaPrimaMinimaAnualAmt"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale="2"
                  fixedDecimalScale
                  suffix={` ${moneda}`}
                  value={roundFloat(endoso.deltaPrimaMinimaAnualAmt * years)}
                  displayType="text"
                  renderText={(value) => <p className="small-form-value">{value}</p>}
                />
              </div>
            </div>
            <div>
              <label htmlFor="prima-minima-mensual">Prima mínima mensual</label>
              <NumberFormat
                id="prima-minima-mensual"
                name="primaMinimaMensualAmt"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                suffix={` ${moneda}`}
                value={endoso.primaMinimaMensualAmt}
                displayType="text"
                renderText={(value) => <p className="small-form-value">{value}</p>}
              />
            </div>
            <div>
              <label htmlFor="gastos-adquisicion">Gastos de adquisición</label>
              <NumberFormat
                id="gastos-adquisicion"
                name="gastosAdquisicion"
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                suffix="%"
                value={endoso.gastosAdquisicion}
                displayType="text"
                renderText={(value) => <p className="small-form-value">{value}</p>}
              />
            </div>
            <FormGroup style={{ gridColumn: '1/5' }}>
              <label htmlFor="materia-asegurada">Materia asegurada</label>
              <textarea
                id="materia-asegurada"
                name="endoso.materiaAsegurada"
                className={`form-control${
                  validate && !endoso.materiaAsegurada ? ' is-invalid' : ''
                }`}
                onChange={(event) => dispatch(this.handleTextArea(event))}
                rows="10"
                value={endoso.materiaAsegurada}
                disabled={readOnly}
              />
            </FormGroup>
            <PlazoMaximoDeCreditoSection
              style={{ gridColumn: '1/3' }}
              plazoMaximoCreditoDiasId={endoso.plazoMaximoCreditoDiasId}
              plazosGestionesPropiasDias={form.plazosGestionesPropiasDias}
              dispatch={dispatch}
              handleSelect={this.handleSelect}
              plazoMinimo={endoso.tasasCostoSeguro.reduce(
                (min, tcs) => Math.max(min, tcs.hastaDias),
                0,
              )}
              validate={validate}
              disabled={readOnly}
            />
            <FormSection title="Indemnización máxima" style={{ gridColumn: '3/5' }}>
              <IndemnizacionMaximaSection
                tipoIndemnizacionMaxId={endoso.tipoIndemnizacionMaxId}
                indemnizacionMaxAmt={endoso.indemnizacionMaxAmt}
                calculate
                endoso={endoso}
                moneda={moneda}
                dispatch={dispatch}
                handleChange={this.handleChange}
                validate={validate}
                disabled={readOnly}
              />
            </FormSection>
            <TasaCostoDeSeguroSection
              style={{ gridColumn: '1/3' }}
              endoso={endoso}
              moneda={moneda}
              dispatch={dispatch}
              handleChange={this.handleChange}
              plazoMaximo={selectedPlazoMaximo ? selectedPlazoMaximo.dias : 0}
              validate={validate}
              disabled={readOnly}
            />
            <FormaDePagoSection
              style={{ gridColumn: '3/5' }}
              planPago={endoso.planPago}
              deltaPrima={endoso.deltaPrimaMinimaAnualAmt}
              inicioVigenciaEndoso={endoso.inicioVigenciaEndoso || endoso.inicioVigencia}
              dispatch={dispatch}
              handleChange={this.handleChange}
              validate={validate}
              tipoVigencia={tipoVigenciaSelectedOption ? tipoVigenciaSelectedOption.value : null}
              cuotas={months}
              disabled={readOnly}
            />
            <TipoDeProvisionSection
              style={{ gridColumn: '1/3' }}
              tipoProvisionId={endoso.tipoProvisionId}
              planPago={endoso.planPago}
              dispatch={dispatch}
              handleChange={this.handleChange}
              disabled={readOnly}
            />
            <CostoDeAvisoDeImpagoSection
              style={{ gridColumn: '3/5' }}
              tipoCostoAvisoImpagoId={endoso.tipoCostoAvisoImpagoId}
              costoAvisoImpago={endoso.costoAvisoImpago}
              moneda={moneda}
              dispatch={dispatch}
              handleChange={this.handleChange}
              validate={validate}
              disabled={readOnly}
            />
            <ConfiguracionDeSiniestroSection
              style={{ gridColumn: '1/3' }}
              configuracionSiniestroDias={endoso.configuracionSiniestroDias}
              dispatch={dispatch}
              handleChange={this.handleChange}
              validate={validate}
              disabled={readOnly}
              ramoFecuId={ramoFecu && ramoFecu.value.id}
              currentPolicyId={currentPolicyId}
            />
            <PeriodoDeGestionesPropiasSection
              style={{ gridColumn: '3/5' }}
              plazoGestionesPropiasDiasId={endoso.plazoGestionesPropiasDiasId}
              tipoGestionesPropiasId={endoso.tipoGestionesPropiasId}
              plazosGestionesPropiasDias={form.plazosGestionesPropiasDias}
              dispatch={dispatch}
              handleChange={this.handleChange}
              handleSelect={this.handleSelect}
              validate={validate}
              disabled={readOnly}
            />
            <ClientesInnominadosSection
              style={{ gridColumn: '1/3' }}
              clientesInnominados={endoso.clientesInnominados}
              dispatch={dispatch}
              handleChange={this.handleChange}
              disabled={readOnly}
            />
            <IndemnizacionMaximaInnominadosSection
              style={{ gridColumn: '3/5' }}
              clientesInnominados={endoso.clientesInnominados}
              indemnizacionMaxInnominado={endoso.indemnizacionMaxInnominado}
              tipoIndemnizacionMaxId={endoso.tipoIndemnizacionMaxId}
              dispatch={dispatch}
              handleChange={this.handleChange}
              validate={validate}
              disabled={readOnly}
            />
            <CoberturaSection
              style={{ gridColumn: '1/3' }}
              clientesInnominados={endoso.clientesInnominados}
              porcentajesCoberturaCredito={form.porcentajesCoberturaCredito}
              coberturaNominadosPctId={endoso.coberturaNominadosPctId}
              coberturaInnominadosPctId={endoso.coberturaInnominadosPctId}
              dispatch={dispatch}
              handleSelect={this.handleSelect}
              validate={validate}
              disabled={readOnly}
            />
            <MontoMaximoClientesInnominadosSection
              style={{ gridColumn: '3/5' }}
              clientesInnominados={endoso.clientesInnominados}
              maximoInnominadosAmt={endoso.maximoInnominadosAmt}
              umbral={endoso.umbralCoberturaAmt}
              moneda={moneda}
              dispatch={dispatch}
              handleChange={this.handleChange}
              validate={validate}
              disabled={readOnly}
            />
            <CostoDeEstudioSection
              style={{ gridColumn: '1/3' }}
              clientesInnominados={endoso.clientesInnominados}
              costoEstudioNominadoAprobadoAmt={endoso.costoEstudioNominadoAprobadoAmt}
              costoEstudioNominadoRechazadoAmt={endoso.costoEstudioNominadoRechazadoAmt}
              costoEstudioInnominadoAprobadoAmt={endoso.costoEstudioInnominadoAprobadoAmt}
              costoEstudioInnominadoRechazadoAmt={endoso.costoEstudioInnominadoRechazadoAmt}
              moneda={moneda}
              dispatch={dispatch}
              handleChange={this.handleChange}
              validate={validate}
              disabled={readOnly}
            />
            <UmbralSection
              style={{ gridColumn: '3/5' }}
              umbralCoberturaAmt={endoso.umbralCoberturaAmt}
              moneda={moneda}
              dispatch={dispatch}
              handleChange={this.handleChange}
              validate={validate}
              disabled={readOnly}
            />
            <CostoDeProrrogaSection
              style={{ gridColumn: '1/3' }}
              clientesInnominados={endoso.clientesInnominados}
              costoProrrogaNominadoPct={endoso.costoProrrogaNominadoPct}
              costoProrrogaInnominadoPct={endoso.costoProrrogaInnominadoPct}
              dispatch={dispatch}
              handleChange={this.handleChange}
              validate={validate}
              disabled={readOnly}
            />
            <DeduciblesSection
              style={{ gridColumn: '3/5' }}
              clientesInnominados={endoso.clientesInnominados}
              deducibleNominadoAmt={endoso.deducibleNominadoAmt}
              deducibleInnominadoAmt={endoso.deducibleInnominadoAmt}
              moneda={moneda}
              dispatch={dispatch}
              handleChange={this.handleChange}
              validate={validate}
              disabled={readOnly}
            />
            <VistoBuenoSection
              sectionName={CP_FS_DETALLES}
              validate={validate}
              style={{ gridColumn: '1 / 5' }}
            />
          </div>
        </div>
        <div className="cp-form-feedback">
          <p>
            {validate &&
              !isValidCreditPolicyDetailsStep(form) &&
              '*Existen errores en el formulario. Por favor, revise los campos marcados en rojo.'}
          </p>
        </div>
      </>
    );
  }
}

CreditPolicyDetailsStep.propTypes = {
  dispatch: PropTypes.func.isRequired,
  creditPolicy: PropTypes.shape({
    form: PropTypes.shape({
      sections: PropTypes.object,
      plazosGestionesPropiasDias: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          dias: PropTypes.number,
        }),
      ),
      porcentajesCoberturaCredito: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          cobertura_pct: PropTypes.number,
        }),
      ),
      tiposVigencia: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          natural_key: PropTypes.string,
          name: PropTypes.string,
          years: PropTypes.number,
        }),
      ),
      ultimoEndosoPoliza: PropTypes.object,
      ultimoEndosoVigencia: PropTypes.object,
      balanceTemplate: PropTypes.object,
      data: PropTypes.shape({
        moneda: PropTypes.object,
        ramoFecu: PropTypes.object,
        producto: PropTypes.object,
        endoso: PropTypes.shape({
          tipoCobertura: PropTypes.object,
          tipoIntermediario: PropTypes.shape({
            value: PropTypes.shape({
              id: PropTypes.number,
            }),
          }),
          inicioVigencia: PropTypes.object,
          inicioVigenciaEndoso: PropTypes.object,
          terminoVigencia: PropTypes.object,
          prorrogaDias: PropTypes.number,
          tipoVigenciaId: PropTypes.number,
          tipoIndemnizacionMaxId: PropTypes.number,
          indemnizacionMaxInnominado: PropTypes.number,
          indemnizacionMaxAmt: PropTypes.number,
          estimacionVentasAmt: PropTypes.number,
          ventasPct: PropTypes.number,
          montoAseguradoEstimadoAmt: PropTypes.number,
          primaMinimaAnualAmt: PropTypes.number,
          deltaPrimaMinimaAnualAmt: PropTypes.number,
          comisionPct: PropTypes.number,
          primaMinimaMensualAmt: PropTypes.number,
          materiaAsegurada: PropTypes.string,
          tipoCostoAvisoImpagoId: PropTypes.number,
          costoAvisoImpago: PropTypes.number,
          planPago: PropTypes.shape({
            tipoId: PropTypes.number,
            cuotas: PropTypes.number,
            cuotasPlan: PropTypes.arrayOf(
              PropTypes.shape({
                neto_amt: PropTypes.number,
              }),
            ),
          }),
          tipoProvisionId: PropTypes.number,
          tipoGestionesPropiasId: PropTypes.number,
          plazoGestionesPropiasDiasId: PropTypes.number,
          configuracionSiniestroDias: PropTypes.number,
          plazoMaximoCreditoDiasId: PropTypes.number,
          clientesInnominados: PropTypes.bool,
          coberturaNominadosPctId: PropTypes.number,
          coberturaInnominadosPctId: PropTypes.number,
          maximoInnominadosAmt: PropTypes.number,
          umbralCoberturaAmt: PropTypes.number,
          costoProrrogaNominadoPct: PropTypes.number,
          costoProrrogaInnominadoPct: PropTypes.number,
          deducibleNominadoAmt: PropTypes.number,
          deducibleInnominadoAmt: PropTypes.number,
          costoEstudioNominadoAprobadoAmt: PropTypes.number,
          costoEstudioNominadoRechazadoAmt: PropTypes.number,
          costoEstudioInnominadoAprobadoAmt: PropTypes.number,
          costoEstudioInnominadoRechazadoAmt: PropTypes.number,
          gastosAdquisicion: PropTypes.number,
          tasaCostoSeguroDefectoPct: PropTypes.number,
          tasasCostoSeguro: PropTypes.array,
          adjuntos: PropTypes.array,
          tipoEndosoId: PropTypes.number,
        }),
      }),
      currentPolicyId: PropTypes.number,
    }),
  }).isRequired,
  loginIdToken: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

CreditPolicyDetailsStep.defaultProps = {
  readOnly: false,
};

export default CreditPolicyDetailsStep;
