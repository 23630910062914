import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import BaseModal from "../UI/BaseModal";

const CheckModal = ({ isOpen, toggle, handleOk, loading, okBtnText, confirmMsg }) => (
  <div>
    <BaseModal handleModal={toggle} modalOpen={isOpen} type="check-modal">
      <div id="inline-block-container" className="modal-check text">
        <div id="inline-block-icon" className="centered-horizontal-container">
          <FontAwesomeIcon icon={faCheckCircle} color="green" />
        </div>
        <div
          id="inline-block-message"
          className="check-modal-text centered-horizontal-container pt-3 pb-3"
        >
          {confirmMsg}
        </div>
      </div>
      <div className="button-container">
        <div className="centered-horizontal-container">
          <Button className="warning-accept" disabled={loading} onClick={() => handleOk()}>
            {okBtnText}
          </Button>
        </div>
      </div>
    </BaseModal>
  </div>
);

CheckModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  okBtnText: PropTypes.string,
  confirmMsg: PropTypes.string.isRequired
};

CheckModal.defaultProps = {
  okBtnText: "Aceptar",
  loading: false,
};

export default CheckModal;
