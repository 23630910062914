import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faExclamation, faEye, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import isUserHasProfile from 'helpers/profileValidator';
import { CREDIT_POLICY_WRITE, CREDIT_POLICY_READ } from 'helpers/profilePermission';
import {
  cellDateOperation,
  cellDobleRowOperation,
  cellOperation,
  headerOperation,
} from '../../helpers/tables';
import BaseTable from '../UI/BaseTable';
import WarningModal from '../UI/WarningModal';
import FilterSelect from '../shared/FilterSelect';
import {
  API,
  BASE_URL,
  CONSTRUCTION_LABEL,
  DRAFT_LABEL,
  EMITTED_LABEL,
  FRONT_DF_1,
  ID_RAMOFECU_CREDITO,
  ID_RAMOFECU_CREDITO_EXPORTACION,
  WORKFLOW_URL,
} from '../../CONST';
import { deleteData } from '../../redux-store/actions';
import {
  CP_DELETE,
  CP_OPEN_DELETE,
  CP_TOGGLE_DELETE,
} from '../../redux-store/constants/action-types';
import { fdate, roundFloat } from '../../helpers/helpers';

const NO_FILTERED_ID = -1;

const renderPolicyIsVigente = (balance) => {
  if (balance) {
    const date = moment(balance.termino_vigencia);
    const hoy = moment();
    return date.isBefore(hoy, 'date') ? 'No' : 'Sí';
  }
  return '';
};

const CreditPolicyListTable = (props) => {
  const {
    policies: { tiposMoneda },
    creditPolicy: {
      list: { creditPolicies, confirmModal, selectedPolicy },
      loading,
      form: { plazosGestionesPropiasDias },
    },
    productos,
    onFetchData,
    onFilteredChange,
    dispatch,
    user,
  } = props;

  const columns = [
    {
      Header: headerOperation('Código'),
      id: 'base__nemotecnico__icontains',
      accessor: (i) => cellOperation(i.base_nemotecnico),
      width: 115,
    },
    {
      Header: headerOperation('Producto'),
      id: 'base__producto__id',
      accessor: (i) => cellOperation(i.base_producto_descripcion, 'left'),
      Filter: (obj) => (
        <FilterSelect
          filter={obj.filter}
          defaultOption={{ label: 'Todos', value: NO_FILTERED_ID }}
          onFilterChange={obj.onChange}
          options={productos
            .filter((elem) =>
              [ID_RAMOFECU_CREDITO_EXPORTACION, ID_RAMOFECU_CREDITO].includes(elem.ramo_asegurado),
            )
            .map((elem) => ({ label: elem.descripcion, value: elem.id }))}
        />
      ),
      sortable: false,
    },
    {
      Header: headerOperation('Asegurado'),
      id: 'base__asegurado',
      accessor: (i) => cellDobleRowOperation(i.base_asegurado_rut, i.base_asegurado_nombre),
      minWidth: 110,
      sortable: false,
    },
    {
      Header: headerOperation('Moneda'),
      id: 'base__moneda_asegurada__id',
      accessor: (i) => cellOperation(i.base_moneda_asegurada_nombre_corto),
      Filter: (obj) => (
        <FilterSelect
          onFilterChange={obj.onChange}
          filter={obj.filter}
          options={tiposMoneda.map((elem) => ({
            label: elem.value.nombre_corto,
            value: elem.value.id,
          }))}
          defaultOption={{ label: 'Todas', value: NO_FILTERED_ID }}
        />
      ),
      minWidth: 110,
    },
    {
      Header: headerOperation('Ventas Estimadas'),
      id: 'balance__estimacion_ventas_amt',
      accessor: (i) =>
        cellOperation(
          i.balance ? (
            <NumberFormat
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              value={i.balance.estimacion_ventas_amt}
              displayType="text"
            />
          ) : (
            '-'
          ),
          'right',
        ),
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Prima Mínima Anual'),
      id: 'balance__prima_minima_anual_amt',
      accessor: (i) =>
        cellOperation(
          i.balance ? (
            <NumberFormat
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              value={roundFloat(i.balance.prima_minima_anual_amt)}
              displayType="text"
            />
          ) : (
            '-'
          ),
          'right',
        ),
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Tasa Costo de Seguro (%)'),
      id: 'balance__tasa_costo_seguro_defecto_pct',
      accessor: (i) =>
        cellOperation(
          i.balance ? (
            <NumberFormat
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              value={i.balance.tasa_costo_seguro_defecto_pct}
              displayType="text"
            />
          ) : (
            '-'
          ),
          'right',
        ),
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Plazo Máximo Crédito'),
      id: 'balance__plazo_maximo_credito_dias__id',
      accessor: (i) =>
        cellOperation(
          i.balance ? (
            <NumberFormat
              thousandSeparator="."
              decimalSeparator=","
              value={i.balance.plazo_maximo_credito_dias}
              displayType="text"
            />
          ) : (
            '-'
          ),
          'right',
        ),
      Filter: (obj) => (
        <FilterSelect
          filter={obj.filter}
          defaultOption={{ label: 'Todos', value: NO_FILTERED_ID }}
          onFilterChange={obj.onChange}
          options={plazosGestionesPropiasDias.map((elem) => ({
            label: String(elem.dias),
            value: elem.id,
          }))}
        />
      ),
      sortable: false,
    },
    {
      Header: headerOperation('Emisión'),
      id: 'emision',
      accessor: (i) => cellDateOperation(i.emision),
      width: 100,
    },
    {
      Header: headerOperation('Inicio Vigencia'),
      id: 'balance__inicio_vigencia',
      accessor: (i) =>
        cellOperation(i.balance ? fdate(i.balance.inicio_vigencia, FRONT_DF_1) : '-'),
      sortable: false,
    },
    {
      Header: headerOperation('Término Vigencia'),
      id: 'balance__termino_vigencia',
      accessor: (i) =>
        cellOperation(i.balance ? fdate(i.balance.termino_vigencia, FRONT_DF_1) : '-'),
      sortable: false,
    },
    {
      Header: headerOperation('Vigente'),
      id: 'vigentes',
      accessor: (i) => cellOperation(renderPolicyIsVigente(i.balance)),
      Filter: (obj) => (
        <FilterSelect
          onFilterChange={obj.onChange}
          filter={obj.filter}
          options={[{ label: 'Sí', value: '1' }, { label: 'No', value: '0' }]}
          defaultOption={{ label: '', value: NO_FILTERED_ID }}
        />
      ),
      width: 100,
      sortable: false,
    },
    {
      Header: headerOperation('Estado'),
      id: 'estado__icontains',
      accessor: (i) => {
        let alertClass = 'alert-success';
        if (i.estado === DRAFT_LABEL) {
          alertClass = 'alert-primary';
        } else if (i.estado === CONSTRUCTION_LABEL) {
          alertClass = 'alert-warning';
        }
        return cellOperation(
          <div className={`alert ${alertClass} text-center`} role="alert">
            {i.estado}
          </div>,
        );
      },
      Filter: (obj) => (
        <FilterSelect
          onFilterChange={obj.onChange}
          filter={obj.filter}
          options={[
            { label: EMITTED_LABEL, value: 'emitida' },
            { label: DRAFT_LABEL, value: 'borrador' },
            { label: CONSTRUCTION_LABEL, value: 'construccion' },
          ]}
          defaultOption={{ label: 'Todos', value: 'all-options' }}
        />
      ),
      minWidth: 120,
    },
    {
      Header: headerOperation('Acciones'),
      id: 'actions',
      accessor: (i) => (
        <>
          {i.estado === EMITTED_LABEL ? (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                padding: '0 25%',
              }}
              className="table-cell"
            >
              <div style={{ textAlign: 'center' }} title="ver">
                <Link to={`/credit/policies/detail/${i.id}`} className="table-link policy">
                  <FontAwesomeIcon icon={faEye} />
                </Link>
              </div>
              {user.is_orsan && user.apps.includes('workflow') && (
                <div style={{ textAlign: 'center' }} title="facturación">
                  <a
                    className="table-link policy"
                    href={`${WORKFLOW_URL}asegurado/facturacion/${i.id}/`}
                  >
                    <FontAwesomeIcon icon={faDollarSign} />
                  </a>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                padding: '0 25%',
              }}
              className="table-cell"
            >
              {isUserHasProfile(CREDIT_POLICY_WRITE) && (
                <div style={{ textAlign: 'center' }}>
                  {selectedPolicy && i.id === selectedPolicy.id ? (
                    <div className="warning-button">
                      <FontAwesomeIcon icon={faExclamation} />
                    </div>
                  ) : (
                    <button
                      type="button"
                      className={`table-link policy ${
                        i.estado !== DRAFT_LABEL && i.estado !== CONSTRUCTION_LABEL
                          ? 'disabled'
                          : ''
                      }`}
                      onClick={() => dispatch({ type: CP_OPEN_DELETE, payload: i })}
                      title="eliminar"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  )}
                </div>
              )}
              {isUserHasProfile(CREDIT_POLICY_READ) && (
                <div style={{ textAlign: 'center' }} title="editar">
                  <Link to={`/credit/policies/${i.id}/emit`} className="table-link policy">
                    <FontAwesomeIcon
                      icon={isUserHasProfile(CREDIT_POLICY_WRITE) ? faEdit : faEye}
                    />
                  </Link>
                </div>
              )}
            </div>
          )}
        </>
      ),
      filterable: false,
      sortable: false,
      minWidth: 80,
    },
  ];
  return (
    <div>
      <div style={{ margin: '0 0 10px 0' }}>
        <BaseTable
          columns={columns}
          data={creditPolicies.data}
          filterable
          loading={loading}
          onFetchData={onFetchData}
          onFilteredChange={onFilteredChange}
          pages={creditPolicies.pages}
          resultCount={creditPolicies.count}
          showPagination
          noBorders
          style={{ height: '63vh' }}
        />
      </div>
      <WarningModal
        isOpen={confirmModal.isOpen}
        toggle={() => dispatch({ type: CP_TOGGLE_DELETE })}
        handleOk={() => {
          dispatch(
            deleteData({
              url: `${BASE_URL}${API.creditos}/${selectedPolicy.id}/`,
              id: selectedPolicy.id,
              type: CP_DELETE,
            }),
          );
        }}
        loading={confirmModal.loading}
        okBtnText="Eliminar Póliza"
        confirmMsg="Está a un paso de eliminar una Póliza"
      />
    </div>
  );
};

CreditPolicyListTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onFetchData: PropTypes.func.isRequired,
  onFilteredChange: PropTypes.func.isRequired,
  policies: PropTypes.shape({
    tiposMoneda: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.shape({
          nombre_corto: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  creditPolicy: PropTypes.shape({
    form: PropTypes.shape({
      plazosGestionesPropiasDias: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
    list: PropTypes.shape({
      creditPolicies: PropTypes.shape({
        data: PropTypes.array,
        pages: PropTypes.number,
        count: PropTypes.number,
      }),
      confirmModal: PropTypes.shape({
        loading: PropTypes.bool,
        isOpen: PropTypes.bool,
      }),
      selectedPolicy: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    loading: PropTypes.bool,
  }).isRequired,
  productos: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.shape({
    is_orsan: PropTypes.bool,
    apps: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  productos: state.conditioned.productos,
  creditPolicy: state.creditPolicy,
  policies: state.policies,
  user: state.login.user,
});

export default connect(mapStateToProps)(CreditPolicyListTable);
