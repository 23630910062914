import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PageContainer from '../PageContainer';
import PolicyForm from './PolicyForm';
import {
  cleanPolicy,
  getData,
  postData,
} from '../../redux-store/actions/index';
import {
  CREATE_POLICY,
  GET_ACTIVE_GENERAL_CONDITIONED_LIST,
  GET_ACTIVIDAD_LIST,
  GET_INTERMEDIARIO_LIST,
  GET_MONEDAS,
  GET_TIPOS_INSTITUCION,
} from '../../redux-store/constants/action-types';
import { API, BASE_URL } from '../../CONST';


class PolicyCreate extends Component {
  componentDidMount() {
    const { dispatchCleanPolicy, dispatchGetData } = this.props;
    dispatchCleanPolicy();
    dispatchGetData({
      url: `${BASE_URL}${API.maestras}/?is_main=0&paginate=0&activa=true&ramo_asegurado__id=1`,
      type: GET_ACTIVE_GENERAL_CONDITIONED_LIST,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.tipos_institucion}`,
      type: GET_TIPOS_INSTITUCION,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.monedas}`,
      type: GET_MONEDAS,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.actividad}`,
      type: GET_ACTIVIDAD_LIST,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.intermediario}`,
      type: GET_INTERMEDIARIO_LIST,
    });
  }

  handleSubmit = (config, onFailedReq = () => {}) => {
    const { dispatchPostData } = this.props;
    dispatchPostData({
      url: `${BASE_URL}${API.garantias}/`,
      type: CREATE_POLICY,
      onFailedReq,
      config,
    });
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const { dispatchCleanPolicy, history } = this.props;
    if (nextProps.policies.successMessage) {
      dispatchCleanPolicy();
      history.push(`/guarantee/policies/edit/${nextProps.policies.selectedPolicy.id}`);
    }
  }

  render() {
    const { policies } = this.props;

    if (policies.condicionadosGenerales.length === 0) {
      return null;
    }

    return (
      <PageContainer
        breadcrumbs={[
          { name: 'GARANTÍA', url: '/guarantee/policies/' },
          { name: 'PÓLIZAS', url: '/guarantee/policies/' },
          { name: 'CREAR PÓLIZA' },
        ]}
        style={{ padding: '0 20px' }}
      >
        <PolicyForm
          isEditable
          handleSubmit={this.handleSubmit}
        />
      </PageContainer>
    );
  }
}

PolicyCreate.propTypes = {
  policies: PropTypes.shape({
    condicionadosGenerales: PropTypes.array,
    successMessage: PropTypes.bool,
    selectedPolicy: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  dispatchCleanPolicy: PropTypes.func.isRequired,
  dispatchGetData: PropTypes.func.isRequired,
  dispatchPostData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  policies: state.policies,
});

const mapDispatchToProps = dispatch => ({
  dispatchCleanPolicy: data => dispatch(cleanPolicy(data)),
  dispatchGetData: data => dispatch(getData(data)),
  dispatchPostData: policy => dispatch(postData(policy)),
});

const PolicyCreateView = connect(mapStateToProps, mapDispatchToProps)(PolicyCreate);
export default PolicyCreateView;
