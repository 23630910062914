import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import conditioned from './conditioned';
import policies from './policies';
import login from './login';
import creditPolicy from './creditPolicyReducer';

const rootReducer = combineReducers({
  conditioned,
  login,
  policies,
  creditPolicy,
  routing: routerReducer,
});

export default rootReducer;
