/* eslint-disable camelcase,jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Switch from 'react-switch';
import { Link } from 'react-router-dom';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, FormFeedback, FormGroup, Input, Row } from 'reactstrap';

import FormSection from '../UI/FormSection';
import { fdate, fileDownload, urlValidator } from '../../helpers/helpers';
import { objectsToOptions } from '../../helpers/selects';
import './GeneralConditioned.css';
import { API, BACKEND_DF, BASE_URL, FRONT_DF_1 } from '../../CONST';
import isUserHasProfile from '../../helpers/profileValidator';
import {
  GENERAL_CONDITIONED_WRITE,
  PERFILES_ESCRITURA_OPERACIONES_CONDICIONADOS,
  PERFILES_ESCRITURA_COMERCIAL_CONDICIONADOS,
  PERFILES_ESCRITURA_CEO_CONDICIONADOS,
  PERFIL_ESCRITURA_OTRO_CONDICIONADOS,
} from '../../helpers/profilePermission';

const moment = require('moment');

class GeneralConditionedForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abstracto: '',
      descripcion: '',
      documento_uuid: undefined,
      activa: false,
      data: undefined,
      inicio_vigencia: moment().format(FRONT_DF_1),
      nemotecnico: '',
      selected_productos: [],
      selected_tipologias_ejecucion: [],
      selected_tipos_cobertura: [],
      selected_ramo_asegurado: null,
      url: '',
      loading: false,
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSave = () => {
    const {
      abstracto,
      activa,
      descripcion,
      documento_uuid,
      inicio_vigencia,
      nemotecnico,
      selected_productos,
      selected_tipos_cobertura,
      selected_tipologias_ejecucion,
      url,
      selected_ramo_asegurado,
    } = this.state;
    const { handleSubmit } = this.props;

    const policy = {
      abstracto,
      activa,
      descripcion,
      documento_id: 1,
      documento_uuid,
      inicio_vigencia: moment(inicio_vigencia, FRONT_DF_1).format(BACKEND_DF),
      nemotecnico,
      productos_ids: selected_productos.map((opt) => opt.value),
      tipos_cobertura_ids: selected_tipos_cobertura.map((opt) => opt.value),
      tipologias_ejecucion_ids: selected_tipologias_ejecucion.map((opt) => opt.value),
      ramo_asegurado_id: selected_ramo_asegurado.value.id,
      url,
    };

    this.setState({ loading: true });
    handleSubmit(policy, () => this.setState({ loading: false }));
  };

  isDisabledByProfile = () => {
    const validRole = isUserHasProfile(GENERAL_CONDITIONED_WRITE);

    return !validRole;
  };

  handleEdit = () => {
    const {
      abstracto,
      activa,
      selected_productos,
      selected_tipos_cobertura,
      selected_tipologias_ejecucion,
      url,
    } = this.state;
    const { handleSubmit } = this.props;

    const policy = {
      abstracto,
      activa,
      url,
      productos_ids: selected_productos.map((opt) => opt.value),
      tipos_cobertura_ids: selected_tipos_cobertura.map((opt) => opt.value),
      tipologias_ejecucion_ids: selected_tipologias_ejecucion.map((opt) => opt.value),
    };

    this.setState({ loading: true });
    handleSubmit(policy, () => this.setState({ loading: false }));
  };

  handleProductos = (selected_productos) => {
    this.setState({
      selected_productos: selected_productos || [],
    });
  };

  handleTiposCobertura = (selected_tipos_cobertura) => {
    this.setState({
      selected_tipos_cobertura: selected_tipos_cobertura || [],
    });
  };

  handleTipologiasEjecucion = (selected_tipologias_ejecucion) => {
    this.setState({
      selected_tipologias_ejecucion: selected_tipologias_ejecucion || [],
    });
  };

  handleRamoAsegurado = (selected_ramo_asegurado) => {
    this.setState({
      selected_ramo_asegurado,
    });
  };

  isValidInicioVigencia = (date) => {
    const esCeo = isUserHasProfile(PERFILES_ESCRITURA_CEO_CONDICIONADOS);
    if (esCeo) {
      // en caso de ser CEO no tiene restriccion
      return true;
    }

    const esComercial = isUserHasProfile(PERFILES_ESCRITURA_COMERCIAL_CONDICIONADOS);
    const esOperaciones = isUserHasProfile(PERFILES_ESCRITURA_OPERACIONES_CONDICIONADOS);
    const esOtro = isUserHasProfile(PERFIL_ESCRITURA_OTRO_CONDICIONADOS);
    let fechaMinima;

    if (esComercial) {
      fechaMinima = moment()
        .subtract(1, 'month')
        .startOf('month');
    } else if (esOperaciones) {
      fechaMinima = moment().startOf('month');
    } else if (esOtro) {
      fechaMinima = moment();
    }

    return fechaMinima && moment(date).isSameOrAfter(fechaMinima);
  };

  isDisabled = () => {
    const {
      abstracto,
      descripcion,
      documento_uuid,
      nemotecnico,
      selected_productos,
      selected_tipos_cobertura,
      selected_tipologias_ejecucion,
      url,
    } = this.state;

    const { edit } = this.props;
    const validationEdit =
      (selected_productos.length &&
        selected_tipos_cobertura.length &&
        selected_tipologias_ejecucion.length &&
        abstracto.length &&
        urlValidator(url)) > 0;
    const validations =
      (nemotecnico.length && documento_uuid !== undefined && descripcion.length && validationEdit) >
      0;
    return edit ? !validationEdit : !validations;
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      activa: !prevState.activa,
    }));
  };

  setInitialDate = (date) => {
    this.setState({
      inicio_vigencia: date,
    });
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const { data, documento_uuid } = this.state;
    if (nextProps.data !== undefined && nextProps.data !== data) {
      const selected_productos = objectsToOptions(nextProps.data.productos, true, true);
      const selected_tipos_cobertura = objectsToOptions(nextProps.data.tipos_cobertura, true, true);
      const selected_tipologias_ejecucion = objectsToOptions(
        nextProps.data.tipologias_ejecucion,
        true,
        true,
      );
      this.setState({
        abstracto: nextProps.data.abstracto,
        activa: nextProps.data.activa,
        data: nextProps.data,
        url: nextProps.data.url,
        selected_productos,
        selected_tipos_cobertura,
        selected_tipologias_ejecucion,
      });
    }
    if (nextProps.documento_uuid !== undefined && nextProps.documento_uuid !== documento_uuid) {
      this.setState({ documento_uuid: nextProps.documento_uuid });
    }
  }

  render() {
    const {
      abstracto,
      activa,
      descripcion,
      inicio_vigencia,
      nemotecnico,
      selected_productos,
      selected_tipos_cobertura,
      selected_tipologias_ejecucion,
      url,
      selected_ramo_asegurado,
      loading,
    } = this.state;
    const {
      data,
      edit,
      handleFile,
      productos,
      tipologias_ejecucion,
      tipos_cobertura,
      ramos_fecu,
      conditionedId,
      loginIdToken,
    } = this.props;

    const optionsProductos = objectsToOptions(productos, true, true);
    const optionsTiposCobertura = objectsToOptions(tipos_cobertura, true, true);
    const optionsTipologiasEjecucion = objectsToOptions(tipologias_ejecucion, true, true);
    const optionsRamoAsegurado = objectsToOptions(ramos_fecu, true);

    return (
      <div>
        <Col md={{ offset: 2, size: 8 }}>
          <FormSection title="Información básica">
            <div
              style={{
                display: 'grid',
                gridGap: '20px 40px',
                gridTemplateColumns: '1fr 1fr 1fr',
                marginTop: '15px',
                padding: '15px 0',
              }}
            >
              {edit ? (
                <FormGroup>
                  <label htmlFor="id">ID</label>
                  <p className="form-values">{data !== undefined ? data.id : ''}</p>
                </FormGroup>
              ) : (
                ''
              )}
              <FormGroup>
                <label htmlFor="nemotecnico">Código CMF</label>
                {edit ? (
                  <p className="form-values">{data !== undefined ? data.nemotecnico : ''}</p>
                ) : (
                  <Input
                    name="nemotecnico"
                    id="nemotecnico"
                    value={nemotecnico}
                    onChange={this.handleChange}
                    placeholder="Ingrese Código CMF"
                  />
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="ramo_asegurado">Ramo FECU</label>
                {!edit ? (
                  <Select
                    id="ramo_asegurado"
                    name="ramo_asegurado"
                    className="custom-policy-select"
                    options={optionsRamoAsegurado}
                    onChange={this.handleRamoAsegurado}
                    value={selected_ramo_asegurado}
                    placeholder="Seleccione Ramo"
                  />
                ) : (
                  <p id="ramo_asegurado" className="form-values">
                    {data && data.ramo_asegurado.descripcion}
                  </p>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="document">Documento</label>
                {edit ? (
                  <div>
                    <Button
                      className="form-values download"
                      onClick={() =>
                        fileDownload(
                          `${BASE_URL}${API.maestras}/${conditionedId}/download/`,
                          loginIdToken,
                        )
                      }
                    >
                      Descargar
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </div>
                ) : (
                  <Input
                    type="file"
                    name="document"
                    id="document"
                    style={{ marginTop: '15px' }}
                    onChange={(e) => handleFile(e)}
                  />
                )}
              </FormGroup>
            </div>
            <div
              style={{
                display: 'grid',
                gridGap: '20px 30px',
                gridTemplateColumns: '1fr 2fr',
                maringTop: '15px',
                padding: '15px 0',
              }}
            >
              <FormGroup>
                <label htmlFor="inicio_vigencia">Fecha depósito</label>
                {edit ? (
                  <p className="form-values">
                    {data !== undefined ? fdate(data.inicio_vigencia, FRONT_DF_1) : ''}
                  </p>
                ) : (
                  <Datetime
                    id="inicio_vigencia"
                    renderInput={(props) => <input {...props} className="rs-input" />}
                    onChange={this.setInitialDate}
                    value={inicio_vigencia}
                    locale="es"
                    dateFormat={FRONT_DF_1}
                    timeFormat={false}
                    closeOnSelect
                    isValidDate={this.isValidInicioVigencia}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="descripcion">Descripción</label>
                {edit ? (
                  <p className="form-values">{data !== undefined ? data.descripcion : ''}</p>
                ) : (
                  <textarea
                    className="form-control"
                    name="descripcion"
                    id="descripcion"
                    value={descripcion}
                    onChange={this.handleChange}
                    rows="1"
                  />
                )}
              </FormGroup>
            </div>
          </FormSection>
          <FormSection title="Detalles">
            <div
              style={{
                display: 'grid',
                gridGap: '20px 40px',
                gridTemplateColumns: '1fr 1fr 1fr',
                maringTop: '15px',
                padding: '15px 0',
              }}
            >
              <FormGroup>
                <label htmlFor="productos">Producto</label>
                <Select
                  id="productos"
                  name="productos"
                  className="custom-policy-select"
                  options={optionsProductos}
                  value={selected_productos}
                  onChange={this.handleProductos}
                  isDisabled={this.isDisabledByProfile()}
                  placeholder="Seleccione Producto"
                  isMulti
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="tipos_cobertura">Tipo de Cobertura</label>
                <Select
                  id="tipos_cobertura"
                  name="tipos_cobertura"
                  className="custom-policy-select"
                  options={optionsTiposCobertura}
                  value={selected_tipos_cobertura}
                  onChange={this.handleTiposCobertura}
                  isDisabled={this.isDisabledByProfile()}
                  placeholder="Seleccione Tipo de Cobertura"
                  isMulti
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="tipologias_ejecucion">Tipología Ejecución</label>
                <Select
                  id="tipologias_ejecucion"
                  name="tipologias_ejecucion"
                  className="custom-policy-select"
                  options={optionsTipologiasEjecucion}
                  value={selected_tipologias_ejecucion}
                  onChange={this.handleTipologiasEjecucion}
                  isDisabled={this.isDisabledByProfile()}
                  placeholder="Seleccione Tipología Ejecución"
                  isMulti
                />
              </FormGroup>
              <FormGroup className="expand-2">
                <label htmlFor="abstracto">Abstracto</label>
                <textarea
                  className="form-control"
                  name="abstracto"
                  id="abstracto"
                  value={abstracto}
                  onChange={this.handleChange}
                  disabled={this.isDisabledByProfile()}
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="url">URL</label>
                <Input
                  name="url"
                  id="url"
                  value={url}
                  onChange={this.handleChange}
                  disabled={this.isDisabledByProfile()}
                  placeholder="Ingrese URL"
                  invalid={url.length > 3 ? !urlValidator(url) : false}
                />
                <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                  Ingrese una url válida.
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <label htmlFor="state">Estado</label>
                <Row>
                  <Col
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 2fr 1fr',
                      marginTop: '15px',
                    }}
                  >
                    <div>
                      <p style={{ color: '#626262' }}>{activa ? 'Activo' : 'Inactivo'}</p>
                    </div>
                    <Switch
                      id="state"
                      checked={activa}
                      checkedIcon={<div />}
                      onChange={this.handleToggle}
                      disabled={this.isDisabledByProfile()}
                      offColor="#EFEFEF"
                      onColor="#39CA74"
                      uncheckedIcon={<div />}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </div>
          </FormSection>
          {!this.isDisabledByProfile() ? 
          (
            <Col md={{ offset: 8 }}>
              <div
                style={{
                  display: 'grid',
                  gridGap: '10px',
                  gridTemplateColumns: '1fr 1fr',
                }}
              >
                <div>
                  <Link
                    style={{
                      background: 'transparent',
                      color: '#F98724',
                      border: '1px solid #F98724',
                      width: '100%',
                    }}
                    className="btn btn-secondary"
                    to="/condicionados/"
                  >
                    Cancelar
                  </Link>
                </div>
                <div>
                  <Button
                    color="orsan"
                    disabled={this.isDisabled() || loading}
                    onClick={edit ? this.handleEdit : this.handleSave}
                  >
                    Guardar
                  </Button>
                </div>
              </div>
            </Col>
          ) : (
            <Col md={{ offset: 8 }}>
              <div
                style={{
                  display: 'grid',
                  gridGap: '10px',
                  gridTemplateColumns: '1fr 1fr',
                }}
              >
                <div>{}</div>
                <div>
                  <Link
                    style={{
                      background: 'transparent',
                      color: '#F98724',
                      border: '1px solid #F98724',
                      width: '100%',
                    }}
                    className="btn btn-secondary"
                    to="/condicionados/"
                  >
                    Volver
                  </Link>
                </div>
              </div>
            </Col>
          )}
        </Col>
      </div>
    );
  }
}

GeneralConditionedForm.propTypes = {
  data: PropTypes.shape({
    productos: PropTypes.array,
    tipos_cobertura: PropTypes.array,
    tipologias_ejecucion: PropTypes.array,
    activa: PropTypes.bool,
    url: PropTypes.string,
    id: PropTypes.number,
    nemotecnico: PropTypes.string,
    ramo_asegurado: PropTypes.shape({
      descripcion: PropTypes.string,
    }),
    inicio_vigencia: PropTypes.string,
    descripcion: PropTypes.string,
  }),
  documento_uuid: PropTypes.string,
  edit: PropTypes.bool,
  productos: PropTypes.arrayOf(PropTypes.object).isRequired,
  tipologias_ejecucion: PropTypes.arrayOf(PropTypes.object).isRequired,
  tipos_cobertura: PropTypes.arrayOf(PropTypes.object).isRequired,
  ramos_fecu: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleFile: PropTypes.func,
  conditionedId: PropTypes.string,
  loginIdToken: PropTypes.string,
};

GeneralConditionedForm.defaultProps = {
  data: undefined,
  edit: false,
  documento_uuid: '',
  handleFile: () => {},
  conditionedId: null,
  loginIdToken: '',
};

export default GeneralConditionedForm;
