import moment from 'moment';
import Numeral from 'numeral';
import { CONSTRUCTION_LABEL, DRAFT_LABEL, FRONT_DF_1 } from '../CONST';


export const urlValidator = (url) => {
  const regEx = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;  // eslint-disable-line
  return regEx.test(url);
};

export const isEditable = state => (state === CONSTRUCTION_LABEL || state === DRAFT_LABEL);

export const ivaCalculation = primaNeta => (parseFloat(primaNeta) * 19 / 100);

export const truncate = (value) => {
  const realValue = parseFloat(value || 0);
  return realValue === 0 ? value : parseFloat(realValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
};

export const primaBrutaCalculation = primaNeta => parseFloat(primaNeta) + ivaCalculation(primaNeta);

export const fileDownload = (url, loginIdToken, handleLoading=false) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.setRequestHeader('Authorization', `JWT ${loginIdToken}`);
  xhr.onload = function onload() {
    if (this.status === 200) {
      const content = xhr.getResponseHeader('Content-Disposition');
      let filename = '';
      if (content.includes('filename=')) {
        filename = content.split('filename=')[1].replace(/^"+|"+$/g, '');
      } else {
        filename = decodeURI(content.split('filename*=utf-8\'\'')[1].replace(/^"+|"+$/g, ''));
      }
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([this.response]));
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 1000);
    }
    if (handleLoading) {
      handleLoading();
    }
  };
  xhr.send();
};

export const undefinedToZero = (value) => value === undefined ? 0 : value;

export const pluralize = (text, number, suffix = 's') => number > 1 ? `${number} ${text}${suffix}` : `${number} ${text}`;

export const getSuggestedInicioVigencia = () => {
  const now = moment();
  const suggestedDate = now.clone().startOf('month');
  if (now.date() <= 15) {
    return suggestedDate
  }
  return suggestedDate.add(1, 'months')
};

export const UFFormatter = new Intl.NumberFormat(
  'es-CL',
  { style: 'decimal', minimumFractionDigits: 2 },
);


export const fdate = (date, format = FRONT_DF_1) => date ? moment(date).format(format) : '';

export const roundFloat = (value, decimals = 2) => {
  const format = '00.'.padEnd(decimals + 3, '0');
  const strValue = Numeral(value).format(format).replace(',', '.');
  return parseFloat(strValue);
};

export const areClose = (first, second) => roundFloat(first) === roundFloat(second);

export const asyncDisableStyle = (isDisable) => ({
  input: () => (isDisable ? { color: '#999999' } : { color: 'black' }),
});

export const emailValidate = email => {
  const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return regEx.test(email);
};

export const bytesToMb = (number) => (number / 1024 / 1024).toFixed(2);

export const mbToBytes = (number) => number * 1024 * 1024;