import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import FormSection from '../../../UI/FormSection';
import { TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID } from '../../../../CONST';

const IndemnizacionMaximaInnominadosSection = ({
  indemnizacionMaxInnominado,
  tipoIndemnizacionMaxId,
  clientesInnominados,
  dispatch,
  handleChange,
  style,
  validate,
  disabled,
}) => {
  const isDisabled =
    !clientesInnominados ||
    tipoIndemnizacionMaxId !==
      TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID ||
    disabled;
  return (
    <FormSection
      title="Indemnización máxima clientes innominados"
      style={style}
    >
      <div className="inline-text-with-input">
        <NumberFormat
          name="indemnizacionMaximaInnominados"
          className={`form-control${
            validate && !indemnizacionMaxInnominado && !isDisabled
              ? ' is-invalid'
              : ''
          }`}
          onValueChange={values => {
            const newValue = values.floatValue;
            dispatch(
              handleChange('endoso.indemnizacionMaxInnominado', newValue),
            );
          }}
          thousandSeparator="."
          decimalSeparator={false}
          allowNegative={false}
          value={indemnizacionMaxInnominado}
          disabled={isDisabled}
          style={{ width: '60px' }}
        />
        <span className="small-form-value">
          &nbsp;&nbsp; veces la prima devengada
        </span>
      </div>
    </FormSection>
  );
};

IndemnizacionMaximaInnominadosSection.propTypes = {
  indemnizacionMaxInnominado: PropTypes.number.isRequired,
  tipoIndemnizacionMaxId: PropTypes.number.isRequired,
  clientesInnominados: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

IndemnizacionMaximaInnominadosSection.defaultProps = {
  style: {},
  disabled: false,
};

export default IndemnizacionMaximaInnominadosSection;
