import React, { useState } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format';
import { Button, FormGroup, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import BaseTable from '../../../UI/BaseTable';
import { cellOperation, headerOperation } from '../../../../helpers/tables';
import {
  addSobreprima, editSobreprima,
  removeSobreprima,
  selectSobreprima,
} from '../../../../redux-store/actions/creditPolicyActions';


const TablaSobreprimasParameter = ({ condition, opcion, dispatch, disabled, validate }) => {
  const { values: { sobreprimas } } = opcion;
  const [ limiteSuperior, setLimiteSuperior ] = useState(null);
  const [ sobreprima, setSobreprima ] = useState(null);
  const [ editing, setEditing ] = useState(null);

  let error = '';
  if (validate) {
    if (!sobreprimas || sobreprimas.length === 0) {
      error = 'La tabla no puede estar vacía';
    } else {
      if (sobreprimas[0].limite_inferior !== opcion.values.porcentajeSiniestralidad
        && opcion.values.porcentajeSiniestralidad !== undefined)
      {
        error = `El primer límite inferior debe ser igual a ${opcion.values.porcentajeSiniestralidad},
         edite el porcentaje de siniestralidad o el primer ajuste de prima para corregir.`;
      }
      if (sobreprimas[sobreprimas.length - 1].limite_superior !== null) {
        error += `${error ? ' ' : ''}Falta la sobreprima para siniestralidad mayor a
         ${sobreprimas[sobreprimas.length - 1].limite_superior}%. Ingrese sobreprima sin indicar límite superior.`;
      }
    }
  }

  let invalidSobreprima = false;
  let sobreprimaMinima = 0;
  let sobreprimaMaxima = Infinity;
  if (sobreprima !== null) {
    // inside this block, null represents Infinity
    let idxSobreprimaMinima;
    let idxSobreprimaMaxima;
    const sobreprimasArray = sobreprimas || [];
    const sobreprimasReversed = sobreprimas ? sobreprimas.slice().reverse() : [];
    if (limiteSuperior !== null) {
      // look for the first sobreprima whose limite_superior is greater
      idxSobreprimaMaxima = sobreprimasArray.findIndex(
        sp => sp.limite_superior === null || sp.limite_superior > limiteSuperior);
      // look for the last sobreprima whose limite_superior is lower
      idxSobreprimaMinima = sobreprimasArray.length - 1 - sobreprimasReversed.findIndex(
        sp => sp.limite_superior !== null && sp.limite_superior < limiteSuperior);
    }
    else {
      // There can't be an interval after limite_superior = Infinity
      idxSobreprimaMaxima = -1;
      // look for the last sobreprima whose limite_superior is lower, i.e. it's not Infinity
      idxSobreprimaMinima = sobreprimasArray.length - 1 - sobreprimasReversed.findIndex(sp => sp.limite_superior !== null);
    }
    if (idxSobreprimaMinima > -1 && idxSobreprimaMinima < sobreprimasArray.length) {
      sobreprimaMinima = sobreprimasArray[idxSobreprimaMinima].sobreprima;
    }
    if (idxSobreprimaMaxima > -1) {
      sobreprimaMaxima = sobreprimasArray[idxSobreprimaMaxima].sobreprima;
    }
    invalidSobreprima = (sobreprimaMinima > sobreprima || sobreprimaMaxima < sobreprima)
  }

  const errorInvalidSobreprima = invalidSobreprima
  ? `La sobreprima de este intervalo debe ${
    sobreprimaMaxima === Infinity
    ? `ser al menos ${sobreprimaMinima}%`
    : `estar entre ${sobreprimaMinima}% y ${sobreprimaMaxima}%`
  }` : '';


  const columns = [
    {
      id: 'limiteInferior',
      Header: headerOperation('Límite inferior'),
      accessor: 'limite_inferior',
      Cell: row => cellOperation((
        <NumberFormat
          thousandSeparator="."
          decimalSeparator=","
          suffix="%"
          decimalScale="1"
          fixedDecimalScale
          value={row.original.limite_inferior}
          displayType="text"
        />
      )),
    },
    {
      id: 'limiteSuperior',
      Header: headerOperation('Límite superior'),
      accessor: 'limite_superior',
      Cell: row => cellOperation(row.original.limite_superior === null ? 'más' : (
        <NumberFormat
          thousandSeparator="."
          decimalSeparator=","
          suffix="%"
          decimalScale="1"
          fixedDecimalScale
          value={row.original.limite_superior}
          displayType="text"
        />
      )),
    },
    {
      id: 'sobreprimaPct',
      Header: headerOperation('% Sobreprima'),
      accessor: 'sobreprima',
      Cell: row => cellOperation((
        <NumberFormat
          thousandSeparator="."
          decimalSeparator=","
          suffix="%"
          decimalScale="2"
          fixedDecimalScale
          value={row.original.sobreprima}
          displayType="text"
        />
      )),
    },
    {
      id: 'acciones',
      Header: headerOperation('Acción'),
      Cell: row => cellOperation((
        <div className='text-center'>
          <button
            type='button'
            className='table-link'
            onClick={() => {
              dispatch(selectSobreprima(condition.id, opcion.id, row.index));
              setEditing(row.index);
              setLimiteSuperior(row.original.limite_superior);
              setSobreprima(row.original.sobreprima);
            }}
            title='editar'
            disabled={editing !== null}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            type="button"
            className="table-link policy"
            onClick={() => dispatch(
              removeSobreprima(condition.id, opcion.id, row.index))}
            title="eliminar"
            style={{ marginLeft: '10px' }}
            disabled={editing !== null}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </div>
      )),
    },
  ];

  return (
    <>
      <div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr 2fr 1fr', gridGap: '5px', marginBottom: '5px' }}>
        <div />
        <FormGroup style={{display: 'grid', alignItems: 'end'}}>
          <Label>Límite Superior</Label>
          <NumberFormat
            className="form-control"
            thousandSeparator="."
            decimalSeparator=","
            suffix="%"
            decimalScale="2"
            fixedDecimalScale
            allowNegative={false}
            placeholder="más"
            value={limiteSuperior}
            onValueChange={values => setLimiteSuperior(values.floatValue === undefined ? null : values.floatValue)}
            disabled={disabled || !opcion.values.porcentajeSiniestralidad}
          />
        </FormGroup>
        <FormGroup style={{display: 'grid', alignItems: 'end'}}>
          <Label>Sobreprima</Label>
          <NumberFormat
            className="form-control"
            thousandSeparator="."
            decimalSeparator=","
            suffix="%"
            decimalScale="2"
            fixedDecimalScale
            allowNegative={false}
            placeholder="% Sobreprima"
            value={sobreprima}
            onValueChange={values => setSobreprima(values.floatValue === undefined ? null : values.floatValue)}
            disabled={disabled || !opcion.values.porcentajeSiniestralidad}
          />
        </FormGroup>
        <FormGroup style={{display: 'grid', alignItems: 'end'}}>
          <Button
            type='button'
            color="orsan-secondary"
            className=""
            style={{ fontSize: '14px' }}
            disabled={disabled || !sobreprima || invalidSobreprima}
            onClick={() => {
              dispatch(editing !== null
                ? editSobreprima(condition.id, opcion.id, editing, limiteSuperior, sobreprima)
                : addSobreprima(condition.id, opcion.id, limiteSuperior, sobreprima));
              setLimiteSuperior(null);
              setSobreprima(null);
              setEditing(null);
            }}
          >
            { editing === null ? 'Agregar' : 'Guardar' }
          </Button>
        </FormGroup>
        <div />
      </div>
      {
        errorInvalidSobreprima && (
          <div className='cp-form-feedback' style={{marginTop: '0px'}}>
            <p>{errorInvalidSobreprima}</p>
          </div>
        )
      }
      {
        error && <div className='cp-form-feedback' style={{marginTop: '0px'}}><p>{error}</p></div>
      }
      <BaseTable
        columns={columns}
        data={sobreprimas}
        sortable={false}
        resizable={false}
        noBorders
      />
    </>
  )
};

TablaSobreprimasParameter.propTypes = {
  dispatch: PropTypes.func.isRequired,
  condition: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  opcion: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    texto: PropTypes.string,
    parametros: PropTypes.shape({
      sobreprimas: PropTypes.object,
      porcentageSiniestralidad: PropTypes.object,
    }),
    values: PropTypes.shape({
      porcentajeSiniestralidad: PropTypes.number,
      sobreprimas: PropTypes.arrayOf(
        PropTypes.shape({
          limite_inferior: PropTypes.number,
          limite_superior: PropTypes.number,
          sobreprima: PropTypes.number,
        })
      )
    }),
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  validate: PropTypes.bool.isRequired,
};

export default TablaSobreprimasParameter;
