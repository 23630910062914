import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Datetime from 'react-datetime';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import {
  FRONT_DF_2,
  FRONT_DF_1,
  BACKEND_DF,
} from '../../CONST';
import {
  cellNumOperation,
  cellOperation,
  headerOperation,
} from '../../helpers/tables';
import BaseTable from '../UI/BaseTable';
import FormSection from '../UI/FormSection';


const PlanPagoVentaEnVerde = ({
  edit,
  inicioVigencia,
  terminoVigencia,
  numCuotas,
  setNumCuotas,
  cuotasPlan,
  setCuotasPlan,
}) => {
  const [editCuotaNumber, setEditCuotaNumber] = useState(null);
  const [nextCuotaNumber, setNextCuotaNumber] = useState(1);
  const [lastFechaPago, setLastFechaPago] = useState(null);

  const [montoCuota, setMontoCuota] = useState(null);
  const [fechaPago, setFechaPago] = useState(null);

  const [cuotasPlan1, setCuotasPlan1] = useState(Array.from({length: 12}, (x, i) => i).map(i => ({ secuencia: i + 1, monto_uf: null, fecha_pago: null }), {}));
  const [cuotasPlan2, setCuotasPlan2] = useState(Array.from({length: 12}, (x, i) => i).map(i => ({ secuencia: i + 13, monto_uf: null, fecha_pago: null }), {}));
  const [cuotasPlan3, setCuotasPlan3] = useState(Array.from({length: 12}, (x, i) => i).map(i => ({ secuencia: i + 25, monto_uf: null, fecha_pago: null }), {}));

  // Parsea cuotas plan del backend al frontend.
  const parseCuotasPlan = (cuotasPlanArray, cuotasPlanNArray) => {
    return cuotasPlanNArray.map((elem, index) => {
      if (index < cuotasPlanArray.length) {
        return ({...cuotasPlanArray[index], fecha_pago: moment(cuotasPlanArray[index].fecha_pago, BACKEND_DF)})
      }
      return elem
    });
  }

  useEffect(() => {
    // Si se está editando el endoso, actualiza las cuotas en este componente.
    if (edit) {
      if (cuotasPlan.length <= 12) {
        setCuotasPlan1(parseCuotasPlan(cuotasPlan.slice(0, cuotasPlan.length), cuotasPlan1));
      }
      else if (cuotasPlan.length <= 24) {
        setCuotasPlan1(parseCuotasPlan(cuotasPlan.slice(0, 12), cuotasPlan1));
        setCuotasPlan2(parseCuotasPlan(cuotasPlan.slice(12, cuotasPlan.length), cuotasPlan2));
      }
      else {
        setCuotasPlan1(parseCuotasPlan(cuotasPlan.slice(0, 12), cuotasPlan1));
        setCuotasPlan2(parseCuotasPlan(cuotasPlan.slice(12, 24), cuotasPlan2));
        setCuotasPlan3(parseCuotasPlan(cuotasPlan.slice(24, cuotasPlan.length), cuotasPlan3));
      }
      setNextCuotaNumber(cuotasPlan.length + 1);
      setLastFechaPago(moment(cuotasPlan[cuotasPlan.length - 1].fecha_pago, BACKEND_DF));
    }
  }, [edit]);

  useEffect(() => {
    // Si se modifica el inicio de vigencia y aún no se ha agregado la primera cuota,
    // se modifica la fecha de pago por default que se muestra.
    if (!edit && cuotasPlan.length === 0) {
      setFechaPago(moment(inicioVigencia));
    }
  }, [inicioVigencia]);

  const updateCuotasPlan = (numCuotasParam) => {
    let cuotasPlanToReturn = []
    if (numCuotasParam <= 12) {
      cuotasPlanToReturn = cuotasPlan1.slice(0, numCuotasParam);
    }
    else if (numCuotasParam <= 24) {
      cuotasPlanToReturn = cuotasPlan1.concat(cuotasPlan2.slice(0, numCuotasParam - 12));
    }
    else { // numCuotasParam === 36
      cuotasPlanToReturn = cuotasPlan1.concat(cuotasPlan2).concat(cuotasPlan3.slice(0, numCuotasParam - 24));
    }

    // Limpiar nulos.
    cuotasPlanToReturn = cuotasPlanToReturn.filter(obj => (obj.fecha_pago != null && obj.monto_uf != null));
    // Modificar formato fechas.
    cuotasPlanToReturn = cuotasPlanToReturn.map(i =>  ({...i, fecha_pago: moment(i.fecha_pago, FRONT_DF_1).format(BACKEND_DF)}));

    // Actualiza cuotasPlan en componente padre (EndosoForm.jsx).
    setCuotasPlan(cuotasPlanToReturn);
  }

  const handleAddPlanPagoCuota = () => {
    // Si se está agregando una nueva cuota.
    if (!editCuotaNumber) {
      const cuota = {
        secuencia: nextCuotaNumber,
        monto_uf: montoCuota,
        fecha_pago: moment(fechaPago, FRONT_DF_1),
      }

      if (!lastFechaPago || moment(fechaPago, FRONT_DF_1).isSameOrAfter(moment(lastFechaPago, FRONT_DF_1), 'date')) {
        setLastFechaPago(moment(fechaPago, FRONT_DF_1));
      }

      if (nextCuotaNumber <= 12) {
        cuotasPlan1[nextCuotaNumber - 1] = cuota;
      }
      else if (nextCuotaNumber <= 24 ) {
        cuotasPlan2[nextCuotaNumber - 13] = cuota;
      }
      else { // <= 36
        cuotasPlan3[nextCuotaNumber - 25] = cuota;
      }

      setNextCuotaNumber(nextCuotaNumber + 1);
    }
    // Si se está editando una cuota desde el botón 'editar'.
    else {
      const cuota = {
        secuencia: editCuotaNumber,
        monto_uf: montoCuota,
        fecha_pago: moment(fechaPago, FRONT_DF_1),
      }

      if (editCuotaNumber >= nextCuotaNumber - 1 || !lastFechaPago ||
        moment(fechaPago, FRONT_DF_1).isSameOrAfter(moment(lastFechaPago, FRONT_DF_1), 'date')
      ) {
        setLastFechaPago(moment(fechaPago, FRONT_DF_1));
      }

      if (editCuotaNumber <= 12) {
        cuotasPlan1[editCuotaNumber - 1] = cuota;
      }
      else if (editCuotaNumber <= 24) {
        cuotasPlan2[editCuotaNumber - 13] = cuota;
      }
      else { // <= 36
        cuotasPlan3[editCuotaNumber - 25] = cuota;
      }

      setEditCuotaNumber(null);
    }

    updateCuotasPlan(numCuotas);
    setMontoCuota(null);
    setFechaPago(null);
  }

  const handleNumCuotasChange = (value) => {
    updateCuotasPlan(value);
    setNumCuotas(value);
  }

  const editCuotaCell = (cuota) => {
    setMontoCuota(cuota.monto_uf);
    setFechaPago(cuota.fecha_pago);
    setEditCuotaNumber(cuota.secuencia);
  }

  const getPrevEditDate = () => {
    const allCuotas = cuotasPlan1.concat(cuotasPlan2).concat(cuotasPlan3);

    // Se obtiene solamente el tramo inferior al arreglo de cuotas.
    const reducedCuotas = allCuotas.slice(0, editCuotaNumber - 1)
    // Se busca la primera cuota previa con fecha de pago distinto de null.
    const lastCuota = reducedCuotas.reverse().find(cuota => cuota.fecha_pago !== null);

    if (lastCuota) {
      return lastCuota.fecha_pago;
    }
    return null;
  }

  const getNextEditDate = () => {
    const allCuotas = cuotasPlan1.concat(cuotasPlan2).concat(cuotasPlan3);

    // Se obtiene solamente el tramo superior al arreglo de cuotas.
    const reducedCuotas = allCuotas.slice(editCuotaNumber, allCuotas.length)
    // Se busca la primera cuota siguiente con fecha de pago distinto de null.
    const firstCuota = reducedCuotas.find(cuota => cuota.fecha_pago !== null);

    if (firstCuota) {
      return firstCuota.fecha_pago;
    }
    return null;
  }

  const isValidDate = (date) => {
    // Si se está editando una cuota.
    if (editCuotaNumber) {
      if (editCuotaNumber === 1) { // Cuando es la cuota 1.
        return (
          moment(date, FRONT_DF_1).isSameOrAfter(moment(inicioVigencia, FRONT_DF_1), 'date') &&
          moment(date, FRONT_DF_1).isSameOrBefore(moment(inicioVigencia, FRONT_DF_1), 'date')
        )
      }

      const prevDate = getPrevEditDate();
      const nextDate = getNextEditDate();

      if (prevDate && nextDate) {
        return (
          moment(date, FRONT_DF_1).isSameOrAfter(moment(prevDate, FRONT_DF_1), 'date') &&
          moment(date, FRONT_DF_1).isSameOrBefore(moment(nextDate, FRONT_DF_1), 'date')
        )
      }
      if (prevDate) {
        return (
          moment(date, FRONT_DF_1).isSameOrAfter(moment(prevDate, FRONT_DF_1), 'date') &&
          moment(date, FRONT_DF_1).isSameOrBefore(moment(terminoVigencia, FRONT_DF_1), 'date')
        )
      }
    }

    // Cuando se está agregando la primera cuota.
    if (nextCuotaNumber === 1) {
      return (
        moment(date, FRONT_DF_1).isSameOrAfter(moment(inicioVigencia, FRONT_DF_1), 'date') &&
        moment(date, FRONT_DF_1).isSameOrBefore(moment(inicioVigencia, FRONT_DF_1), 'date')
      )
    }

    // Cuando se agregan todas las otras cuotas.
    return (
      moment(date, FRONT_DF_1).isSameOrAfter(moment(lastFechaPago, FRONT_DF_1), 'date') &&
      moment(date, FRONT_DF_1).isSameOrBefore(moment(terminoVigencia, FRONT_DF_1), 'date')
    )
  }

  // Colorea las filas fuera del rango de cuotas como deshabilitadas.
  const getDisabledRows = (rowInfo) => {
    const trProps = {};
    if (rowInfo && rowInfo.row) {
      // eslint-disable-next-line no-underscore-dangle
      if (numCuotas && rowInfo.row._original.secuencia > numCuotas) {
        trProps.backgroundColor = '#e6e6e6';
      }
      else if (!numCuotas) {
        trProps.backgroundColor = '#e6e6e6';
      }
    }
    return { style: trProps };
  }

  const columns = [
    {
      id: 'cuota',
      Header: headerOperation('#'),
      accessor: data => cellOperation(data.secuencia, 'center'),
      minWidth: 30,
    },
    {
      id: 'fechaPago',
      Header: headerOperation('Fecha Pago'),
      accessor: data => cellOperation(data.fecha_pago && data.fecha_pago.format(FRONT_DF_2) || '-'),
      minWidth: 95,
    },
    {
      id: 'monto',
      Header: headerOperation('Monto UF'),
      accessor: data => cellNumOperation(data.monto_uf || '-', 'center'),
      minWidth: 85,
    },
    {
      Header: headerOperation(''),
      id: 'acciones',
      fixed: 'right',
      Cell: ({ original }) => (
        cellOperation(
          <div style={{ textAlign: 'center' }} title="Editar">
            {original.fecha_pago && original.secuencia <= numCuotas && (
              <Button
                className="table-link"
                id="editar"
                onClick={() => editCuotaCell(original)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
          </div>
        )
      ),
      minWidth: 35,
    },
  ];

  return (
    <>
      <FormSection
        style={{ paddingTop: '20px' }}
        title='Plan de Pago'
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 2fr 1fr',
            gridGap: '10px',
          }}
        >
          <FormGroup>
            <Label>Número Cuotas</Label>
            <NumberFormat
              id="num-cuotas"
              name="numCuotas"
              className="form-control"
              allowNegative={false}
              decimalSeparator={false}
              value={numCuotas}
              onValueChange={values => handleNumCuotasChange(values.floatValue)}
              isAllowed={({floatValue}) => (floatValue >= 1 && floatValue <= 36) || floatValue === undefined}
            />
          </FormGroup>
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '2fr 2fr 1fr',
            gridGap: '10px',
          }}
        >
          <FormGroup>
            <Label>Fecha de Pago</Label>
            <Datetime
              closeOnSelect
              dateFormat={FRONT_DF_2}
              timeFormat={false}
              viewDate={lastFechaPago}
              id="fecha-de-pago"
              locale="es"
              value={fechaPago}
              onChange={setFechaPago}
              inputProps={{ disabled: !inicioVigencia }}
              isValidDate={date => isValidDate(date)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Monto (UF)</Label>
            <NumberFormat
              id="ce-monto-cuota"
              name="montoCuota"
              className="form-control"
              allowNegative={false}
              thousandSeparator="."
              decimalSeparator=","
              fixedDecimalScale
              value={montoCuota}
              onValueChange={values => setMontoCuota(values.floatValue)}
            />
          </FormGroup>
          <FormGroup style={{ display: 'grid', alignItems: 'end' }}>
            <Button
              type="button"
              className=""
              color="orsan-secondary"
              style={{ fontSize: '14px', marginLeft: '10px' }}
              disabled={
                !numCuotas ||
                !montoCuota ||
                !moment(fechaPago, FRONT_DF_1, true).isValid() ||
                (!editCuotaNumber && moment(fechaPago, FRONT_DF_1).isBefore(moment(lastFechaPago, FRONT_DF_1), 'date')) ||
                (!editCuotaNumber && nextCuotaNumber > numCuotas)
              }
              onClick={handleAddPlanPagoCuota}
            >
              {!editCuotaNumber ? 'Agregar' : 'Editar'}
              {!editCuotaNumber ? nextCuotaNumber <= numCuotas && ` (#${nextCuotaNumber})` : ` (#${editCuotaNumber})`}
            </Button>
          </FormGroup>
        </div>

        <div style={{ display: 'flex', paddingTop: '10px' }}>
          <BaseTable
            numCuotasVentaEnVerde={numCuotas}
            columns={columns}
            data={cuotasPlan1}
            sortable={false}
            resizable={false}
            shrink
            style={{ flex: '33%' }}
            getTrProps={(state, rowInfo) => getDisabledRows(rowInfo)}
          />
          <BaseTable
            numCuotasVentaEnVerde={numCuotas}
            columns={columns}
            data={cuotasPlan2}
            sortable={false}
            resizable={false}
            shrink
            style={{ flex: '33%' }}
            getTrProps={(state, rowInfo) => getDisabledRows(rowInfo)}
          />
          <BaseTable
            numCuotasVentaEnVerde={numCuotas}
            columns={columns}
            data={cuotasPlan3}
            sortable={false}
            resizable={false}
            shrink
            style={{ flex: '33%' }}
            getTrProps={(state, rowInfo) => getDisabledRows(rowInfo)}
          />
        </div>
      </FormSection>
    </>
  );
};

PlanPagoVentaEnVerde.propTypes = {
  edit: PropTypes.bool.isRequired,
  inicioVigencia: PropTypes.instanceOf(moment).isRequired,
  terminoVigencia: PropTypes.instanceOf(moment).isRequired,
  numCuotas: PropTypes.number,
  setNumCuotas: PropTypes.func.isRequired,
  cuotasPlan: PropTypes.arrayOf(
    PropTypes.shape({
      secuencia: PropTypes.number,
      monto_uf: PropTypes.number,
      fecha_pago: PropTypes.string,
    })
  ).isRequired,
  setCuotasPlan: PropTypes.func.isRequired,
};

PlanPagoVentaEnVerde.defaultProps = {
  numCuotas: null,
};


export default PlanPagoVentaEnVerde;
