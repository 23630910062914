import React from 'react';
import PropTypes from 'prop-types';

const BoolParameter = ({spec, val, onChange, disabled}) => (
  <div style={{
    marginTop: '10px',
  }}
  >
    <label htmlFor={spec.label}>
      <input
        id={spec.label}
        type='checkbox'
        checked={val}
        onChange={() => onChange(!val)}
        style={{marginRight: '10px'}}
        disabled={disabled}
      />
      {spec.label}
    </label>
  </div>
);

BoolParameter.propTypes = {
  spec: PropTypes.shape({
    label: PropTypes.string,
    unit: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  val: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

BoolParameter.defaultProps = {
  val: false,
};

export default BoolParameter;
