export const BASE_URL = process.env.REACT_APP_API;
export const BASE_OAUTH = process.env.REACT_APP_OAUTH;
export const CLIENT_ID = process.env.REACT_APP_CLIENT;
export const LOCAL_URL = process.env.REACT_APP_LOCAL;
export const USERS_URL = process.env.REACT_APP_USERS;
export const DOCS_URL = process.env.REACT_APP_DOCS;
export const ACCOUNTANT_URL = process.env.REACT_APP_ACCOUNTANT;
export const REASEGURO_URL = process.env.REACT_APP_REASEGURO;
export const RISK_URL = process.env.REACT_APP_RISK;
export const WORKFLOW_URL = process.env.REACT_APP_WORKFLOW;
export const ANALYST_URL = process.env.REACT_APP_ANALYST;
export const SEARCH_DELAY_MS = process.env.REACT_APP_SEARCH_DELAY_MS;
export const ORSAN_LOGO_URL = "https://orsan-static.s3.amazonaws.com/public/logotipo_orsan_seguros.png";

export const DEFAULT_PAGE_SIZE = 20;
export const calculatePages = (rows) => Math.ceil(rows / DEFAULT_PAGE_SIZE);

export const API = {
  alias: 'alias/',
  actividades: 'actividades/',
  actividad: 'actividad_economica',
  endSession: 'end-session',
  garantias: 'garantias',
  endosos: 'endosos_garantia',
  endosos_credito: 'endosos_credito',
  intermediario: 'tipos_intermediario',
  login: 'authorize',
  maestras: 'maestras',
  productos: 'productos/',
  tipos_cobertura: 'tipos_cobertura/',
  ramos_fecu: 'ramos_fecu/',
  gasto_adquisicion: 'gasto_adquisicion/',
  tipos_institucion: 'tipos_institucion/',
  tipologias_ejecucion: 'tipologia_ejecucion/',
  upload: 'upload',
  download: 'download',
  monedas: 'monedas/',
  clientes: 'clientes',
  userinfo: 'userinfo',
  tipos_endoso_garantia: 'tipos_endoso_garantia/',
  balance: 'balance_garantia',
  creditos: 'creditos',
  endoso_calculated_fields: 'endoso_calculated_fields',
  plazos_credito: 'plazos_credito',
  condiciones_particulares: 'condiciones_particulares',
  porcentajes_cobertura_credito: 'porcentajes_cobertura_credito',
  tipos_vigencia: 'tipos_vigencia',
  condiciones_parametrizadas: 'condiciones_parametrizadas',
  oportunidades_garantia: 'oportunidad_garantia',
  estados_oportunidad: 'estado_oportunidad_garantia',
  info_oportunidad: 'info_oportunidad_garantia',
  antecedentes_oportunidad: 'antecedentes_oportunidad_garantia',
  responsables_oportunidad: 'responsables_oportunidad',
  suscriptores: 'suscriptores',
  observaciones: 'observacion_oportunidad_garantia',
  motivos_rechazo_oportunidad: 'motivo_rechazo_oportunidad',
  motivos_desistimiento_oportunidad: 'motivo_desistimiento_oportunidad',
  proyectos: 'proyecto_garantia/',
  estado_proyecto: 'estado_proyecto/',
  polizaProyecto: 'poliza_proyecto/',
  lineasGarantia: 'lineas_garantia/',
  movimientos: 'movimientos_linea/',
  regiones: 'regiones/',
  comunas: 'comunas/',
  tieneMovimientos: 'tiene_movimientos_linea',
  facturacion: 'tipo_facturacion/',
  tipoCobranza: 'tipo_cobranza/',
  formaPoliza: 'forma_poliza/',
  tipoIntermediario: 'tipos_intermediario/',
  actividadEconomica: 'actividad_economica/',
  tipoInstitucion: 'tipos_institucion',
  unidadAsegurada: 'tipo_unidad/',
  estadoPolizaProyecto: 'estado_poliza_proyecto/',
  organizationTypes: 'organization_type/',
  roles: 'roles/',
  organizaciones: 'organizaciones/',
  download_prod_vverde: 'download_libro_prod_vverde/',
};

export const PROFILES = {
  ceo: 'ceo',
  consultaBasico: 'consulta-basico',
  comercialGarantia: 'comercial-garantia',
  suscripcionGarantia: 'suscripcion-garantia',
  gerenteRiesgoGarantia: 'gerente-riesgo-garantia',
  analistaRiesgoGarantia: 'analista-riesgo-garantia',
  comercialCredito: 'comercial-credito',
  riesgoCredito: 'riesgo-credito',
  fiscalia: 'fiscalia',
  actuariado: 'actuariado',
  gerenteOperaciones: 'gerente-operaciones',
  analistaOperaciones: 'analista-operaciones',
  riesgoOperacional: 'riesgo-operacional',
  siniestros: 'siniestros',
  recuperoSiniestros: 'recupero-siniestros',
  administrador: 'administrador',
  consultaEspecial: 'consulta-especial',
  gerenteGarantia: 'gerente-garantia',
  externoOrganizacion: 'externo-organizacion',
  externoProyecto: 'externo-proyecto',
};

export const ROLES = {
  intermediarioGarantia: 'intermediariogarantia',
  afianzadoVentaVerde: 'afianzadoventaenverde',
};

export const ESTADO_PROYECTO = {
  activo: 'activo',
  caducado: 'caducado',
  borrador: 'borrador',
};

export const ESTADO_POLIZA = {
  borrador: 'borrador',
  construccion: 'construccion',
  emitida: 'emitida',
  cancelada: 'cancelada',
};

// API constants
export const TIPO_INTERMEDIARIO_DIRECTO_ID = 1;
export const CREDITO_RAMO_FECU_FILTER = 'credit_only';

export const TIPO_PLAN_CUOTAS_ID = 2;
export const TIPO_PLAN_LIBRO_VENTAS_ID = 3;
export const TIPO_PLAN_PLAN_PAGOS_ID = 4;

export const TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID = 1;
export const TIPO_INDEMNIZACION_MAXIMA_MONTO_UF = 2;

export const TIPO_COSTO_AVISO_IMPAGO_SINIESTRO_ID = 1;
export const TIPO_COSTO_AVISO_IMPAGO_MONTO_SINIESTRO_ID = 2;

export const TIPO_GESTIONES_PROPIAS_EMISION_ID = 1;
export const TIPO_GESTIONES_PROPIAS_VENCIMIENTO_ID = 2;

export const ID_RAMOFECU_GARANTIA = 1;
export const ID_RAMOFECU_CREDITO = 2;
export const ID_RAMOFECU_CREDITO_EXPORTACION = 3;

export const TIPO_ENDOSO_EMISION_ID = 1;
export const TIPO_ENDOSO_RENOVACION_ID = 2;
export const TIPO_ENDOSO_MODIFICACION_ID = 3;

export const CONDICIONADO_GENERAL_VENTA_EN_VERDE = 'pol120131810';

// nemotecnicos TipoEndosoGarantia
export const ENDOSO_EMISION = 'tp-eg-emision';
export const ENDOSO_PRORROGA = 'tp-eg-prorroga';
export const ENDOSO_CANCELACION = 'tp-eg-cancelacion';
export const ENDOSO_REINSTALACION = 'tp-eg-reinstalacion';
export const ENDOSO_CAMBIO_SUMA = 'tp-eg-cambio-suma';
export const ENDOSO_CAMBIO_COMISION = 'tp-eg-cambio-comision';
export const ENDOSO_CAMBIO_GLOSA = 'tp-eg-cambio-glosa';
export const ENDOSO_CAMBIO_BENEFICIARIO = 'tp-eg-cambio-beneficiario';
export const ENDOSO_CAMBIO_INTERMEDIARIO = 'tp-eg-cambio-intermediario';
export const ENDOSO_ANULACION = 'tp-eg-anulacion';
export const ENDOSO_REDUCCION = 'tp-eg-reduccion';

// nemotecnicos Roles Usuario
export const ROL_ASEGURADO_GARANTIA = 'aseguradogarantia';
export const ROL_INTERMEDIARIO_GARANTIA = 'intermediariogarantia';
export const ROL_AFIANZADO_GARANTIA = 'afianzadogarantia';
export const ROL_BENEFICIARIO_GARANTIA = 'beneficiariogarantia';
export const ROL_ASEGURADO_CREDITO = 'aseguradocredito';
export const ROL_DEUDOR_CREDITO = 'deudorcredito';
export const ROL_INTERMEDIARIO_CREDITO = 'intermediariocredito';
export const ROL_BENEFICIARIO_CREDITO = 'beneficiariocredito';
export const ROL_FILIAL_CREDITO = 'filialcredito';
export const ROL_BENEFICIARIO_VENTA_VERDE = 'beneficiarioventaenverde';

// nemotecnicos Perfiles Usuario
export const PERFIL_CEO = 'ceo';
export const PERFIL_CONSULTA = 'consulta';
export const PERFIL_COMERCIAL_GARANTIA = 'comercial-garantia';
export const PERFIL_SUBSCRIPCION_GARANTIA = 'subscripcion-garantia';
export const PERFIL_RIESGO_GARANTIA = 'analista-riesgo-garantia';
export const PERFIL_COMERCIAL_CREDITO = 'comercial-credito';
export const PERFIL_RIESGO_CREDITO = 'riesgo-credito';
export const PERFIL_FISCALIA = 'fiscalia';
export const PERFIL_ACTUARIADO = 'actuariado';
export const PERFIL_GERENTE_OPERACIONES = 'gerente-operaciones';
export const PERFIL_ANALISTA_OPERACIONES = 'analista-operaciones';
export const PERFIL_RIESGO_OPERACIONAL = 'riesgo-operacional';
export const PERFIL_SINIESTROS = 'siniestros';
export const PERFIL_RECUPERO_SINIESTROS = 'recupero-siniestros';
export const PERFIL_ADMINISTRADOR = 'administrador';

export const BACKEND_DF = 'YYYY-MM-DD';
export const FRONT_DF_1 = 'DD-MM-YYYY';
export const FRONT_DF_2 = 'DD-MMM-YYYY';

export const IVA_PCT = 0.19;

// Credit Policy Form Section names
export const CP_FS_BASICOS = 'basicos';
export const CP_FS_DETALLES = 'detalles';
export const CP_FS_CLIENTES = 'clientes';
export const CP_FS_CONDICIONES = 'condiciones';
export const CP_FS_CLAUSULAS = 'clausulas';

export const CONSTRUCTION_LABEL = 'Construcción';
export const DRAFT_LABEL = 'Borrador';
export const EMITTED_LABEL = 'Emitida';
export const CANCELED_LABEL = 'Cancelada';

export const TIPOS_COBERTURA_SIN_BENEFICIARIO = ['tp-cb-corredores-seguros'];

export const TIPO_PROVISION_MENSUAL_ID = 1;
export const TIPO_PROVISION_CUOTAS_FORMA_PAGO_ID = 2;

export const FORMA_PAGO_MAX_CUOTAS = 12;
export const PLAN_PAGO_MAX_CUOTAS_ANUALES = 6;

export const ESTADOS_OPORTUNIDAD = {
  creado: "creado",
  responsableAsignado: "responsable-asignado",
  suscriptorAsignado: "suscriptor-asignado",
  informacionInicialCargada: "informacion-inicial-cargada",
  informacionInicialValidada: "informacion-inicial-validada",
  informacionCompletadaEsperandoSuscriptor: "informacion-completada-esperando-suscriptor",
  informacionCompletadaEsperandoRiesgo: "informacion-completada-esperando-riesgo",
  informacionCompletadaEsperandoComite: "informacion-completada-esperando-comite",
  evaluacionAprobada: "evaluacion-aprobada",
  rechazado: "rechazado",
  parametrosIngresados: "parametros-comerciales-ingresados",
  borradorGenerado: "borrador-generado",
  borradorEnviado: "borrador-enviado",
  desistido: "desistido",
  borradorAceptado: "borrador-aceptado",
  requisitosEmisionValidados: "requisitos-emision-validados",
  emitida: "emitida",
}

export const OPORTUNIDADES_STATE_SECTION_MAP = {
  "creado": 1,
  "responsable-asignado": 2,
  "suscriptor-asignado": 3,
  "informacion-inicial-cargada": 4,
  "informacion-inicial-validada": 5,
  "informacion-completada-esperando-suscriptor": 6,
  "informacion-completada-esperando-riesgo": 7,
  "informacion-completada-esperando-comite": 8,
  "evaluacion-aprobada": 9,
  "rechazado": 10,
  "parametros-comerciales-ingresados": 11,
  "borrador-generado": 12,
  "borrador-enviado": 13,
  "desistido": 14,
  "borrador-aceptado": 15,
  "requisitos-emision-validados": 16,
  "emitida": 17,
}