/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React from 'react';
import { Col, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import FormSection from '../UI/FormSection';
import FileTable from '../shared/FileTable';
import {
  API,
  BASE_URL,
  FRONT_DF_2,
  TIPO_PLAN_CUOTAS_ID,
  TIPO_PLAN_LIBRO_VENTAS_ID,
  TIPO_PLAN_PLAN_PAGOS_ID,
  TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID,
  TIPO_COSTO_AVISO_IMPAGO_SINIESTRO_ID,
  TIPO_GESTIONES_PROPIAS_EMISION_ID,
} from '../../CONST';
import {
  addAdjunto,
  deleteAdjunto,
  deleteAdjuntoNuevo,
  attachFiles,
} from '../../redux-store/actions/creditPolicyActions';
import { fdate, pluralize, roundFloat } from '../../helpers/helpers';
import { CREDIT_POLICY_WRITE } from '../../helpers/profilePermission';
import isUserHasProfile from '../../helpers/profileValidator';

const CreditPolicyDetailBasicInfo = ({
  policy,
  loginIdToken,
  loading,
  dispatchAddAdjunto,
  dispatchDeleteAdjunto,
  dispatchDeleteAdjuntoNuevo,
  dispatchSaveFiles,
}) => {
  const {
    base,
    base: { poliza_maestra: polizaMaestra },
    balance,
  } = policy;

  const note = '*Los datos mostrados corresponden a valores de la póliza vigente al día de hoy.';

  if (!balance) {
    return null;
  }
  const {
    condiciones_parametrizadas: condicionesParametrizadas,
    condiciones_particulares: condicionesParticulares,
  } = balance;

  return (
    <Col md={{ offset: 2, size: 8 }}>
      <FormSection
        title={`Información básica - ${base.nemotecnico} (Vigencia: #${balance.numero_vigencia} Endoso: #${balance.secuencia_endoso})`}
        style={{ marginTop: '70px' }}
      >
        <p className="small-text">{note}</p>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridGap: '20px',
          }}
        >
          <div>
            <label htmlFor="ramo-fecu">Ramo FECU</label>
            <p id="ramo-fecu" className="small-form-value">
              {polizaMaestra.ramo_asegurado.descripcion}
            </p>
          </div>
          <div>
            <label htmlFor="moneda">Moneda</label>
            <p id="moneda" className="small-form-value">
              {base.moneda_asegurada.descripcion}
            </p>
          </div>
          <div>
            <label htmlFor="condicionado-general">Condicionado general</label>
            <p id="condicionado-general" className="small-form-value">
              {polizaMaestra.nemotecnico}
            </p>
          </div>
          <div>
            <label htmlFor="producto">Producto</label>
            <p id="producto" className="small-form-value">
              {base.producto.descripcion}
            </p>
          </div>
          <div>
            <label htmlFor="tipo-cobertura">Tipo de cobertura</label>
            <p id="tipo-cobertura" className="small-form-value">
              {base.tipo_cobertura.descripcion}
            </p>
          </div>
          <div>
            <label htmlFor="tipologia-ejecucion">Tipología de ejecución</label>
            <p id="tipologia-ejecucion" className="small-form-value">
              {base.tipologia_ejecucion.descripcion}
            </p>
          </div>
          <div>
            <label htmlFor="tipo-intermediario">Tipo de intermediario</label>
            <p id="tipo-intermediario" className="small-form-value">
              {base.tipo_intermediario.descripcion}
            </p>
          </div>
          <div>
            <label htmlFor="intermediario">Intermediario</label>
            <p id="intermediario" className="small-form-value">
              {balance.intermediario
                ? `${balance.intermediario.rut} - ${balance.intermediario.nombre}`
                : ''}
            </p>
          </div>
          <div>
            <label htmlFor="asegurado">Asegurado</label>
            <p id="asegurado" className="small-form-value">
              {`${base.asegurado.rut} - ${base.asegurado.nombre}`}
            </p>
          </div>
          <div>
            <label htmlFor="alias-asegurado">Alias asegurado</label>
            <p id="alias-asegurado" className="small-form-value">
              {base.asegurado_alias ? base.asegurado_alias.alias : ''}
            </p>
          </div>
          <div style={{ gridColumn: '3 / 5', gridRow: '3 / 5' }}>
            <label htmlFor="materia-asegurada">Materia asegurada</label>
            <p id="materia-asegurada" className="small-form-value">
              {balance.glosa}
            </p>
          </div>
          <FormGroup style={{ gridColumn: '1/3' }}>
            <label htmlFor="document">Adjuntos</label>
            <FileTable
              fileList={policy.adjuntos}
              newFiles={policy.adjuntos_uuids}
              handleAdd={dispatchAddAdjunto}
              handleDelete={dispatchDeleteAdjunto}
              handleDeleteNew={dispatchDeleteAdjuntoNuevo}
              uploadUrl={`${BASE_URL}${API.upload}/`}
              downloadUrl={`${BASE_URL}${API.download}/`}
              loginIdToken={loginIdToken}
              showSave={isUserHasProfile(CREDIT_POLICY_WRITE)}
              handleSave={() => dispatchSaveFiles(policy)}
              loading={loading}
              validProfiles={CREDIT_POLICY_WRITE}
            />
          </FormGroup>
        </div>
      </FormSection>
      <FormSection title="Detalles" style={{ marginTop: '20px' }}>
        <p className="small-text">{note}</p>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridGap: '20px',
          }}
        >
          <div>
            <label htmlFor="fecha-emision">Fecha de emisión</label>
            <p id="fecha-emision" className="small-form-value">
              {fdate(balance.fecha_emision, FRONT_DF_2)}
            </p>
          </div>
          <div>
            <label htmlFor="inicio-vigencia-endoso">Inicio vigencia endoso</label>
            <p id="inicio-vigencia-endoso" className="small-form-value">
              {fdate(balance.inicio_vigencia_endoso || balance.inicio_vigencia, FRONT_DF_2)}
            </p>
          </div>
          <div>
            <label htmlFor="inicio-vigencia">Inicio vigencia póliza</label>
            <p id="inicio-vigencia" className="small-form-value">
              {fdate(balance.inicio_vigencia, FRONT_DF_2)}
            </p>
          </div>
          <div>
            <label htmlFor="fin-vigencia">Fin de vigencia</label>
            <p id="fin-vigencia" className="small-form-value">
              {fdate(balance.termino_vigencia, FRONT_DF_2)}
            </p>
          </div>
          <div>
            <label htmlFor="estimacion-ventas">Estimación de ventas</label>
            <NumberFormat
              id="estimacion-ventas"
              name="estimacionVentasAmt"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              suffix={` ${base.moneda_asegurada.nombre_corto}`}
              value={balance.estimacion_ventas_amt}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
          <div>
            <label htmlFor="tasa-costo-seguro-defecto">Tasa costo seguro</label>
            <NumberFormat
              id="tasa-costo-seguro-defecto"
              name="tasaCostoSeguroDefectoPct"
              decimalSeparator=","
              decimalScale="3"
              fixedDecimalScale
              suffix="%"
              value={balance.tasa_costo_seguro_defecto_pct}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
          <div>
            <label htmlFor="prima-minima-anual">Prima mínima anual</label>
            <NumberFormat
              id="prima-minima-anual"
              name="primaMinimaAnualAmt"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              suffix={` ${base.moneda_asegurada.nombre_corto}`}
              value={roundFloat(balance.prima_minima_anual_amt)}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
          <div>
            <label htmlFor="comision">Comisión</label>
            <NumberFormat
              id="comision"
              name="tasaCostoSeguroDefectoPct"
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              suffix="%"
              value={balance.comision_pct}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
          <div>
            <label htmlFor="plazo-maximo-credito">Plazo máximo de crédito</label>
            <NumberFormat
              id="plazo-maximo-credito"
              name="plazoMaximoCredito"
              suffix=" días"
              value={balance.plazo_maximo_credito_dias}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
          <div>
            <label htmlFor="indemnizacion-maxima">Indemnización máxima</label>
            <NumberFormat
              id="indemnizacion-maxima"
              name="indemnizacionMaxima"
              thousandSeparator="."
              decimalSeparator={
                balance.tipo_indemnizacion_max === TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID
                  ? ''
                  : ','
              }
              decimalScale={
                balance.tipo_indemnizacion_max === TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID
                  ? '0'
                  : '2'
              }
              fixedDecimalScale
              suffix={
                balance.tipo_indemnizacion_max === TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID
                  ? ' veces la prima devengada'
                  : ` ${base.moneda_asegurada.nombre_corto}`
              }
              value={balance.indemnizacion_max_amt}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
          <div>
            <label htmlFor="forma-pago">Forma de pago</label>
            <p id="forma-pago" className="small-form-value">
              {balance.plan_pago.tipo === TIPO_PLAN_LIBRO_VENTAS_ID && 'Libro de Ventas'}
              {balance.plan_pago.tipo === TIPO_PLAN_CUOTAS_ID &&
                `En ${pluralize('cuota', balance.plan_pago.cuotas)}`}
              {balance.plan_pago.tipo === TIPO_PLAN_PLAN_PAGOS_ID && 'Plan de pago'}
            </p>
          </div>
          <div>
            <label htmlFor="costo-aviso-impago">Costo de aviso de impago</label>
            <NumberFormat
              id="costo-aviso-impago"
              name="costoAvisoImpago"
              thousandSeparator="."
              decimalSeparator={
                balance.tipo_costo_aviso_impago === TIPO_COSTO_AVISO_IMPAGO_SINIESTRO_ID ? '' : ','
              }
              decimalScale={
                balance.tipo_costo_aviso_impago === TIPO_COSTO_AVISO_IMPAGO_SINIESTRO_ID ? '0' : '2'
              }
              fixedDecimalScale
              suffix={
                balance.tipo_costo_aviso_impago === TIPO_COSTO_AVISO_IMPAGO_SINIESTRO_ID
                  ? ` ${base.moneda_asegurada.nombre_corto} por cada siniestro`
                  : '% del monto de cada siniestro'
              }
              value={balance.costo_aviso_impago}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
          <div>
            <label htmlFor="configuracion-siniestro">Configuración de siniestro</label>
            <NumberFormat
              id="configuracion-siniestro"
              name="confoiguracionSiniestro"
              suffix=" días"
              value={balance.configuracion_siniestro_dias}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
          <div>
            <label htmlFor="periodo-gestiones-propias">Período de gestiones propias</label>
            <NumberFormat
              id="periodo-gestiones-propias"
              name="periodoGestionesPropias"
              suffix={
                balance.tipo_gestiones_propias === TIPO_GESTIONES_PROPIAS_EMISION_ID
                  ? ' días desde la emisión'
                  : ' días desde el vencimiento'
              }
              value={balance.plazo_gestiones_propias_dias}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
          <div>
            <label htmlFor="umbral">Umbral</label>
            <NumberFormat
              id="umbral"
              name="umbral"
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              suffix={` ${base.moneda_asegurada.nombre_corto}`}
              value={balance.umbral_cobertura_amt}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
          <div style={{ gridColumn: '1 / 5' }}>
            <label htmlFor="filiales">Filiales</label>
            <p id="filiales" className="small-form-value">
              {balance.clienteendoso_set
                .filter((cliente) => cliente.is_filial)
                .map((cliente) => (
                  <span key={cliente.cliente.rut} style={{ display: 'block' }}>
                    {`${cliente.cliente.rut} - ${cliente.cliente.nombre}`}
                  </span>
                ))}
            </p>
          </div>
        </div>
      </FormSection>
      <FormSection title="Clientes nominados" style={{ marginTop: '20px' }}>
        <p className="small-text">{note}</p>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridGap: '20px',
          }}
        >
          <div>
            <label htmlFor="cobertura-nominados">Cobertura</label>
            <NumberFormat
              id="cobertura-nominados"
              name="coberturaNominados"
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              suffix="%"
              value={balance.cobertura_nominados_pct}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
          <div>
            <label htmlFor="costo-prorroga">Costo prórroga</label>
            <NumberFormat
              id="costo-prorroga"
              name="costoProrroga"
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              suffix="%"
              value={balance.costo_prorroga_nominado_pct}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
          <div>
            <label htmlFor="deducible">Deducibles</label>
            <NumberFormat
              id="deducible"
              name="deducible"
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              suffix={` ${base.moneda_asegurada.nombre_corto}`}
              value={balance.deducible_nominado_amt}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
          <div>
            <label htmlFor="costo-estudio-aprobado-nominados">Costo de estudio</label>
            <NumberFormat
              id="costo-estudio-aprobado-nominados"
              name="costoEstudioAprobado"
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              suffix={` ${base.moneda_asegurada.nombre_corto} clientes aprobados`}
              value={balance.costo_estudio_nominado_aprobado_amt}
              displayType="text"
              renderText={(value) => (
                <p className="small-form-value" style={{ marginBottom: 0 }}>
                  {value}
                </p>
              )}
            />
            <NumberFormat
              id="costo-estudio-rechazado-nominados"
              name="costoEstudioRechazado"
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              suffix={` ${base.moneda_asegurada.nombre_corto} clientes rechazados`}
              value={balance.costo_estudio_nominado_rechazado_amt}
              displayType="text"
              renderText={(value) => <p className="small-form-value">{value}</p>}
            />
          </div>
        </div>
      </FormSection>
      {balance.clientes_innominados && (
        <FormSection title="Clientes innominados" style={{ marginTop: '20px' }}>
          <p className="small-text">{note}</p>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gridGap: '20px',
            }}
          >
            <div>
              <label htmlFor="cobertura-innominados">Cobertura</label>
              <NumberFormat
                id="cobertura-innominados"
                name="coberturaInnominados"
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                suffix="%"
                value={balance.cobertura_innominados_pct}
                displayType="text"
                renderText={(value) => <p className="small-form-value">{value}</p>}
              />
            </div>
            <div>
              <label htmlFor="costo-prorroga-innominados">Costo prórroga</label>
              <NumberFormat
                id="costo-prorroga-innominados"
                name="costoProrrogaInnominados"
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                suffix="%"
                value={balance.costo_prorroga_innominado_pct}
                displayType="text"
                renderText={(value) => <p className="small-form-value">{value}</p>}
              />
            </div>
            <div>
              <label htmlFor="deducible-innominados">Deducibles</label>
              <NumberFormat
                id="deducible-innominados"
                name="deducibleInnominado"
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                suffix={` ${base.moneda_asegurada.nombre_corto}`}
                value={balance.deducible_innominado_amt}
                displayType="text"
                renderText={(value) => <p className="small-form-value">{value}</p>}
              />
            </div>
            <div>
              <label htmlFor="costo-estudio-aprobado-innominados">Costo de estudio</label>
              <NumberFormat
                id="costo-estudio-aprobado-innominados"
                name="costoEstudioAprobadoInnominado"
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                suffix={` ${base.moneda_asegurada.nombre_corto} clientes aprobados`}
                value={balance.costo_estudio_innominado_aprobado_amt}
                displayType="text"
                renderText={(value) => (
                  <p className="small-form-value" style={{ marginBottom: 0 }}>
                    {value}
                  </p>
                )}
              />
              <NumberFormat
                id="costo-estudio-rechazado-innominados"
                name="costoEstudioRechazadoInnominado"
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                suffix={` ${base.moneda_asegurada.nombre_corto} clientes rechazados`}
                value={balance.costo_estudio_innominado_rechazado_amt}
                displayType="text"
                renderText={(value) => <p className="small-form-value">{value}</p>}
              />
            </div>
            <div>
              <label htmlFor="monto-maximo-innominados">Monto máximo</label>
              <NumberFormat
                id="monto-maximo-innominados"
                name="montoMaximoInnominados"
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                suffix={` ${base.moneda_asegurada.nombre_corto}`}
                value={balance.maximo_innominados_amt}
                displayType="text"
                renderText={(value) => <p className="small-form-value">{value}</p>}
              />
            </div>
            {balance.tipo_indemnizacion_max ===
              TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID && (
              <div>
                <label htmlFor="indemnizacion-maxima-innominados">Indemnización máxima</label>
                <NumberFormat
                  id="indemnizacion-maxima-innominados"
                  name="indemnizacionMaximaInnominados"
                  suffix=" veces la prima devengada "
                  value={balance.indemnizacion_max_innominado}
                  displayType="text"
                  renderText={(value) => <p className="small-form-value">{value}</p>}
                />
              </div>
            )}
          </div>
        </FormSection>
      )}
      <FormSection title="Condiciones" style={{ marginTop: '20px' }}>
        <p className="small-text">{note}</p>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridGap: '20px',
          }}
        >
          <div>
            {/* eslint-disable */}
            <label>Condiciones particulares</label>
            {condicionesParticulares.length > 0 &&
            typeof condicionesParticulares[0] === 'string' ? (
              condicionesParticulares.map((condicion, index) => (
                <p key={index} className="small-text" style={{ marginBottom: 0 }}>
                  {condicion}
                </p>
              ))
            ) : (
              <p className="small-text">Sin condiciones</p>
            )}
          </div>
          <div>
            <label>Cláusulas parametrizadas</label>
            {condicionesParametrizadas.length > 0 &&
            typeof condicionesParticulares[0] === 'string' ? (
              condicionesParametrizadas.map((condicion, index) => (
                <p key={index} className="small-text" style={{ marginBottom: 0 }}>
                  {condicion}
                </p>
              ))
            ) : (
              <p className="small-text">Sin condiciones</p>
            )}
            {/* eslint-enable */}
          </div>
        </div>
      </FormSection>
    </Col>
  );
};

CreditPolicyDetailBasicInfo.propTypes = {
  policy: PropTypes.shape({
    id: PropTypes.number.isRequired,
    balance: PropTypes.object,
    base: PropTypes.object,
    adjuntos: PropTypes.array,
    adjuntos_uuids: PropTypes.array,
  }).isRequired,
  loginIdToken: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatchAddAdjunto: PropTypes.func.isRequired,
  dispatchDeleteAdjunto: PropTypes.func.isRequired,
  dispatchDeleteAdjuntoNuevo: PropTypes.func.isRequired,
  dispatchSaveFiles: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loginIdToken: state.login.id_token,
  policy: state.creditPolicy.policy,
  loading: state.creditPolicy.loading,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchAddAdjunto: (uuid) => dispatch(addAdjunto(uuid)),
  dispatchDeleteAdjunto: (adjunto) => dispatch(deleteAdjunto(adjunto)),
  dispatchDeleteAdjuntoNuevo: (uuid) => dispatch(deleteAdjuntoNuevo(uuid)),
  dispatchSaveFiles: (policy) => dispatch(attachFiles(policy)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditPolicyDetailBasicInfo);
