import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BASE_URL, API, OPORTUNIDADES_STATE_SECTION_MAP, ESTADOS_OPORTUNIDAD } from 'CONST';
import { get } from 'helpers/requests';
import PageContainer from 'components/PageContainer';
import WarningModal from 'components/UI/WarningModal';
import CrearOportunidadSection from './CrearOportunidadSection';
import OportunitiesFormAccordion from './OportunitiesFormAccordion';
import AsignarResponsableSection from './AsignarResponsableSection';
import AsignarSuscriptorSection from './AsignarSuscriptorSection';
import InformacionInicialSection from './InformacionInicialSection';
import ValidarInformacionSection from './ValidarInformacionSection';
import CompletarInformacionSection from './CompletarInformacionSection';
import RevisionGenericSection from './RevisionGenericSection';
import OportunidadRechazadaSection from './OportunidadRechazadaSection';
import ParametrosComercialesSection from './ParametrosComercialesSection';
import GenerarBorradorSection from './GenerarBorradorSection';
import EnviarBorradorSection from './EnviarBorradorSection';
import AceptarBorradorSection from './AceptarBorradorSection';
import OportunidadDesistidaSection from './OportunidadDesistidaSection';
import RevisarRequisitosEmisionSection from './RevisarRequisitosEmisionSection';
import EmitirPolizaSection from './EmitirPolizaSection';
import OportunidadEmitidaSection from './OportunidadEmitidaSection';

const OportunitiesForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const [nameBreadcrumb, setNameBreadcrumb] = useState('CREAR OPORTUNIDAD');
  const [loaded, setLoaded] = useState(false);
  const [edit, setEdit] = useState(false);
  const [estadosOportunidad, setEstadosOportunidad] = useState([]);
  const [observaciones, setObservaciones] = useState([]);
  const [fullData, setFullData] = useState({});
  const [errorModal, setErrorModal] = useState(false);
  const [initialSection, setInitialSection] = useState(0);
  const reg = new RegExp('^[0-9]*$');

  useEffect(() => {
    if (id !== undefined) {
      setEdit(true);
      if (reg.test(id)) {
        get(`${BASE_URL}${API.oportunidades_garantia}/${id}/`)
          .then((response) => {
            setFullData(response);
            setInitialSection(OPORTUNIDADES_STATE_SECTION_MAP[response.estado.nemotecnico]);
            setLoaded(true);
          })
          .catch((error) => {
            if (error.status === 404) {
              setErrorModal(true);
            }
          });
      } else {
        setErrorModal(true);
      }
    } else {
      setLoaded(true);
    }
    get(`${BASE_URL}${API.estados_oportunidad}/`).then((response) => {
      setEstadosOportunidad(response.results);
    });
  }, []);

  useEffect(() => {
    if (id && Object.keys(fullData).length) {
      setNameBreadcrumb('EDITAR OPORTUNIDAD');
      if (reg.test(id)) {
        get(`${BASE_URL}${API.observaciones}/?oportunidad=${id}`).then((response) => {
          setObservaciones(response);
        });
      }
    }
  }, [fullData]);

  const sections = [
    {
      name: 'Crear Oportunidad',
      component: <CrearOportunidadSection fullData={fullData} edit={edit} id={id} />,
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Responsable de la Oportunidad',
      isVisible: true,
    },
    {
      name: 'Asignar Responsable',
      component: (
        <AsignarResponsableSection
          fullData={fullData}
          setFullData={setFullData}
          setInitialSection={setInitialSection}
          id={id}
          estadosOportunidad={estadosOportunidad}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Responsable de la Oportunidad',
      isVisible: true,
    },
    {
      name: 'Asignar Suscriptor',
      component: (
        <AsignarSuscriptorSection
          fullData={fullData}
          setFullData={setFullData}
          setInitialSection={setInitialSection}
          id={id}
          estadosOportunidad={estadosOportunidad}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Responsable de la Oportunidad',
      isVisible: true,
    },
    {
      name: 'Información Inicial',
      component: (
        <InformacionInicialSection
          fullData={fullData}
          estadosOportunidad={estadosOportunidad}
          observaciones={observaciones}
          id={id}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Suscriptor',
      isVisible: true,
    },
    {
      name: 'Validar Información Inicial',
      component: (
        <ValidarInformacionSection
          fullData={fullData}
          setFullData={setFullData}
          setInitialSection={setInitialSection}
          estadosOportunidad={estadosOportunidad}
          observaciones={observaciones}
          id={id}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Suscriptor',
      isVisible: true,
    },
    {
      name: 'Completar Información Oportunidad',
      component: (
        <CompletarInformacionSection
          fullData={fullData}
          setFullData={setFullData}
          setInitialSection={setInitialSection}
          estadosOportunidad={estadosOportunidad}
          id={id}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Suscriptor',
      isVisible: true,
    },
    {
      name: 'Revisar Condiciones Suscripción',
      component: (
        <RevisionGenericSection
          fullData={fullData}
          estadosOportunidad={estadosOportunidad}
          observaciones={observaciones}
          id={id}
          revisor={0}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Suscriptor',
      isVisible: true,
    },
    {
      name: 'Revisar Condiciones Riesgo',
      component: (
        <RevisionGenericSection
          fullData={fullData}
          estadosOportunidad={estadosOportunidad}
          observaciones={observaciones}
          id={id}
          revisor={1}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Área de Riesgo',
      isVisible:
        fullData &&
        fullData.estado_suscriptor_riesgo_comite &&
        [
          ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoRiesgo,
          ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoComite,
        ].includes(fullData.estado_suscriptor_riesgo_comite.nemotecnico),
    },
    {
      name: 'Revisar Condiciones Comité',
      component: (
        <RevisionGenericSection
          fullData={fullData}
          estadosOportunidad={estadosOportunidad}
          observaciones={observaciones}
          id={id}
          revisor={2}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Comité de Riesgo',
      isVisible:
        fullData &&
        fullData.estado_suscriptor_riesgo_comite &&
        [ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoComite].includes(
          fullData.estado_suscriptor_riesgo_comite.nemotecnico,
        ),
    },
    {
      name: 'Parámetros Comerciales',
      component: (
        <ParametrosComercialesSection
          fullData={fullData}
          setFullData={setFullData}
          setInitialSection={setInitialSection}
          estadosOportunidad={estadosOportunidad}
          observaciones={observaciones}
          id={id}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Suscriptor',
      isVisible: !(
        fullData &&
        fullData.estado &&
        [ESTADOS_OPORTUNIDAD.rechazado].includes(fullData.estado.nemotecnico)
      ),
    },
    {
      name: 'Rechazado',
      component: <OportunidadRechazadaSection />,
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Responsable de la Oportunidad',
      isVisible:
        fullData &&
        fullData.estado &&
        [ESTADOS_OPORTUNIDAD.rechazado].includes(fullData.estado.nemotecnico),
    },
    {
      name: 'Generar Borrador',
      component: (
        <GenerarBorradorSection
          fullData={fullData}
          setFullData={setFullData}
          setInitialSection={setInitialSection}
          estadosOportunidad={estadosOportunidad}
          observaciones={observaciones}
          id={id}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Suscriptor',
      isVisible: true,
    },
    {
      name: 'Enviar Borrador',
      component: (
        <EnviarBorradorSection
          fullData={fullData}
          setFullData={setFullData}
          setInitialSection={setInitialSection}
          estadosOportunidad={estadosOportunidad}
          observaciones={observaciones}
          id={id}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Responsable de la Oportunidad',
      isVisible: true,
    },
    {
      name: 'Aceptar Borrador',
      component: (
        <AceptarBorradorSection
          fullData={fullData}
          estadosOportunidad={estadosOportunidad}
          observaciones={observaciones}
          id={id}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Responsable de la Oportunidad',
      isVisible: true,
    },
    {
      name: 'Desistido',
      component: <OportunidadDesistidaSection />,
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Responsable de la Oportunidad',
      isVisible:
        fullData &&
        fullData.estado &&
        [ESTADOS_OPORTUNIDAD.desistido].includes(fullData.estado.nemotecnico),
    },
    {
      name: 'Revisar Requisitos de Emisión',
      component: (
        <RevisarRequisitosEmisionSection
          fullData={fullData}
          setFullData={setFullData}
          setInitialSection={setInitialSection}
          estadosOportunidad={estadosOportunidad}
          observaciones={observaciones}
          id={id}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Responsable de la Oportunidad',
      isVisible: true,
    },
    {
      name: 'Emitir Póliza',
      component: (
        <EmitirPolizaSection
          fullData={fullData}
          setFullData={setFullData}
          setInitialSection={setInitialSection}
          estadosOportunidad={estadosOportunidad}
          observaciones={observaciones}
          id={id}
        />
      ),
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Responsable de la Oportunidad',
      isVisible: true,
    },
    {
      name: 'Póliza Emitida',
      component: <OportunidadEmitidaSection />,
      collapsed: true,
      isValid: true,
      expansible: true,
      responsable: 'Responsable: Responsable de la Oportunidad',
      isVisible: true,
    },
  ];
  sections[initialSection].isValid = false;
  sections[initialSection].collapsed = false;

  return (
    <PageContainer
      breadcrumbs={[
        { id: 0, name: 'PÓLIZA', url: '/' },
        { id: 1, name: 'GARANTÍA', url: '/guarantee' },
        { id: 2, name: 'OPORTUNIDADES', url: '/guarantee/oportunities' },
        { id: 3, name: nameBreadcrumb },
      ]}
    >
      {loaded && (
        <OportunitiesFormAccordion
          sections={sections.slice(0, initialSection + 1)}
          openSection={initialSection}
        />
      )}
      <WarningModal
        isOpen={errorModal}
        toggle={() => setErrorModal(false)}
        handleOk={() => setErrorModal(false)}
        handleClearError={() => {
          setErrorModal(false);
          history.push('/guarantee/oportunities');
        }}
        confirmMsg="Oportunidad no encontrada."
        errorMsg="Oportunidad no encontrada."
      />
    </PageContainer>
  );
};

export default OportunitiesForm;
