import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import BaseTable from '../../../UI/BaseTable';
import { cellOperation, headerOperation } from '../../../../helpers/tables';
import { CP_DELETE_CLIENTE_ENDOSO } from '../../../../redux-store/constants/action-types';
import {
  handleFilialActividadChange,
  toggleClienteEndosoCheck,
} from '../../../../redux-store/actions/creditPolicyActions';
import {
  ROL_BENEFICIARIO_CREDITO,
  ROL_FILIAL_CREDITO,
} from '../../../../CONST';

const FilialesOrBeneficiariosList = ({
  clientes,
  dispatch,
  validate,
  disabled,
}) => {
  const columns = [
    {
      id: 'clienteNombre',
      Header: headerOperation('Nombre'),
      accessor: data => cellOperation(data.cliente.nombre),
      minWidth: 200,
      fixed: 'left',
    },
    {
      id: 'clienteRut',
      Header: headerOperation('RUT'),
      accessor: data => cellOperation(data.cliente.rut),
      minWidth: 100,
      fixed: 'left',
    },
    {
      id: 'isFilial',
      Header: headerOperation('Filial'),
      Cell: row =>
        cellOperation(
          <input
            type="checkbox"
            checked={row.original.is_filial}
            onChange={() =>
              dispatch(
                toggleClienteEndosoCheck(row.original.cliente.id, 'is_filial'),
              )
            }
            disabled={row.original.disabled || disabled}
          />,
        ),
      width: 80,
    },
    {
      id: 'giro',
      Header: headerOperation('Giro'),
      Cell: row => {
        const actividadesOptions = row.original.cliente.actividades
          ? row.original.cliente.actividades.map(act => ({
              label: `${act.codigo} - ${act.nombre}`,
              value: act,
            }))
          : [];
        const selectedActividadOption = actividadesOptions.find(
          opt => opt.value.id === row.original.actividad_id,
        );
        return cellOperation(
          <Select
            id="filial-actividad"
            name="filialActividad"
            className="custom-policy-select"
            isDisabled={!row.original.is_filial || disabled}
            isClearable
            onChange={selectedOption =>
              dispatch(
                handleFilialActividadChange(
                  selectedOption,
                  row.original.cliente.id,
                ),
              )
            }
            options={actividadesOptions}
            placeholder="Seleccione Giro"
            value={selectedActividadOption}
          />,
          'left',
          false,
        );
      },
      minWidth: 300,
    },
    {
      id: 'facturacion',
      Header: headerOperation('Facturación'),
      Cell: row =>
        cellOperation(
          <input
            type="checkbox"
            checked={row.original.facturacion}
            onChange={() =>
              dispatch(
                toggleClienteEndosoCheck(
                  row.original.cliente.id,
                  'facturacion',
                ),
              )
            }
            disabled={!row.original.is_filial || disabled}
          />,
        ),
    },
    {
      id: 'isBeneficiario',
      Header: headerOperation('Beneficiario'),
      Cell: row => {
        let help = '';
        if (!row.original.cliente.roles.includes(ROL_FILIAL_CREDITO)) {
          help =
            'Solo puede ser beneficiario, no tiene rol de filial de crédito.';
        } else if (row.original.cliente.actividades.length === 0) {
          help =
            'Solo puede ser beneficiario, no tiene giro para ser marcado como filial.';
        } else if (
          !row.original.cliente.roles.includes(ROL_BENEFICIARIO_CREDITO)
        ) {
          help =
            'Solo puede ser filial, no tiene rol de beneficiario de crédito.';
        }

        return cellOperation(
          <div style={{ display: 'grid', gridTemplateColumns: '10fr 1fr' }}>
            <div className="text-center">
              <input
                type="checkbox"
                checked={row.original.is_beneficiario}
                onChange={() =>
                  dispatch(
                    toggleClienteEndosoCheck(
                      row.original.cliente.id,
                      'is_beneficiario',
                    ),
                  )
                }
                disabled={row.original.disabled || disabled}
              />
            </div>
            {row.original.disabled && (
              <>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  id={`help-${row.original.cliente.id}`}
                  style={{ float: 'right' }}
                />
                <UncontrolledPopover
                  trigger="click hover"
                  placement="bottom"
                  target={`help-${row.original.cliente.id}`}
                >
                  <PopoverBody>{help}</PopoverBody>
                </UncontrolledPopover>
              </>
            )}
          </div>,
        );
      },
    },
  ];

  if (!disabled) {
    const actionColumn = {
      id: 'accion',
      Header: headerOperation('Acción'),
      Cell: row =>
        cellOperation(
          <button
            type="button"
            className="table-link"
            onClick={() =>
              dispatch({
                type: CP_DELETE_CLIENTE_ENDOSO,
                payload: row.original.cliente.id,
              })
            }
            title="eliminar"
            style={{ marginLeft: '10px' }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>,
        ),
      minWidth: 90,
      fixed: 'right',
    };

    columns.push(actionColumn);
  }

  return (
    <div id="filiales-beneficiarios-table-container">
      <BaseTable
        columns={columns}
        data={clientes}
        sortable={false}
        resizable={false}
        hasFixedColumns
        getTrProps={(state, rowInfo) => {
          let trProps = {};
          if (validate && rowInfo) {
            const cli = rowInfo.original;
            const isValidType = cli.is_filial || cli.is_beneficiario;
            const isValidFilial =
              !cli.is_filial ||
              (cli.cliente.actividades.length === 0 || cli.actividad_id);

            if (!isValidType || !isValidFilial)
              trProps = {
                style: {
                  border: '3px solid red',
                },
              };
          }
          return trProps;
        }}
        xScroll={false}
      />
    </div>
  );
};

FilialesOrBeneficiariosList.propTypes = {
  clientes: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func.isRequired,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

FilialesOrBeneficiariosList.defaultProps = {
  disabled: false,
};

export default FilialesOrBeneficiariosList;
