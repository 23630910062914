import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import connect from 'react-redux/es/connect/connect';
import FormSection from '../UI/FormSection';
import EndosoListTable from './EndosoListTable';

const EndosoCreate = (props) => {
  const {
    dropdownOpen,
    handleDelete,
    handleDropdown,
    handleEdit,
    handleModal,
    handleWarning,
    policies,
    warningOpen,
    deleteWarningOpen,
    loginIdToken,
  } = props;
  return (
    <div>
      <FormSection title="Endosos">
        <div className="text-right">
          <ButtonDropdown
            className={`endoso-dropdown ${
              policies.endoso_type_list.length === 0 ? 'disabled' : ''
            }`}
            isOpen={policies.endoso_type_list.length ? dropdownOpen : false}
            toggle={handleDropdown}
          >
            <DropdownToggle caret>Seleccionar tipo de endoso</DropdownToggle>
            <DropdownMenu
              style={{ width: '100%', heigh: 'auto', maxHeight: '150px', overflowX: 'hidden' }}
            >
              {policies.endoso_type_list.length
                ? policies.endoso_type_list.map((node) => {
                    return (
                      <Button
                        key={node.nemotecnico}
                        onClick={(e) => handleModal(e, node)}
                        className="modal-button"
                      >
                        {node.descripcion}
                      </Button>
                    );
                  })
                : ''}
            </DropdownMenu>
          </ButtonDropdown>
        </div>
        <div style={{ paddingBottom: '15px' }}>
          <EndosoListTable
            data={policies.endosoList}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handleWarning={handleWarning}
            warningOpen={warningOpen}
            deleteWarningOpen={deleteWarningOpen}
            loginIdToken={loginIdToken}
          />
        </div>
      </FormSection>
    </div>
  );
};

const mapStateToProps = (state) => ({
  policies: state.policies,
  loginIdToken: state.login.id_token,
});

const EndosoCreateView = connect(mapStateToProps)(EndosoCreate);

EndosoCreate.propTypes = {
  dropdownOpen: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleDropdown: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleModal: PropTypes.func.isRequired,
  handleWarning: PropTypes.func.isRequired,
  policies: PropTypes.shape({
    endoso_type_list: PropTypes.array,
    endosoList: PropTypes.array,
  }).isRequired,
  warningOpen: PropTypes.bool.isRequired,
  deleteWarningOpen: PropTypes.bool.isRequired,
  loginIdToken: PropTypes.string.isRequired,
};

export default EndosoCreateView;
