import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import NumberFormat from 'react-number-format';
import FormSection from '../../../UI/FormSection';
import { changePlanPagoDocument } from '../../../../redux-store/actions/creditPolicyActions';
import {
  API,
  BASE_URL,
  TIPO_PLAN_CUOTAS_ID,
  TIPO_PLAN_LIBRO_VENTAS_ID,
  TIPO_PLAN_PLAN_PAGOS_ID,
} from '../../../../CONST';
import auth from '../../../../helpers/auth';
import TablaCuotasPlan from './TablaCuotasPlan';
import { getCuotasPagadas, getMaxCuotas } from '../../../../helpers/validation';

class FormaDePagoSection extends Component {
  handleOptionChange = event => {
    const { dispatch, handleChange } = this.props;
    const tipoId = parseInt(event.target.value, 10);
    dispatch(handleChange('endoso.planPago.tipoId', tipoId));
  };

  handleFile = e => {
    const { dispatch } = this.props;
    const { files } = e.target;
    const reader = new FileReader();
    if (files.length > 0) {
      const { name: filename } = files[0];
      reader.readAsArrayBuffer(files[0]);
      reader.onload = error => {
        const body = error.target.result;
        auth
          .fetch(`${BASE_URL}${API.upload}/`, {
            method: 'PUT',
            body,
            headers: {
              'Content-Disposition': `attachment; filename="${encodeURI(
                filename,
              )}"`,
            },
          })
          .then(response =>
            dispatch(changePlanPagoDocument(filename, response.uuid)),
          );
      };
    }
  };

  render() {
    const {
      planPago,
      inicioVigenciaEndoso,
      dispatch,
      style,
      validate,
      tipoVigencia,
      handleChange,
      cuotas,
      disabled,
      deltaPrima,
    } = this.props;

    const cuotasPagadas = getCuotasPagadas(planPago, inicioVigenciaEndoso).length;
    const maxCuotas = getMaxCuotas(cuotasPagadas, cuotas);
    const invalidCuotasPagadas =
      deltaPrima > 0 ? planPago.cuotas <= cuotasPagadas : planPago.cuotas < cuotasPagadas;

    const invalidCuotas =
      validate &&
      planPago.tipoId === TIPO_PLAN_CUOTAS_ID &&
      (invalidCuotasPagadas || planPago.cuotas > maxCuotas);

    return (
      <FormSection title="Forma de pago" style={style}>
        <FormGroup check className="radio-with-input-at-the-end">
          <Label check>
            <Input
              type="radio"
              value={TIPO_PLAN_LIBRO_VENTAS_ID}
              checked={planPago.tipoId === TIPO_PLAN_LIBRO_VENTAS_ID}
              onChange={this.handleOptionChange}
              disabled={disabled}
            />
            Libro de ventas
          </Label>
        </FormGroup>
        <FormGroup check className="radio-with-input-in-the-middle">
          <Label check>
            <Input
              type="radio"
              value={TIPO_PLAN_CUOTAS_ID}
              checked={planPago.tipoId === TIPO_PLAN_CUOTAS_ID}
              onChange={this.handleOptionChange}
              disabled={disabled}
            />
            Prima mínima anual
            <NumberFormat
              id="forma-pago-cuotas"
              name="planPagoCuotas"
              className={`form-control${invalidCuotas ? ' is-invalid' : ''}`}
              onValueChange={values => {
                const newValue = values.floatValue;
                if (newValue !== undefined && newValue !== planPago.cuotas) {
                  dispatch(handleChange('endoso.planPago.cuotas', newValue));
                }
              }}
              thousandSeparator="."
              decimalSeparator={false}
              allowNegative={false}
              isAllowed={values =>
                !values.value || values.floatValue <= maxCuotas
              }
              value={
                planPago.tipoId === TIPO_PLAN_CUOTAS_ID ? planPago.cuotas : 0
              }
              disabled={planPago.tipoId !== TIPO_PLAN_CUOTAS_ID || disabled}
              style={{ width: '70px' }}
            />
            cuotas
          </Label>
          {invalidCuotas && planPago.cuotas > 0 && (
            <div className="error-msg-small">
              {`La cantidad de cuotas debe ser mayor a la cantidad de cuotas vencidas (${cuotasPagadas})`}
            </div>
          )}
        </FormGroup>
        <FormGroup check className="radio-with-input-at-the-end">
          <Label check>
            <Input
              type="radio"
              value={TIPO_PLAN_PLAN_PAGOS_ID}
              checked={planPago.tipoId === TIPO_PLAN_PLAN_PAGOS_ID}
              onChange={this.handleOptionChange}
              disabled={disabled}
            />
            Plan de pago
          </Label>
        </FormGroup>
        {planPago.tipoId === TIPO_PLAN_PLAN_PAGOS_ID && (
          <TablaCuotasPlan
            tipoVigencia={tipoVigencia}
            validate={validate}
            disabled={disabled}
          />
        )}
      </FormSection>
    );
  }
}

FormaDePagoSection.propTypes = {
  planPago: PropTypes.shape({
    id: PropTypes.number,
    tipoId: PropTypes.number,
    cuotas: PropTypes.number,
    planPagosDocument: PropTypes.object,
    selectedPlanPagosDocumentName: PropTypes.string,
    planPagosDocumentUuid: PropTypes.string,
    cuotasPlan: PropTypes.array,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  inicioVigenciaEndoso: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tipoVigencia: PropTypes.object,
  cuotas: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  deltaPrima: PropTypes.number.isRequired,
};

FormaDePagoSection.defaultProps = {
  style: {},
  tipoVigencia: { years: 0 },
  inicioVigenciaEndoso: null,
  disabled: false,
};

export default FormaDePagoSection;
