import { BASE_URL, API, SEARCH_DELAY_MS } from 'CONST';
import { get, post } from 'helpers/requests';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormGroup, Col, Label, Button } from 'reactstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckModal from 'components/shared/CheckModal';
import WarningModal from 'components/UI/WarningModal';
import AvanzarOportunidadConfirmationModal from './AvanzarOportunidadConfirmationModal';

const CrearOportunidadSection = ({ edit, fullData, id }) => {
  const history = useHistory();
  const [sectionData, setSectionData] = useState({
    afianzado_id: null,
  });
  const [afianzadoOptions, setAfianzadoOptions] = useState([]);
  const [afianzadoSearch, setAfianzadoSearch] = useState('');
  const [afianzado, setAfianzado] = useState(null);

  const [showCreacionModal, setShowCreacionModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [mensajeErrorModal, setMensajeErrorModal] = useState(
    'Ha ocurrido un error al crear la oportunidad',
  );

  useEffect(() => {
    if (!id) {
      get(`${BASE_URL}${API.clientes}?roles__term=afianzadogarantia`).then((response) => {
        setAfianzadoOptions(
          response.results.map((e) => ({
            value: e.id,
            label: `${e.rut} - ${e.nombre}`,
          })),
        );
      });
    }
  }, []);

  useEffect(() => {
    if (edit && fullData.afianzado) {
      setAfianzado({
        label: `${fullData.afianzado.rut} - ${fullData.afianzado.nombre}`,
        value: -1,
      });
    }
  }, [edit, fullData]);

  const searchAfianzado = (search) => {
    const searchURL = search ? `&search=${search}` : '';
    get(`${BASE_URL}${API.clientes}?roles__term=afianzadogarantia${searchURL}`).then((response) => {
      setAfianzadoOptions(
        response.results.map((e) => ({
          value: e.id,
          label: `${e.rut} - ${e.nombre}`,
        })),
      );
    });
  };

  const searchAfianzadoDebounced = useCallback(
    _.debounce((search) => searchAfianzado(search), SEARCH_DELAY_MS),
    [],
  );

  useEffect(() => {
    if (afianzadoSearch) {
      searchAfianzadoDebounced(afianzadoSearch);
    }
  }, [afianzadoSearch]);

  const crearOportunidad = () => {
    setLoading(true);
    post(`${BASE_URL}${API.oportunidades_garantia}/`, sectionData)
      .then(() => {
        setShowCreacionModal(true);
      })
      .catch((error) => {
        error.json().then((errorData) => setMensajeErrorModal(errorData.errors));
        setShowErrorModal(true);
      })
      .finally(() => {
        setConfirmationModal(false);
        setLoading(false);
      });
  };

  return (
    <div>
      <FormGroup row>
        <Col sm={2}>
          <Label for="afianzado">
            Afianzado
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col sm={5}>
          <Select
            className="custom-policy-select"
            options={afianzadoOptions}
            value={afianzado}
            isDisabled={edit}
            onChange={(event) => {
              setSectionData({ afianzado_id: event.value });
              setAfianzado(event);
            }}
            onInputChange={(event) => {
              setAfianzadoSearch(event);
            }}
            placeholder="Seleccione un afianzado"
          />
        </Col>
      </FormGroup>
      {!edit && (
        <div className="button-container d-flex justify-content-end">
          <Button
            className="btn btn-secondary orange"
            onClick={() => setConfirmationModal(true)}
            disabled={afianzado === null || confirmationModal}
          >
            {loading ? <FontAwesomeIcon spin={loading} icon={faSpinner} /> : 'Crear Oportunidad'}
          </Button>
        </div>
      )}
      <AvanzarOportunidadConfirmationModal
        openModal={confirmationModal}
        setOpenModal={setConfirmationModal}
        avanzarOportunidad={crearOportunidad}
        mensajeModal="¿Está seguro que desea CREAR esta oportunidad?"
        loading={loading}
      />
      <CheckModal
        isOpen={showCreacionModal}
        toggle={() => history.push('/guarantee/oportunities')}
        handleOk={() => history.push('/guarantee/oportunities')}
        okBtnText="OK"
        confirmMsg="Oportunidad Creada Satisfactoriamente."
      />
      <WarningModal
        isOpen={showErrorModal}
        toggle={() => setShowErrorModal(false)}
        handleOk={() => {
          setShowErrorModal(false);
          setMensajeErrorModal('Ha ocurrido un error al crear la oportunidad.');
        }}
        okBtnText="Aceptar"
        confirmMsg={mensajeErrorModal}
      />
    </div>
  );
};

CrearOportunidadSection.propTypes = {
  edit: PropTypes.bool.isRequired,
  fullData: PropTypes.shape({
    afianzado: PropTypes.shape({
      rut: PropTypes.string,
      nombre: PropTypes.string,
    }),
  }).isRequired,
  id: PropTypes.string,
};

CrearOportunidadSection.defaultProps = {
  id: undefined,
};

export default CrearOportunidadSection;
