/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  API,
  BASE_URL,
  CP_FS_BASICOS,
  CONSTRUCTION_LABEL,
  EMITTED_LABEL,
  ROL_ASEGURADO_CREDITO,
  ROL_INTERMEDIARIO_CREDITO,
  TIPO_ENDOSO_RENOVACION_ID,
} from '../../../CONST';
import { loadAsyncOptions, objectsToOptions, hasOptionChanged } from '../../../helpers/selects';
import { loadClientsOptions } from '../../../helpers/clients';
import {
  getGastosAdquisicion,
  handleAseguradoSelectChange,
  handleFormSelectInputChange,
  handleGeneralConditionedSelectChange,
  handleRamoFecuSelectChange,
  handleSelectChange,
} from '../../../redux-store/actions/creditPolicyActions';
import { getData } from '../../../redux-store/actions';
import {
  CP_ADD_ADJUNTO_FORM,
  CP_DELETE_ADJUNTO_FORM,
  CP_DELETE_ADJUNTO_NUEVO_FORM,
  CP_HANDLE_FORM_ELEMENT_CHANGE,
  CP_SET_SECTION_VALIDATION,
  GET_ACTIVE_GENERAL_CONDITIONED_LIST,
} from '../../../redux-store/constants/action-types';
import FileTable from '../../shared/FileTable';
import {
  isIntermediarioDirecto,
  isValidCreditPolicyBasicInfoStep,
  isValidIntermediario,
} from '../../../helpers/validation';
import VistoBuenoSection from './helpers/VistoBuenoSection';

class CreditPolicyBasicInfoStep extends Component {
  state = {
    validateInputs: false,
  };

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      creditPolicy: {
        form,
        form: { sections },
      },
    } = this.props;
    const isValid = isValidCreditPolicyBasicInfoStep(form);
    if (isValid !== sections[CP_FS_BASICOS].valid) {
      dispatch({
        type: CP_SET_SECTION_VALIDATION,
        payload: { stepName: CP_FS_BASICOS, isValid },
      });
    }
    this.refreshGeneralConditioned(prevProps, this.props);
    this.refreshGastosAdquisicion(prevProps, this.props);
  }

  setValidateInputs = (validateInputs) => this.setState({ validateInputs });

  refreshGeneralConditioned = (prevProps, props) => {
    const {
      creditPolicy: {
        form: {
          data: { ramoFecu: prevRamoFecu },
        },
      },
    } = prevProps;
    const {
      dispatch,
      creditPolicy: {
        form: {
          data: { ramoFecu },
        },
      },
    } = props;

    if (hasOptionChanged(prevRamoFecu, ramoFecu)) {
      dispatch(
        getData({
          url: `${BASE_URL}${API.maestras}/?is_main=0&paginate=0&activa=true&ramo_asegurado__id=${ramoFecu.value.id}`,
          type: GET_ACTIVE_GENERAL_CONDITIONED_LIST,
        }),
      );
    }
  };

  refreshGastosAdquisicion = (prevProps, props) => {
    const {
      creditPolicy: {
        form: { data: prevData },
      },
    } = prevProps;
    const {
      producto: prevProducto,
      endoso: { tipoCobertura: prevTipoCobertura },
    } = prevData;
    const {
      dispatch,
      creditPolicy: {
        form: { data },
      },
    } = props;
    const {
      ramoFecu,
      producto,
      endoso: { tipoCobertura },
    } = data;

    if (
      ramoFecu &&
      producto &&
      tipoCobertura &&
      (hasOptionChanged(prevProducto, producto) ||
        hasOptionChanged(prevTipoCobertura, tipoCobertura))
    ) {
      dispatch(getGastosAdquisicion(ramoFecu, producto, tipoCobertura));
    }
  };

  addAdjunto = (uuid) => {
    const { dispatch } = this.props;
    dispatch({
      type: CP_ADD_ADJUNTO_FORM,
      payload: uuid,
    });
  };

  deleteAdjunto = (adjunto) => {
    const { dispatch } = this.props;
    dispatch({
      type: CP_DELETE_ADJUNTO_FORM,
      payload: adjunto.id,
    });
  };

  deleteAdjuntoNuevo = (uuid) => {
    const { dispatch } = this.props;
    dispatch({
      type: CP_DELETE_ADJUNTO_NUEVO_FORM,
      payload: uuid,
    });
  };

  handleSelect = (selectedOption, action) =>
    handleSelectChange(CP_FS_BASICOS, selectedOption, action);

  handleFormSelectInput = (oldText, newText, elemName) =>
    handleFormSelectInputChange(CP_FS_BASICOS, oldText, newText, elemName);

  isEndosoRenovacion = () => {
    const {
      creditPolicy: {
        form: {
          data: {
            endoso: { tipoEndosoId },
          },
        },
      },
    } = this.props;
    return tipoEndosoId === TIPO_ENDOSO_RENOVACION_ID;
  };

  render() {
    const {
      dispatch,
      policies: {
        tiposMoneda,
        condicionadosGenerales,
        tiposIntermediario,
        aliasList,
        actividadesList,
        actividadCreditoList,
        actividadCreditoExportacionList,
      },
      creditPolicy: {
        form: { estado, data: form },
        loading,
      },
      ramosFecu,
      loginIdToken,
      readOnly,
    } = this.props;

    if (ramosFecu.length === 0) {
      return null;
    }

    const canEdit = estado === CONSTRUCTION_LABEL && !readOnly;
    const isPolicyEmitted = estado === EMITTED_LABEL;
    const { validateInputs: validate } = this.state;

    let actividadesEconomicas = [];
    if (form.ramoFecu) {
      if (form.ramoFecu.value.id === 2) {
        actividadesEconomicas = actividadCreditoList;
      } else if (form.ramoFecu.value.id === 3) {
        actividadesEconomicas = actividadCreditoExportacionList;
      }
    }

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridGap: '20px',
        }}
      >
        <FormGroup className={validate && !form.ramoFecu ? 'has-error' : ''}>
          <label htmlFor="ramo-fecu">Ramo FECU</label>
          <Select
            id="ramo-fecu"
            name="ramoFecu"
            className="custom-policy-select"
            options={objectsToOptions(ramosFecu, true)}
            onChange={(selectedOption, action) =>
              dispatch(handleRamoFecuSelectChange(selectedOption, action))
            }
            value={form.ramoFecu}
            placeholder="Seleccione Ramo"
            isDisabled={!canEdit || readOnly}
          />
        </FormGroup>
        <FormGroup className={validate && !form.moneda ? 'has-error' : ''}>
          <label htmlFor="moneda">Moneda</label>
          <Select
            id="moneda"
            name="moneda"
            className="custom-policy-select"
            options={tiposMoneda}
            onChange={(selectedOption, action) =>
              dispatch(this.handleSelect(selectedOption, action))
            }
            value={form.moneda}
            placeholder="Seleccione Moneda"
            isDisabled={!canEdit}
          />
        </FormGroup>
        <FormGroup className={validate && !form.condicionadoGeneral ? 'has-error' : ''}>
          <label htmlFor="condicionado-general">Condicionado general</label>
          <AsyncSelect
            id="condicionado-general"
            name="condicionadoGeneral"
            className="custom-policy-select"
            loadOptions={(inputValue) => loadAsyncOptions(condicionadosGenerales, inputValue)}
            isClearable
            defaultOptions={condicionadosGenerales}
            inputValue={form.condicionadoGeneralSearch}
            value={form.condicionadoGeneral}
            onInputChange={(newText) =>
              dispatch(
                this.handleFormSelectInput(
                  form.condicionadoGeneralSearch,
                  newText,
                  'condicionadoGeneralSearch',
                ),
              )
            }
            onChange={(selectedOption, action) =>
              dispatch(handleGeneralConditionedSelectChange(selectedOption, action))
            }
            placeholder="Seleccione Condicionado General"
            isDisabled={!canEdit}
          />
        </FormGroup>
        <FormGroup className={validate && !form.producto ? 'has-error' : ''}>
          <label htmlFor="producto">Producto</label>
          <Select
            id="producto"
            name="producto"
            className="custom-policy-select"
            options={
              form.condicionadoGeneral
                ? objectsToOptions(form.condicionadoGeneral.value.productos, true)
                : []
            }
            value={form.producto}
            onChange={(selectedOption, action) =>
              dispatch(this.handleSelect(selectedOption, action))
            }
            placeholder="Seleccione Producto"
            isDisabled={!canEdit}
          />
        </FormGroup>
        <FormGroup className={validate && !form.endoso.tipoCobertura ? 'has-error' : ''}>
          <label htmlFor="tipo-cobertura">Tipo de cobertura</label>
          <Select
            id="tipo-cobertura"
            name="endoso.tipoCobertura"
            className="custom-policy-select"
            options={
              form.condicionadoGeneral
                ? objectsToOptions(form.condicionadoGeneral.value.tipos_cobertura, true)
                : []
            }
            value={form.endoso.tipoCobertura}
            onChange={(selectedOption, action) =>
              dispatch(this.handleSelect(selectedOption, action))
            }
            placeholder="Seleccione Tipo de Cobertura"
            isDisabled={!canEdit && !this.isEndosoRenovacion()}
          />
        </FormGroup>
        <FormGroup className={validate && !form.tipologiaEjecucion ? 'has-error' : ''}>
          <label htmlFor="tipologia-ejecucion">Tipología de ejecución</label>
          <Select
            id="tipologia-ejecucion"
            name="tipologiaEjecucion"
            className="custom-policy-select"
            options={
              form.condicionadoGeneral
                ? objectsToOptions(form.condicionadoGeneral.value.tipologias_ejecucion, true)
                : []
            }
            value={form.tipologiaEjecucion}
            onChange={(selectedOption, action) =>
              dispatch(this.handleSelect(selectedOption, action))
            }
            placeholder="Seleccione Tipología de Ejecución"
            isDisabled={!canEdit}
          />
        </FormGroup>
        <FormGroup className={validate && !form.endoso.tipoIntermediario ? 'has-error' : ''}>
          <label htmlFor="tipos-intermediario">Tipo de intermediario</label>
          <Select
            id="tipos-intermediario"
            name="endoso.tipoIntermediario"
            className="custom-policy-select"
            options={tiposIntermediario}
            onChange={(selectedOption, action) => {
              if (selectedOption.value.nemotecnico === 'ti-directo') {
                dispatch({
                  type: CP_HANDLE_FORM_ELEMENT_CHANGE,
                  payload: {
                    section: CP_FS_BASICOS,
                    name: 'endoso.intermediario',
                    value: null,
                  },
                });
              }
              dispatch(this.handleSelect(selectedOption, action));
            }}
            placeholder="Seleccione Tipo de Intermediario"
            value={form.endoso.tipoIntermediario}
            isDisabled={readOnly}
          />
        </FormGroup>
        <FormGroup
          style={{ gridColumn: '2/4' }}
          className={
            !isValidIntermediario(
              form.endoso.tipoIntermediario,
              form.endoso.intermediario,
              validate,
            )
              ? 'has-error'
              : ''
          }
        >
          <label htmlFor="intermediario">Intermediario</label>
          <AsyncSelect
            id="intermediario"
            name="endoso.intermediario"
            className="custom-policy-select"
            loadOptions={(inputValue) => loadClientsOptions(inputValue, ROL_INTERMEDIARIO_CREDITO)}
            defaultOptions
            inputValue={form.intermediarioSearch}
            value={form.endoso.intermediario}
            onInputChange={(newText) =>
              dispatch(
                this.handleFormSelectInput(
                  form.intermediarioSearch,
                  newText,
                  'intermediarioSearch',
                ),
              )
            }
            onChange={(selectedOption, action) =>
              dispatch(this.handleSelect(selectedOption, action))
            }
            placeholder="Seleccione Intermediario"
            isDisabled={isIntermediarioDirecto(form.endoso.tipoIntermediario) || readOnly}
          />
        </FormGroup>
        <FormGroup
          style={{ gridColumn: '1/3' }}
          className={validate && !form.asegurado ? 'has-error' : ''}
        >
          <label htmlFor="asegurado">Asegurado</label>
          <AsyncSelect
            id="asegurado"
            name="asegurado"
            className="custom-policy-select"
            loadOptions={(inputValue) =>
              loadClientsOptions(inputValue, ROL_ASEGURADO_CREDITO, true)
            }
            defaultOptions
            inputValue={form.aseguradoSearch}
            value={form.asegurado}
            onInputChange={(newText) =>
              dispatch(this.handleFormSelectInput(form.aseguradoSearch, newText, 'aseguradoSearch'))
            }
            onChange={(selectedOption, action) =>
              dispatch(handleAseguradoSelectChange(CP_FS_BASICOS, selectedOption, action))
            }
            placeholder="Seleccione Asegurado"
            isDisabled={!canEdit || readOnly}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="alias-asegurado">Alias asegurado</label>
          <Select
            id="alias-asegurado"
            name="endoso.aseguradoAlias"
            className="custom-policy-select"
            isDisabled={
              (isPolicyEmitted && !this.isEndosoRenovacion()) || aliasList.length === 0 || readOnly
            }
            isClearable
            onChange={(selectedOption, action) =>
              dispatch(this.handleSelect(selectedOption, action))
            }
            options={aliasList}
            placeholder="Seleccione Alias"
            value={form.endoso.aseguradoAlias}
          />
        </FormGroup>
        <FormGroup className={validate && !form.endoso.aseguradoActividad ? 'has-error' : ''}>
          <label htmlFor="actividad-asegurado">Giro</label>
          <Select
            id="actividad-asegurado"
            name="endoso.aseguradoActividad"
            className="custom-policy-select"
            isDisabled={
              (isPolicyEmitted && !this.isEndosoRenovacion()) ||
              actividadesList.length === 0 ||
              readOnly
            }
            isClearable
            onChange={(selectedOption, action) =>
              dispatch(this.handleSelect(selectedOption, action))
            }
            options={actividadesList}
            placeholder="Seleccione Giro"
            value={form.endoso.aseguradoActividad}
          />
        </FormGroup>
        <FormGroup className={validate && !form.endoso.actividadAsegurada ? 'has-error' : ''}>
          <label htmlFor="actividad-asegurada">Actividad asegurada</label>
          <Select
            id="actividad-asegurada"
            name="endoso.actividadAsegurada"
            className="custom-policy-select"
            options={actividadesEconomicas}
            value={form.endoso.actividadAsegurada}
            onChange={(selectedOption, action) =>
              dispatch(this.handleSelect(selectedOption, action))
            }
            placeholder="Seleccione Actividad Asegurada"
            isDisabled={(isPolicyEmitted && !this.isEndosoRenovacion()) || readOnly}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="document">Adjuntos</label>
          <FileTable
            fileList={form.adjuntos}
            newFiles={form.adjuntos_uuids}
            uploadUrl={`${BASE_URL}${API.upload}/`}
            downloadUrl={`${BASE_URL}${API.download}/`}
            loginIdToken={loginIdToken}
            handleAdd={this.addAdjunto}
            handleDelete={this.deleteAdjunto}
            handleDeleteNew={this.deleteAdjuntoNuevo}
            loading={loading}
            readOnly={readOnly}
          />
        </FormGroup>
        <VistoBuenoSection
          sectionName={CP_FS_BASICOS}
          validate={validate}
          style={{ gridColumn: '1 / 4' }}
        />
      </div>
    );
  }
}

CreditPolicyBasicInfoStep.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  creditPolicy: PropTypes.shape({
    loading: PropTypes.bool,
    form: PropTypes.shape({
      sections: PropTypes.object,
      estado: PropTypes.string,
      data: PropTypes.shape({
        moneda: PropTypes.object,
        ramoFecu: PropTypes.object,
        producto: PropTypes.object,
        endoso: PropTypes.shape({
          tipoCobertura: PropTypes.object,
          tipoEndosoId: PropTypes.number,
        }),
      }),
    }),
  }).isRequired,
  policies: PropTypes.shape({
    selectedPolicy: PropTypes.shape({
      tiposMoneda: PropTypes.array,
    }),
    tiposMoneda: PropTypes.array,
    condicionadosGenerales: PropTypes.array,
    tiposIntermediario: PropTypes.array,
    aliasList: PropTypes.array,
    actividadesList: PropTypes.array,
    actividadCreditoList: PropTypes.array,
    actividadCreditoExportacionList: PropTypes.array,
  }).isRequired,
  ramosFecu: PropTypes.arrayOf(PropTypes.object).isRequired,
  loginIdToken: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

CreditPolicyBasicInfoStep.defaultProps = {
  readOnly: false,
};

export default CreditPolicyBasicInfoStep;
