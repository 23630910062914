/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { Button, Col, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { faDownload, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import isUserHasProfile from 'helpers/profileValidator';
import { ENDOSO_CREDITO_WRITE, ENDOSO_CREDITO_READ } from 'helpers/profilePermission';
import FormSection from '../UI/FormSection';
import BaseTable from '../UI/BaseTable';
import { cellDateOperation, cellOperation, headerOperation } from '../../helpers/tables';
import {
  API,
  BASE_URL,
  CONSTRUCTION_LABEL,
  DRAFT_LABEL,
  FRONT_DF_2,
  TIPO_ENDOSO_MODIFICACION_ID,
} from '../../CONST';
import { fdate, roundFloat } from '../../helpers/helpers';
import {
  CP_DELETE_ENDOSO,
  CP_OPEN_DELETE_ENDOSO,
  CP_TOGGLE_DELETE_ENDOSO,
} from '../../redux-store/constants/action-types';
import { deleteData } from '../../redux-store/actions';
import WarningModal from '../UI/WarningModal';
import { detailsChangeVigencia } from '../../redux-store/actions/creditPolicyActions';

class CreditPolicyDetailValidities extends Component {
  vigenciasToOptions = (endososVigencia) =>
    endososVigencia.map((endoso) => ({
      label: `#${endoso.numero_vigencia}: del ${fdate(
        endoso.inicio_vigencia,
        FRONT_DF_2,
      )} al ${fdate(endoso.termino_vigencia, FRONT_DF_2)}`,
      value: endoso,
    }));

  render() {
    const {
      dispatch,
      creditPolicy: {
        policy,
        policy: { endosos, balances_vigencia: vigencias },
        detail: { selectedVigencia: sVigencia },
        selectedEndoso,
        confirmModalEndoso,
      },
    } = this.props;

    let selectedVigencia = sVigencia || vigencias[vigencias.length - 1];

    const vigenciasOptions = this.vigenciasToOptions(vigencias);
    let selectedVigenciaOption = vigenciasOptions.find(
      (opt) => opt.value.id === selectedVigencia.id,
    );
    if (!selectedVigenciaOption && vigencias.length > 0) {
      [selectedVigencia] = vigencias;
      selectedVigenciaOption = vigenciasOptions.find((opt) => opt.value.id === selectedVigencia.id);
    }

    const endososModificacion = endosos.filter(
      (endoso) => endoso.numero_vigencia === selectedVigencia.numero_vigencia,
    );

    const columns = [
      {
        Header: headerOperation('Emisión'),
        id: 'emision',
        accessor: (i) => cellDateOperation(i.fecha_emision),
        sortable: false,
      },
      {
        Header: headerOperation('Inicio Vigencia Endoso'),
        id: 'inicio-vigencia-endoso',
        accessor: (i) => cellOperation(i.inicio_vigencia_endoso || i.inicio_vigencia),
        sortable: false,
      },
      {
        Header: headerOperation('#'),
        id: 'secuencia',
        accessor: (i) => cellOperation(i.secuencia_endoso),
        sortable: false,
      },
      {
        Header: headerOperation('Forma de Pago'),
        id: 'tipo_pago',
        accessor: (i) => cellOperation(i.tipo_pago, 'left'),
        sortable: false,
      },
      {
        Header: headerOperation('Tasa Costo Seguro'),
        id: 'tasa_costo_seguro',
        accessor: (i) => cellOperation(`${i.tasa_costo_seguro_defecto_pct}%`, 'right'),
        sortable: false,
      },
      {
        Header: headerOperation('Prima Mínima Anual'),
        id: 'prima_minima_anual',
        accessor: (i) =>
          cellOperation(
            <NumberFormat
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              suffix={` ${policy.base.moneda_asegurada.nombre_corto}`}
              value={roundFloat(i.prima_minima_anual_amt)}
              displayType="text"
            />,
            'right',
          ),
        sortable: false,
      },
      {
        Header: headerOperation('Estimación de Ventas'),
        id: 'estimacion_ventas',
        accessor: (i) =>
          cellOperation(
            <NumberFormat
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              suffix={` ${policy.base.moneda_asegurada.nombre_corto}`}
              value={i.estimacion_ventas_amt}
              displayType="text"
            />,
            'right',
          ),
        sortable: false,
      },
      {
        Header: headerOperation('Estado'),
        id: 'estado',
        accessor: (i) => {
          let alertClass = 'alert-success';
          if (i.estado === DRAFT_LABEL) {
            alertClass = 'alert-primary';
          } else if (i.estado === CONSTRUCTION_LABEL) {
            alertClass = 'alert-warning';
          }
          return cellOperation(
            <div className={`alert ${alertClass} text-center small`} role="alert">
              {i.estado}
            </div>,
            'center',
            false,
          );
        },
        sortable: false,
      },
      {
        Header: headerOperation('Acciones'),
        id: 'acciones',
        accessor: (i) =>
          cellOperation(
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: `${i.documento ? '1fr' : ''} ${i.emitido ? '' : '1fr 1fr'}`,
                padding: '0 25%',
              }}
            >
              {!i.emitido && (
                <>
                  {isUserHasProfile(ENDOSO_CREDITO_READ) && (
                    <Link
                      to={`/credit/policies/${policy.id}/${
                        i.tipo === TIPO_ENDOSO_MODIFICACION_ID ? 'modify' : 'renew'
                      }/${selectedVigencia.numero_vigencia}`}
                      className="table-link policy"
                    >
                      <FontAwesomeIcon
                        icon={isUserHasProfile(ENDOSO_CREDITO_WRITE) ? faEdit : faEye}
                      />
                    </Link>
                  )}
                  {isUserHasProfile(ENDOSO_CREDITO_WRITE) && (
                    <button
                      type="button"
                      className="table-link policy"
                      onClick={() => dispatch({ type: CP_OPEN_DELETE_ENDOSO, payload: i })}
                      title="eliminar"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  )}
                </>
              )}
              {i.documento && (
                <a
                  className="reactstrap-table-link"
                  href={i.documento.file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faDownload} />
                </a>
              )}
            </div>,
          ),
        sortable: false,
      },
    ];

    return (
      <Col md={{ offset: 2, size: 8 }}>
        <FormSection title="Endosos">
          <FormGroup>
            <label htmlFor="vigencia">Vigencia</label>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '50% max-content max-content max-content',
                gridGap: '10px',
              }}
            >
              <div style={{ zIndex: 20 }}>
                <Select
                  id="vigencia"
                  name="vigencia"
                  className="custom-policy-select"
                  value={selectedVigenciaOption}
                  onChange={(selectedOption) => dispatch(detailsChangeVigencia(selectedOption))}
                  options={vigenciasOptions}
                />
              </div>

              {isUserHasProfile(ENDOSO_CREDITO_WRITE) && (
                <Button
                  color="orsan-primary"
                  tag={Link}
                  to={`/credit/policies/${policy.id}/renew/${vigencias[0].numero_vigencia}`}
                  disabled={!!policy.borrador}
                >
                  Nueva vigencia
                </Button>
              )}
              {isUserHasProfile(ENDOSO_CREDITO_WRITE) && (
                <Button
                  color="orsan-secondary"
                  tag={Link}
                  to={`/credit/policies/${policy.id}/modify/${selectedVigencia.numero_vigencia}`}
                  disabled={!!policy.borrador}
                >
                  Nuevo endoso
                </Button>
              )}
              {isUserHasProfile(ENDOSO_CREDITO_READ) && (
                <Button
                  outline
                  color="orsan-primary"
                  tag={Link}
                  to={`/credit/policies/${policy.id}/balance/${selectedVigencia.numero_vigencia}`}
                  disabled={
                    !(
                      policy.numero_vigencias_emitidas &&
                      policy.numero_vigencias_emitidas.includes(selectedVigencia.numero_vigencia)
                    )
                  }
                >
                  Ver detalles
                </Button>
              )}
            </div>
          </FormGroup>
          <BaseTable
            columns={columns}
            data={endososModificacion.sort((a, b) => a.secuencia_endoso - b.secuencia_endoso)}
          />
          <WarningModal
            isOpen={confirmModalEndoso.isOpen}
            toggle={() => dispatch({ type: CP_TOGGLE_DELETE_ENDOSO })}
            handleOk={() => {
              dispatch(
                deleteData({
                  url: `${BASE_URL}${API.endosos_credito}/${selectedEndoso.id}/`,
                  id: selectedEndoso.id,
                  type: CP_DELETE_ENDOSO,
                }),
              );
            }}
            loading={confirmModalEndoso.loading}
            okBtnText="Eliminar Endoso"
            confirmMsg="Está a un paso de eliminar un Endoso"
          />
        </FormSection>
      </Col>
    );
  }
}

CreditPolicyDetailValidities.propTypes = {
  dispatch: PropTypes.func.isRequired,
  creditPolicy: PropTypes.shape({
    selectedEndoso: PropTypes.object,
    confirmModalEndoso: PropTypes.shape({
      isOpen: PropTypes.bool,
      loading: PropTypes.bool,
    }),
    policy: PropTypes.shape({
      id: PropTypes.number.isRequired,
      base: PropTypes.object,
      endosos: PropTypes.array,
      balances_vigencia: PropTypes.array,
      borrador: PropTypes.object,
      numero_vigencias_emitidas: PropTypes.array,
    }),
    detail: PropTypes.shape({
      vigencias: PropTypes.array,
      selectedVigencia: PropTypes.object,
      endososModificacion: PropTypes.array,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  loginIdToken: state.login.id_token,
  creditPolicy: state.creditPolicy,
});

export default connect(mapStateToProps)(CreditPolicyDetailValidities);
