import React from 'react';
import PropTypes from 'prop-types';
import { faCheckCircle, faChevronRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'reactstrap';

const CreditPolicyFormAccordionSection = ({ title, children, toggle, collapsed, isValid, expansible }) => (
  <Col
    className='form-section'
    md={{ offset: 1, size: 10 }}
    style={{opacity: expansible ? 1 : 0.5}}
  >
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
    <div
      className={`accordion-section-header${expansible ? ' expansible' : ''}`}
      style={{
        marginTop: '20px',
        display: 'grid',
        gridTemplateColumns: '20px auto 20px',
        gridGap: '2px',
        alignItems: 'center',
        cursor: expansible ? 'pointer' : undefined,
      }}
      onClick={expansible ? toggle : () => {}}
    >
      <FontAwesomeIcon
        icon={isValid ? faCheckCircle : faExclamationCircle}
        style={{ marginBottom: '5px', color: `${isValid ? '#315e90' : '#dc3545'}` }}
      />
      <div><h5 className="form-title">{title}</h5></div>
      <FontAwesomeIcon
        icon={faChevronRight}
        style={{ cursor: expansible ? 'pointer' : undefined, marginBottom: '5px' }}
        transform={collapsed ? {} : {rotate: 90}}
      />
    </div>
    <hr className="form-separator" />
    {children}
  </Col>
);

CreditPolicyFormAccordionSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  toggle: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  expansible: PropTypes.bool.isRequired,
};

export default CreditPolicyFormAccordionSection;
