import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import FormSection from '../../../UI/FormSection';

const CostoDeProrrogaSection = ({
  clientesInnominados,
  costoProrrogaNominadoPct,
  costoProrrogaInnominadoPct,
  dispatch,
  handleChange,
  style,
  validate,
  disabled,
}) => {
  return (
    <FormSection title="Costo de prorroga" style={style}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '110px auto',
          gridGap: '6px 8px',
          alignItems: 'center',
        }}
      >
        <div>
          <NumberFormat
            id="costo-prorroga-nominado"
            name="costoProrrogaNominadoPct"
            className={`form-control${
              validate && costoProrrogaNominadoPct === null ? ' is-invalid' : ''
            }`}
            onValueChange={values => {
              const newValue = values.floatValue;
              dispatch(
                handleChange('endoso.costoProrrogaNominadoPct', newValue),
              );
            }}
            allowNegative={false}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale="2"
            fixedDecimalScale
            value={costoProrrogaNominadoPct}
            suffix="%"
            style={{ width: '110px' }}
            disabled={disabled}
          />
        </div>
        <div className="small-form-value">Clientes nominados</div>
        <div>
          <NumberFormat
            id="costo-prorroga-innominado"
            name="costoProrrogaInnominadoPct"
            className={`form-control${
              validate &&
              clientesInnominados &&
              costoProrrogaInnominadoPct === null
                ? ' is-invalid'
                : ''
            }`}
            onValueChange={values => {
              const newValue = values.floatValue;
              dispatch(
                handleChange('endoso.costoProrrogaInnominadoPct', newValue),
              );
            }}
            allowNegative={false}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale="2"
            fixedDecimalScale
            value={costoProrrogaInnominadoPct}
            suffix="%"
            style={{ width: '110px' }}
            disabled={!clientesInnominados || disabled}
          />
        </div>
        <div className="small-form-value">Clientes innominados</div>
      </div>
    </FormSection>
  );
};

CostoDeProrrogaSection.propTypes = {
  clientesInnominados: PropTypes.bool.isRequired,
  costoProrrogaNominadoPct: PropTypes.number,
  costoProrrogaInnominadoPct: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CostoDeProrrogaSection.defaultProps = {
  costoProrrogaNominadoPct: null,
  costoProrrogaInnominadoPct: null,
  style: {},
  disabled: false,
};

export default CostoDeProrrogaSection;
