import React, { useState, Fragment } from 'react';
import { Card, CardBody, Collapse, FormGroup, Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { parameterizedConditionType } from '../../../../redux-store/types';
import {
  changeParameterizedConditionValue,
  handleParameterizedConditionedOptionChange,
  toggleParameterizedConditionCheckbox,
} from '../../../../redux-store/actions/creditPolicyActions';
import Parameter from './Parameter';
import BoolParameter from './BoolParameter';
import TablaSobreprimasParameter from '../conditions/TablaSobreprimasParameter';
import { isValidOption, isValidParameterizedCondition } from '../../../../helpers/validation';
import isUserHasProfile from '../../../../helpers/profileValidator';
import {
  CP_COBERTURA_ESPECIAL_CEO,
  CP_COBERTURA_ESPECIAL_OPERACIONES,
  CP_COBERTURA_OC_SIMPLE_CEO,
  CP_COBERTURA_OC_SIMPLE_OPERACIONES,
  CP_CONTROVERSIAS_COMERCIALES_CEO,
  CP_CONTROVERSIAS_COMERCIALES_OPERACIONES,
} from '../../../../helpers/profilePermission';

const ParameterizedCondition = ({ condition, disabled, dispatch, moneda, validate }) => {
  const [collapse, setCollapse] = useState(true);

  const toggle = () => setCollapse(!collapse);

  const getText = (opcion) =>
    Object.keys(opcion.parametros).reduce((txt, key) => {
      let val;
      if (opcion.parametros[key].type !== 'bool') {
        val = opcion.values[key] || '??';
      } else {
        val = opcion.values[key] ? opcion.parametros[key].value : '';
      }
      if (opcion.parametros[key].unit === 'CURRENCY') {
        val = opcion.values[key]
          ? `${String(opcion.values[key]).replace('.', ',')} ${moneda}`
          : '??';
      }
      return txt.replace(new RegExp(`{{${key}}}`, 'g'), val);
    }, opcion.texto);

  const isValidCondition = isValidParameterizedCondition(condition);

  const isValidCoberturaEspecial = (value) => {
    const esCeo = isUserHasProfile(CP_COBERTURA_ESPECIAL_CEO);
    const esOperaciones = isUserHasProfile(CP_COBERTURA_ESPECIAL_OPERACIONES);

    if (esCeo) {
      return value <= 60;
    }
    if (esOperaciones) {
      return value <= 25;
    }
    return value <= 15;
  };

  const isValidCoberturaOCSimple = (value) => {
    const esCeo = isUserHasProfile(CP_COBERTURA_OC_SIMPLE_CEO);
    const esOperaciones = isUserHasProfile(CP_COBERTURA_OC_SIMPLE_OPERACIONES);

    if (esCeo) {
      return value <= 60;
    }
    if (esOperaciones) {
      return value <= 45;
    }
    return value <= 30;
  };

  const isValidControversiasComerciales = (value) => {
    const esCeo = isUserHasProfile(CP_CONTROVERSIAS_COMERCIALES_CEO);
    const esOperaciones = isUserHasProfile(CP_CONTROVERSIAS_COMERCIALES_OPERACIONES);

    if (esCeo) {
      return value <= 30;
    }
    if (esOperaciones) {
      return value <= 10;
    }
    return value <= 5;
  };

  const isAllowed = {
    'clasificacion-innominada': (values) =>
      !values.value || (values.floatValue > 0 && values.floatValue <= 12),
    'notas-de-credito': (values) => !values.value || values.floatValue <= 180,
    'cobertura-especial': (values) => !values.value || isValidCoberturaEspecial(values.floatValue),
    'controversias-comerciales': (values) =>
      !values.value || isValidControversiasComerciales(values.floatValue),
    'cobertura-oc-simple': (values) =>
    !values.value || values.floatValue > 0 && isValidCoberturaOCSimple(values.floatValue),
  };

  const allowNegative = {
    'clasificacion-innominada': false,
    'notas-de-credito': false,
    'cobertura-especial': false,
    'controversias-comerciales': false,
    'cobertura-oc-simple': false
  };

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 20px',
          alignItems: 'center',
        }}
      >
        <label
          htmlFor={condition.name}
          style={{ fontSize: '16px', fontWeight: 'normal', marginBottom: 0 }}
          className={validate && !isValidCondition ? 'error-color' : ''}
        >
          <input
            id={condition.name}
            type="checkbox"
            style={{ marginRight: '20px' }}
            checked={condition.selected}
            disabled={disabled}
            onChange={() => dispatch(toggleParameterizedConditionCheckbox(condition.id))}
          />
          {condition.name}
        </label>
        <FontAwesomeIcon
          icon={faChevronRight}
          onClick={toggle}
          style={{ cursor: 'pointer' }}
          className={validate && !isValidCondition ? 'error-color' : ''}
          transform={collapse ? {} : { rotate: 90 }}
        />
      </div>
      <div>
        <Collapse isOpen={!collapse} style={{ paddingTop: '20px', paddingLeft: '34px' }}>
          {condition.opciones.map((opcion) => (
            <Fragment key={opcion.name}>
              {condition.opciones.length > 1 && (
                <FormGroup check style={{ margin: '20px -25px' }}>
                  <Label check className={validate && !isValidOption(opcion) ? 'error-color' : ''}>
                    <Input
                      type="radio"
                      name={`radio-option-${condition.id}`}
                      defaultChecked={opcion.selected}
                      onChange={() =>
                        dispatch(
                          handleParameterizedConditionedOptionChange(condition.id, opcion.id),
                        )
                      }
                      disabled={!condition.selected || disabled}
                      style={{ marginTop: '3px' }}
                    />
                    &nbsp;&nbsp;
                    {opcion.name}
                  </Label>
                </FormGroup>
              )}
              {Object.keys(opcion.parametros).map(
                (key) =>
                  !['bool', 'tabla_sobreprimas'].includes(opcion.parametros[key].type) && (
                    <Parameter
                      key={key + condition.selected + opcion.selected}
                      paramKey={key}
                      spec={opcion.parametros[key]}
                      val={opcion.values[key]}
                      onChange={(value) =>
                        dispatch(
                          changeParameterizedConditionValue(condition.id, opcion.id, key, value),
                        )
                      }
                      moneda={moneda}
                      required={condition.selected && opcion.selected}
                      validate={validate}
                      disabled={disabled}
                      isAllowed={isAllowed[condition.natural_key]}
                      allowNegative={allowNegative[condition.natural_key]}
                    />
                  ),
              )}
              {Object.keys(opcion.parametros).length !== 1 ||
                (opcion.parametros[Object.keys(opcion.parametros)[0]].type !== 'text' && (
                  <Card style={{ marginTop: '20px' }}>
                    <CardBody className="small-text pre-wrap-white-space">
                      {getText(opcion)}
                    </CardBody>
                  </Card>
                ))}
              {Object.keys(opcion.parametros).map(
                (key) =>
                  opcion.parametros[key].type === 'bool' && (
                    <BoolParameter
                      key={key + condition.selected + opcion.selected}
                      spec={opcion.parametros[key]}
                      val={opcion.values[key]}
                      onChange={(value) =>
                        dispatch(
                          changeParameterizedConditionValue(condition.id, opcion.id, key, value),
                        )
                      }
                      disabled={disabled || !opcion.selected}
                    />
                  ),
              )}
              {Object.keys(opcion.parametros).map(
                (key) =>
                  opcion.parametros[key].type === 'tabla_sobreprimas' && (
                    <div
                      key={key + condition.selected + opcion.selected}
                      style={{ marginTop: '20px', maxWidth: '600px' }}
                    >
                      <TablaSobreprimasParameter
                        condition={condition}
                        opcion={opcion}
                        dispatch={dispatch}
                        disabled={disabled || !opcion.selected}
                        validate={condition.selected && opcion.selected && validate}
                      />
                    </div>
                  ),
              )}
            </Fragment>
          ))}
        </Collapse>
        <hr />
      </div>
    </>
  );
};

ParameterizedCondition.propTypes = {
  dispatch: PropTypes.func.isRequired,
  condition: parameterizedConditionType.isRequired,
  disabled: PropTypes.bool.isRequired,
  moneda: PropTypes.string.isRequired,
  validate: PropTypes.bool.isRequired,
};

export default ParameterizedCondition;
