import { useRef, useEffect } from 'react';

const useExitPrompt = (fn, checker) => {
  const cb = useRef(fn);

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => {
      if (cb.current) {
        cb.current(...args);
      }
    };
    if (checker) {
      window.addEventListener('beforeunload', onUnload);
    } else {
      window.removeEventListener('beforeunload', onUnload);
    }
    return () => window.removeEventListener('beforeunload', onUnload);
  }, [checker]);
};

export default useExitPrompt;