
export const objectToOption = (object, labelField = 'descripcion') => ({
  label: object[labelField],
  value: object,
});

export const objectToOptionForMultiSelect = (object, labelField = 'descripcion') => ({
  label: object[labelField],
  value: object.id,
  object,
});

export const objectsToOptions = (objects, activeOnly = false, isMultiSelect = false, labelField = 'descripcion') => {
  let objs = objects;
  if (activeOnly) {
    objs = objects.filter(node => node.activo);
  }
  if (isMultiSelect) {
    return objs.map(obj => (objectToOptionForMultiSelect(obj, labelField)));
  }
  return objs.map(obj => (objectToOption(obj, labelField)));
};

export const filterOption = (inputValue, options) => options.filter(
  i => i.label.toLowerCase().includes(inputValue.toLowerCase()),
);

export const loadAsyncOptions = (options, inputValue) => new Promise((resolve) => {
  setTimeout(() => {
    resolve(filterOption(inputValue, options));
  }, 1000);
});

export const hasOptionChanged = (prevOption, option) => {
    let hasChanged = false;
    if (prevOption && option) {
        hasChanged = prevOption.value.id !== option.value.id;
    } else if (!prevOption && option) {
        hasChanged = true;
    }
    return hasChanged;
};

export const clientToOption = client => {
  if (client) {
    return {
      label: `${client.rut} - ${client.nombre}`,
      value: client,
    };
  }
  return null;
};

export const getTiposVigenciaOptions = form => {
  return objectsToOptions(form.tiposVigencia, false, false, 'name');
};

export const getSelectedTipoVigencia = form => {
  return getTiposVigenciaOptions(form).find(tv => tv.value.id === form.data.endoso.tipoVigenciaId);
};
