import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import OportunitiesList from 'components/oportunities/OportunitiesList';
import OportunitiesForm from 'components/oportunities/OportunitiesForm';
import GeneralConditionedList from '../components/condicionados/GeneralConditionedList';
import GeneralConditionedCreate from '../components/condicionados/GeneralConditionedCreate';
import GeneralConditionedEdit from '../components/condicionados/GeneralConditionedEdit';
import PolicyList from '../components/policies/PolicyList';
import PolicyCreate from '../components/policies/PolicyCreate';
import PolicyEdit from '../components/policies/PolicyEdit';
import CreditPolicyList from '../components/credits/CreditPolicyList';
import CreditPolicyForm from '../components/credits/wizard/CreditPolicyForm';
import CreditPolicyBalanceForm from '../components/credits/wizard/CreditPolicyBalanceForm';
import CreditPolicyDetail from '../components/credits/CreditPolicyDetail';
import ProjectForm from '../components/projectComponents/ProjectForm';
import ProjectList from '../components/projectComponents/ProjectList';
import ProjectPolicyCreate from '../components/projectPoliciesComponents/ProjectPolicyCreate';
import {
  TIPO_ENDOSO_EMISION_ID,
  TIPO_ENDOSO_MODIFICACION_ID,
  TIPO_ENDOSO_RENOVACION_ID,
} from '../CONST';
import AppRedirect from '../components/shared/AppRedirect';
import isUserHasProfile from '../helpers/profileValidator';
import {
  POLICY_WRITE,
  POLICY_READ,
  GENERAL_CONDITIONED_WRITE,
  GENERAL_CONDITIONED_READ,
  CREDIT_POLICY_READ,
  ENDOSO_CREDITO_READ,
  CREDIT_POLICY_WRITE,
  PROYECTO_VV_READ,
  POLIZA_VV_READ,
  PROYECTO_VV_WRITE,
  POLIZA_VV_WRITE,
  EXTERNO_VV,
} from '../helpers/profilePermission';


const LoggedInRoutes = ({ user }) => {
  let defaultComponent;
  if (user.is_orsan) {
    if (isUserHasProfile(GENERAL_CONDITIONED_READ)) {
      defaultComponent = GeneralConditionedList;
    } else if (isUserHasProfile(POLICY_READ)) {
      defaultComponent = PolicyList;
    } else {
      defaultComponent = CreditPolicyList;
    }
  }
  else if (isUserHasProfile(EXTERNO_VV)) {
    defaultComponent = ProjectList;
  }

  return !user.has_access ? (
    <AppRedirect />
  ) : (
    <Switch>
      <Route exact path="/" component={defaultComponent} />
      {isUserHasProfile(GENERAL_CONDITIONED_READ) && (
        <Route exact path="/condicionados" component={GeneralConditionedList} />
      )}

      {isUserHasProfile(GENERAL_CONDITIONED_WRITE) && (
        <Route exact path="/condicionados/create" component={GeneralConditionedCreate} />
      )}

      {isUserHasProfile(GENERAL_CONDITIONED_READ) && (
        <Route path="/condicionados/edit/:id" component={GeneralConditionedEdit} />
      )}

      {isUserHasProfile(POLICY_READ) && (
        <Route exact path="/guarantee/policies" component={PolicyList} />
      )}

      {isUserHasProfile(POLICY_READ) && (
        <Route exact path="/guarantee/oportunities" component={OportunitiesList} />
      )}

      {isUserHasProfile(POLICY_WRITE) && (
        <Route exact path="/guarantee/oportunities/create" component={OportunitiesForm} />
      )}

      {isUserHasProfile(POLICY_WRITE) && (
        <Route exact path="/guarantee/oportunities/:id" component={OportunitiesForm} />
      )}

      {isUserHasProfile(POLICY_WRITE) && (
        <Route exact path="/guarantee/policies/create" component={PolicyCreate} />
      )}

      {isUserHasProfile(POLICY_READ) && (
        <Route path="/guarantee/policies/edit/:id" component={PolicyEdit} />
      )}

      {isUserHasProfile(CREDIT_POLICY_READ) && (
        <Route exact path="/credit/policies" component={CreditPolicyList} />
      )}
      {isUserHasProfile(ENDOSO_CREDITO_READ) && (
        <Route exact path="/credit/policies/detail/:id" component={CreditPolicyDetail} />
      )}
      {isUserHasProfile(CREDIT_POLICY_WRITE) && (
        <Route
          exact
          path="/credit/policies/create"
          render={(props) => <CreditPolicyForm {...props} tipoEndosoId={TIPO_ENDOSO_EMISION_ID} />}
        />
      )}
      {isUserHasProfile(ENDOSO_CREDITO_READ) && (
        <Route
          path="/credit/policies/:id/emit"
          render={(props) => <CreditPolicyForm {...props} tipoEndosoId={TIPO_ENDOSO_EMISION_ID} />}
        />
      )}
      <Route
        path="/credit/policies/:id/edit"
        render={(props) => (
          <CreditPolicyForm {...props} tipoEndosoId={TIPO_ENDOSO_MODIFICACION_ID} />
        )}
      />
      {isUserHasProfile(ENDOSO_CREDITO_READ) && (
        <Route
          path="/credit/policies/:id/modify/:numeroVigencia"
          render={(props) => (
            <CreditPolicyForm {...props} tipoEndosoId={TIPO_ENDOSO_MODIFICACION_ID} />
          )}
        />
      )}
      {isUserHasProfile(ENDOSO_CREDITO_READ) && (
        <Route
          path="/credit/policies/:id/renew/:numeroVigencia"
          render={(props) => (
            <CreditPolicyForm {...props} tipoEndosoId={TIPO_ENDOSO_RENOVACION_ID} />
          )}
        />
      )}
      {isUserHasProfile(ENDOSO_CREDITO_READ) && (
        <Route
          path="/credit/policies/:id/balance/:numeroVigencia"
          render={(props) => <CreditPolicyBalanceForm {...props} />}
        />
      )}

      {/* Rutas de Proyectos Venta en Verde */}
      {isUserHasProfile(PROYECTO_VV_READ) && (
        <Route exact path="/garantia/proyectos" component={ProjectList} />
      )}

      {isUserHasProfile(PROYECTO_VV_READ) && (
        <Route exact path="/garantia/proyectos/:id/detalle" component={ProjectForm} />
      )}

      {isUserHasProfile(PROYECTO_VV_WRITE) && (
        <Route exact path="/garantia/proyectos/crear" component={ProjectForm} />
      )}

      {isUserHasProfile(POLIZA_VV_WRITE) && (
        <Route exact path="/garantia/proyectos/:idProyecto/poliza/crear" component={ProjectPolicyCreate} />
      )}

      {isUserHasProfile(POLIZA_VV_READ) && (
        <Route exact path="/garantia/proyectos/:idProyecto/poliza/:idPoliza/detalle" component={ProjectPolicyCreate} />
      )}

      <Route render={() => <Redirect to="/" />} />
    </Switch>
  )};

LoggedInRoutes.propTypes = {
  user: PropTypes.shape({
    has_access: PropTypes.bool,
    is_orsan: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
  };
};

export default connect(mapStateToProps)(LoggedInRoutes);
