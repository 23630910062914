import sso from './auth';
import { API, BASE_URL } from '../CONST';
import { clientToOption } from './selects';

export const filterClientOption = (
  search,
  roles,
  filterGiro,
  localFilter,
) => {
  const params = [];
  if (search.length > 0) {
    params.push(`&search=${search}`);
  }
  if (roles !== null) {
    if (Array.isArray(roles)) {
      params.push(`roles__terms=${roles.join('__')}`);
    } else {
      params.push(`roles__term=${roles}`);
    }
  }
  if (filterGiro) {
    params.push('actividades__exists=true');
  }
  const url = `${BASE_URL}${API.clientes}/${params ? `?${params.join('&')}` : ''}`;
  return sso.fetch(url)
    .then(data => data.results.filter(localFilter).map(client => clientToOption(client)));
};

export const loadClientsOptions = (
  search,
  roles = null,
  filterGiro = false,
  localFilter = () => true,
) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(filterClientOption(search, roles, filterGiro, localFilter));
    }, 1000);
  });
