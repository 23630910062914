import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import FormSection from '../../../UI/FormSection';
import { objectsToOptions } from '../../../../helpers/selects';

const PlazoMaximoDeCreditoSection = ({
  plazoMaximoCreditoDiasId,
  plazosGestionesPropiasDias,
  plazoMinimo,
  dispatch,
  handleSelect,
  style,
  validate,
  disabled,
}) => {
  const plazosOptions = objectsToOptions(plazosGestionesPropiasDias, false, false, 'dias').map(
    (opt) => ({ ...opt, disabled: opt.value.dias < plazoMinimo }),
  );
  const plazoSelectedOption = plazosOptions.find(
    (plazo) => plazo.value.id === plazoMaximoCreditoDiasId,
  );

  if (plazoMaximoCreditoDiasId === null && plazosOptions.length > 0) {
    const defaultOption = plazosOptions.find((plazo) => plazo.label === 90);
    const action = {
      action: 'select-option',
      name: 'endoso.plazoMaximoCreditoDiasId',
      option: defaultOption,
    };
    dispatch(handleSelect(defaultOption.value.id, action));
  }

  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <FormSection title="Plazo máximo de crédito" style={style}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '80px auto',
          gridGap: '6px 8px',
          alignItems: 'center',
        }}
      >
        <div className={validate && !plazoSelectedOption ? 'has-error' : ''}>
          <Select
            id="plazo-maximo-credito-dias"
            name="endoso.plazoMaximoCreditoDiasId"
            className="custom-policy-select"
            options={plazosOptions}
            isOptionDisabled={(opt) => opt.disabled}
            onChange={(selectedOption, action) =>
              dispatch(handleSelect(selectedOption.value.id, action))
            }
            value={plazoSelectedOption}
            placeholder=""
            onMenuOpen={() => setMenuOpened(true)}
            onMenuClose={() => setMenuOpened(false)}
            isDisabled={disabled}
          />
        </div>
        <div className="small-form-value">
          días para todo cliente, salvo que se indique algo distinto para algún cliente.
        </div>
        <div />
        {menuOpened && plazosOptions.some((opt) => opt.disabled) && (
          <div className="alert-light">Debe incluir todos los plazos de la tabla inferior.</div>
        )}
      </div>
    </FormSection>
  );
};

PlazoMaximoDeCreditoSection.propTypes = {
  plazoMaximoCreditoDiasId: PropTypes.number,
  plazosGestionesPropiasDias: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      dias: PropTypes.number,
    }),
  ).isRequired,
  plazoMinimo: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

PlazoMaximoDeCreditoSection.defaultProps = {
  style: {},
  plazoMaximoCreditoDiasId: null,
  disabled: false,
};

export default PlazoMaximoDeCreditoSection;
