/* eslint-disable camelcase,jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';
import { Button, Col, FormGroup } from 'reactstrap';
import FormSection from '../UI/FormSection';
import { loadAsyncOptions, objectsToOptions, objectToOption, clientToOption } from '../../helpers/selects';
import { getData } from '../../redux-store/actions';
import {
  GET_ACTIVIDADES,
  GET_ALIASES,
  POLICY_DELETE_ADJUNTO,
} from '../../redux-store/constants/action-types';
import {
  API,
  BASE_URL,
  ROL_AFIANZADO_GARANTIA,
  ROL_ASEGURADO_GARANTIA,
  ROL_INTERMEDIARIO_GARANTIA,
  TIPO_INTERMEDIARIO_DIRECTO_ID,
} from '../../CONST';
import { loadClientsOptions } from '../../helpers/clients';
import FileTable from '../shared/FileTable';
import { isIntermediarioDirecto } from '../../helpers/validation';

import isUserHasProfile from '../../helpers/profileValidator';
import { POLICY_WRITE } from '../../helpers/profilePermission';
import { asyncDisableStyle } from '../../helpers/helpers';

class PolicyForm extends Component {
  constructor(props) {
    super(props);
    this.filesTable = React.createRef();
    this.state = {
      asegurado_alias: '',
      giro: null,
      actividad_asegurada: undefined,
      afianzado: undefined,
      afianzado_search: undefined,
      asegurado: undefined,
      asegurado_search: undefined,
      data: undefined,
      moneda: undefined,
      producto: null,
      tipo_cobertura: null,
      tipo_institucion: undefined,
      tipologia_ejecucion: null,
      condicionado_general: undefined,
      condicionado_general_search: undefined,
      tipo_intermediario: undefined,
      intermediario: undefined,
      intermediario_search: undefined,
      awaitingResponse: false,
    };
  }

  componentDidMount() {
    const {
      conditioned: { tipos_institucion },
      edit,
      dispatchGetData,
      policies,
    } = this.props;
    const {
      actividadGarantiaList,
      condicionadosGenerales,
      selectedPolicy,
      tiposIntermediario,
      tiposMoneda,
      balance,
    } = policies;

    if (edit) {
      const afianzado = clientToOption(selectedPolicy.afianzado);
      const asegurado = clientToOption(selectedPolicy.base.asegurado);
      const asegurado_alias = selectedPolicy.base.asegurado_alias
        ? {
            label: selectedPolicy.base.asegurado_alias.alias,
            value: selectedPolicy.base.asegurado_alias.id,
          }
        : '';
      const giro = selectedPolicy.actividad
        ? {
            label: `${selectedPolicy.actividad.codigo} - ${selectedPolicy.actividad.nombre}`,
            value: selectedPolicy.actividad.id,
          }
        : null;
      const condicionadoGeneral = condicionadosGenerales.find(
        (node) => node.value.id === selectedPolicy.base.poliza_maestra.id,
      );

      const intermediario =
        balance.length > 0 && selectedPolicy.emitido
          ? clientToOption(balance[0].intermediario)
          : clientToOption(selectedPolicy.base.intermediario);

      const tipoIntermediarioId =
        balance.length > 0 && selectedPolicy.emitido
          ? balance[0].tipo_intermediario_id
          : selectedPolicy.base.tipo_intermediario.id;

      dispatchGetData({
        url: `${BASE_URL}${API.alias}?organization_id=${selectedPolicy.base.asegurado.id}`,
        type: GET_ALIASES,
      });
      dispatchGetData({
        url: `${BASE_URL}${API.actividades}?organization_id=${selectedPolicy.afianzado.id}`,
        type: GET_ACTIVIDADES,
      });
      this.setState({
        actividad_asegurada: actividadGarantiaList.find(
          (node) => node.value.id === selectedPolicy.base.actividad_economica.id,
        ),
        afianzado,
        asegurado,
        asegurado_alias,
        condicionado_general: condicionadoGeneral,
        data: selectedPolicy,
        intermediario,
        moneda: tiposMoneda.find(
          (node) => node.value.id === selectedPolicy.base.moneda_asegurada.id,
        ),
        producto: objectToOption(selectedPolicy.base.producto),
        tipo_cobertura: objectToOption(selectedPolicy.base.tipo_cobertura),
        tipo_intermediario: tiposIntermediario.find(
          (node) => node.value.id === tipoIntermediarioId,
        ),
        tipo_institucion: tipos_institucion.find(
          (node) => node.value.id === selectedPolicy.base.tipo_institucion.id,
        ),
        tipologia_ejecucion: objectToOption(selectedPolicy.base.tipologia_ejecucion),
        giro,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      policies: { balance, tiposIntermediario },
    } = this.props;

    const {
      policies: { balance: prevBalance },
    } = prevProps;

    if (balance && balance.length > 0 && prevBalance && prevBalance.length > 0) {
      const { tipo_intermediario_id, intermediario_id, intermediario } = balance[0];

      const {
        tipo_intermediario_id: oldTipoIntermediario,
        intermediario_id: oldIntermediario,
      } = prevBalance[0];

      if (oldTipoIntermediario !== tipo_intermediario_id) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          tipo_intermediario: tiposIntermediario.find(
            (node) => node.value.id === tipo_intermediario_id,
          ),
        });
      }

      if (oldIntermediario !== intermediario_id) {
        if (tipo_intermediario_id && tipo_intermediario_id === TIPO_INTERMEDIARIO_DIRECTO_ID) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ intermediario: null });
        } else {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            intermediario: clientToOption(intermediario),
          });
        }
      }
    }
  }

  isDisabled = () => {
    const {
      moneda,
      producto,
      tipologia_ejecucion,
      tipo_cobertura,
      tipo_institucion,
      condicionado_general,
      intermediario,
      asegurado,
      actividad_asegurada,
      afianzado,
      tipo_intermediario,
      giro,
    } = this.state;

    const validation =(
        moneda &&
        condicionado_general &&
        producto &&
        tipo_cobertura &&
        tipologia_ejecucion &&
        tipo_institucion &&
        asegurado &&
        actividad_asegurada &&
        afianzado &&
        giro &&
        (
          intermediario ||
          tipo_intermediario && tipo_intermediario.value.id === TIPO_INTERMEDIARIO_DIRECTO_ID
        )
      )

    return !validation;
  };

  isDisabledByProfile = () => {
    const validRole = isUserHasProfile(POLICY_WRITE);
    return !validRole;
  };

  isAliasDisabled = () => {
    const { aliasList } = this.props;
    return !(aliasList.length > 0);
  };

  handleSelectChange = (option, e) => {
    const { dispatchGetData } = this.props;
    this.setState({
      [e.name]: option,
    });
    if (e.name === 'asegurado') {
      this.setState({
        asegurado_alias: '',
      });
      dispatchGetData({
        url: `${BASE_URL}${API.alias}?organization_id=${option.value.id}`,
        type: GET_ALIASES,
      });
    } else if (e.name === 'afianzado') {
      this.setState({
        giro: null,
      });
      dispatchGetData({
        url: `${BASE_URL}${API.actividades}?organization_id=${option.value.id}`,
        type: GET_ACTIVIDADES,
      });
    } else if (e.name === 'condicionado_general') {
      this.setState({
        producto: null,
        tipo_cobertura: null,
        tipologia_ejecucion: null,
      });
    }
  };

  handleCondicionado = (newValue) => {
    this.setState({
      condicionado_general_search: newValue,
    });
  };

  handleTipoIntermediario = (tipo_intermediario) => {
    if (isIntermediarioDirecto(tipo_intermediario)) {
      this.setState({ intermediario: null });
    }
    this.setState({
      tipo_intermediario,
    });
  };

  handleIntermediario = (newValue) => {
    this.setState({
      intermediario_search: newValue,
    });
  };

  handleAsegurado = (newValue) => {
    this.setState({
      asegurado_search: newValue,
    });
  };

  handleAfianzado = (newValue) => {
    this.setState({
      afianzado_search: newValue,
    });
  };

  handleSave = () => {
    const {
      actividad_asegurada,
      afianzado,
      asegurado_alias,
      asegurado,
      condicionado_general,
      data,
      intermediario,
      moneda,
      tipo_intermediario,
      producto,
      tipo_cobertura,
      tipo_institucion,
      tipologia_ejecucion,
      giro,
    } = this.state;
    const { edit, handleSubmit, policies } = this.props;
    if (edit) {
      const form = {
        base: {
          poliza_maestra_id: condicionado_general.value.id,
          actividad_economica_id: actividad_asegurada.value.id,
          asegurado_alias_id: asegurado_alias ? asegurado_alias.value : null,
          tipo_intermediario_id: tipo_intermediario.value.id,
          moneda_asegurada_id: moneda.value.id,
          asegurado_id: asegurado.value ? asegurado.value.id : data.base.asegurado.id,
          producto_id: producto.value.id,
          tipo_cobertura_id: tipo_cobertura.value.id,
          tipologia_ejecucion_id: tipologia_ejecucion.value.id,
          tipo_institucion_id: tipo_institucion.value.id,
        },
        afianzado_id: afianzado.value ? afianzado.value.id : data.afianzado.id,
        actividad_id: giro ? giro.value : null,
        adjuntos_uuids: this.filesTable.current.getNewFilesUuids(),
        adjuntos_ids: policies.selectedPolicy.adjuntos_ids,
      };
      if (tipo_intermediario.value.id !== TIPO_INTERMEDIARIO_DIRECTO_ID) {
        form.base.intermediario_id = intermediario.value
          ? intermediario.value.id
          : data.base.intermediario.id;
      }
      this.setState({ awaitingResponse: true });
      handleSubmit(form, () => this.setState({ awaitingResponse: false }));
    } else {
      const form = {
        base: {
          poliza_maestra_id: condicionado_general.value.id,
          actividad_economica_id: actividad_asegurada.value.id,
          tipo_intermediario_id: tipo_intermediario.value.id,
          moneda_asegurada_id: moneda.value.id,
          asegurado_id: asegurado.value.id,
          asegurado_alias_id: asegurado_alias.value,
          producto_id: producto.value.id,
          tipo_cobertura_id: tipo_cobertura.value.id,
          tipologia_ejecucion_id: tipologia_ejecucion.value.id,
          tipo_institucion_id: tipo_institucion.value.id,
        },
        afianzado_id: afianzado.value.id,
        actividad_id: giro ? giro.value : null,
        adjuntos_uuids: this.filesTable.current.getNewFilesUuids(),
      };
      if (tipo_intermediario.value.id !== TIPO_INTERMEDIARIO_DIRECTO_ID) {
        form.base.intermediario_id = intermediario.value.id;
      }
      this.setState({ awaitingResponse: true });
      handleSubmit(form, () => this.setState({ awaitingResponse: false }));
    }
  };

  render() {
    const {
      asegurado_alias,
      afianzado,
      afianzado_search,
      actividad_asegurada,
      asegurado,
      asegurado_search,
      awaitingResponse,
      condicionado_general,
      condicionado_general_search,
      moneda,
      producto,
      tipo_cobertura,
      tipo_institucion,
      tipologia_ejecucion,
      tipo_intermediario,
      intermediario,
      intermediario_search,
      giro,
    } = this.state;
    const {
      aliasList,
      conditioned,
      edit,
      endosos,
      isEditable,
      policies,
      deleteAdjunto,
      loginIdToken,
    } = this.props;
    const {
      selectedPolicy,
      tiposIntermediario,
      tiposMoneda,
      condicionadosGenerales,
      actividadesList,
    } = policies;
    const { tipos_institucion } = conditioned;

    if (edit && !selectedPolicy) {
      return null;
    }

    const sectionTitle = edit
      ? `Información Básica - ${selectedPolicy.base.nemotecnico}`
      : 'Información Básica';

    const disabledByProfile = this.isDisabledByProfile();

    return (
      <div>
        <Col md={{ offset: 2, size: 8 }}>
          {edit && endosos}
          <FormSection title={sectionTitle}>
            <div
              style={{
                display: 'grid',
                gridGap: '20px',
                gridTemplateColumns: '1fr 1fr 1fr',
              }}
            >
              <div>
                <label htmlFor="ramo_fecu">Ramo FECU</label>
                <p id="ramo_fecu" className="form-values">
                  Garantía
                </p>
              </div>
              <FormGroup>
                <label htmlFor="moneda">Moneda</label>
                {isEditable ? (
                  <Select
                    id="moneda"
                    name="moneda"
                    className="custom-policy-select"
                    options={tiposMoneda}
                    onChange={this.handleSelectChange}
                    isDisabled={disabledByProfile}
                    value={moneda}
                    placeholder="Seleccione Moneda"
                  />
                ) : (
                  <p id="moneda" className="form-values">
                    {selectedPolicy ? selectedPolicy.base.moneda_asegurada.nombre_corto : '-'}
                  </p>
                )}
              </FormGroup>
              {!isEditable && (
                <FormGroup>
                  <label htmlFor="tipo_moneda">Póliza</label>
                  <p className="form-values">{selectedPolicy.base.nemotecnico}</p>
                </FormGroup>
              )}
              <FormGroup>
                <label htmlFor="condicionado_general">Condicionado General</label>
                {isEditable ? (
                  <AsyncSelect
                    id="condicionado_general"
                    name="condicionado_general"
                    className="custom-policy-select"
                    loadOptions={(inputValue) =>
                      loadAsyncOptions(condicionadosGenerales, inputValue)
                    }
                    isClearable
                    defaultOptions
                    inputValue={condicionado_general_search || ''}
                    value={condicionado_general}
                    onInputChange={this.handleCondicionado}
                    onChange={this.handleSelectChange}
                    isDisabled={disabledByProfile}
                    placeholder="Seleccione Condicionado General"
                    styles={asyncDisableStyle(disabledByProfile)}
                  />
                ) : (
                  <p className="form-values">
                    {condicionado_general ? condicionado_general.label : '-'}
                  </p>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="productos">Producto</label>
                {isEditable ? (
                  <Select
                    id="producto"
                    name="producto"
                    className="custom-policy-select"
                    options={
                      condicionado_general
                        ? objectsToOptions(condicionado_general.value.productos, true)
                        : []
                    }
                    value={producto}
                    onChange={this.handleSelectChange}
                    isDisabled={disabledByProfile}
                    placeholder="Seleccione Producto"
                  />
                ) : (
                  <p className="form-values">{producto ? producto.label : '-'}</p>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="tipo_cobertura">Tipo de Cobertura</label>
                {isEditable ? (
                  <Select
                    id="tipo_cobertura"
                    name="tipo_cobertura"
                    className="custom-policy-select"
                    options={
                      condicionado_general
                        ? objectsToOptions(condicionado_general.value.tipos_cobertura, true)
                        : []
                    }
                    value={tipo_cobertura}
                    onChange={this.handleSelectChange}
                    isDisabled={disabledByProfile}
                    placeholder="Seleccione Tipo de Cobertura"
                  />
                ) : (
                  <p className="form-values">{tipo_cobertura ? tipo_cobertura.label : '-'}</p>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="tipo_cobertura">Tipología de Ejecución</label>
                {isEditable ? (
                  <Select
                    id="tipologia_ejecucion"
                    name="tipologia_ejecucion"
                    className="custom-policy-select"
                    options={
                      condicionado_general
                        ? objectsToOptions(condicionado_general.value.tipologias_ejecucion, true)
                        : []
                    }
                    value={tipologia_ejecucion}
                    onChange={this.handleSelectChange}
                    isDisabled={disabledByProfile}
                    placeholder="Seleccione Tipología de Ejecución"
                  />
                ) : (
                  <p className="form-values">
                    {tipologia_ejecucion ? tipologia_ejecucion.label : '-'}
                  </p>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="tipos_intermediario">Tipo de Intermediario</label>
                {isEditable ? (
                  <Select
                    id="tipos_intermediario"
                    name="tipo_intermediario"
                    className="custom-policy-select"
                    options={tiposIntermediario}
                    onChange={this.handleTipoIntermediario}
                    isDisabled={disabledByProfile}
                    placeholder="Seleccione Tipo de Intermediario"
                    value={tipo_intermediario}
                  />
                ) : (
                  <p className="form-values">
                    {tipo_intermediario ? tipo_intermediario.label : '-'}
                  </p>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="intermediario">Intermediario</label>
                {isEditable ? (
                  <AsyncSelect
                    id="intermediario"
                    name="intermediario"
                    className="custom-policy-select"
                    loadOptions={(inputValue) =>
                      loadClientsOptions(inputValue, ROL_INTERMEDIARIO_GARANTIA)
                    }
                    defaultOptions
                    inputValue={intermediario_search || ''}
                    value={intermediario}
                    onInputChange={this.handleIntermediario}
                    onChange={this.handleSelectChange}
                    placeholder="Seleccione Intermediario"
                    isDisabled={
                      (tipo_intermediario && tipo_intermediario.value.id === TIPO_INTERMEDIARIO_DIRECTO_ID) ||
                      disabledByProfile
                    }
                    styles={asyncDisableStyle(disabledByProfile)}
                  />
                ) : (
                  <p className="form-values">{intermediario ? intermediario.label : '-'}</p>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="tipo_institucion">Tipo de Institución</label>
                {isEditable ? (
                  <Select
                    id="tipo_institucion"
                    name="tipo_institucion"
                    className="custom-policy-select"
                    options={tipos_institucion}
                    value={tipo_institucion}
                    onChange={this.handleSelectChange}
                    isDisabled={disabledByProfile}
                    placeholder="Seleccione Tipo de Institución"
                  />
                ) : (
                  <p className="form-values">{tipo_institucion ? tipo_institucion.label : '-'}</p>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="asegurado">Asegurado</label>
                {isEditable ? (
                  <AsyncSelect
                    id="asegurado"
                    name="asegurado"
                    className="custom-policy-select"
                    loadOptions={(inputValue) =>
                      loadClientsOptions(inputValue, ROL_ASEGURADO_GARANTIA)
                    }
                    defaultOptions
                    inputValue={asegurado_search || ''}
                    value={asegurado}
                    onInputChange={this.handleAsegurado}
                    onChange={this.handleSelectChange}
                    isDisabled={disabledByProfile}
                    placeholder="Seleccione Asegurado"
                    styles={asyncDisableStyle(disabledByProfile)}
                  />
                ) : (
                  <p className="form-values">{asegurado ? asegurado.label : '-'}</p>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="alias_asegurado">Alias Asegurado</label>
                {isEditable ? (
                  <Select
                    id="alias_asegurado"
                    name="asegurado_alias"
                    className="custom-policy-select"
                    isDisabled={this.isAliasDisabled() || disabledByProfile}
                    isClearable
                    onChange={this.handleSelectChange}
                    options={aliasList}
                    placeholder="Seleccione Alias"
                    value={asegurado_alias}
                  />
                ) : (
                  <p className="form-values">{asegurado_alias ? asegurado_alias.label : '-'}</p>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="actividad_asegurada">Actividad Asegurada</label>
                {isEditable ? (
                  <Select
                    id="actividad_asegurada"
                    name="actividad_asegurada"
                    className="custom-policy-select"
                    options={policies.actividadGarantiaList}
                    onChange={this.handleSelectChange}
                    isDisabled={disabledByProfile}
                    value={actividad_asegurada}
                    placeholder="Seleccione Actividad Asegurada"
                  />
                ) : (
                  <p className="form-values">
                    {actividad_asegurada ? actividad_asegurada.label : '-'}
                  </p>
                )}
              </FormGroup>
            </div>
          </FormSection>
          <FormSection title="Detalles">
            <div
              style={{
                marginTop: '30px',
                display: 'grid',
                gridGap: '20px',
                gridTemplateColumns: '1fr 1fr',
              }}
            >
              <FormGroup>
                <label htmlFor="afianzado">Afianzado</label>
                {isEditable ? (
                  <AsyncSelect
                    id="afianzado"
                    name="afianzado"
                    className="custom-policy-select"
                    loadOptions={(inputValue) =>
                      loadClientsOptions(inputValue, ROL_AFIANZADO_GARANTIA)
                    }
                    defaultOptions
                    inputValue={afianzado_search || ''}
                    value={afianzado}
                    onInputChange={this.handleAfianzado}
                    onChange={this.handleSelectChange}
                    isDisabled={disabledByProfile}
                    placeholder="Seleccione Afianzado"
                    styles={asyncDisableStyle(disabledByProfile)}
                  />
                ) : (
                  <p className="form-values">{afianzado ? afianzado.label : '-'}</p>
                )}
              </FormGroup>
              <FormGroup>
                <label htmlFor="giro">Giro</label>
                {isEditable ? (
                  <Select
                    id="giro"
                    name="giro"
                    className="custom-policy-select"
                    options={actividadesList}
                    value={giro}
                    onChange={this.handleSelectChange}
                    isDisabled={disabledByProfile}
                    placeholder="Seleccione Giro"
                  />
                ) : (
                  <p className="form-values">{giro ? giro.label : '-'}</p>
                )}
              </FormGroup>
              <div>
                <FormGroup>
                  <label htmlFor="document">Adjunto Glosa</label>
                  <FileTable
                    ref={this.filesTable}
                    fileList={selectedPolicy ? selectedPolicy.adjuntos : []}
                    handleDelete={(file) => deleteAdjunto(file.id)}
                    loginIdToken={loginIdToken}
                    uploadUrl={`${BASE_URL}${API.upload}/`}
                    downloadUrl={`${BASE_URL}${API.download}/`}
                    validProfiles={POLICY_WRITE}
                  />
                </FormGroup>
              </div>
            </div>
          </FormSection>
          {!disabledByProfile ? (
            <Col md={{ offset: 8 }}>
              <div
                style={{
                  display: 'grid',
                  gridGap: '10px',
                  gridTemplateColumns: '1fr 1fr',
                  margin: '30px 0 20px',
                }}
              >
                <div>
                  <Link
                    style={{
                      background: 'transparent',
                      color: '#F98724',
                      border: '1px solid #F98724',
                      width: '100%',
                    }}
                    className="btn btn-secondary"
                    to="/guarantee/policies/"
                  >
                    Cancelar
                  </Link>
                </div>
                <div>
                  <Button
                    color="orsan"
                    disabled={this.isDisabled() || awaitingResponse}
                    onClick={this.handleSave}
                  >
                    Guardar
                  </Button>
                </div>
              </div>
            </Col>
          ) : (
            <Col md={{ offset: 8 }}>
              <div
                style={{
                  display: 'grid',
                  gridGap: '10px',
                  gridTemplateColumns: '1fr 1fr',
                  margin: '30px 0 20px',
                }}
              >
                <div>{}</div>
                <div>
                  <Link
                    style={{
                      background: 'transparent',
                      color: '#F98724',
                      border: '1px solid #F98724',
                      width: '100%',
                    }}
                    className="btn btn-secondary"
                    to="/guarantee/policies/"
                  >
                    Volver
                  </Link>
                </div>
              </div>
            </Col>
          )}
        </Col>
      </div>
    );
  }
}

PolicyForm.propTypes = {
  aliasList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  conditioned: PropTypes.shape({
    tipos_institucion: PropTypes.array,
  }).isRequired,
  loginIdToken: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  endosos: PropTypes.element,
  isEditable: PropTypes.bool.isRequired,
  policies: PropTypes.shape({
    selectedPolicy: PropTypes.shape({
      id: PropTypes.number,
      adjuntos_ids: PropTypes.arrayOf(PropTypes.number),
      adjuntos: PropTypes.arrayOf(PropTypes.object),
      afianzado: PropTypes.shape({
        id: PropTypes.number,
        rut: PropTypes.string,
        nombre: PropTypes.string,
      }),
      actividad: PropTypes.shape({
        id: PropTypes.number,
        codigo: PropTypes.string,
        nombre: PropTypes.string,
      }),
      base: PropTypes.shape({
        nemotecnico: PropTypes.string,
        asegurado: PropTypes.shape({
          id: PropTypes.number,
          rut: PropTypes.string,
          nombre: PropTypes.string,
        }),
        asegurado_alias: PropTypes.shape({
          id: PropTypes.number,
          alias: PropTypes.string,
        }),
        poliza_maestra: PropTypes.shape({
          id: PropTypes.number,
        }),
        intermediario: PropTypes.shape({
          rut: PropTypes.string,
          nombre: PropTypes.string,
        }),
        actividad_economica: PropTypes.shape({
          id: PropTypes.number,
        }),
        producto: PropTypes.object,
        tipo_cobertura: PropTypes.object,
        tipologia_ejecucion: PropTypes.object,
        moneda_asegurada: PropTypes.shape({
          id: PropTypes.number,
          nombre_corto: PropTypes.string,
        }),
        tipo_intermediario: PropTypes.shape({
          id: PropTypes.number,
        }),
        tipo_institucion: PropTypes.shape({
          id: PropTypes.number,
        }),
      }),
      emitido: PropTypes.bool,
    }),
    actividadGarantiaList: PropTypes.array,
    condicionadosGenerales: PropTypes.array,
    tiposIntermediario: PropTypes.array,
    tiposMoneda: PropTypes.array,
    actividadesList: PropTypes.array,
    balance: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatchGetData: PropTypes.func.isRequired,
  deleteAdjunto: PropTypes.func.isRequired,
};

PolicyForm.defaultProps = {
  aliasList: [],
  edit: false,
  endosos: null,
};

const mapStateToProps = (state) => ({
  aliasList: state.policies.aliasList,
  policies: state.policies,
  conditioned: state.conditioned,
  loginIdToken: state.login.id_token,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetData: (data) => dispatch(getData(data)),
  deleteAdjunto: (adjuntoId) =>
    dispatch({
      type: POLICY_DELETE_ADJUNTO,
      payload: { id: adjuntoId },
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PolicyForm);
