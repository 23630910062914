import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { FormGroup, Label, Col, Input, Button } from 'reactstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ESTADOS_OPORTUNIDAD, API, BASE_URL, OPORTUNIDADES_STATE_SECTION_MAP } from 'CONST';
import { patch, post } from 'helpers/requests';
import CheckModal from 'components/shared/CheckModal';
import WarningModal from 'components/UI/WarningModal';
import AvanzarOportunidadConfirmationModal from './AvanzarOportunidadConfirmationModal';

const ValidarInformacionSection = ({
  fullData,
  setFullData,
  setInitialSection,
  id,
  estadosOportunidad,
  observaciones,
}) => {
  const history = useHistory();

  const [observacion, setObservacion] = useState('');
  const [observacionesEstado, setObservacionesEstado] = useState([]);
  const [editableSection, setEditableSection] = useState(false);
  const [showUpdateGoodModal, setShowUpdateGoodModal] = useState(false);
  const [showUpdateBadModal, setShowUpdateBadModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [confirmationGoodModal, setConfirmationGoodModal] = useState(false);
  const [confirmationBadModal, setConfirmationBadModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const editableStates = [ESTADOS_OPORTUNIDAD.informacionInicialCargada];

  useEffect(() => {
    if (observaciones) {
      setObservacionesEstado(
        observaciones.filter(
          (obs) => obs.estado.nemotecnico === ESTADOS_OPORTUNIDAD.informacionInicialValidada,
        ),
      );
    }
    if (fullData && fullData.estado && editableStates.includes(fullData.estado.nemotecnico)) {
      setEditableSection(true);
    }
  }, [observaciones, fullData]);

  const postObservacion = () => {
    if (observacion) {
      const body = {
        oportunidad_id: id,
        estado_id: estadosOportunidad.find(
          (e) => e.nemotecnico === ESTADOS_OPORTUNIDAD.informacionInicialValidada,
        ).id,
        observacion,
      };
      post(`${BASE_URL}${API.observaciones}/`, body).catch(() => {
        setShowErrorModal(true);
      });
    }
  };

  const validarOportunidad = () => {
    setLoading(true);
    const body = {
      estado_id: estadosOportunidad.find(
        (e) => e.nemotecnico === ESTADOS_OPORTUNIDAD.informacionInicialValidada,
      ).id,
    };
    patch(`${BASE_URL}${API.oportunidades_garantia}/${id}/`, body)
      .then((response) => {
        postObservacion();
        setFullData(response);
        setInitialSection(OPORTUNIDADES_STATE_SECTION_MAP[response.estado.nemotecnico]);
        setEditableSection(false);
        setShowUpdateGoodModal(true);
      })
      .catch(() => {
        setShowErrorModal(true);
      })
      .finally(() => {
        setConfirmationGoodModal(false);
        setLoading(false);
      });
  };

  const devolverOportunidad = () => {
    setLoading(true);
    const body = {
      estado_id: estadosOportunidad.find(
        (e) => e.nemotecnico === ESTADOS_OPORTUNIDAD.suscriptorAsignado,
      ).id,
    };
    patch(`${BASE_URL}${API.oportunidades_garantia}/${id}/`, body)
      .then(() => {
        postObservacion();
        setShowUpdateBadModal(true);
      })
      .catch(() => {
        setShowErrorModal(true);
      })
      .finally(() => {
        setConfirmationBadModal(false);
        setLoading(false);
      });
  };

  return (
    <div>
      <FormGroup row>
        <Col sm={2}>
          <Label>Observaciones</Label>
        </Col>
        <Col>
          <Input
            id="observacion-info-inicial"
            type="textarea"
            value={
              !editableSection && observacionesEstado.length > 0
                ? observacionesEstado[0].observacion
                : observacion || ''
            }
            onChange={(event) => setObservacion(event.target.value)}
            rows="3"
            disabled={!editableSection}
          />
        </Col>
      </FormGroup>
      {observacionesEstado.slice(!editableSection).map((obs) => {
        return (
          <FormGroup row key={obs.id}>
            <Col sm={2} />
            <Col>
              <Input type="textarea" value={obs.observacion} rows="3" disabled />
            </Col>
          </FormGroup>
        );
      })}
      {editableSection && (
        <div className="button-container d-flex justify-content-end">
          <Button
            className="btn btn-secondary warning-cancel no-bold"
            style={{ marginRight: 25 }}
            onClick={() => setConfirmationBadModal(true)}
            disabled={confirmationBadModal || confirmationGoodModal}
          >
            {loading && confirmationBadModal ? (
              <FontAwesomeIcon spin={loading} icon={faSpinner} />
            ) : (
              'Solicitar Revisión Información Inicial'
            )}
          </Button>
          <Button
            className="btn btn-secondary orange"
            onClick={() => setConfirmationGoodModal(true)}
            disabled={confirmationBadModal || confirmationGoodModal}
          >
            {loading && confirmationGoodModal ? (
              <FontAwesomeIcon spin={loading} icon={faSpinner} />
            ) : (
              'Validar Información Oportunidad'
            )}
          </Button>
        </div>
      )}
      <CheckModal
        isOpen={showUpdateGoodModal}
        toggle={() => setShowUpdateGoodModal(false)}
        handleOk={() => setShowUpdateGoodModal(false)}
        okBtnText="OK"
        confirmMsg="Información inicial validada exitosamente."
      />
      <AvanzarOportunidadConfirmationModal
        openModal={confirmationGoodModal}
        setOpenModal={setConfirmationGoodModal}
        avanzarOportunidad={validarOportunidad}
        loading={loading}
      />
      <CheckModal
        isOpen={showUpdateBadModal}
        toggle={() => history.push('/guarantee/oportunities')}
        handleOk={() => history.push('/guarantee/oportunities')}
        okBtnText="OK"
        confirmMsg="Oportunidad devuelta a estado anterior."
      />
      <AvanzarOportunidadConfirmationModal
        openModal={confirmationBadModal}
        setOpenModal={setConfirmationBadModal}
        avanzarOportunidad={devolverOportunidad}
        mensajeModal="¿Está seguro que desea RETROCEDER esta oportunidad?"
        loading={loading}
      />
      <WarningModal
        isOpen={showErrorModal}
        toggle={() => setShowErrorModal(false)}
        handleOk={() => setShowErrorModal(false)}
        okBtnText="Aceptar"
        confirmMsg="Ha ocurrido un error."
        loading={false}
        onlyCancelButton
      />
    </div>
  );
};

ValidarInformacionSection.propTypes = {
  fullData: PropTypes.shape({
    estado: PropTypes.shape({
      nemotecnico: PropTypes.string,
    }),
  }),
  setFullData: PropTypes.func.isRequired,
  setInitialSection: PropTypes.func.isRequired,
  id: PropTypes.string,
  estadosOportunidad: PropTypes.arrayOf(PropTypes.shape({})),
  observaciones: PropTypes.arrayOf(PropTypes.shape({})),
};

ValidarInformacionSection.defaultProps = {
  fullData: null,
  id: null,
  estadosOportunidad: [],
  observaciones: [],
};

export default ValidarInformacionSection;
