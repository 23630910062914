import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Button, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { ACCOUNTANT_URL, DOCS_URL, RISK_URL, USERS_URL, WORKFLOW_URL, REASEGURO_URL, ANALYST_URL, ORSAN_LOGO_URL } from '../CONST';
import { signout } from '../helpers/auth';
import isUserHasProfile from '../helpers/profileValidator';
import {
  POLICY_READ,
  GENERAL_CONDITIONED_READ,
  CREDITO_NAVBAR,
} from '../helpers/profilePermission';

class BaseNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appsOpen: false,
      guaranteeDropdownOpen: false,
      creditDropdownOpen: false,
    };
  }

  toggleApps = () => {
    this.setState((prevState) => ({
      appsOpen: !prevState.appsOpen,
    }));
  };

  toggleGuaranteeDropdown = () => {
    this.setState((prevState) => ({
      guaranteeDropdownOpen: !prevState.guaranteeDropdownOpen,
    }));
  };

  toggleCreditDropdown = () => {
    this.setState((prevState) => ({
      creditDropdownOpen: !prevState.creditDropdownOpen,
    }));
  };

  handleSignOut = (event) => {
    event.preventDefault();
    signout();
  };

  render() {
    const { user } = this.props;
    const { guaranteeDropdownOpen, creditDropdownOpen, appsOpen } = this.state;

    return (
      user && (
        <nav className="navbar-content">
          <div className="nav-logo-container">
            <Link to="/">
              <img src={ORSAN_LOGO_URL} className="nav-logo" alt="" />
            </Link>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
            <ul className="nav-links-group">
              <Dropdown nav className="nav-link-element" isOpen={appsOpen} toggle={this.toggleApps}>
                <DropdownToggle nav caret className="navbar-header">
                  PÓLIZA
                </DropdownToggle>
                <DropdownMenu>
                  {user.apps.includes('users') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={USERS_URL}>
                        USUARIOS
                      </a>
                    </DropdownItem>
                  )}
                  {user.apps.includes('docs') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={DOCS_URL}>
                        DOCUMENTOS
                      </a>
                    </DropdownItem>
                  )}
                  {user.apps.includes('accountant') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={ACCOUNTANT_URL}>
                        RESERVAS
                      </a>
                    </DropdownItem>
                  )}
                  {user.apps.includes('reaseguro') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={REASEGURO_URL}>
                        REASEGURO
                      </a>
                    </DropdownItem>
                  )}
                  {user.apps.includes('risk') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={RISK_URL}>
                        RIESGO
                      </a>
                    </DropdownItem>
                  )}
                  {user.apps.includes('analista') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={ANALYST_URL}>
                        RIESGO DE CRÉDITO
                      </a>
                    </DropdownItem>
                  )}
                  {user.apps.includes('workflow') && (
                    <DropdownItem>
                      <a className="nav-link navbar-header" href={WORKFLOW_URL}>
                        WORKFLOW
                      </a>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
              {isUserHasProfile(POLICY_READ) && (
                <Dropdown
                  nav
                  className="nav-link-element"
                  isOpen={guaranteeDropdownOpen}
                  toggle={this.toggleGuaranteeDropdown}
                >
                  <DropdownToggle nav caret>
                    Garantía
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <Link className="nav-link" to="/guarantee/policies">
                        Pólizas
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link className="nav-link" to="/garantia/proyectos">
                        Venta en Verde
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link className="nav-link" to="/guarantee/oportunities">
                        Oportunidades
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
              {isUserHasProfile(CREDITO_NAVBAR) && (
                <Dropdown
                  nav
                  className="nav-link-element"
                  isOpen={creditDropdownOpen}
                  toggle={this.toggleCreditDropdown}
                >
                  <DropdownToggle nav caret>
                    Crédito
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <Link className="nav-link" to="/credit/policies">
                        Pólizas
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
              {isUserHasProfile(GENERAL_CONDITIONED_READ) && (
                <li className="nav-link-element nav-item dropdown">
                  <Link className="nav-link" to="/condicionados">
                    Condicionados Generales
                  </Link>
                </li>
              )}
            </ul>
            <ul className="nav-links-group">
              <li className="nav-link-element nav-ite dropdown">
                <p className="nav-link">{user ? user.username : ''}</p>
              </li>
              <li className="nav-link-element nav-item dropdown">
                <Button className="nav-link" onClick={this.handleSignOut}>
                  Cerrar Sesión
                </Button>
              </li>
            </ul>
          </div>
        </nav>
      )
    );
  }
}

BaseNavbar.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    username: PropTypes.string,
    full_name: PropTypes.string,
    has_access: PropTypes.bool,
    apps: PropTypes.arrayOf(PropTypes.string),
    organization: PropTypes.number,
    sub: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.login.user,
  login: state.login,
});

export default withRouter(connect(mapStateToProps)(BaseNavbar));
