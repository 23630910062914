import PropTypes from 'prop-types';
import {
  CP_FS_BASICOS,
  CP_FS_CLAUSULAS,
  CP_FS_CLIENTES,
  CP_FS_CONDICIONES,
  CP_FS_DETALLES
} from '../CONST';


export const particularConditionType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  texto: PropTypes.string,
});

export const clienteEndosoType = PropTypes.shape({
  cliente: PropTypes.object,
  is_filial: PropTypes.bool,
  is_beneficiario: PropTypes.bool,
});

export const parameterizedConditionType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  texto: PropTypes.string,
  parametros: PropTypes.object,
});

const sectionStatusType = PropTypes.shape({
  is_valid: PropTypes.bool,
  vb: PropTypes.bool,
  hasChanged: PropTypes.bool,
});

export const sectionsType = PropTypes.shape({
  [CP_FS_BASICOS]: sectionStatusType,
  [CP_FS_DETALLES]: sectionStatusType,
  [CP_FS_CLIENTES]: sectionStatusType,
  [CP_FS_CONDICIONES]: sectionStatusType,
  [CP_FS_CLAUSULAS]: sectionStatusType,
});
