import React, { useState } from 'react';
import { Button } from 'reactstrap';
import AsyncSelect from 'react-select/async/dist/react-select.esm';
import PropTypes from 'prop-types';
import { loadClientsOptions } from '../../../../helpers/clients';
import { addClienteEndoso } from '../../../../redux-store/actions/creditPolicyActions';
import FormSection from '../../../UI/FormSection';
import FilialesOrBeneficiariosList from './FilialesOrBeneficiariosList';
import {
  ROL_BENEFICIARIO_CREDITO,
  ROL_FILIAL_CREDITO,
} from '../../../../CONST';

const FilialesOrBeneficiarios = ({
  form,
  dispatch,
  style,
  validate,
  disabled,
}) => {
  const [clientSearch, setClientSearch] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);

  const addCliente = () => {
    if (selectedClient) {
      dispatch(addClienteEndoso(selectedClient.value));
    }
    setSelectedClient(null);
  };

  const getOptions = inputValue =>
    loadClientsOptions(
      inputValue,
      [ROL_BENEFICIARIO_CREDITO, ROL_FILIAL_CREDITO],
      false,
      client =>
        client.roles.includes(ROL_BENEFICIARIO_CREDITO) || client.tiene_giro,
    );

  return (
    <FormSection title="Filiales y/o beneficiarios" style={style}>
      {!disabled && (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridGap: '20px',
            marginBottom: '30px',
          }}
        >
          <div style={{ gridColumn: '1 / 3', zIndex: 9999 }}>
            <AsyncSelect
              id="cliente-benef"
              name="cliente-benef"
              className="custom-policy-select"
              loadOptions={inputValue => getOptions(inputValue)}
              defaultOptions
              inputValue={clientSearch}
              onInputChange={setClientSearch}
              value={selectedClient}
              onChange={selected => setSelectedClient(selected)}
              placeholder="Seleccione una filial o beneficiario"
              isOptionDisabled={option =>
                form.data.endoso.clientesEndoso.find(
                  elem => elem.cliente.id === option.value.id,
                ) || form.data.asegurado.value.id === option.value.id
              }
            />
          </div>
          <div>
            <Button
              color="orsan-secondary"
              style={{ fontSize: '14px' }}
              onClick={addCliente}
            >
              Agregar cliente
            </Button>
          </div>
        </div>
      )}
      <div style={{ marginTop: '10px' }}>
        <FilialesOrBeneficiariosList
          clientes={form.data.endoso.clientesEndoso}
          dispatch={dispatch}
          validate={validate}
          disabled={disabled}
        />
      </div>
    </FormSection>
  );
};

FilialesOrBeneficiarios.propTypes = {
  form: PropTypes.shape({
    data: PropTypes.shape({
      asegurado: PropTypes.object,
      endoso: PropTypes.shape({
        clientesEndoso: PropTypes.array,
      }),
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

FilialesOrBeneficiarios.defaultProps = {
  style: {},
  disabled: false,
};

export default FilialesOrBeneficiarios;
