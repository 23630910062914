import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  ModalFooter,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { BASE_URL, API, ESTADOS_OPORTUNIDAD } from 'CONST';
import { get } from 'helpers/requests';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MotivosRechazoModal = ({
  openModal,
  toggleModal,
  observacion,
  setObservacion,
  avanzarOportunidad,
  motivoId,
  setMotivoId,
  loading,
  esRechazo,
  fullData,
}) => {
  const [motivosRechazo, setMotivosRechazo] = useState([]);
  const [motivosDesistimiento, setMotivosDesistimiento] = useState([]);

  useEffect(() => {
    if (
      fullData &&
      fullData.estado &&
      [
        ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoSuscriptor,
        ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoRiesgo,
        ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoComite,
      ].includes(fullData.estado.nemotecnico)
    ) {
      get(`${BASE_URL}${API.motivos_rechazo_oportunidad}/`).then((response) => {
        setMotivosRechazo(
          response.results.map((e) => ({
            value: e.id,
            label: e.descripcion,
          })),
        );
      });
    }
    if (
      fullData &&
      fullData.estado &&
      [ESTADOS_OPORTUNIDAD.borradorEnviado].includes(fullData.estado.nemotecnico)
    ) {
      get(`${BASE_URL}${API.motivos_desistimiento_oportunidad}/`).then((response) => {
        setMotivosDesistimiento(
          response.results.map((e) => ({
            value: e.id,
            label: e.descripcion,
          })),
        );
      });
    }
  }, []);

  const onCloseModal = () => {
    setMotivoId(null);
    toggleModal();
  };

  return (
    <Modal
      isOpen={openModal}
      toggle={onCloseModal}
      type="modal-warning warning-container"
      style={{ maxWidth: '1100px', width: '80%' }}
    >
      <ModalHeader toggle={onCloseModal}>
        {esRechazo ? <span>Rechazar Oportunidad</span> : <span>Desistir Oportunidad</span>}
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row style={{ marginBottom: '1rem' }}>
            <Col md="3">
              {esRechazo ? (
                <Label> Motivo del Rechazo </Label>
              ) : (
                <Label> Motivo del Desistimiento </Label>
              )}
            </Col>
            <Col>
              <Select
                name="motivos"
                className="custom-policy-select"
                options={esRechazo ? motivosRechazo : motivosDesistimiento}
                onChange={(event) => setMotivoId(event.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <Label>Observaciones</Label>
            </Col>
            <Col>
              <Input
                id="observacion-info-inicial"
                type="textarea"
                value={observacion || ''}
                onChange={(event) => setObservacion(event.target.value)}
                rows="3"
              />
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          className="warning-cancel mr-auto"
          onClick={() => onCloseModal()}
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          className="warning-accept"
          onClick={() => {
            avanzarOportunidad(
              esRechazo ? 'Se rechazó la oportunidad.' : 'Se desistió la oportunidad.',
              esRechazo
                ? 'No se pudo rechazar la oportunidad.'
                : 'No se pudo desistir la oportunidad.',
              esRechazo ? ESTADOS_OPORTUNIDAD.rechazado : ESTADOS_OPORTUNIDAD.desistido,
            );
          }}
          disabled={!motivoId || loading}
        >
          {loading ? (
            <FontAwesomeIcon spin={loading} icon={faSpinner} />
          ) : (
            <>
              {esRechazo && 'Rechazar Negocio'}
              {!esRechazo && 'Desistir'}
            </>
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

MotivosRechazoModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  observacion: PropTypes.string.isRequired,
  setObservacion: PropTypes.func.isRequired,
  avanzarOportunidad: PropTypes.func.isRequired,
  motivoId: PropTypes.number,
  setMotivoId: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  esRechazo: PropTypes.bool,
  fullData: PropTypes.shape({
    estado: PropTypes.shape({
      nemotecnico: PropTypes.string,
    }),
  }),
};

MotivosRechazoModal.defaultProps = {
  motivoId: null,
  loading: false,
  esRechazo: true,
  fullData: null,
};

export default MotivosRechazoModal;
