import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PageContainer from '../PageContainer';
import GeneralConditionedForm from './GeneralConditionedForm';
import { cleanGeneralConditioned, getData, patchData } from '../../redux-store/actions';
import {
  SAVE_GENERAL_CONDITIONED,
  GET_GENERAL_CONDITIONED,
  GET_PRODUCTOS,
  GET_TIPOS_COBERTURA,
  GET_TIPOLOGIAS_EJECUCION,
  GET_RAMOS_FECU,
} from '../../redux-store/constants/action-types';
import { API, BASE_URL } from '../../CONST';


class GeneralConditionedEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
    };
  }

  componentDidMount() {
    const { dispatchCleanGeneralConditioned, dispatchGetData, match } = this.props;
    dispatchCleanGeneralConditioned();
    dispatchGetData({
      url: `${BASE_URL}${API.maestras}/${match.params.id}`,
      type: GET_GENERAL_CONDITIONED,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.productos}`,
      type: GET_PRODUCTOS,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.tipos_cobertura}`,
      type: GET_TIPOS_COBERTURA,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.tipologias_ejecucion}`,
      type: GET_TIPOLOGIAS_EJECUCION,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.ramos_fecu}`,
      type: GET_RAMOS_FECU,
    });
  }

  handleSubmit = (config, onFailedReq = () => {}) => {
    const { dispatchPatchData, match } = this.props;
    dispatchPatchData({
      url: `${BASE_URL}${API.maestras}/${match.params.id}/`,
      type: SAVE_GENERAL_CONDITIONED,
      onFailedReq,
      config,
    });
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const { data } = this.state;
    const { history } = this.props;

    if (data === undefined && nextProps.conditioned.selectedPolicy) {
      this.setState({
        data: nextProps.conditioned.selectedPolicy,
      });
    }
    if (nextProps.conditioned.successMessage) {
      history.push('/condicionados/');
    }
  }

  render() {
    const { conditioned, match, loginIdToken } = this.props;
    const { data } = this.state;
    return (
      <PageContainer
        breadcrumbs={[
          { name: 'CONDICIONADOS GENERALES', url: '/condicionados/' },
          { name: 'EDITAR CONDICIONADO GENERAL' },
        ]}
        style={{ padding: '0 20px' }}
      >
        <GeneralConditionedForm
          data={data}
          edit
          error={conditioned.errorMessage}
          handleSubmit={this.handleSubmit}
          productos={conditioned.productos}
          tipos_cobertura={conditioned.tipos_cobertura}
          tipologias_ejecucion={conditioned.tipologias_ejecucion}
          ramos_fecu={conditioned.ramos_fecu}
          conditionedId={match.params.id}
          loginIdToken={loginIdToken}
        />
      </PageContainer>
    );
  }
}

GeneralConditionedEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  conditioned: PropTypes.shape({
    selectedPolicy: PropTypes.object,
    successMessage: PropTypes.bool,
    errorMessage: PropTypes.bool,
    productos: PropTypes.array,
    tipos_cobertura: PropTypes.array,
    tipologias_ejecucion: PropTypes.array,
    ramos_fecu: PropTypes.array,
  }).isRequired,
  loginIdToken: PropTypes.string.isRequired,
  dispatchCleanGeneralConditioned: PropTypes.func.isRequired,
  dispatchGetData: PropTypes.func.isRequired,
  dispatchPatchData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  conditioned: state.conditioned,
  loginIdToken: state.login.id_token,
});

const mapDispatchToProps = dispatch => ({
  dispatchCleanGeneralConditioned: policy => dispatch(cleanGeneralConditioned(policy)),
  dispatchGetData: policy => dispatch(getData(policy)),
  dispatchPatchData: policy => dispatch(patchData(policy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralConditionedEdit);
