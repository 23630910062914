import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import FormSection from '../../../UI/FormSection';

const UmbralSection = ({
  umbralCoberturaAmt,
  moneda,
  dispatch,
  style,
  validate,
  handleChange,
  disabled,
}) => (
  <FormSection title="Umbral" style={style}>
    <div className="inline-text-with-input">
      <NumberFormat
        id="umbral-cobertura"
        name="umbralCoberturaAmt"
        className={`form-control${
          validate && !umbralCoberturaAmt ? ' is-invalid' : ''
        }`}
        onValueChange={values => {
          const newValue = values.floatValue;
          dispatch(handleChange('endoso.umbralCoberturaAmt', newValue));
        }}
        allowNegative={false}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale="2"
        fixedDecimalScale
        value={umbralCoberturaAmt}
        suffix={` ${moneda}`}
        style={{ width: '110px' }}
        disabled={disabled}
      />
      <span className="small-form-value">
        &nbsp;&nbsp; para todo cliente, salvo que se indique algo distinto para
        algún cliente.
      </span>
    </div>
  </FormSection>
);

UmbralSection.propTypes = {
  umbralCoberturaAmt: PropTypes.number,
  moneda: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

UmbralSection.defaultProps = {
  umbralCoberturaAmt: null,
  style: {},
  disabled: false,
};

export default UmbralSection;
