import moment from 'moment';
import store from 'redux-store/store';
import {
  BACKEND_DF,
  CP_FS_BASICOS,
  CP_FS_CLAUSULAS,
  CP_FS_CLIENTES,
  CP_FS_CONDICIONES,
  CP_FS_DETALLES,
  FRONT_DF_2,
  PERFIL_ADMINISTRADOR,
  PERFIL_ANALISTA_OPERACIONES,
  PERFIL_CEO,
  PERFIL_COMERCIAL_CREDITO,
  PERFIL_GERENTE_OPERACIONES,
  PERFIL_ACTUARIADO,
  PLAN_PAGO_MAX_CUOTAS_ANUALES,
  TIPO_ENDOSO_EMISION_ID,
  TIPO_ENDOSO_MODIFICACION_ID,
  TIPO_ENDOSO_RENOVACION_ID,
  TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID,
  TIPO_INTERMEDIARIO_DIRECTO_ID,
  TIPO_PLAN_CUOTAS_ID,
  TIPO_PLAN_LIBRO_VENTAS_ID,
  TIPO_PLAN_PLAN_PAGOS_ID,
} from '../CONST';
import { areClose } from './helpers';
import { getSelectedTipoVigencia } from './selects';
import isUserHasProfile from './profileValidator';
import {
  PERFILES_INICIO_VIGENCIA_COMERCIAL,
  PERFILES_INICIO_VIGENCIA_OPERACIONES,
  PERFILES_INICIO_VIGENCIA_CEO,
} from './profilePermission';

export const isIntermediarioDirecto = (tipoIntermediario) => {
  return tipoIntermediario && tipoIntermediario.value.id === TIPO_INTERMEDIARIO_DIRECTO_ID;
};

export const isValidIntermediario = (tipo, intermediario, validate = true) => {
  return !validate || (isIntermediarioDirecto(tipo) && !intermediario) || !!intermediario;
};

export const isEndosoModificacion = (form) =>
  form.data.endoso.tipoEndosoId === TIPO_ENDOSO_MODIFICACION_ID;

export const isValidCreditPolicyBasicInfoStep = ({ data: form, sections }) => {
  const isValidForm =
    form.moneda &&
    form.condicionadoGeneral &&
    form.producto &&
    form.endoso.tipoCobertura &&
    form.tipologiaEjecucion &&
    form.endoso.tipoIntermediario &&
    isValidIntermediario(form.endoso.tipoIntermediario, form.endoso.intermediario) &&
    form.asegurado &&
    form.endoso.aseguradoActividad &&
    form.endoso.actividadAsegurada &&
    (form.endoso.tipoEndosoId !== TIPO_ENDOSO_RENOVACION_ID || sections[CP_FS_BASICOS].vb);
  return !!isValidForm;
};

export const getCuotasPagadas = (planPago, inicioVigenciaEndoso) => {
  return planPago.cuotasPlan.filter(
    (cuota) => inicioVigenciaEndoso && cuota.fecha_pago.isBefore(inicioVigenciaEndoso, 'days'),
  );
};

export const getMaxCuotas = (cuotasPagadas, maxCuotas) =>
  cuotasPagadas > maxCuotas - 1 ? cuotasPagadas + 1 : maxCuotas;

export const getInicioVigenciaSeleccionada = (form) => {
  return form.ultimoEndosoVigencia
    ? moment(form.ultimoEndosoVigencia.inicio_vigencia, BACKEND_DF)
    : null;
};

export const getTerminoVigenciaSeleccionada = (form) => {
  return form.ultimoEndosoVigencia
    ? moment(form.ultimoEndosoVigencia.termino_vigencia, BACKEND_DF)
    : null;
};

export const canTerminate = (form) => {
  const { user } = store.getState().login;
  const privilegedProfiles = [
    PERFIL_COMERCIAL_CREDITO,
    PERFIL_ANALISTA_OPERACIONES,
    PERFIL_CEO,
    PERFIL_GERENTE_OPERACIONES,
    PERFIL_ADMINISTRADOR,
  ];
  const userCanTerminate = user.profiles.some((profile) => privilegedProfiles.includes(profile));
  const policyCanBeTerminated = form.balanceTemplate && form.balanceTemplate.puede_terminar;
  return (
    form.data.endoso.tipoEndosoId === TIPO_ENDOSO_MODIFICACION_ID &&
    policyCanBeTerminated &&
    userCanTerminate
  );
};

export const canProrrogate = (form) =>
  form.data.endoso.tipoEndosoId === TIPO_ENDOSO_MODIFICACION_ID &&
  form.ultimoEndosoVigencia &&
  form.ultimoEndosoVigencia.puede_prorrogar;

export const isEndosoEmision = (form) => {
  return form.data.endoso.tipoEndosoId === TIPO_ENDOSO_EMISION_ID;
};

export const isEndosoRenovacion = (form) => {
  return form.data.endoso.tipoEndosoId === TIPO_ENDOSO_RENOVACION_ID;
};

export const getTerminoVigenciaReferencia = (form) => {
  const { ultimoEndosoVigencia: balance } = form;
  if (balance === null) {
    return null;
  }
  return balance.termino_vigencia;
};

export const isValidInicioVigencia = (form, date) => {
  const esCeo = isUserHasProfile(PERFILES_INICIO_VIGENCIA_CEO);
  if (esCeo) {
    // en caso de ser CEO no tiene restriccion
    return true;
  }

  const esComercial = isUserHasProfile(PERFILES_INICIO_VIGENCIA_COMERCIAL);
  const esOperaciones = isUserHasProfile(PERFILES_INICIO_VIGENCIA_OPERACIONES);
  let fechaMinima;

  if (isEndosoEmision(form) || isEndosoRenovacion(form)) {
    if (esComercial) {
      fechaMinima = moment()
        .subtract(1, 'month')
        .startOf('month');
    } else if (esOperaciones) {
      fechaMinima = moment().startOf('month');
    }
  }

  const fechaMaxima = moment()
    .startOf('month')
    .add(2, 'month');

  return (
    fechaMinima &&
    moment(date).isSameOrAfter(fechaMinima) &&
    moment(date).isSameOrBefore(fechaMaxima)
  );
};

export const isValidTerminoVigencia = (form, date) => {
  const { user } = store.getState().login;
  const esComercial = (profile) => [PERFIL_COMERCIAL_CREDITO].includes(profile);
  const esOperaciones = (profile) => [PERFIL_ANALISTA_OPERACIONES].includes(profile);
  const esSuperior = (profile) => [PERFIL_ACTUARIADO, PERFIL_GERENTE_OPERACIONES].includes(profile);

  if (!isEndosoModificacion(form)) {
    return true;
  }
  const {
    balanceTemplate,
    data: {
      endoso: { inicioVigencia },
    },
  } = form;
  const refDate = moment(getTerminoVigenciaReferencia(form));
  const currDate = moment(getTerminoVigenciaSeleccionada(form));
  const aMonthAgo = moment.max(moment().subtract(1, 'month'), inicioVigencia);
  const twoMonthsAgo = moment.max(moment().subtract(2, 'month'), inicioVigencia);

  const tentativeTerminationDates = user.profiles.map((profile) => {
    if (esComercial(profile)) {
      return aMonthAgo;
    }
    if (esOperaciones(profile)) {
      return twoMonthsAgo;
    }
    if (esSuperior(profile)) {
      return inicioVigencia;
    }
    return currDate;
  });
  tentativeTerminationDates.push(currDate);
  const tentativeTerminationDate = moment.min(tentativeTerminationDates);
  const allowedTerminationDate =
    balanceTemplate && balanceTemplate.puede_terminar ? tentativeTerminationDate : currDate;

  return moment(date).isBetween(
    allowedTerminationDate,
    canProrrogate(form) ? refDate.add(1, 'months').endOf('month') : currDate,
    'date',
    '[]',
  );
};

export const isValidInicioVigenciaEndoso = (form, date) => {
  const dt = date !== undefined ? date : moment(form.data.endoso.inicioVigenciaEndoso, FRONT_DF_2);
  const initDate = form.ultimoEndosoVigencia.inicio_vigencia_endoso;
  const endDate = form.data.endoso.terminoVigencia;
  const day = moment.isMoment(endDate) ? endDate.format(BACKEND_DF) : endDate;

  return dt.isValid() && dt.isSameOrAfter(initDate) && dt.isSameOrBefore(`${day} 01:00`);
};

export const isValidComision = (comisionPct, tipoIntermediario) =>
  comisionPct > 0 || isIntermediarioDirecto(tipoIntermediario);

export const isValidPlanDePago = (form) => {
  const {
    data: {
      endoso,
      endoso: {
        planPago: { cuotasPlan },
        inicioVigenciaEndoso,
        deltaPrimaMinimaAnualAmt,
      },
    },
    cambioPrima,
    ultimoEndosoVigencia,
  } = form;

  const tipoVigencia = getSelectedTipoVigencia(form);
  const years = tipoVigencia ? tipoVigencia.value.years : 0;

  // netoIsValid
  const netoTarget = years * (endoso.primaMinimaAnualAmt || 0);
  const netoSum = cuotasPlan.reduce((total, cuota) => total + cuota.neto_amt, 0);
  const netoIsValid = areClose(netoSum, netoTarget);

  // nCuotasIsValid
  const maxCuotas = years * PLAN_PAGO_MAX_CUOTAS_ANUALES;
  const cuotasPendientes = cuotasPlan.reduce((total, cuota) => {
    if (inicioVigenciaEndoso && cuota.fecha_pago.isSameOrAfter(inicioVigenciaEndoso, 'days')) {
      return total + 1;
    }
    return total;
  }, 0);

  const cuotasBalance = ultimoEndosoVigencia
    ? ultimoEndosoVigencia.plan_pago.cuotas_plan.length
    : 0;
  let nCuotasIsValid;
  if (deltaPrimaMinimaAnualAmt !== 0) {
    nCuotasIsValid = cuotasPendientes <= maxCuotas;
  } else if (cambioPrima) {
    nCuotasIsValid = cuotasPlan.length <= Math.max(maxCuotas, cuotasBalance);
  } else {
    nCuotasIsValid = cuotasPlan.length <= maxCuotas;
  }

  // datesIsValid
  const datesIsValid = cuotasPlan.every(
    (cuota) =>
      cuota.fecha_pago.isSameOrAfter(endoso.inicioVigencia, 'days') &&
      cuota.fecha_pago.isSameOrBefore(endoso.terminoVigencia, 'days'),
  );

  return netoIsValid && nCuotasIsValid && datesIsValid;
};

export const isValidFormaDePago = (form) => {
  const {
    data: {
      endoso,
      endoso: { planPago, inicioVigenciaEndoso },
    },
  } = form;
  const cuotasPagadas = getCuotasPagadas(planPago, inicioVigenciaEndoso).length;
  const months = Math.round(endoso.terminoVigencia.diff(endoso.inicioVigencia, 'months', true));
  const validCuotasPagadas =
    endoso.deltaPrimaMinimaAnualAmt > 0
      ? endoso.planPago.cuotas > cuotasPagadas
      : endoso.planPago.cuotas >= cuotasPagadas;

  return (
    endoso.planPago.tipoId === TIPO_PLAN_LIBRO_VENTAS_ID ||
    (endoso.planPago.tipoId === TIPO_PLAN_CUOTAS_ID &&
      validCuotasPagadas &&
      endoso.planPago.cuotas <= getMaxCuotas(cuotasPagadas, months)) ||
    (endoso.planPago.tipoId === TIPO_PLAN_PLAN_PAGOS_ID && isValidPlanDePago(form))
  );
};

export const isValidCreditPolicyDetailsStep = (form) => {
  const {
    data,
    data: { endoso },
  } = form;

  const isValidFormaPago = isValidFormaDePago(form);

  const isValidNominados =
    endoso.coberturaNominadosPctId &&
    endoso.costoEstudioNominadoAprobadoAmt !== undefined &&
    endoso.costoEstudioNominadoRechazadoAmt !== undefined &&
    endoso.costoProrrogaNominadoPct !== undefined &&
    endoso.deducibleNominadoAmt !== undefined;
  const isValidInnominados =
    endoso.coberturaInnominadosPctId &&
    endoso.maximoInnominadosAmt > endoso.umbralCoberturaAmt &&
    endoso.costoEstudioInnominadoAprobadoAmt !== undefined &&
    endoso.costoEstudioInnominadoRechazadoAmt !== undefined &&
    endoso.costoProrrogaInnominadoPct !== undefined &&
    endoso.deducibleInnominadoAmt !== undefined &&
    (endoso.tipoIndemnizacionMaxId !== TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID ||
      endoso.indemnizacionMaxInnominado);

  const comisionIsValid = isValidComision(endoso.comisionPct, data.endoso.tipoIntermediario);

  const isValidForm =
    endoso.inicioVigencia &&
    endoso.inicioVigenciaEndoso &&
    endoso.terminoVigencia &&
    isValidTerminoVigencia(form, endoso.terminoVigencia) &&
    (!isEndosoModificacion(form) || isValidInicioVigenciaEndoso(form)) &&
    endoso.estimacionVentasAmt > 0 &&
    endoso.ventasPct > 0 &&
    comisionIsValid &&
    endoso.materiaAsegurada &&
    endoso.plazoMaximoCreditoDiasId &&
    endoso.indemnizacionMaxAmt > 0 &&
    endoso.tasaCostoSeguroDefectoPct > 0 &&
    isValidFormaPago &&
    endoso.configuracionSiniestroDias > 0 &&
    endoso.costoAvisoImpago !== undefined &&
    endoso.plazoGestionesPropiasDiasId &&
    endoso.umbralCoberturaAmt > 0 &&
    isValidNominados &&
    (!endoso.clientesInnominados || isValidInnominados) &&
    (endoso.tipoEndosoId !== TIPO_ENDOSO_RENOVACION_ID || form.sections[CP_FS_DETALLES].vb);
  return !!isValidForm;
};

export const isValidClientesYDedudoresStep = (form) => {
  const {
    sections: { [CP_FS_CLIENTES]: section },
    data: {
      endoso,
      endoso: { clientesEndoso },
    },
  } = form;
  const validClientesEndoso = clientesEndoso.every((cli) => {
    const initialValidation = cli.is_filial || cli.is_beneficiario;
    const filialValidation =
      !cli.is_filial || (cli.cliente.actividades.length === 0 || cli.actividad_id);
    return initialValidation && filialValidation;
  });
  return validClientesEndoso && (endoso.tipoEndosoId !== TIPO_ENDOSO_RENOVACION_ID || section.vb);
};

export const isValidParticularConditionsStep = (form) => {
  const {
    sections,
    data: {
      endoso,
      endoso: { noCondicionesParticulares, condicionesParticulares },
    },
  } = form;
  return (
    (noCondicionesParticulares || condicionesParticulares.length > 0) &&
    (endoso.tipoEndosoId !== TIPO_ENDOSO_RENOVACION_ID || sections[CP_FS_CONDICIONES].vb)
  );
};

const isValidValue = (value, type, option) => {
  if (type === 'bool') {
    return true;
  }
  if (type === 'tabla_sobreprimas') {
    return (
      value &&
      value.length > 0 &&
      value[0].limite_inferior === option.values.porcentajeSiniestralidad &&
      value[value.length - 1].limite_superior === null
    );
  }
  return ![undefined, null, ''].includes(value);
};

export const isValidOption = (option) =>
  !option.selected ||
  Object.keys(option.parametros).every((key) =>
    isValidValue(option.values[key], option.parametros[key].type, option),
  );

export const isValidParameterizedCondition = (condition) =>
  !condition.selected || condition.opciones.every((option) => isValidOption(option));

export const isValidParameterizedConditionsStep = (form) => {
  const {
    condicionesParametrizadasList,
    sections,
    data: {
      endoso,
      endoso: { noCondicionesParametrizadas },
    },
  } = form;

  const somethingSelected =
    noCondicionesParametrizadas || condicionesParametrizadasList.some((cond) => cond.selected);

  return (
    somethingSelected &&
    condicionesParametrizadasList.every(isValidParameterizedCondition) &&
    (endoso.tipoEndosoId !== TIPO_ENDOSO_RENOVACION_ID || sections[CP_FS_CLAUSULAS].vb)
  );
};

export const isValidCreditPolicyForm = (form) => {
  const isValidCreditPolicyBasicInfo = isValidCreditPolicyBasicInfoStep(form);
  const isValidCreditPolicyDetails = isValidCreditPolicyDetailsStep(form);
  const isValidClientesYDedudores = isValidClientesYDedudoresStep(form);
  const isValidParticularConditions = isValidParticularConditionsStep(form);
  const isValidParameterizedConditions = isValidParameterizedConditionsStep(form);
  return (
    isValidCreditPolicyBasicInfo &&
    isValidCreditPolicyDetails &&
    isValidClientesYDedudores &&
    isValidParticularConditions &&
    isValidParameterizedConditions
  );
};
