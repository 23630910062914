import React, { useState } from 'react';
import { CardBody, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import ProjectFormAccordionSection from './ProjectFormAccordionSection';

const ProjectFormAccordion = ({sections, isAllOpen}) => {
  const initialAccordion = Array(sections.length).fill(false);
  initialAccordion[0] = true;
  const [ accordion, setAccordion ] = useState(initialAccordion);

  const handleToggleAccordion = index => () => {
    setAccordion(accordion.map((bool, idx) => idx === index ? !bool : false));
  };

  return (
    <>
      {sections.map( (section, idx) => (
        <ProjectFormAccordionSection
          key={section.name}
          title={section.name}
          toggle={handleToggleAccordion(idx)}
          collapsed={!accordion[idx]}
          isValid={section.isValid}
          expansible={section.expansible}
        >
          <Collapse isOpen={isAllOpen || accordion[idx]}>
            <CardBody>
              {section.component}
            </CardBody>
          </Collapse>
        </ProjectFormAccordionSection>
      ))}
    </>
  );
};

ProjectFormAccordion.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAllOpen: PropTypes.bool,
};

ProjectFormAccordion.defaultProps = {
  isAllOpen: false,
};

export default ProjectFormAccordion;
