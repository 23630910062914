import React from 'react';

const OportunidadDesistidaSection = () => {
  return (
    <div>
      <p>OportunidadDesistidaSection</p>
    </div>
  );
};

OportunidadDesistidaSection.propTypes = {};

OportunidadDesistidaSection.defaultProps = {};

export default OportunidadDesistidaSection;
