import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ESTADOS_OPORTUNIDAD, API, BASE_URL } from 'CONST';
import { FormGroup, Label, Col, Input, Button } from 'reactstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { patch, post } from 'helpers/requests';
import CheckModal from 'components/shared/CheckModal';
import WarningModal from 'components/UI/WarningModal';
import AvanzarOportunidadConfirmationModal from './AvanzarOportunidadConfirmationModal';
import MotivosRechazoModal from './MotivosRechazoModal';

const RevisionGenericSection = ({ fullData, id, estadosOportunidad, observaciones, revisor }) => {
  const history = useHistory();

  const [editableState, setEditableState] = useState('');
  const [revisorName, setRevisorName] = useState('');
  const [siguienteRevisorName, setSiguienteRevisorName] = useState('');
  const [siguienteEstado, setSiguienteEstado] = useState('');

  const [observacion, setObservacion] = useState('');
  const [observacionesEstado, setObservacionesEstado] = useState([]);
  const [editableSection, setEditableSection] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [mensajeCheckModal, setMensajeCheckModal] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [mensajeWarningModal, setMensajeWarningModal] = useState('');
  const [motivoRechazoId, setMotivoRechazoId] = useState(null);
  const [confirmationApprovalModal, setConfirmationApprovalModal] = useState(false);
  const [confirmationScalationModal, setConfirmationScalationModal] = useState(false);
  const [confirmationRevisionModal, setConfirmationRevisionModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showRechazoModal, setShowRechazoModal] = useState(false);

  useEffect(() => {
    let editState;
    // Revisores:
    // Suscripcion => 0
    // Riesgo => 1
    // Comite de riesgo => 2
    if (revisor === 0) {
      editState = ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoSuscriptor;
      setEditableState(editState);
      setRevisorName('Área de Suscripción');
      setSiguienteRevisorName('área de riesgo');
      setSiguienteEstado(ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoRiesgo);
    } else if (revisor === 1) {
      editState = ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoRiesgo;
      setEditableState(editState);
      setRevisorName('Área de Riesgo');
      setSiguienteRevisorName('comité de riesgo');
      setSiguienteEstado(ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoComite);
    } else if (revisor === 2) {
      editState = ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoComite;
      setEditableState(editState);
      setRevisorName('Comité de Riesgo');
    }

    if (observaciones) {
      setObservacionesEstado(observaciones.filter((obs) => obs.estado.nemotecnico === editState));
    }
    if (fullData && fullData.estado && fullData.estado.nemotecnico === editState) {
      setEditableSection(true);
    }
  }, [observaciones, fullData]);

  const postObservacion = () => {
    if (observacion) {
      const body = {
        oportunidad_id: id,
        estado_id: estadosOportunidad.find((e) => e.nemotecnico === editableState).id,
        observacion,
      };
      post(`${BASE_URL}${API.observaciones}/`, body).catch(() => {
        setMensajeWarningModal('Hubo un problema al guardar la observación');
        setShowErrorModal(true);
      });
    }
  };

  const avanzarOportunidad = (mensajeCheck, mensajeWarning, estadoFinal) => {
    setLoading(true);
    setMensajeCheckModal(mensajeCheck);
    setMensajeWarningModal(mensajeWarning);
    const body = {
      estado_id: estadosOportunidad.find((e) => e.nemotecnico === estadoFinal).id,
    };
    if (estadoFinal === ESTADOS_OPORTUNIDAD.evaluacionAprobada) {
      body.estado_suscriptor_riesgo_comite_id = estadosOportunidad.find(
        (e) => e.nemotecnico === editableState,
      ).id;
    } else if (
      [
        ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoRiesgo,
        ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoComite,
      ].includes(estadoFinal)
    ) {
      body.estado_suscriptor_riesgo_comite_id = estadosOportunidad.find(
        (e) => e.nemotecnico === estadoFinal,
      ).id;
    }
    if (motivoRechazoId) {
      body.motivo_rechazo_id = motivoRechazoId;
    }
    patch(`${BASE_URL}${API.oportunidades_garantia}/${id}/`, body)
      .then(() => {
        postObservacion();
        setShowUpdateModal(true);
      })
      .catch(() => {
        setShowErrorModal(true);
      })
      .finally(() => {
        setConfirmationApprovalModal(false);
        setConfirmationScalationModal(false);
        setConfirmationRevisionModal(false);
        setShowRechazoModal(false);
        setLoading(false);
      });
  };

  const toggleRechazoModal = () => {
    return setShowRechazoModal(!showRechazoModal);
  };

  return (
    <div>
      <FormGroup row>
        <Col sm={2}>
          <Label>{`Observaciones ${revisorName}`}</Label>
        </Col>
        <Col>
          <Input
            id="observacion-info-inicial"
            type="textarea"
            value={
              !editableSection && observacionesEstado.length > 0
                ? observacionesEstado[0].observacion
                : observacion || ''
            }
            onChange={(event) => setObservacion(event.target.value)}
            rows="3"
            disabled={!editableSection}
          />
        </Col>
      </FormGroup>
      {observacionesEstado.slice(!editableSection).map((obs) => {
        return (
          <FormGroup row key={obs.id}>
            <Col sm={2} />
            <Col>
              <Input type="textarea" value={obs.observacion} rows="3" disabled />
            </Col>
          </FormGroup>
        );
      })}
      {editableSection && (
        <div className="button-container d-flex justify-content-end">
          <Button
            style={{ marginRight: 25 }}
            className="btn btn-secondary warning-cancel no-bold"
            onClick={() => setShowRechazoModal(true)}
            disabled={
              showRechazoModal ||
              confirmationScalationModal ||
              confirmationApprovalModal ||
              confirmationRevisionModal
            }
          >
            {loading && showRechazoModal ? (
              <FontAwesomeIcon spin={loading} icon={faSpinner} />
            ) : (
              'Rechazar Negocio'
            )}
          </Button>
          <Button
            style={{ marginRight: 25 }}
            className="btn btn-secondary warning-cancel white mb-0 no-bold"
            onClick={() => setConfirmationRevisionModal(true)}
            disabled={
              showRechazoModal ||
              confirmationScalationModal ||
              confirmationApprovalModal ||
              confirmationRevisionModal
            }
          >
            {loading && confirmationRevisionModal ? (
              <FontAwesomeIcon spin={loading} icon={faSpinner} />
            ) : (
              'Solicitar Revisión Información'
            )}
          </Button>
          {/* Si el revisor no es comité se puede escalar la oportunidad */}
          {revisor !== 2 && (
            <Button
              style={{ marginRight: 25 }}
              className="btn btn-secondary warning-cancel white mb-0 no-bold"
              onClick={() => setConfirmationScalationModal(true)}
              disabled={
                showRechazoModal ||
                confirmationScalationModal ||
                confirmationApprovalModal ||
                confirmationRevisionModal
              }
            >
              {loading && confirmationScalationModal ? (
                <FontAwesomeIcon spin={loading} icon={faSpinner} />
              ) : (
                `Solicitar Validación a ${siguienteRevisorName}`
              )}
            </Button>
          )}
          <Button
            className="btn btn-secondary orange"
            onClick={() => setConfirmationApprovalModal(true)}
            disabled={
              showRechazoModal ||
              confirmationScalationModal ||
              confirmationApprovalModal ||
              confirmationRevisionModal
            }
          >
            {loading && confirmationApprovalModal ? (
              <FontAwesomeIcon spin={loading} icon={faSpinner} />
            ) : (
              'Validar Oportunidad'
            )}
          </Button>
        </div>
      )}
      <CheckModal
        isOpen={showUpdateModal}
        toggle={() => history.push('/guarantee/oportunities')}
        handleOk={() => history.push('/guarantee/oportunities')}
        okBtnText="OK"
        confirmMsg={mensajeCheckModal}
      />
      <AvanzarOportunidadConfirmationModal
        openModal={confirmationApprovalModal}
        setOpenModal={setConfirmationApprovalModal}
        avanzarOportunidad={() =>
          avanzarOportunidad(
            'Se validó la oportunidad.',
            'No se pudo validar la oportunidad',
            ESTADOS_OPORTUNIDAD.evaluacionAprobada,
          )
        }
        loading={loading}
      />
      <AvanzarOportunidadConfirmationModal
        openModal={confirmationScalationModal}
        setOpenModal={setConfirmationScalationModal}
        avanzarOportunidad={() =>
          avanzarOportunidad(
            `Se envió la oportunidad al ${siguienteRevisorName}`,
            `No se pudo enviar la oportunidad al ${siguienteRevisorName}`,
            siguienteEstado,
          )
        }
        mensajeModal="¿Está seguro que desea ESCALAR esta oportunidad?"
        loading={loading}
      />
      <AvanzarOportunidadConfirmationModal
        openModal={confirmationRevisionModal}
        setOpenModal={setConfirmationRevisionModal}
        avanzarOportunidad={() =>
          avanzarOportunidad(
            'Oportunidad devuelta a estado anterior.',
            'No se pudo solicitar la revisión de la oportunidad',
            ESTADOS_OPORTUNIDAD.informacionInicialValidada,
          )
        }
        mensajeModal="¿Está seguro que desea RETROCEDER esta oportunidad?"
        loading={loading}
      />
      <WarningModal
        isOpen={showErrorModal}
        toggle={() => setShowErrorModal(false)}
        handleOk={() => setShowErrorModal(false)}
        okBtnText="Aceptar"
        confirmMsg={mensajeWarningModal}
        loading={false}
        onlyCancelButton
      />
      <MotivosRechazoModal
        openModal={showRechazoModal}
        toggleModal={toggleRechazoModal}
        observacion={observacion}
        setObservacion={setObservacion}
        avanzarOportunidad={avanzarOportunidad}
        motivoId={motivoRechazoId}
        setMotivoId={setMotivoRechazoId}
        loading={loading}
        fullData={fullData}
      />
    </div>
  );
};

RevisionGenericSection.propTypes = {
  id: PropTypes.string,
  fullData: PropTypes.shape({
    actualizacion_ts: PropTypes.string,
    afianzado: PropTypes.shape({
      id: PropTypes.number,
      nombre: PropTypes.string,
      rut: PropTypes.string,
    }),
    afianzado_id: PropTypes.number,
    creacion_ts: PropTypes.string,
    estado: PropTypes.shape({
      descripcion: PropTypes.string,
      id: PropTypes.number,
      nemotecnico: PropTypes.string,
    }),
    id: PropTypes.number,
    monto: PropTypes.number,
    responsable_id: PropTypes.number,
    suscriptor_id: PropTypes.number,
  }),
  estadosOportunidad: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      nemotecnico: PropTypes.string,
      descripcion: PropTypes.string,
    }),
  ),
  observaciones: PropTypes.arrayOf(
    PropTypes.shape({
      actualizacion_ts: PropTypes.string,
      creacion_ts: PropTypes.string,
      estado: PropTypes.shape({
        descripcion: PropTypes.string,
        id: PropTypes.number,
        nemotecnico: PropTypes.string,
      }),
      id: PropTypes.number,
    }),
  ),
  revisor: PropTypes.number.isRequired,
};

RevisionGenericSection.defaultProps = {
  fullData: null,
  id: null,
  estadosOportunidad: [],
  observaciones: [],
};

export default RevisionGenericSection;
