import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormSection from '../../../UI/FormSection';
import { CP_TOGGLE_VB } from '../../../../redux-store/constants/action-types';
import { sectionsType } from '../../../../redux-store/types';
import { TIPO_ENDOSO_RENOVACION_ID } from '../../../../CONST';


const VistoBuenoSection = ({ dispatch, style, sectionName, sections, tipoEndosoId, validate }) => {
  if (tipoEndosoId !== TIPO_ENDOSO_RENOVACION_ID) {
    return null;
  }
  const value = sections[sectionName].vb;
  const id = `vb${sectionName}`;
  return (
    <FormSection title="Visto Bueno" style={style}>
      <label htmlFor={id}>
        <input
          id={id}
          name={id}
          type="checkbox"
          checked={value}
          onChange={() => dispatch({type: CP_TOGGLE_VB, payload: sectionName})}
          required={validate}
        />
        <span className="small-form-value">
          &nbsp;&nbsp;
            Confirmo que estos valores son correctos.
        </span>
        {
          validate && !value && (
            <span className='error-color'>&nbsp;&nbsp;Debe marcar esta opción</span>
          )
        }
      </label>
    </FormSection>
  );
};


VistoBuenoSection.propTypes = {
  sections: sectionsType.isRequired,
  tipoEndosoId: PropTypes.number.isRequired,
  validate: PropTypes.bool.isRequired,
  sectionName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

VistoBuenoSection.defaultProps = {
  style: {marginTop: '35px'},
};

const mapStateToProps = state => ({
  sections: state.creditPolicy.form.sections,
  tipoEndosoId: state.creditPolicy.form.data.endoso.tipoEndosoId,
});

export default connect(mapStateToProps)(VistoBuenoSection);
