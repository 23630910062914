import React, { useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row, Modal, ModalBody } from 'reactstrap';
import { API, BASE_URL } from 'CONST';
import { get, post } from 'helpers/requests';
import AsyncSelect from 'react-select/async';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Select from 'react-select';
import isUserHasProfile from '../../helpers/profileValidator';
import { objectsToOptions } from '../../helpers/selects';
import { POLIZA_VV_WRITE } from '../../helpers/profilePermission';

const BeneficiarioCreateModal = ({
  isOpen,
  toggle,
  handleOk,
  rut,
  handleRutChange,
  organizationTypes,
  regiones,
  allRoles,
  rolesVentaEnVerde,
}) => {

  const [comunas, setComunas] = useState([]);

  // Formulario
  const [organizationType, setOrganizationType] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [fantasyName, setFantasyName] = useState('');
  const [razonSocial, setRazonSocial] = useState('');
  const [region, setRegion] = useState(null);
  const [comuna, setComuna] = useState(null);
  const [address, setAddress] = useState('');
  const [actividades, setActividades] = useState(null);
  const [phone, setPhone] = useState('');

  const handlePersonType = (option) => {
    setOrganizationType(option);
  }

  const handleFirstName = (option) => {
    setFirstName(option.target.value);
  }

  const handleLastName = (option) => {
    setLastName(option.target.value);
  }

  const handleFantasyName = (option) => {
    setFantasyName(option.target.value);
  }

  const handleRazonSocial = (option) => {
    setRazonSocial(option.target.value);
  }

  const handleRegiones = (option) => {
    setRegion(option);
    setComuna(null);

    get(`${BASE_URL}${API.comunas}?provincia__region__id=${option.value}`)
    .then((response) => {
      setComunas(objectsToOptions(response, false, true, 'name'));
    });
  }

  const handleComunas = (option) => {
    setComuna(option);
  }

  const handleAddress = (option) => {
    setAddress(option.target.value);
  }

  const handlePhone = (option) => {
    setPhone(option.target.value);
  }

  const handleActividades = (option) => {
    setActividades(option || []);
  }

  const filterOption = (inputValue, resolve) => {
    get(`${BASE_URL}${API.actividades}?search=${inputValue}`)
    .then((data) => {
      // Se muestran los primeros 20 solamente. Se pueden buscar por las demás ingresando búsqueda.
      const options = data.slice(0, 20).map(node => ({
        label: `${node.codigo} - ${node.nombre}`,
        value: node,
      }));
      resolve(options);
    });
  };

  const loadOptions = (inputValue) =>
    new Promise(resolve => filterOption(inputValue, resolve));

  const cleanModal = () => {
    setOrganizationType(null);
    setFirstName('');
    setLastName('');
    setRazonSocial('');
    setFantasyName('');
    setComunas([]);
    setRegion(null);
    setComuna(null);
    setAddress('');
    setActividades(null);
    setPhone('');
  };

  const isDisabled = () => {
    const baseConditions =
      comuna !== null &&
      organizationType !== null &&
      rolesVentaEnVerde.length > 0 &&
      address.length > 0;

    const personConditions =
      organizationType && organizationType.value === 1
        ? firstName.length > 0 && lastName.length > 0
        : fantasyName.length > 0 && razonSocial.length > 0

    const validRole = isUserHasProfile(POLIZA_VV_WRITE);

    return !validRole || !personConditions || !baseConditions
  }

  const handleSubmit = () => {
    const body = {
      rut: rut.replace(/\./g, ''),
      comuna_id: comuna.value,
      type_id: organizationType.value,
      roles_set: rolesVentaEnVerde.map(rol => rol.value),
      actividades_set: actividades ? actividades.map(node => node.value.id) : [],
      address,
      phone,
    };

    if (organizationType.value === 1) {
      body.first_name = firstName;
      body.last_name = lastName;
    } else {
      body.nombre_fantasia = fantasyName;
      body.razon_social = razonSocial;
    }

    post(`${BASE_URL}${API.organizaciones}`, body)
    .then((response) => {
      handleRutChange(response.rut)
      cleanModal();
      handleOk();
    });
  }

  return (
    <Modal className='check-modal' isOpen={isOpen} toggle={toggle} style={{maxWidth: '1300px', width: '100%'}}>
      <ModalBody className='check-modal'>
        <div className="form-box">
          <Col>
            <p className="form-title">Información Cliente</p>
            {/* Rut */}
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="rut">
                RUT
                <span className="required text-danger">*</span>
              </Label>
              <Col>
                <Input
                  name="rut"
                  id="rut"
                  value={rut}
                  disabled
                />
              </Col>
            </FormGroup>
            {/* Tipo Persona */}
            <FormGroup className="row">
              <Label
                className="col-sm-2 col-form-label"
                for="organization_type"
              >
                Tipo de Persona
                <span className="required text-danger">*</span>
              </Label>
              <Col>
                <Select
                  id="organization_type"
                  name="organization_type"
                  clearable={false}
                  removeSelected={false}
                  options={organizationTypes}
                  onChange={handlePersonType}
                  value={organizationType}
                />
              </Col>
            </FormGroup>
            {/* Nombre */}
            <FormGroup
              className={`row ${
                organizationType && organizationType.value === 1 ? '' : 'd-none'
              }`}
            >
              <Label className="col-sm-2 col-form-label" for="name">
                Nombre
                <span className="required text-danger">*</span>
              </Label>
              <Col>
                <Input
                  name="name"
                  id="name"
                  value={firstName}
                  onChange={handleFirstName}
                  placeholder="Ingrese nombre"
                />
              </Col>
            </FormGroup>
            {/* Apellido */}
            <FormGroup
              className={`row ${
                organizationType && organizationType.value === 1 ? '' : 'd-none'
              }`}
            >
              <Label className="col-sm-2 col-form-label" for="name">
                Apellidos
                <span className="required text-danger">*</span>
              </Label>
              <Col>
                <Input
                  name="lastName"
                  id="last_name"
                  value={lastName}
                  onChange={handleLastName}
                  placeholder="Ingrese apellido"
                />
              </Col>
            </FormGroup>
            {/* Nombre Fantasía */}
            <FormGroup
              className={`row ${
                organizationType && organizationType.value === 2 ? '' : 'd-none'
              }`}
            >
              <Label className="col-sm-2 col-form-label" for="nombre_fantasia">
                Nombre de fantasía
                <span className="required text-danger">*</span>
              </Label>
              <Col>
                <Input
                  name="fantasyName"
                  id="fantasy_name"
                  value={fantasyName}
                  onChange={handleFantasyName}
                  placeholder="Ingrese nombre de fantasía"
                />
              </Col>
            </FormGroup>
            {/* Razón Social */}
            <FormGroup
              className={`row ${
                organizationType && organizationType.value === 2 ? '' : 'd-none'
              }`}
            >
              <Label className="col-sm-2 col-form-label" for="razon_social">
                Razón social
                <span className="required text-danger">*</span>
              </Label>
              <Col>
                <Input
                  name="razonSocial"
                  id="razon_social"
                  value={razonSocial}
                  onChange={handleRazonSocial}
                  placeholder="Ingrese razón social"
                />
              </Col>
            </FormGroup>
            {/* Región y Comuna */}
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="region">
                Región
                <span className="required text-danger">*</span>
              </Label>
              <Col md={4}>
                <Select
                  id="region"
                  name="region"
                  placeholder="Seleccione una región"
                  options={regiones}
                  onChange={handleRegiones}
                  value={region}
                />
              </Col>
              <Label className="col-sm-1 col-form-label" for="position">
                Comuna
                <span className="required text-danger">*</span>
              </Label>
              <Col md={5}>
                <Select
                  id="comuna"
                  name="comuna"
                  placeholder="Seleccione una comuna"
                  options={comunas}
                  isDisabled={region === null}
                  onChange={handleComunas}
                  value={comuna}
                />
              </Col>
            </FormGroup>
            {/* Roles */}
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="roles">
                Roles
                <span className="required text-danger">*</span>
              </Label>
              <Col>
                <Select
                  id="roles"
                  name="roles"
                  placeholder="Seleccione roles"
                  options={allRoles}
                  value={rolesVentaEnVerde}
                  getOptionValue={role => role.value}
                  isMulti
                  isDisabled
                />
              </Col>
            </FormGroup>
            {/* Dirección */}
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="lastNames">
                Dirección
                <span className="required text-danger">*</span>
              </Label>
              <Col>
                <Input
                  name="address"
                  id="address"
                  value={address}
                  onChange={handleAddress}
                  placeholder="Ingrese dirección"
                />
              </Col>
            </FormGroup>
            {/* Actividades */}
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="position">
                Actividades
              </Label>
              <Col>
                <AsyncSelect
                  isMulti
                  id="actividad"
                  name="actividad"
                  cacheOptions
                  defaultOptions
                  loadOptions={loadOptions}
                  onChange={handleActividades}
                  value={actividades}
                  placeholder="Seleccione una actividad"
                />
              </Col>
            </FormGroup>
            {/* Teléfono */}
            <FormGroup className="row">
              <Label className="col-sm-2 col-form-label" for="phone">
                Teléfono
              </Label>
              <Col>
                <Input
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={handlePhone}
                  placeholder="Ingrese teléfono"
                />
              </Col>
            </FormGroup>
            {/* Submit */}
            <Row>
              <div className="col-md-3">
                <Button
                  className="form-button"
                  style={{
                    margin: '20px 0 10px',
                    width: '100%',
                    backgroundColor: '#F98724',
                    border: '#F98724',
                  }}
                  disabled={isDisabled()}
                  onClick={handleSubmit}
                >
                  <FontAwesomeIcon icon={faSave} className="mr-1" />
                  Guardar
                </Button>
              </div>
            </Row>
          </Col>
        </div>
      </ModalBody>
    </Modal>
  )
}

BeneficiarioCreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  rut: PropTypes.string.isRequired,
  handleRutChange: PropTypes.func.isRequired,
  organizationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      object: PropTypes.object,
    })
  ).isRequired,
  regiones: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      object: PropTypes.object,
    })
  ).isRequired,
  allRoles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      object: PropTypes.object,
    })
  ).isRequired,
  rolesVentaEnVerde: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      object: PropTypes.object,
    })
  ).isRequired,
}

export default BeneficiarioCreateModal;
