import { BASE_URL, API, ESTADOS_OPORTUNIDAD, OPORTUNIDADES_STATE_SECTION_MAP } from 'CONST';
import { get, patch } from 'helpers/requests';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Button, Col, FormGroup, Label } from 'reactstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckModal from 'components/shared/CheckModal';
import WarningModal from 'components/UI/WarningModal';
import AvanzarOportunidadConfirmationModal from './AvanzarOportunidadConfirmationModal';

const AsignarResponsableSection = ({
  fullData,
  setFullData,
  setInitialSection,
  id,
  estadosOportunidad,
}) => {
  const [responsableOptions, setResponsableOptions] = useState([]);
  const [responsable, setResponsable] = useState(null);
  const [sectionData, setSectionData] = useState({
    responsable_id: null,
  });
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [editableSection, setEditableSection] = useState(false);
  const [loading, setLoading] = useState(false);

  const editableStates = [ESTADOS_OPORTUNIDAD.creado];

  useEffect(() => {
    if (fullData && fullData.estado && editableStates.includes(fullData.estado.nemotecnico)) {
      get(`${BASE_URL}${API.responsables_oportunidad}/`).then((response) => {
        setResponsableOptions(
          response.results.map((e) => ({
            value: e.id,
            label: `${e.rut} - ${e.user.first_name} ${e.user.last_name}`,
          })),
        );
      });
    }
  }, []);

  useEffect(() => {
    if (fullData && fullData.estado && editableStates.includes(fullData.estado.nemotecnico)) {
      setEditableSection(true);
    }
  }, [fullData]);

  useEffect(() => {
    if (fullData.responsable_id) {
      get(`${BASE_URL}${API.responsables_oportunidad}/${fullData.responsable_id}/`).then(
        (response) => {
          setResponsable({
            value: response.id,
            label: `${response.rut} - ${response.user.first_name} ${response.user.last_name}`,
          });
        },
      );
    }
  }, [fullData, responsableOptions]);

  const asignarResponsable = () => {
    setLoading(true);
    const body = {
      ...sectionData,
      estado_id: estadosOportunidad.find(
        (e) => e.nemotecnico === ESTADOS_OPORTUNIDAD.responsableAsignado,
      ).id,
    };
    patch(`${BASE_URL}${API.oportunidades_garantia}/${id}/`, body)
      .then((response) => {
        setFullData(response);
        setInitialSection(OPORTUNIDADES_STATE_SECTION_MAP[response.estado.nemotecnico]);
        setEditableSection(false);
        setShowUpdateModal(true);
      })
      .catch(() => {
        setShowErrorModal(true);
      })
      .finally(() => {
        setConfirmationModal(false);
        setLoading(false);
      });
  };

  return (
    <div>
      <FormGroup row>
        <Col sm={2}>
          <Label>
            Responsable
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col sm={5}>
          <Select
            className="custom-policy-select"
            options={responsableOptions}
            value={responsable}
            onChange={(event) => {
              setSectionData({ responsable_id: event.value });
              setResponsable(event);
            }}
            isDisabled={!editableSection}
            placeholder="Seleccione un responsable"
          />
        </Col>
      </FormGroup>
      {editableSection && (
        <div className="button-container d-flex justify-content-end">
          <Button
            className="btn btn-secondary orange"
            onClick={() => setConfirmationModal(true)}
            disabled={responsable === null || confirmationModal}
          >
            {loading ? <FontAwesomeIcon spin={loading} icon={faSpinner} /> : 'Asignar Responsable'}
          </Button>
        </div>
      )}
      <AvanzarOportunidadConfirmationModal
        openModal={confirmationModal}
        setOpenModal={setConfirmationModal}
        avanzarOportunidad={asignarResponsable}
        loading={loading}
      />
      <CheckModal
        isOpen={showUpdateModal}
        toggle={() => setShowUpdateModal(false)}
        handleOk={() => setShowUpdateModal(false)}
        okBtnText="OK"
        confirmMsg="Responsable Asignado Satisfactoriamente."
      />
      <WarningModal
        isOpen={showErrorModal}
        toggle={() => setShowErrorModal(false)}
        handleOk={() => setShowErrorModal(false)}
        okBtnText="Aceptar"
        confirmMsg="Ha ocurrido un error al asignar al responsable."
        loading={false}
        onlyCancelButton
      />
    </div>
  );
};

AsignarResponsableSection.propTypes = {
  id: PropTypes.string,
  fullData: PropTypes.shape({
    estado: PropTypes.shape({
      nemotecnico: PropTypes.string,
    }),
    responsable_id: PropTypes.number,
  }),
  setFullData: PropTypes.func.isRequired,
  setInitialSection: PropTypes.func.isRequired,
  estadosOportunidad: PropTypes.arrayOf(
    PropTypes.shape({
      nemotecnico: PropTypes.string,
    }),
  ),
};

AsignarResponsableSection.defaultProps = {
  id: null,
  fullData: null,
  estadosOportunidad: [],
};

export default AsignarResponsableSection;
