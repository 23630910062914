import store from 'redux-store/store';

export default function isUserHasProfile(validProfiles) {
  const state = store.getState();
  const userProfiles = state.login.user.profiles;

  for (let index = 0; index < userProfiles.length; index += 1) {
    const profile = userProfiles[index];
    if (validProfiles.includes(profile)) {
      return true;
    }
  }

  return false;
}

