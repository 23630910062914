import React, { Component } from 'react';

import PropTypes from 'prop-types';
import ParticularCondition from './helpers/ParticularCondition';
import {
  deselectParticularCondition,
  selectParticularCondition,
  setNoParticularConditionSelected,
} from '../../../redux-store/actions/creditPolicyActions';
import { particularConditionType } from '../../../redux-store/types';
import { CP_SET_SECTION_VALIDATION } from '../../../redux-store/constants/action-types';
import { isValidParticularConditionsStep } from '../../../helpers/validation';
import VistoBuenoSection from './helpers/VistoBuenoSection';
import { CP_FS_CONDICIONES } from '../../../CONST';

class CreditPolicyParticularConditionsStep extends Component {
  state = {
    validateInputs: false,
  };

  componentDidUpdate() {
    const {
      dispatch,
      creditPolicy: {
        form,
        form: { sections },
      },
    } = this.props;
    const isValid = isValidParticularConditionsStep(form);
    if (isValid !== sections[CP_FS_CONDICIONES].valid) {
      dispatch({
        type: CP_SET_SECTION_VALIDATION,
        payload: { stepName: CP_FS_CONDICIONES, isValid },
      });
    }
  }

  setValidateInputs = validateInputs => this.setState({ validateInputs });

  render() {
    const {
      dispatch,
      creditPolicy: {
        form,
        form: {
          data: { ramoFecu },
        },
      },
      readOnly,
    } = this.props;
    const { validateInputs } = this.state;
    const selected = form.data.endoso.condicionesParticulares;
    const particularConditions = form.condicionesParticularesList.filter(cond =>
      cond.ramos.includes(ramoFecu ? ramoFecu.value.id : ''),
    );
    const noneSelected = form.data.endoso.noCondicionesParticulares;

    const handleClickedCond = node => {
      if (selected.includes(node.id))
        dispatch(deselectParticularCondition(node.id));
      else dispatch(selectParticularCondition(node.id));
    };

    return (
      <>
        {particularConditions.map(node => (
          <ParticularCondition
            condition={node}
            key={node.id}
            disabled={noneSelected || readOnly}
            checked={selected.includes(node.id)}
            onChange={handleClickedCond}
          />
        ))}
        <div style={{ marginTop: '60px' }}>
          <label
            htmlFor="no-agregar-condiciones"
            style={{ fontSize: '16px', fontWeight: 'normal', marginBottom: 0 }}
          >
            <input
              id="no-agregar-condiciones"
              type="checkbox"
              style={{ marginRight: '20px' }}
              checked={noneSelected}
              onChange={() =>
                dispatch(setNoParticularConditionSelected(!noneSelected))
              }
              disabled={readOnly}
            />
            No agregar condiciones particulares
          </label>
        </div>
        <VistoBuenoSection
          sectionName={CP_FS_CONDICIONES}
          validate={validateInputs}
        />
        <div className="cp-form-feedback">
          <p>
            {validateInputs &&
              !noneSelected &&
              selected.length === 0 &&
              '*Es necesario seleccionar al menos una alternativa. Si no desea agregar Condiciones' +
                ' Particulares, seleccione dicha opción.'}
          </p>
        </div>
      </>
    );
  }
}

CreditPolicyParticularConditionsStep.propTypes = {
  dispatch: PropTypes.func.isRequired,
  creditPolicy: PropTypes.shape({
    form: PropTypes.shape({
      sections: PropTypes.object,
      condicionesParticularesList: PropTypes.arrayOf(particularConditionType),
      data: PropTypes.shape({
        ramoFecu: PropTypes.object,
        endoso: PropTypes.shape({
          condicionesParticulares: PropTypes.arrayOf(PropTypes.number),
          noCondicionesParticulares: PropTypes.bool,
        }),
      }),
    }),
  }).isRequired,
  readOnly: PropTypes.bool,
};

CreditPolicyParticularConditionsStep.defaultProps = {
  readOnly: false,
};

export default CreditPolicyParticularConditionsStep;
