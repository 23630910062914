import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ModalFooter, ModalHeader } from 'reactstrap';
import BaseModal from './BaseModal';
import { alertModal } from '../../redux-store/actions';
import auth from '../../helpers/auth';


class MissingTokenModal extends Component {
  handleModal = () => {
    const { hideModal } = this.props;
    hideModal();
    auth.authenticate();
  };

  render() {
    const { tokenWarning } = this.props;
    return (
      <BaseModal
        handleModal={this.handleModal}
        modalOpen={tokenWarning}
        type="token"
      >
        <ModalHeader toggle={this.handleModal} />
        <div className="body">
          <div className="title-text font-weight-bold">
            Su sesión ha expirado
          </div>
          <div className="body-text">
            Por Favor, vuelva a iniciar sesión con sus credenciales
          </div>
        </div>
        <ModalFooter className="text-right">
          <Button className="token-accept" onClick={this.handleModal}>
            Aceptar
          </Button>
        </ModalFooter>
      </BaseModal>
    );
  }
}

MissingTokenModal.propTypes = {
  tokenWarning: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tokenWarning: state.login.tokenWarning
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(alertModal(false))
});

export default connect(mapStateToProps, mapDispatchToProps)(MissingTokenModal);
