import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PageContainer from '../PageContainer';
import GeneralConditionedForm from './GeneralConditionedForm';
import { getData, postData, uploadFile } from '../../redux-store/actions/index';
import {
  GET_PRODUCTOS,
  GET_TIPOLOGIAS_EJECUCION,
  GET_TIPOS_COBERTURA,
  CREATE_GENERAL_CONDITIONED,
  UPLOAD_FILE, GET_RAMOS_FECU,
} from '../../redux-store/constants/action-types';
import { API, BASE_URL } from '../../CONST';

class GeneralConditionedCreate extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  componentDidMount() {
    const { dispatchGetData } = this.props;
    dispatchGetData({
      url: `${BASE_URL}${API.productos}`,
      type: GET_PRODUCTOS,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.tipologias_ejecucion}`,
      type: GET_TIPOLOGIAS_EJECUCION,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.tipos_cobertura}`,
      type: GET_TIPOS_COBERTURA,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.ramos_fecu}`,
      type: GET_RAMOS_FECU,
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const { history } = this.props;
    if (nextProps.conditioned.successMessage) {
      history.push('/condicionados/');
    }
  }

  handleSubmit(config, onFailedReq = () => {}) {
    const { dispatchPostData } = this.props;
    dispatchPostData({
      url: `${BASE_URL}${API.maestras}/`,
      type: CREATE_GENERAL_CONDITIONED,
      onFailedReq,
      config,
    });
  }

  handleFile(e) {
    const { files } = e.target;
    const reader = new FileReader();
    const { name } = e.target.files[0];
    const { dispatchUploadFile } = this.props;
    reader.readAsArrayBuffer(files[0]);
    reader.onload = (error) => {
      const body = error.target.result;
      dispatchUploadFile({
        url: `${BASE_URL}${API.upload}/`,
        type: UPLOAD_FILE,
        config: { body, name },
      });
    };
  }

  render() {
    const { conditioned } = this.props;
    return (
      <PageContainer
        breadcrumbs={[
          { name: 'CONDICIONADOS GENERALES', url: '/condicionados/' },
          { name: 'CREAR CONDICIONADO GENERAL' },
        ]}
        style={{ padding: '0 20px' }}
      >
        <GeneralConditionedForm
          documento_uuid={conditioned.documento_uuid}
          handleSubmit={this.handleSubmit}
          handleFile={this.handleFile}
          productos={conditioned.productos}
          tipologias_ejecucion={conditioned.tipologias_ejecucion}
          ramos_fecu={conditioned.ramos_fecu}
          tipos_cobertura={conditioned.tipos_cobertura}
        />
      </PageContainer>
    );
  }
}

GeneralConditionedCreate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  conditioned: PropTypes.shape({
    documento_uuid: PropTypes.string,
    productos: PropTypes.array,
    tipologias_ejecucion: PropTypes.array,
    tipos_cobertura: PropTypes.array,
    ramos_fecu: PropTypes.array,
  }).isRequired,
  dispatchGetData: PropTypes.func.isRequired,
  dispatchPostData: PropTypes.func.isRequired,
  dispatchUploadFile: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  conditioned: state.conditioned,
});

const mapDispatchToProps = dispatch => ({
  dispatchGetData: data => dispatch(getData(data)),
  dispatchPostData: policy => dispatch(postData(policy)),
  dispatchUploadFile: file => dispatch(uploadFile(file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralConditionedCreate);
