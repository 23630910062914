import React, { useEffect, useState } from 'react';

import { API, BASE_URL, ROLES } from 'CONST';
import { get } from 'helpers/requests';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { cellNumOperation, cellOperation } from 'helpers/tables';
import Select from 'react-select';


const ProjectContratanteSection = ({
  edit,
  dataEdition,
  organizacion,
  setOrganizacion,
  linea,
  isDisabled,
  actividadesList,
  giro,
  setGiro,
  setHasGiro,
}) => {
  const [organizaciones, setOrganizaciones] = useState([]);

  useEffect(() => {
    get(`${BASE_URL}${API.clientes}?roles__term=${ROLES.afianzadoVentaVerde}`).then((response) => {
      const options = response.results.map(node => ({
        label: `${node.rut} - ${node.nombre}`,
        value: node,
      }));
      setOrganizaciones(options);
    });
  }, []);

  useEffect(() => {
    if (edit && dataEdition) {
      if (dataEdition.organizacion) {
        setOrganizacion({
          label: `${dataEdition.organizacion.rut} - ${dataEdition.organizacion.nombre}`,
          value: dataEdition.organizacion,
        });
      }
      if (dataEdition.actividad) {
        setGiro({
          label: dataEdition.actividad.nombre,
          value: dataEdition.actividad,
        });
      }
    }
  }, [edit, dataEdition]);

  return (
    <div>
      <FormGroup row>
        <Label sm={3} for="organizacion">
          Contratante
          <span className="required text-danger">*</span>
        </Label>
        {edit ?
          (
            <Col style={{ padding: 0, marginTop: '4px' }}>
              {organizacion && cellOperation(organizacion.label, 'left')}
            </Col>
          ):(
            <Col style={{ padding: 0 }}>
              <Select
                id="organizacion"
                name="organizacion"
                className="custom-policy-select"
                options={organizaciones}
                onChange={(event) => {
                  setGiro(null);
                  setHasGiro(false);
                  setOrganizacion(event);
                }}
                value={organizacion}
                placeholder="Seleccione Contratante"
                isDisabled={!isDisabled}
              />
            </Col>
          )
        }
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="giro">
          Giro
          <span className="required text-danger">*</span>
        </Label>
        {edit ? (
          <Col style={{ padding: 0, marginTop: '4px' }}>
            {giro && cellOperation(giro.label, 'left')}
          </Col>
        ) : (
          <Col style={{ padding: 0 }}>
            <Select
              id="giro"
              name="giro"
              className="custom-policy-select"
              options={actividadesList}
              value={giro}
              onChange={(event) => setGiro(event)}
              placeholder="Seleccione Giro"
              isDisabled={!isDisabled}
            />
          </Col>
        )}
      </FormGroup>
      {organizacion && Object.keys(linea).length !== 0 && (
        <FormGroup>
          <Row>
            <Label sm={3} for="linea-otorgada">
              Línea Venta en Verde otorgada (UF)
            </Label>
            <Col style={{ padding: 0, marginTop: '4px' }}>
              {linea && linea.balance && cellNumOperation(linea.balance.linea_proyectos, 'left')}
            </Col>
          </Row>
          <Row>
            <Label sm={3} for="monto-asignado-proyectos">
              Monto asignado a Proyectos Venta en Verde (UF)
            </Label>
            <Col style={{ padding: 0, marginTop: '4px' }}>
              {linea && linea.balance && cellNumOperation(linea.balance.monto_asignado_proyecto, 'left')}
            </Col>
          </Row>
          <Row>
            <Label sm={3} for="cupo-disponible">
              Cupo disponible Línea Venta en Verde (UF)
            </Label>
            <Col style={{ padding: 0, marginTop: '4px' }}>
              {linea && linea.balance && cellNumOperation(linea.balance.cupo_disponible_proyectos, 'left')}
            </Col>
          </Row>
        </FormGroup>
      )}
    </div>
  );
}

ProjectContratanteSection.propTypes = {
  organizacion: PropTypes.shape({
    label: PropTypes.string,
  }),
  setOrganizacion: PropTypes.func.isRequired,
  giro: PropTypes.shape({
    label: PropTypes.string,
  }),
  setGiro: PropTypes.func.isRequired,
  setHasGiro: PropTypes.func.isRequired,
  linea: PropTypes.shape({
    balance: PropTypes.shape({
      cupo_restante_proyectos: PropTypes.number,
      linea_proyectos: PropTypes.number,
      linea: PropTypes.number,
      monto_asignado_proyecto: PropTypes.number,
      cupo_disponible_proyectos: PropTypes.number,
    })
  }).isRequired,
  edit: PropTypes.bool.isRequired,
  dataEdition: PropTypes.shape({
    organizacion: PropTypes.shape({
      rut: PropTypes.string,
      nombre: PropTypes.string,
    }),
    actividad: PropTypes.shape({
      nombre: PropTypes.string,
    }),
  }),
  isDisabled: PropTypes.bool.isRequired,
  actividadesList: PropTypes.arrayOf(PropTypes.object),
};

ProjectContratanteSection.defaultProps = {
  dataEdition: PropTypes.shape({
    organizacion: null,
    giro: null,
  }),
  organizacion: null,
  giro: null,
  actividadesList: [],
};

export default ProjectContratanteSection;
