import store from '../redux-store/store/index';

const fileDownload = (downloadUrl, id) => {
  const { login } = store.getState();
  const xhr = new XMLHttpRequest();
  xhr.open('GET', `${downloadUrl}${id}`, true);
  xhr.responseType = 'blob';
  xhr.setRequestHeader('Authorization', `JWT ${login.id_token}`);
  xhr.onload = function onload() {
    if (this.status === 200) {
      const content = xhr.getResponseHeader('Content-Disposition');
      let filename = '';
      if (content.includes('filename=')) {
        filename = content.split('filename=')[1].replace(/^"+|"+$/g, '');
      } else {
        filename = decodeURI(content.split('filename*=utf-8\'\'')[1].replace(/^"+|"+$/g, ''));
      }
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([this.response]));
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 1000);
    }
  };
  xhr.send()
};

export default fileDownload;