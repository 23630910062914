import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormText, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import fileDownload from 'helpers/fileDownload';
import auth from '../../helpers/auth';
import './FileTable.css';
import { bytesToMb, mbToBytes } from '../../helpers/helpers';

import SafeBtn from './SafeBtn';

class NewFileTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      individualMaxFileSizeError: false,
      pendingDeletion: false,
      globalMaxFileSizeError: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { loading } = this.props;
    if (prevProps.loading && !loading) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        pendingDeletion: false,
        uploading: false,
      });
    }
  }

  getCurrentFilesSize = () => {
    const { newFiles } = this.props;
    return newFiles.reduce((acc, file) => acc + file.size, 0);
  };

  validateMaxSize = (fileSize) => {
    const { individualMaxFileSize, globalMaxFileSize } = this.props;

    if (fileSize > mbToBytes(individualMaxFileSize)) {
      return {
        isValid: false,
        errorType: 'individual',
      };
    }

    if (globalMaxFileSize && bytesToMb(this.getCurrentFilesSize() + fileSize) > globalMaxFileSize) {
      return {
        isValid: false,
        errorType: 'global',
      };
    }

    return { isValid: true };
  };

  handleFile = (e) => {
    const { uploadUrl } = this.props;
    const { files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      const maxSizeValidation = this.validateMaxSize(file.size);
      if (maxSizeValidation.isValid) {
        this.setState({
          individualMaxFileSizeError: false,
        });
        const reader = new FileReader();
        const { name } = file;
        reader.readAsArrayBuffer(file);
        reader.onload = (event) => {
          const body = event.target.result;
          this.uploadFile({
            url: uploadUrl,
            config: { body, name },
            size: file.size,
          });
        };
      } else if (maxSizeValidation.errorType === 'individual') {
        this.setState({ individualMaxFileSizeError: true });
      } else {
        this.setState({ globalMaxFileSizeError: true });
      }
    }
  };

  uploadFile = (object) => {
    const { handleAdd, prefijo } = this.props;
    const filename = prefijo ? prefijo.concat('_', object.config.name) : object.config.name
    this.setState({
      uploading: true,
    });
    auth
      .fetch(object.url, {
        method: 'PUT',
        body: object.config.body,
        headers: {
          'Content-Disposition': `attachment; filename="${encodeURI(filename)}"`,
        },
      })
      .then((response) => {
        const addedFile = {
          original_name: object.config.name,
          uuid: response.uuid,
          size: object.size,
        };
        this.setState({ uploading: false });
        handleAdd(addedFile);
      });
  };

  handleDelete = (file, isNewFile) => {
    const { handleDelete, handleDeleteNew } = this.props;
    if (isNewFile) {
      handleDeleteNew(file);
    } else {
      this.setState({
        pendingDeletion: true,
      });
      handleDelete(file);
    }
  };

  fileRow = (file, isNew, readOnly) => {
    const { loginIdToken, downloadUrl, tempDownload, tempDownloadUrl } = this.props;
    return (
      <tr key={file.id || file.uuid} className={isNew ? 'new' : ''}>
        <td className="filename">{file.original_name}</td>
        <td>
          {!isNew && (
            <Button
              className="reactstrap-table-link"
              color="link"
              onClick={() => fileDownload(`${downloadUrl}${file.id}`, loginIdToken)}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          )}
        </td>
        <td>
          {isNew && tempDownload && (
            <Button
              className="reactstrap-table-link"
              color="link"
              onClick={() => fileDownload(`${tempDownloadUrl}${file.uuid}`, loginIdToken)}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          )}
        </td>
        {!readOnly && (
          <td>
            <Button close onClick={() => this.handleDelete(file, isNew)} />
          </td>
        )}
      </tr>
    );
  };

  render() {
    const {
      fileList,
      newFiles,
      readOnly,
      showSave,
      handleSave,
      loading,
      individualMaxFileSize,
      globalMaxFileSize,
    } = this.props;
    const {
      individualMaxFileSizeError,
      globalMaxFileSizeError,
      pendingDeletion,
      uploading,
    } = this.state;
    return (
      <>
        {(fileList.length > 0 || newFiles.length > 0) && (
          <div className="file-table-container">
            <table className={`file-table ${readOnly ? 'read-only' : ''}`}>
              <tbody>
                {fileList && fileList.map((file) => this.fileRow(file, false, readOnly))}
                {newFiles.map((file) => this.fileRow(file, true, readOnly))}
              </tbody>
            </table>
          </div>
        )}
        {/* eslint-disable */}
        <div style={{ display: 'flex' }}>
          {!readOnly && (
            <div className="file-upload" style={{ width: '100%', marginTop: 0 }}>
              <label>
                {uploading ? (
                  <span>
                    <FontAwesomeIcon icon={faSpinner} pulse />
                    &nbsp;&nbsp;Subiendo...
                  </span>
                ) : (
                  'Seleccionar archivo'
                )}
                <Input
                  type="file"
                  name="file"
                  id="file"
                  invalid
                  style={{ marginTop: '15px' }}
                  onChange={this.handleFile}
                  disabled={loading || uploading}
                />
              </label>
              <div className={!individualMaxFileSizeError ? 'd-none' : 'invalid-feedback'}>
                El archivo supera el tamaño máximo permitido
              </div>
              <div className={!globalMaxFileSizeError ? 'd-none' : 'invalid-feedback'}>
                Los archivos superan el tamaño máximo total permitido
              </div>
              <FormText>
                <div>Tamaño máximo por archivo {individualMaxFileSize} MB</div>
                {globalMaxFileSize && (
                  <div>
                    Tamaño máximo total {globalMaxFileSize} MB (
                    {bytesToMb(this.getCurrentFilesSize())} MB actuales)
                  </div>
                )}
              </FormText>
            </div>
          )}
          {showSave && (
            <div className="file-save" style={{ width: '100%' }}>
              <SafeBtn
                color="orsan-secondary"
                style={{ fontSize: '14px' }}
                onClick={handleSave}
                loading={loading}
                disabled={loading || (!pendingDeletion && newFiles.length === 0)}
              >
                Guardar
              </SafeBtn>
            </div>
          )}
        </div>
        {/* eslint-enable */}
      </>
    );
  }
}

NewFileTable.propTypes = {
  readOnly: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleAdd: PropTypes.func,
  handleDeleteNew: PropTypes.func,
  fileList: PropTypes.arrayOf(PropTypes.object),
  newFiles: PropTypes.arrayOf(
    PropTypes.shape({
      original_name: PropTypes.string,
      uuid: PropTypes.string,
    }),
  ).isRequired,
  loginIdToken: PropTypes.string.isRequired,
  uploadUrl: PropTypes.string,
  downloadUrl: PropTypes.string,
  showSave: PropTypes.bool,
  handleSave: PropTypes.func,
  loading: PropTypes.bool,
  individualMaxFileSize: PropTypes.number,
  globalMaxFileSize: PropTypes.number,
  tempDownloadUrl: PropTypes.string,
  tempDownload: PropTypes.bool,
  prefijo: PropTypes.string,
};

NewFileTable.defaultProps = {
  readOnly: false,
  handleDelete: () => {},
  handleAdd: () => {},
  handleDeleteNew: () => {},
  fileList: [],
  uploadUrl: '',
  downloadUrl: '',
  tempDownloadUrl: '',
  tempDownload: false,
  showSave: false,
  handleSave: () => {},
  loading: false,
  individualMaxFileSize: 100, // MBs
  globalMaxFileSize: null, // MBs
  prefijo: '',
};

export default NewFileTable;
