import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import FormSection from '../../../UI/FormSection';
import {
  TIPO_GESTIONES_PROPIAS_EMISION_ID,
  TIPO_GESTIONES_PROPIAS_VENCIMIENTO_ID,
} from '../../../../CONST';
import { objectsToOptions } from '../../../../helpers/selects';

class PeriodoDeGestionesPropiasSection extends Component {
  handleOptionChange = event => {
    const { dispatch, handleChange } = this.props;
    const tipoId = parseInt(event.target.value, 10);
    dispatch(handleChange('endoso.tipoGestionesPropiasId', tipoId));
  };

  render() {
    const {
      tipoGestionesPropiasId,
      plazoGestionesPropiasDiasId,
      plazosGestionesPropiasDias,
      dispatch,
      handleSelect,
      style,
      validate,
      disabled,
    } = this.props;
    const plazosOptions = objectsToOptions(
      plazosGestionesPropiasDias,
      false,
      false,
      'dias',
    );
    const plazoSelectedOption = plazosOptions.find(
      plazo => plazo.value.id === plazoGestionesPropiasDiasId,
    );

    const hasError = validate && !plazoSelectedOption;
    const fromEmision =
      tipoGestionesPropiasId === TIPO_GESTIONES_PROPIAS_EMISION_ID;

    return (
      <FormSection title="Período de gestiones propias" style={style}>
        <FormGroup check className="radio-with-select-in-the-middle">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '125px 80px auto',
              gridGap: '6px 8px',
              alignItems: 'center',
            }}
          >
            <div>
              <Label check>
                <Input
                  type="radio"
                  value={TIPO_GESTIONES_PROPIAS_EMISION_ID}
                  checked={
                    tipoGestionesPropiasId === TIPO_GESTIONES_PROPIAS_EMISION_ID
                  }
                  onChange={this.handleOptionChange}
                  disabled={disabled}
                />
                Desde la emisión
              </Label>
            </div>
            <div className={fromEmision && hasError ? 'has-error' : ''}>
              <Select
                id="plazo-gestiones-propias-dias"
                name="endoso.plazoGestionesPropiasDiasId"
                className="custom-policy-select"
                options={plazosOptions}
                onChange={(selectedOption, action) =>
                  dispatch(handleSelect(selectedOption.value.id, action))
                }
                value={
                  tipoGestionesPropiasId === TIPO_GESTIONES_PROPIAS_EMISION_ID
                    ? plazoSelectedOption
                    : null
                }
                placeholder=""
                isDisabled={
                  tipoGestionesPropiasId !==
                    TIPO_GESTIONES_PROPIAS_EMISION_ID || disabled
                }
              />
            </div>
            <div className="label">días</div>
            <div>
              <Label check>
                <Input
                  type="radio"
                  value={TIPO_GESTIONES_PROPIAS_VENCIMIENTO_ID}
                  checked={
                    tipoGestionesPropiasId ===
                    TIPO_GESTIONES_PROPIAS_VENCIMIENTO_ID
                  }
                  onChange={this.handleOptionChange}
                  disabled={disabled}
                />
                Desde el vencimiento
              </Label>
            </div>
            <div className={!fromEmision && hasError ? 'has-error' : ''}>
              <Select
                id="plazo-gestiones-propias-dias"
                name="endoso.plazoGestionesPropiasDiasId"
                className="custom-policy-select"
                options={plazosOptions}
                onChange={(selectedOption, action) =>
                  dispatch(handleSelect(selectedOption.value.id, action))
                }
                value={
                  tipoGestionesPropiasId ===
                  TIPO_GESTIONES_PROPIAS_VENCIMIENTO_ID
                    ? plazoSelectedOption
                    : null
                }
                placeholder=""
                isDisabled={
                  tipoGestionesPropiasId !==
                    TIPO_GESTIONES_PROPIAS_VENCIMIENTO_ID || disabled
                }
              />
            </div>
            <div className="label">días</div>
          </div>
        </FormGroup>
      </FormSection>
    );
  }
}

PeriodoDeGestionesPropiasSection.propTypes = {
  tipoGestionesPropiasId: PropTypes.number.isRequired,
  plazoGestionesPropiasDiasId: PropTypes.number,
  plazosGestionesPropiasDias: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      dias: PropTypes.number,
    }),
  ).isRequired,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

PeriodoDeGestionesPropiasSection.defaultProps = {
  style: {},
  plazoGestionesPropiasDiasId: null,
  disabled: false,
};

export default PeriodoDeGestionesPropiasSection;
