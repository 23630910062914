import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import DownloadBtn from './shared/DownloadBtn';
import Breadcrumbs from './Breadcrumbs';
import TimeDate from './TimeDate';

const PageContainer = ({ children, mainAction, breadcrumbs, downloadXlsxUrl, downloadProdVVerdeUrl}) => (
  <div>
    <TimeDate />
    <div style={{display: 'grid', gridTemplateColumns: '5fr auto auto auto'}}>
      <Breadcrumbs arrayMap={breadcrumbs} />
      <div style={{ paddingTop: '12px', gridColumnStart: 2, marginRight: '5px' }}>
        { downloadProdVVerdeUrl && (
          <DownloadBtn
            width="200px"
            className="btn btn-orsan"
            id="xlsx-download-vverde"
            text="Libro Prod. Operaciones"
            tipText="Descargar Producción VentaVerde"
            url={downloadProdVVerdeUrl}
            icon={faFileContract}
          />
        )}
      </div>
      <div style={{ paddingTop: '12px', gridColumnStart: 3, marginRight: '5px' }}>
        { downloadXlsxUrl && (
          <DownloadBtn
            className="btn btn-orsan"
            id="xlsx-download"
            text="Descargar Proyectos"
            tipText="Descargar Planilla Excel"
            url={downloadXlsxUrl}
          />
        )}
      </div>
      <div style={{paddingTop: '12px', gridColumnStart: 4}}>
        {mainAction && (
          <Link
            className="btn btn-orsan"
            disabled={false}
            to={mainAction.url}
            style={{maxWidth: '300px', float: 'right',  fontSize: '14px'}}
          >
            {mainAction.caption}
          </Link>
        )}
      </div>
    </div>
    {children}
    <div className="footer text-left">
      {`© ${moment().year()} ORSAN. Encomenderos 113, Of. 801 Las Condes.`}
    </div>
  </div>
);

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  mainAction: PropTypes.shape({
    url: PropTypes.string,
    caption: PropTypes.string,
  }),
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ).isRequired,
  downloadXlsxUrl: PropTypes.string,
  downloadProdVVerdeUrl: PropTypes.string,
};

PageContainer.defaultProps = {
  mainAction: null,
  downloadXlsxUrl: '',
  downloadProdVVerdeUrl: '',
};

export default PageContainer;
