import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import FormSection from '../../../UI/FormSection';
import {
  handleBalanceCalculatedFieldsChange,
  handleTasaCostoSeguroAdd,
  handleTasaCostoSeguroDelete,
} from '../../../../redux-store/actions/creditPolicyActions';
import BaseTable from '../../../UI/BaseTable';
import { cellOperation, headerOperation } from '../../../../helpers/tables';

class TasaCostoDeSeguroSection extends Component {
  state = {
    errorMsg: '',
  };

  handleAddTasa = () => {
    const {
      endoso: {
        tasasCostoSeguro,
        currentTasaCostoSeguro: { desdeDias, hastaDias },
      },
      plazoMaximo,
      dispatch,
    } = this.props;

    if (desdeDias === 0) {
      this.setState({
        errorMsg: 'El inicio del período no puede ser cero',
      });
    } else if (desdeDias > hastaDias) {
      this.setState({
        errorMsg:
          'El inicio del período no puede ser mayor que el fin del período',
      });
    } else if (hastaDias > plazoMaximo) {
      this.setState({
        errorMsg:
          'El fin del período no puede ser mayor al plazo máximo de crédito',
      });
    } else if (
      tasasCostoSeguro.some(
        tcs => desdeDias <= tcs.hastaDias && hastaDias >= tcs.desdeDias,
      )
    ) {
      this.setState({
        errorMsg: 'Los períodos no se pueden superponer',
      });
    } else {
      this.setState({
        errorMsg: '',
      });
      dispatch(handleTasaCostoSeguroAdd());
    }
  };

  handleInputKeyDown = target => {
    if (target.keyCode === 13) {
      this.handleAddTasa();
    }
  };

  render() {
    const {
      endoso,
      moneda,
      dispatch,
      style,
      validate,
      handleChange,
      disabled,
    } = this.props;
    const { errorMsg } = this.state;
    const {
      tasaCostoSeguroDefectoPct,
      currentTasaCostoSeguro,
      tasasCostoSeguro,
    } = endoso;
    const columns = [
      {
        id: 'desdeDias',
        Header: headerOperation('Desde'),
        accessor: data => cellOperation(`${data.desdeDias} días`),
      },
      {
        id: 'hastaDias',
        Header: headerOperation('Hasta'),
        accessor: data => cellOperation(`${data.hastaDias} días`),
      },
      {
        id: 'tasaCostoSeguroPct',
        Header: headerOperation('%'),
        accessor: data => cellOperation(data.tasaCostoSeguroPct),
      },
      !disabled && {
        id: 'actions',
        Header: headerOperation('Acción'),
        Cell: row =>
          cellOperation(
            <button
              type="button"
              className="table-link policy"
              onClick={() => dispatch(handleTasaCostoSeguroDelete(row.index))}
              title="eliminar"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>,
          ),
      },
    ];
    return (
      <FormSection title="Tasa costo de seguro" style={style}>
        <div className="inline-text-with-input">
          <NumberFormat
            id="tasa-costo-seguro-defecto"
            name="tasaCostoSeguroDefectoPct"
            className={`form-control${
              validate && !tasaCostoSeguroDefectoPct ? ' is-invalid' : ''
            }`}
            onValueChange={values => {
              const newValue = values.floatValue;
              if (
                newValue !== undefined &&
                newValue !== tasaCostoSeguroDefectoPct
              ) {
                dispatch(
                  handleBalanceCalculatedFieldsChange(
                    'tasa_costo_seguro_defecto_pct',
                    'tasaCostoSeguroDefectoPct',
                    newValue,
                    endoso,
                    moneda,
                  ),
                );
              } else {
                dispatch(
                  handleChange(`endoso.tasaCostoSeguroDefectoPct`, newValue),
                );
              }
            }}
            decimalSeparator=","
            decimalScale="3"
            fixedDecimalScale
            allowNegative={false}
            isAllowed={values => !values.value || values.floatValue <= 100}
            value={tasaCostoSeguroDefectoPct}
            suffix="%"
            style={{ width: '70px' }}
            disabled={disabled}
          />
          <span className="small-form-value">
            &nbsp;&nbsp; Para todo documento salvo para casos especificados.
          </span>
        </div>
        {!disabled && (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gridGap: '5px',
              marginTop: '15px',
            }}
          >
            <NumberFormat
              id="tasa-costo-seguro-desde"
              name="desdeDias"
              className="form-control"
              onValueChange={values => {
                const newValue = values.floatValue;
                if (newValue !== undefined) {
                  dispatch(
                    handleChange(
                      'endoso.currentTasaCostoSeguro.desdeDias',
                      newValue,
                    ),
                  );
                }
              }}
              allowNegative={false}
              decimalSeparator={false}
              value={currentTasaCostoSeguro.desdeDias}
              suffix=" días"
              style={{ width: '100%' }}
              onKeyDown={this.handleInputKeyDown}
            />
            <NumberFormat
              id="tasa-costo-seguro-hasta"
              name="hastaDias"
              className="form-control"
              onValueChange={values => {
                const newValue = values.floatValue;
                if (newValue !== undefined) {
                  dispatch(
                    handleChange(
                      'endoso.currentTasaCostoSeguro.hastaDias',
                      newValue,
                    ),
                  );
                }
              }}
              allowNegative={false}
              decimalSeparator={false}
              value={currentTasaCostoSeguro.hastaDias}
              suffix=" días"
              style={{ width: '100%' }}
              onKeyDown={this.handleInputKeyDown}
            />
            <NumberFormat
              id="tasa-costo-seguro-tasa"
              name="tasaCostoSeguroPct"
              className="form-control"
              onValueChange={values => {
                const newValue = values.floatValue;
                if (newValue !== undefined) {
                  dispatch(
                    handleChange(
                      'endoso.currentTasaCostoSeguro.tasaCostoSeguroPct',
                      newValue,
                    ),
                  );
                }
              }}
              decimalSeparator=","
              decimalScale="3"
              fixedDecimalScale
              allowNegative={false}
              isAllowed={values => !values.value || values.floatValue <= 100}
              value={currentTasaCostoSeguro.tasaCostoSeguroPct}
              suffix="%"
              style={{ width: '100%' }}
              onKeyDown={this.handleInputKeyDown}
            />
            <Button
              color="orsan-primary"
              className="btn-full-width"
              style={{ fontSize: '14px' }}
              onClick={() => this.handleAddTasa()}
            >
              Agregar
            </Button>
          </div>
        )}
        <div className="error-msg-small" style={{ marginTop: '5px' }}>
          {errorMsg}
        </div>
        {tasasCostoSeguro.length > 0 && (
          <div style={{ marginTop: '10px' }}>
            <BaseTable
              columns={columns}
              data={tasasCostoSeguro}
              sortable={false}
              resizable={false}
              noBorders
            />
          </div>
        )}
      </FormSection>
    );
  }
}

TasaCostoDeSeguroSection.propTypes = {
  endoso: PropTypes.shape({
    estimacionVentasAmt: PropTypes.number,
    ventasPct: PropTypes.number,
    tasaCostoSeguroDefectoPct: PropTypes.number,
    tipoIndemnizacionMaxId: PropTypes.number,
    indemnizacionMaxAmt: PropTypes.number,
    tasasCostoSeguro: PropTypes.array,
    currentTasaCostoSeguro: PropTypes.shape({
      desdeDias: PropTypes.number,
      hastaDias: PropTypes.number,
      tasaCostoSeguroPct: PropTypes.number,
    }),
  }).isRequired,
  moneda: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  plazoMaximo: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

TasaCostoDeSeguroSection.defaultProps = {
  style: {},
  disabled: false,
};

export default TasaCostoDeSeguroSection;
