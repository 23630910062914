import moment from 'moment';
import _ from 'lodash';
import {
  startType,
  errorType,
  CP_DELETE,
  CP_GET_LIST,
  CP_TOGGLE_DELETE,
  CP_OPEN_DELETE,
  CP_GET,
  CP_HANDLE_FORM_ELEMENT_CHANGE,
  CP_HANDLE_RAMO_FECU_SELECT_CHANGE,
  CP_HANDLE_GENERAL_CONDITIONED_SELECT_CHANGE,
  CP_CREATE,
  CP_EDIT,
  CP_CLEAR_FORM,
  CP_HANDLE_INICIO_VIGENCIA_CHANGE,
  CP_HANDLE_BALANCE_CALCULATED_FIELDS_CHANGE,
  CP_HANDLE_TASA_COSTO_SEGURO_ADD,
  CP_HANDLE_TASA_COSTO_SEGURO_DELETE,
  CP_GET_PLAZOS_CREDITO,
  CP_SELECT_PARTICULAR_CONDITION,
  CP_DESELECT_PARTICULAR_CONDITION,
  CP_GET_PARTICULAR_CONDITIONS,
  CP_SET_NO_PARTICULAR_CONDITION_SELECTED,
  CP_GET_PORCENTAJES_COBERTURA_CREDITO,
  CP_GET_TIPOS_VIGENCIA,
  CP_HANDLE_TIPO_VIGENCIA_CHANGE,
  CP_GET_GASTOS_ADQUISICION,
  CP_HANDLE_CLIENTE_ESPECIAL_ADD,
  CP_TOGGLE_CLIENTE_ESPECIAL_DELETE_MODAL,
  CP_DELETE_CLIENTE_ESPECIAL,
  CP_OPEN_CLIENTE_ESPECIAL_DELETE,
  CP_EDIT_CLIENTE_ESPECIAL,
  CP_ADD_CLIENTE_ENDOSO,
  CP_DELETE_CLIENTE_ENDOSO,
  CP_TOGGLE_NO_PARAMETERIZED_CONDITION_SELECTED,
  CP_GET_PARAMETERIZED_CONDITIONS,
  CP_HANDLE_CANCEL_CLIENTE_ESPECIAL_EDITION,
  CP_HANDLE_CLIENTE_ESPECIAL_SAVE_EDITION,
  CP_EMIT,
  CP_DELETE_ADJUNTO,
  CP_ADD_ADJUNTO,
  CP_DELETE_ADJUNTO_NUEVO,
  CP_GET_POLICY,
  CP_SAVE_POLICY_ATTACHMENTS,
  CP_CHANGE_PLAN_PAGO_DOCUMENT,
  CP_TOGGLE_PARAMETERIZED_CONDITION_CHECKBOX,
  CP_CHANGE_PARAMETERIZED_CONDITION_VALUE,
  CP_HANDLE_PARAMETERIZED_CONDITIONED_OPTION_CHANGE,
  CP_CHANGE_TIPO_ENDOSO,
  CP_HANDLE_TERMINO_VIGENCIA_CHANGE,
  CP_EDIT_AND_CONTINUE,
  CP_SET_LAST_STEP,
  CP_SET_SECTION_VALIDATION,
  CP_TOGGLE_CLIENTE_ENDOSO_CHECK,
  CP_HANDLE_FILIAL_ACTIVIDAD_CHANGE,
  CP_ADD_ADJUNTO_FORM,
  CP_DELETE_ADJUNTO_FORM,
  CP_DELETE_ADJUNTO_NUEVO_FORM,
  CP_OPEN_DELETE_ENDOSO,
  CP_DELETE_ENDOSO,
  CP_TOGGLE_DELETE_ENDOSO,
  CP_ADD_PLAN_PAGO_CUOTA,
  CP_DELETE_PLAN_PAGO_CUOTA,
  CP_EDIT_PLAN_PAGO_CUOTA,
  CP_REMOVE_SOBREPRIMA,
  CP_ADD_SOBREPRIMA,
  CP_EDIT_SOBREPRIMA,
  CP_SELECT_SOBREPRIMA,
  CP_TOGGLE_VB,
  CP_HANDLE_INICIO_VIGENCIA_ENDOSO_CHANGE,
  CP_DETAILS_CHANGE_VIGENCIA,
  CP_RESET_CURRENT_CLIENTE_ESPECIAL,
  CLEAN_ERROR,
} from '../constants/action-types';
import {
  clientToOption,
  objectToOption,
  objectToOptionForMultiSelect,
} from '../../helpers/selects';
import { getSuggestedInicioVigencia } from '../../helpers/helpers';
import {
  CP_FS_BASICOS,
  CP_FS_CLAUSULAS,
  CP_FS_CLIENTES,
  CP_FS_CONDICIONES,
  CP_FS_DETALLES,
  CONSTRUCTION_LABEL,
  DRAFT_LABEL,
  ROL_BENEFICIARIO_CREDITO,
  ROL_FILIAL_CREDITO,
  TIPO_ENDOSO_EMISION_ID,
  EMITTED_LABEL,
  TIPO_ENDOSO_RENOVACION_ID,
} from '../../CONST';

const initialTasaCostoSeguro = {
  desdeDias: 0,
  hastaDias: 0,
  tasaCostoSeguroPct: 0,
};

const initialClienteEspecial = {
  tasaCostoSeguroPctSobrescribir: false,
  tasaCostoSeguroPct: 0,
  indemnizacionMaxSobrescribir: false,
  tipoIndemnizacionMaxId: 1,
  indemnizacionMaxAmt: 0,
  plazoGestionesPropiasDiasSobrescribir: false,
  plazoGestionesPropiasDiasId: null,
  plazoGestionesPropiasDiasValue: 0,
  configuracionSiniestroDiasSobrescribir: false,
  configuracionSiniestroDias: 0,
  plazoMaximoCreditoDiasSobrescribir: false,
  plazoMaximoCreditoDiasId: null,
  plazoMaximoCreditoDiasValue: 0,
  coberturaPctSobrescribir: false,
  coberturaPctId: null,
  coberturaPctValue: null,
  umbralCoberturaAmtSobrescribir: false,
  umbralCoberturaAmt: 0,
  costoProrrogaPctSobrescribir: false,
  costoProrrogaPct: 0,
  deducibleAmtSobrescribir: false,
  deducibleAmt: 0,
  cliente: null,
  searchText: '',
};

const suggestedInicioVigencia = getSuggestedInicioVigencia();

const initialForm = {
  currentPolicyId: null,
  estado: CONSTRUCTION_LABEL,
  emitted: false,
  plazosGestionesPropiasDias: [],
  condicionesParticularesList: [],
  porcentajesCoberturaCredito: [],
  tiposVigencia: [],
  condicionesParametrizadasList: [],
  base: null,
  balance: null,
  ultimoEndosoPoliza: null,
  isLastStep: false,
  sections: {
    // hasChanged will not mutate after changing to true
    // it will serve to activate vb the first time a user changes a form section element
    [CP_FS_BASICOS]: { valid: false, vb: false, hasChanged: false },
    [CP_FS_DETALLES]: { valid: false, vb: false, hasChanged: false },
    [CP_FS_CLIENTES]: { valid: false, vb: false, hasChanged: false },
    [CP_FS_CONDICIONES]: { valid: false, vb: false, hasChanged: false },
    [CP_FS_CLAUSULAS]: { valid: false, vb: false, hasChanged: false },
  },
  data: {
    ramoFecu: null,
    moneda: null,
    condicionadoGeneral: null,
    condicionadoGeneralSearch: '',
    producto: null,
    tipologiaEjecucion: null,
    intermediarioSearch: '',
    asegurado: null,
    aseguradoSearch: '',
    adjuntos: [],
    adjuntos_uuids: [],
    endoso: {
      tipoCobertura: null,
      tipoIntermediario: null,
      intermediario: null,
      aseguradoAlias: null,
      actividadAsegurada: null,
      aseguradoActividad: null,
      emitted: false,
      fechaEmision: null,
      inicioVigencia: suggestedInicioVigencia,
      inicioVigenciaEndoso: suggestedInicioVigencia,
      tipoVigenciaId: 1,
      tipoVigenciaYears: 1,
      terminoVigencia: getSuggestedInicioVigencia()
        .add(1, 'years')
        .subtract(1, 'days'),
      estimacionVentasAmt: 0,
      ventasPct: 0,
      comisionPct: 0,
      montoAseguradoEstimadoAmt: 0,
      primaMinimaAnualAmt: 0,
      deltaPrimaMinimaAnualAmt: 0,
      prorrogaDias: 0,
      primaMinimaMensualAmt: 0,
      gastosAdquisicion: 0,
      materiaAsegurada: '',
      tipoIndemnizacionMaxId: 1,
      indemnizacionMaxAmt: 0,
      tipoCostoAvisoImpagoId: 1,
      costoAvisoImpago: 0,
      planPago: {
        id: null,
        tipoId: 3,
        cuotas: 0,
        planPagosDocument: null,
        planPagosDocumentUuid: null,
        selectedPlanPagosDocumentName: null,
        cuotasPlan: [],
      },
      tipoProvisionId: 1,
      tasaCostoSeguroDefectoPct: 0,
      currentTasaCostoSeguro: {
        ...initialTasaCostoSeguro,
      },
      tasasCostoSeguro: [],
      configuracionSiniestroDias: 0,
      tipoGestionesPropiasId: 1,
      plazoGestionesPropiasDiasId: null,
      condicionesParticulares: [],
      noCondicionesParticulares: false,
      plazoMaximoCreditoDiasId: null,
      clientesInnominados: false,
      coberturaNominadosPctId: null,
      coberturaInnominadosPctId: null,
      maximoInnominadosAmt: 0,
      indemnizacionMaxInnominado: 0,
      umbralCoberturaAmt: 0,
      costoProrrogaNominadoPct: 0,
      costoProrrogaInnominadoPct: 0,
      deducibleNominadoAmt: 0,
      deducibleInnominadoAmt: 0,
      costoEstudioNominadoAprobadoAmt: 0,
      costoEstudioNominadoRechazadoAmt: 0,
      costoEstudioInnominadoAprobadoAmt: 0,
      costoEstudioInnominadoRechazadoAmt: 0,
      clientesEspeciales: [],
      currentClienteEspecial: {
        ...initialClienteEspecial,
      },
      editingClienteEspecial: false,
      clienteEspecialToDeleteIndex: null,
      clienteEspecialDeleteModal: {
        isOpen: false,
        loading: false,
      },
      condicionesParametrizadas: [],
      noCondicionesParametrizadas: false,
      clientesEndoso: [],
      documentoId: null,
      tipoEndosoId: TIPO_ENDOSO_EMISION_ID,
      cambios: {},
    },
  },
};

const initialState = {
  list: {
    creditPolicies: {
      data: [],
      pages: 0,
      count: 0,
      next: '',
      previous: '',
    },
    selectedPolicy: null,
    confirmModal: {
      isOpen: false,
      loading: false,
    },
  },
  form: {
    ...initialForm,
  },
  selectedEndoso: null,
  confirmModalEndoso: {
    isOpen: false,
    loading: false,
  },
  detail: {
    selectedVigencia: null,
  },
  policy: null,
  loading: false,
  loaded: false,
  error: null,
};

const initLoading = (state) => ({
  ...state,
  loading: true,
  policy: null,
});

const initRefreshing = (state) => ({
  ...state,
  loading: true,
});

const error = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.body,
});

const getList = (state, action) => ({
  ...state,
  loading: false,
  loaded: true,
  list: {
    ...state.list,
    creditPolicies: {
      ...state.list.creditPolicies,
      data: action.payload.results,
      pages: Math.ceil(action.payload.count / 10),
      count: action.payload.count,
      next: action.payload.next,
      previous: action.payload.previous,
    },
  },
});

const openDelete = (state, action) => ({
  ...state,
  list: {
    ...state.list,
    selectedPolicy: action.payload,
    confirmModal: {
      ...state.list.confirmModal,
      isOpen: true,
      loading: false,
    },
  },
});

const openDeleteEndoso = (state, action) => ({
  ...state,
  selectedEndoso: action.payload,
  confirmModalEndoso: {
    ...state.confirmModalEndoso,
    isOpen: true,
    loading: false,
  },
});

const initConfirmModalLoading = (state) => ({
  ...state,
  list: {
    ...state.list,
    confirmModal: {
      ...state.list.confirmModal,
      loading: true,
    },
  },
});

const deletePolicy = (state, action) => ({
  ...state,
  list: {
    ...state.list,
    creditPolicies: {
      ...state.list.creditPolicies,
      data: state.list.creditPolicies.data.filter((policy) => policy.id !== action.payload.id),
    },
    confirmModal: {
      ...state.list.confirmModal,
      loading: false,
      isOpen: false,
    },
    selectedPolicy: null,
  },
});

const deleteEndoso = (state, action) => ({
  ...state,
  policy: {
    ...state.policy,
    endosos: state.policy.endosos.filter((endoso) => endoso.id !== action.payload.id),
    borrador:
      !state.policy.borrador || action.payload.id === state.policy.borrador.id
        ? null
        : { ...state.policy.borrador },
  },
  confirmModalEndoso: {
    ...state.confirmModalEndoso,
    loading: false,
    isOpen: false,
  },
  selectedEndoso: null,
});

const toggleDelete = (state) => ({
  ...state,
  list: {
    ...state.list,
    selectedPolicy: state.list.confirmModal.isOpen ? null : state.list.selectedPolicy,
    confirmModal: {
      ...state.list.confirmModal,
      isOpen: !state.list.confirmModal.isOpen,
    },
  },
});

const toggleDeleteEndoso = (state) => ({
  ...state,
  selectedEndoso: state.confirmModalEndoso.isOpen ? null : state.selectedEndoso,
  confirmModalEndoso: {
    ...state.confirmModalEndoso,
    isOpen: !state.confirmModalEndoso.isOpen,
  },
});

const refreshCondicionesParametrizadasList = (list, current) =>
  list.map((cond) => {
    const isSelectedCond = current.some(
      (selectedCond) => selectedCond.condicionparametrizada === cond.id,
    );
    const mappedCond = {
      ...cond,
      selected: isSelectedCond,
      opciones: cond.opciones.map((option) => {
        const selectedOption = current.find(
          (selectedCond) => selectedCond.opcion_seleccionada === option.id,
        );
        return {
          ...option,
          selected: !!selectedOption,
          values: selectedOption ? selectedOption.values : {},
        };
      }),
    };
    if (!mappedCond.selected) {
      mappedCond.opciones[0].selected = true;
    }
    return mappedCond;
  });

const internalGetPolicy = (state, action) => {
  const {
    form: {
      sections,
      data: {
        endoso: { tipoEndosoId },
      },
    },
  } = state;

  const policy = action.payload;
  policy.adjuntos_uuids = [];
  const {
    base,
    borrador,
    balance,
    vigencia_seleccionada: vigenciaSeleccionada,
    ultimo_endoso_vigencia: ultimoEndosoVigencia,
    ultimo_endoso_poliza: ultimoEndosoPoliza,
  } = policy;

  let balanceTemplate;
  if (tipoEndosoId) {
    balanceTemplate =
      tipoEndosoId === TIPO_ENDOSO_RENOVACION_ID ? ultimoEndosoPoliza : ultimoEndosoVigencia;
  } else {
    // utlizado para vista de CreditPolicyBalanceForm
    balanceTemplate = vigenciaSeleccionada || balance;
  }

  const endoso = borrador || balanceTemplate;
  const newState = {
    ...state,
    form: {
      ...state.form,
      emitted: policy.estado === EMITTED_LABEL,
      currentPolicyId: policy.id,
      estado: policy.estado,
      base,
      balance,
      ultimoEndosoVigencia,
      balanceTemplate,
      ultimoEndosoPoliza,
      cambioPrima: policy.cambio_prima,
      sections: {
        ...state.form.sections,
        [CP_FS_BASICOS]: {
          ...sections[CP_FS_BASICOS],
          vb: policy.vb_basicos,
          hasChanged: policy.vb_basicos,
        },
        [CP_FS_DETALLES]: {
          ...sections[CP_FS_DETALLES],
          vb: policy.vb_detalles,
          hasChanged: policy.vb_detalles,
        },
        [CP_FS_CLIENTES]: {
          ...sections[CP_FS_CLIENTES],
          vb: policy.vb_clientes,
          hasChanged: policy.vb_clientes,
        },
        [CP_FS_CONDICIONES]: {
          ...sections[CP_FS_CONDICIONES],
          vb: policy.vb_condiciones,
          hasChanged: policy.vb_condiciones,
        },
        [CP_FS_CLAUSULAS]: {
          ...sections[CP_FS_CLAUSULAS],
          vb: policy.vb_clausulas,
          hasChanged: policy.vb_clausulas,
        },
      },
      data: {
        ...state.form.data,
        moneda: objectToOption(base.moneda_asegurada),
        ramoFecu: objectToOption(base.poliza_maestra.ramo_asegurado),
        condicionadoGeneral: objectToOption(base.poliza_maestra, 'nemotecnico'),
        producto: objectToOption(base.producto),
        tipologiaEjecucion: objectToOption(base.tipologia_ejecucion),
        asegurado: clientToOption(base.asegurado),
        adjuntos: policy.adjuntos || [],
        adjuntos_uuids: [],
        endoso: { ...state.form.data.endoso },
      },
    },
    loading: false,
    loaded: true,
    policy,
  };
  if (endoso) {
    newState.form.data.endoso = {
      ...newState.form.data.endoso,
      numeroVigencia: endoso.numero_vigencia,
      tipoCobertura: objectToOption(endoso.tipo_cobertura),
      tipoIntermediario: objectToOption(endoso.tipo_intermediario),
      intermediario: endoso.intermediario ? clientToOption(endoso.intermediario) : null,
      aseguradoAlias: endoso.asegurado_alias
        ? objectToOptionForMultiSelect(endoso.asegurado_alias, 'alias')
        : null,
      actividadAsegurada: objectToOption(endoso.actividad_economica),
      aseguradoActividad: endoso.actividad
        ? {
            label: `${endoso.actividad.codigo} - ${endoso.actividad.nombre}`,
            value: endoso.actividad.id,
          }
        : null,
      emitted: false,
      fechaEmision: endoso.emitido ? moment(endoso.fecha_emision) : null,
      inicioVigencia: endoso.inicio_vigencia ? moment(endoso.inicio_vigencia) : null,
      inicioVigenciaEndoso:
        borrador && borrador.inicio_vigencia_endoso
          ? moment(borrador.inicio_vigencia_endoso)
          : null,
      terminoVigencia: endoso.termino_vigencia ? moment(endoso.termino_vigencia) : null,
      tipoVigenciaId: endoso.tipo_vigencia,
      estimacionVentasAmt: endoso.estimacion_ventas_amt,
      ventasPct: endoso.ventas_pct,
      comisionPct: endoso.comision_pct,
      montoAseguradoEstimadoAmt: endoso.monto_asegurado_estimado_amt,
      prevPrimaMinimaAnualAmt: ultimoEndosoVigencia
        ? ultimoEndosoVigencia.prima_minima_anual_amt
        : 0,
      primaMinimaAnualAmt: endoso.prima_minima_anual_amt,
      deltaPrimaMinimaAnualAmt: borrador ? borrador.delta_prima_minima_anual_amt : 0,
      prorrogaDias:
        tipoEndosoId === TIPO_ENDOSO_RENOVACION_ID && !borrador ? 0 : endoso.prorroga_dias,
      prorrogaDiasInicial: endoso.prorroga_dias,
      primaMinimaMensualAmt: endoso.prima_minima_mensual_amt,
      materiaAsegurada: endoso.glosa,
      tipoIndemnizacionMaxId: endoso.tipo_indemnizacion_max,
      indemnizacionMaxAmt: endoso.indemnizacion_max_amt,
      tipoCostoAvisoImpagoId: endoso.tipo_costo_aviso_impago,
      costoAvisoImpago: endoso.costo_aviso_impago,
      planPago: {
        id: endoso.plan_pago.id,
        tipoId: endoso.plan_pago.tipo,
        cuotas: endoso.plan_pago.cuotas,
        cuotasPlan:
          tipoEndosoId === TIPO_ENDOSO_RENOVACION_ID && !borrador
            ? []
            : endoso.plan_pago.cuotas_plan.map((cuota) => ({
                ...cuota,
                fecha_pago: moment(cuota.fecha_pago),
              })),
      },
      tipoProvisionId: endoso.tipo_provision,
      tasaCostoSeguroDefectoPct: endoso.tasa_costo_seguro_defecto_pct,
      tasasCostoSeguro: endoso.tasa_costo_seguro.map((tasa) => ({
        id: tasa.id,
        desdeDias: tasa.desde_dias,
        hastaDias: tasa.hasta_dias,
        tasaCostoSeguroPct: tasa.tasa_costo_seguro_pct,
      })),
      configuracionSiniestroDias: endoso.configuracion_siniestro_dias,
      tipoGestionesPropiasId: endoso.tipo_gestiones_propias,
      plazoGestionesPropiasDiasId: endoso.plazo_gestiones_propias_dias,
      condicionesParticulares: endoso.condiciones_particulares.map((cond) => cond.id),
      noCondicionesParticulares: endoso.no_agregar_condiciones_particulares,
      plazoMaximoCreditoDiasId: endoso.plazo_maximo_credito_dias,
      clientesInnominados: endoso.clientes_innominados,
      coberturaNominadosPctId: endoso.cobertura_nominados_pct,
      coberturaInnominadosPctId: endoso.cobertura_innominados_pct,
      maximoInnominadosAmt: endoso.maximo_innominados_amt,
      indemnizacionMaxInnominado: endoso.indemnizacion_max_innominado,
      umbralCoberturaAmt: endoso.umbral_cobertura_amt,
      costoProrrogaNominadoPct: endoso.costo_prorroga_nominado_pct,
      costoProrrogaInnominadoPct: endoso.costo_prorroga_innominado_pct,
      deducibleNominadoAmt: endoso.deducible_nominado_amt,
      deducibleInnominadoAmt: endoso.deducible_innominado_amt,
      costoEstudioNominadoAprobadoAmt: endoso.costo_estudio_nominado_aprobado_amt,
      costoEstudioNominadoRechazadoAmt: endoso.costo_estudio_nominado_rechazado_amt,
      costoEstudioInnominadoAprobadoAmt: endoso.costo_estudio_innominado_aprobado_amt,
      costoEstudioInnominadoRechazadoAmt: endoso.costo_estudio_innominado_rechazado_amt,
      clientesEspeciales: endoso.clientes_especiales.map((cliente) => ({
        tasaCostoSeguroPctSobrescribir: cliente.tasa_costo_seguro_pct_sobrescribir,
        tasaCostoSeguroPct: cliente.tasa_costo_seguro_pct,
        indemnizacionMaxSobrescribir: cliente.indemnizacion_max_sobrescribir,
        tipoIndemnizacionMaxId: cliente.tipo_indemnizacion_max,
        indemnizacionMaxAmt: cliente.indemnizacion_max_amt,
        plazoGestionesPropiasDiasSobrescribir: cliente.plazo_gestiones_propias_dias_sobrescribir,
        plazoGestionesPropiasDiasId: cliente.plazo_gestiones_propias_dias,
        plazoGestionesPropiasDiasValue: cliente.plazo_gestiones_propias_dias_value,
        configuracionSiniestroDiasSobrescribir: cliente.configuracion_siniestro_dias_sobrescribir,
        configuracionSiniestroDias: cliente.configuracion_siniestro_dias,
        plazoMaximoCreditoDiasSobrescribir: cliente.plazo_maximo_credito_dias_sobrescribir,
        plazoMaximoCreditoDiasId: cliente.plazo_maximo_credito_dias,
        plazoMaximoCreditoDiasValue: cliente.plazo_maximo_credito_dias_value,
        coberturaPctSobrescribir: cliente.cobertura_pct_sobrescribir,
        coberturaPctId: cliente.cobertura_pct,
        coberturaPctValue: cliente.cobertura_pct_value,
        umbralCoberturaAmtSobrescribir: cliente.umbral_cobertura_amt_sobrescribir,
        umbralCoberturaAmt: cliente.umbral_cobertura_amt,
        costoProrrogaPctSobrescribir: cliente.costo_prorroga_pct_sobrescribir,
        costoProrrogaPct: cliente.costo_prorroga_pct,
        deducibleAmtSobrescribir: cliente.deducible_amt_sobrescribir,
        deducibleAmt: cliente.deducible_amt,
        cliente: cliente.cliente,
      })),
      condicionesParametrizadas: endoso.condiciones_parametrizadas,
      noCondicionesParametrizadas: endoso.no_agregar_condiciones_parametrizadas,
      clientesEndoso: endoso.clienteendoso_set.map((ce) => ({
        ...ce,
        disabled:
          !ce.cliente.roles.includes(ROL_FILIAL_CREDITO) ||
          ce.cliente.actividades.length === 0 ||
          !ce.cliente.roles.includes(ROL_BENEFICIARIO_CREDITO),
      })),
      documentoId: endoso.documento_id,
      documento: endoso.documento,
      cambios: borrador ? borrador.cambios : {},
    };
    newState.form.condicionesParametrizadasList = refreshCondicionesParametrizadasList(
      state.form.condicionesParametrizadasList,
      endoso.condiciones_parametrizadas,
    );
  } else {
    newState.form.data.endoso = {
      ...newState.form.data.endoso,
      empty: true,
    };
  }
  return newState;
};

const getPolicy = (state, action) => {
  const newState = internalGetPolicy(state, action);
  const {
    form: {
      data: { endoso },
    },
  } = newState;
  if (endoso) {
    newState.form.isLastStep = endoso.estado === DRAFT_LABEL;
  }
  return newState;
};

const getCurrentPolicy = (state, action) => {
  return { ...state, policy: { ...action.payload, adjuntos_uuids: [] } };
};

const handleSectionActivity = (state, section) => {
  return {
    ...state,
    form: {
      ...state.form,
      sections: {
        ...state.form.sections,
        [section]: {
          ...state.form.sections[section],
          hasChanged: true,
        },
      },
    },
  };
};

const handleFormElementChange = (state, action) => {
  const {
    payload: { section, name, value },
  } = action;
  const newState = _.cloneDeep(state);
  _.set(newState, `form.data.${name}`, value);

  return handleSectionActivity(newState, section);
};

const handleRamoFecuSelectChange = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      ramoFecu: action.payload.selectedOption,
      condicionadoGeneral: null,
      producto: null,
      tipologiaEjecucion: null,
      endoso: {
        ...state.form.data.endoso,
        tipoCobertura: null,
      },
    },
  },
});

const handleGeneralConditionedSelectChange = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      condicionadoGeneral: action.payload.selectedOption,
      producto: null,
      tipologiaEjecucion: null,
      endoso: {
        ...state.form.data.endoso,
        tipoCobertura: null,
      },
    },
  },
});

const editPolicy = (state, action) => ({
  ...internalGetPolicy(state, action),
  loading: false,
});

const editAndContinue = (state, action) => {
  const newState = internalGetPolicy(state, action);
  return {
    ...newState,
    form: {
      ...newState.form,
      isLastStep: true,
    },
    loading: false,
  };
};

const emitPolicy = (state, action) => {
  const newState = editPolicy(state, action);
  return {
    ...newState,
    form: {
      ...newState.form,
      data: {
        ...newState.form.data,
        endoso: {
          ...newState.form.data.endoso,
          emitted: true,
        },
      },
    },
  };
};

const clearForm = (state) => ({
  ...state,
  loading: false,
  loaded: false,
  error: null,
  form: {
    ...initialForm,
    data: {
      ...initialForm.data,
      endoso: {
        ...initialForm.data.endoso,
        planPago: {
          ...initialForm.data.endoso.planPago,
        },
        currentTasaCostoSeguro: {
          ...initialForm.data.endoso.currentTasaCostoSeguro,
        },
      },
    },
  },
});

const handleInicioVigenciaChange = (state, action) => {
  const newInicioVigencia = moment.isMoment(action.payload) ? action.payload : null;
  const yearsToAdd = state.form.data.endoso.tipoVigenciaYears;
  const newTerminoVigencia = newInicioVigencia
    ? newInicioVigencia
        .clone()
        .add(yearsToAdd, 'years')
        .subtract(1, 'days')
    : null;
  return {
    ...state,
    form: {
      ...state.form,
      data: {
        ...state.form.data,
        endoso: {
          ...state.form.data.endoso,
          inicioVigencia: newInicioVigencia,
          inicioVigenciaEndoso: newInicioVigencia,
          terminoVigencia: newTerminoVigencia,
        },
      },
    },
  };
};

const handleInicioVigenciaEndosoChange = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      endoso: {
        ...state.form.data.endoso,
        inicioVigenciaEndoso: moment.isMoment(action.payload) ? action.payload : null,
      },
    },
  },
});

const handleTipoVigenciaChange = (state, action) => {
  const { inicioVigencia } = state.form.data.endoso;
  return handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            tipoVigenciaId: action.payload.id,
            tipoVigenciaYears: action.payload.years,
            terminoVigencia: inicioVigencia
              .clone()
              .add(action.payload.years, 'years')
              .subtract(1, 'days'),
          },
        },
      },
    },
    CP_FS_DETALLES,
  );
};

const handleTerminoVigenciaChange = (state, action) => {
  const { inicioVigenciaEndoso, terminoVigencia, prorrogaDias } = action.payload;
  return handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            inicioVigenciaEndoso:
              inicioVigenciaEndoso || state.form.data.endoso.inicioVigenciaEndoso,
            terminoVigencia,
            prorrogaDias,
          },
        },
      },
    },
    CP_FS_DETALLES,
  );
};

const handleBalanceCalculatedFieldsChange = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      endoso: {
        ...state.form.data.endoso,
        montoAseguradoEstimadoAmt: action.payload.monto_asegurado_estimado_amt,
        primaMinimaAnualAmt: action.payload.prima_minima_anual_amt,
        primaMinimaMensualAmt: action.payload.prima_minima_mensual_amt,
        deltaPrimaMinimaAnualAmt:
          state.form.data.endoso.prevPrimaMinimaAnualAmt > 0 &&
          state.form.data.endoso.tipoEndosoId !== TIPO_ENDOSO_RENOVACION_ID
            ? action.payload.prima_minima_anual_amt - state.form.data.endoso.prevPrimaMinimaAnualAmt
            : 0,
      },
    },
  },
});

const handleTasaCostoSeguroAdd = (state) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            tasasCostoSeguro: [
              ...state.form.data.endoso.tasasCostoSeguro,
              {
                ...state.form.data.endoso.currentTasaCostoSeguro,
              },
            ],
            currentTasaCostoSeguro: {
              ...initialTasaCostoSeguro,
            },
          },
        },
      },
    },
    CP_FS_DETALLES,
  );

const handleTasaCostoSeguroDelete = (state, action) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            tasasCostoSeguro: state.form.data.endoso.tasasCostoSeguro.filter(
              (elem, index) => index !== action.payload,
            ),
          },
        },
      },
    },
    CP_FS_DETALLES,
  );

const getPlazosCredito = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    plazosGestionesPropiasDias: action.payload,
  },
});

const handleSelectParticularCondition = (state, action) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            condicionesParticulares: [
              ...state.form.data.endoso.condicionesParticulares,
              action.payload,
            ],
          },
        },
      },
    },
    CP_FS_CONDICIONES,
  );

const handleDeselectParticularCondition = (state, action) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            condicionesParticulares: state.form.data.endoso.condicionesParticulares.filter(
              (id) => id !== action.payload,
            ),
          },
        },
      },
    },
    CP_FS_CONDICIONES,
  );

const handleSetNoParticularCondition = (state, action) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            condicionesParticulares: action.payload
              ? []
              : state.form.data.endoso.condicionesParticulares,
            noCondicionesParticulares: action.payload,
          },
        },
      },
    },
    CP_FS_CONDICIONES,
  );

const handleToggleNoParameterizedCondition = (state) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        condicionesParametrizadasList: state.form.condicionesParametrizadasList.map((cond) =>
          !state.form.data.endoso.noCondicinoesParametrizadas
            ? {
                ...cond,
                selected: false,
                opciones: cond.opciones.map((opc) => ({ ...opc, values: {} })),
              }
            : cond,
        ),
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            noCondicionesParametrizadas: !state.form.data.endoso.noCondicionesParametrizadas,
          },
        },
      },
    },
    CP_FS_CLAUSULAS,
  );

const handleGetParticularConditions = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    condicionesParticularesList: action.payload,
  },
});

const getPorcentajesCoberturaCredito = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    porcentajesCoberturaCredito: action.payload,
  },
});

const getTiposVigencia = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    tiposVigencia: action.payload,
  },
});

const getGastosAdquisicion = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      endoso: {
        ...state.form.data.endoso,
        gastosAdquisicion: action.payload.valor,
      },
    },
  },
});

const handleGetParameterizedConditions = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    condicionesParametrizadasList: refreshCondicionesParametrizadasList(
      action.payload,
      state.form.data.endoso.condicionesParametrizadas,
    ),
  },
});

const handleAddClienteEndoso = (state, action) => {
  const cliente = {
    ...action.payload.cliente,
    actividades: action.payload.actividades,
  };
  const forceFilial = !cliente.roles.includes(ROL_BENEFICIARIO_CREDITO);
  const forceBeneficiario = !cliente.roles.includes(ROL_FILIAL_CREDITO) || !cliente.tiene_giro;
  const disabled = forceFilial || forceBeneficiario;
  const newState = {
    ...state,
    form: {
      ...state.form,
      data: {
        ...state.form.data,
        endoso: {
          ...state.form.data.endoso,
          clientesEndoso: [
            ...state.form.data.endoso.clientesEndoso,
            {
              cliente,
              is_filial: forceFilial,
              selectedActividad: null,
              actividad_id: null,
              facturacion: false,
              is_beneficiario: forceBeneficiario,
              disabled,
            },
          ],
        },
      },
    },
  };
  return handleSectionActivity(newState, CP_FS_CLIENTES);
};

const handleClienteEspecialAdd = (state) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            clientesEspeciales: [
              ...state.form.data.endoso.clientesEspeciales,
              {
                ...state.form.data.endoso.currentClienteEspecial,
              },
            ],
            currentClienteEspecial: {
              ...initialClienteEspecial,
            },
          },
        },
      },
    },
    CP_FS_CLIENTES,
  );

const handleClienteEspecialSaveEdition = (state) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            clientesEspeciales: state.form.data.endoso.clientesEspeciales.map((cliente) => {
              if (cliente.selectedRow) {
                const current = state.form.data.endoso.currentClienteEspecial;
                delete current.selectedRow;
                return current;
              }
              return cliente;
            }),
            currentClienteEspecial: {
              ...initialClienteEspecial,
            },
            editingClienteEspecial: false,
          },
        },
      },
    },
    CP_FS_CLIENTES,
  );

const openClienteEspecialDelete = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      endoso: {
        ...state.form.data.endoso,
        clienteEspecialToDeleteIndex: action.payload,
        clienteEspecialDeleteModal: {
          ...state.form.data.endoso.clienteEspecialDeleteModal,
          isOpen: true,
          loading: false,
        },
      },
    },
  },
});

const toggleClienteEspecialDeleteModal = (state) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      endoso: {
        ...state.form.data.endoso,
        clienteEspecialToDeleteIndex: state.form.data.endoso.clienteEspecialDeleteModal.isOpen
          ? null
          : state.form.data.endoso.clienteEspecialToDeleteIndex,
        clienteEspecialDeleteModal: {
          ...state.form.data.endoso.clienteEspecialDeleteModal,
          isOpen: !state.form.data.endoso.clienteEspecialDeleteModal.isOpen,
        },
      },
    },
  },
});

const deleteClienteEspecial = (state) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            clientesEspeciales: state.form.data.endoso.clientesEspeciales.filter(
              (elem, index) => index !== state.form.data.endoso.clienteEspecialToDeleteIndex,
            ),
            clienteEspecialDeleteModal: {
              ...state.form.data.endoso.clienteEspecialDeleteModal,
              loading: false,
              isOpen: false,
            },
            clienteEspecialToDeleteIndex: null,
          },
        },
      },
    },
    CP_FS_CLIENTES,
  );

const editClienteEspecial = (state, action) => {
  const ceToEdit = state.form.data.endoso.clientesEspeciales.find(
    (elem, index) => index === action.payload,
  );
  return handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            currentClienteEspecial: { ...ceToEdit },
            clientesEspeciales: state.form.data.endoso.clientesEspeciales.map((elem, index) => {
              if (index === action.payload) {
                return { ...elem, selectedRow: true };
              }
              return elem;
            }),
            editingClienteEspecial: true,
          },
        },
      },
    },
    CP_FS_CLIENTES,
  );
};

const handleCancelClienteEspecialEdition = (state) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      endoso: {
        ...state.form.data.endoso,
        clientesEspeciales: state.form.data.endoso.clientesEspeciales.map((cliente) => {
          if (cliente.selectedRow) {
            const clienteCopy = { ...cliente };
            delete clienteCopy.selectedRow;
            return clienteCopy;
          }
          return cliente;
        }),
        currentClienteEspecial: {
          ...initialClienteEspecial,
        },
        editingClienteEspecial: false,
      },
    },
  },
});

const handleDeleteClienteEndoso = (state, action) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            clientesEndoso: state.form.data.endoso.clientesEndoso.filter(
              (data) => data.cliente.id !== action.payload,
            ),
          },
        },
      },
    },
    CP_FS_CLIENTES,
  );

const toggleClienteEndosoCheck = (state, action) => {
  const newState = {
    ...state,
    form: {
      ...state.form,
      data: {
        ...state.form.data,
        endoso: {
          ...state.form.data.endoso,
          clientesEndoso: state.form.data.endoso.clientesEndoso.map((ce) =>
            ce.cliente.id === action.payload.clienteId
              ? {
                  ...ce,
                  [action.payload.fieldName]: !ce[action.payload.fieldName],
                }
              : ce,
          ),
        },
      },
    },
  };
  return handleSectionActivity(newState, CP_FS_CLIENTES);
};

const handleDeleteAdjunto = (state, action) => ({
  ...state,
  policy: {
    ...state.policy,
    adjuntos: state.policy.adjuntos.filter((adjunto) => adjunto.id !== action.payload),
  },
});

const handleDeleteAdjuntoNuevo = (state, action) => ({
  ...state,
  policy: {
    ...state.policy,
    adjuntos_uuids: state.policy.adjuntos_uuids.filter((id) => id !== action.payload),
  },
});

const handleAddAdjunto = (state, action) => ({
  ...state,
  policy: {
    ...state.policy,
    adjuntos_uuids: [...state.policy.adjuntos_uuids, action.payload],
  },
});

const handleSavePolicyAttachments = (state, action) => ({
  ...state,
  loading: false,
  policy: {
    ...action.payload,
    adjuntos_uuids: [],
  },
});

const changePlanPagoDocument = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      endoso: {
        ...state.form.data.endoso,
        planPago: {
          ...state.form.data.endoso.planPago,
          planPagosDocumentUuid: action.payload.uuid,
          selectedPlanPagosDocumentName: action.payload.filename,
        },
      },
    },
  },
});

const sortEnumerateCuotas = (cuotas) =>
  cuotas
    .sort((a, b) => a.fecha_pago - b.fecha_pago)
    .map((cuota, i) => ({ ...cuota, numero: i + 1 }));

const addPlanPagoCuota = (state, action) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            planPago: {
              ...state.form.data.endoso.planPago,
              cuotasPlan: sortEnumerateCuotas([
                ...state.form.data.endoso.planPago.cuotasPlan,
                action.payload,
              ]),
            },
          },
        },
      },
    },
    CP_FS_DETALLES,
  );

const deletePlanPagoCuota = (state, action) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            planPago: {
              ...state.form.data.endoso.planPago,
              cuotasPlan: sortEnumerateCuotas(
                state.form.data.endoso.planPago.cuotasPlan.filter(
                  (cuota) => cuota.numero !== action.payload.numero,
                ),
              ),
            },
          },
        },
      },
    },
    CP_FS_DETALLES,
  );

const editPlanPagoCuota = (state, action) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        data: {
          ...state.form.data,
          endoso: {
            ...state.form.data.endoso,
            planPago: {
              ...state.form.data.endoso.planPago,
              cuotasPlan: sortEnumerateCuotas(
                state.form.data.endoso.planPago.cuotasPlan.map((cuota) =>
                  cuota.numero !== action.payload.numero ? cuota : action.payload,
                ),
              ),
            },
          },
        },
      },
    },
    CP_FS_DETALLES,
  );

const toggleParameterizedConditionCheckbox = (state, action) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        condicionesParametrizadasList: state.form.condicionesParametrizadasList.map((cond) =>
          cond.id === action.payload
            ? {
                ...cond,
                selected: !cond.selected,
                opciones: cond.opciones.map((opc) => ({ ...opc, values: {} })),
              }
            : cond,
        ),
      },
    },
    CP_FS_CLAUSULAS,
  );

const changeParameterizedConditionValue = (state, action) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        condicionesParametrizadasList: state.form.condicionesParametrizadasList.map((cond) =>
          cond.id === action.payload.conditionId
            ? {
                ...cond,
                opciones: cond.opciones.map((opc) =>
                  opc.id === action.payload.optionId
                    ? {
                        ...opc,
                        values: {
                          ...opc.values,
                          [action.payload.name]: action.payload.value,
                        },
                      }
                    : opc,
                ),
              }
            : cond,
        ),
      },
    },
    CP_FS_CLAUSULAS,
  );

const handleParameterizedConditionedOptionChange = (state, action) =>
  handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        condicionesParametrizadasList: state.form.condicionesParametrizadasList.map((cond) =>
          cond.id === action.payload.conditionId
            ? {
                ...cond,
                opciones: cond.opciones.map((opc) =>
                  opc.id === action.payload.optionId
                    ? {
                        ...opc,
                        selected: true,
                        values: {},
                      }
                    : {
                        ...opc,
                        selected: false,
                        values: {},
                      },
                ),
              }
            : cond,
        ),
      },
    },
    CP_FS_CLAUSULAS,
  );

const handleChangeTipoEndoso = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      endoso: {
        ...state.form.data.endoso,
        tipoEndosoId: action.payload,
      },
    },
  },
});

const setLastStep = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    isLastStep: action.payload,
  },
});

const setStepValidation = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    sections: {
      ...state.form.sections,
      [action.payload.stepName]: {
        ...state.form.sections[action.payload.stepName],
        valid: action.payload.isValid,
      },
    },
  },
});

const handleFilialActividadChange = (state, action) => {
  const newState = {
    ...state,
    form: {
      ...state.form,
      data: {
        ...state.form.data,
        endoso: {
          ...state.form.data.endoso,
          clientesEndoso: state.form.data.endoso.clientesEndoso.map((ce) =>
            ce.cliente.id === action.payload.clienteId
              ? {
                  ...ce,
                  actividad_id: action.payload.selectedOption
                    ? action.payload.selectedOption.value.id
                    : null,
                }
              : ce,
          ),
        },
      },
    },
  };
  return handleSectionActivity(newState, CP_FS_CLIENTES);
};

const handleAddAdjuntoForm = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      adjuntos_uuids: [...state.form.data.adjuntos_uuids, action.payload],
    },
  },
});

const handleDeleteAdjuntoForm = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      adjuntos: state.form.data.adjuntos.filter((adjunto) => adjunto.id !== action.payload),
    },
  },
});

const handleDeleteAdjuntoNuevoForm = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      adjuntos_uuids: state.form.data.adjuntos_uuids.filter((id) => id !== action.payload),
    },
  },
});

const removeSobreprima = (state, action) => {
  const limiteInferior = state.form.condicionesParametrizadasList
    .find((cond) => cond.id === action.payload.conditionId)
    .opciones.find((opc) => opc.id === action.payload.optionId).values.sobreprimas[
    action.payload.index
  ].limite_inferior;
  return handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        condicionesParametrizadasList: state.form.condicionesParametrizadasList.map((cond) =>
          cond.id === action.payload.conditionId
            ? {
                ...cond,
                opciones: cond.opciones.map((opc) =>
                  opc.id === action.payload.optionId
                    ? {
                        ...opc,
                        values: {
                          ...opc.values,
                          sobreprimas: opc.values.sobreprimas
                            .map((elem, index) =>
                              index === action.payload.index + 1
                                ? {
                                    ...elem,
                                    limite_inferior: limiteInferior,
                                  }
                                : elem,
                            )
                            .filter((elem, index) => index !== action.payload.index),
                        },
                      }
                    : opc,
                ),
              }
            : cond,
        ),
      },
    },
    CP_FS_CLAUSULAS,
  );
};

const selectSobreprima = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    condicionesParametrizadasList: state.form.condicionesParametrizadasList.map((cond) =>
      cond.id === action.payload.conditionId
        ? {
            ...cond,
            opciones: cond.opciones.map((opc) =>
              opc.id === action.payload.optionId
                ? {
                    ...opc,
                    values: {
                      ...opc.values,
                      sobreprimas: opc.values.sobreprimas.map((elem, index) => ({
                        ...elem,
                        selectedRow: index === action.payload.index,
                      })),
                    },
                  }
                : opc,
            ),
          }
        : cond,
    ),
  },
});

const addSobreprima = (state, action) => {
  const opcion = state.form.condicionesParametrizadasList
    .find((cond) => cond.id === action.payload.conditionId)
    .opciones.find((opc) => opc.id === action.payload.optionId);
  const sobreprimas = opcion.values.sobreprimas || [];

  // Here, null represents Infinity

  const limiteSuperior = [undefined, null].includes(action.payload.limiteSuperior)
    ? null
    : action.payload.limiteSuperior;

  // Finding the index at which to insert this sobreprima
  let index;
  if (limiteSuperior === null) {
    index = sobreprimas.findIndex((sp) => sp.limite_superior === null);
  } else {
    index = sobreprimas.findIndex(
      (sp) => sp.limite_superior === null || sp.limite_superior >= limiteSuperior,
    );
  }
  let index2 = index;
  if (index === -1) {
    // we will insert at the end of the array
    index = sobreprimas.length;
    index2 = index;
  } else if (sobreprimas[index].limite_superior === limiteSuperior) {
    // we will replace the range at index because it previously had the same superior limit
    index2 += 1;
  }
  const limiteInferior =
    index > 0
      ? sobreprimas[index - 1].limite_superior + 0.1
      : opcion.values.porcentajeSiniestralidad;
  const sobreprimasBefore = sobreprimas.slice(0, index);
  const sobreprimasAfter = sobreprimas.slice(index2, sobreprimas.length);

  return handleSectionActivity(
    {
      ...state,
      form: {
        ...state.form,
        condicionesParametrizadasList: state.form.condicionesParametrizadasList.map((cond) =>
          cond.id === action.payload.conditionId
            ? {
                ...cond,
                opciones: cond.opciones.map((opc) =>
                  opc.id === action.payload.optionId
                    ? {
                        ...opc,
                        values: {
                          ...opc.values,
                          sobreprimas: [
                            ...sobreprimasBefore,
                            {
                              limite_inferior: limiteInferior,
                              limite_superior: limiteSuperior,
                              sobreprima: action.payload.sobreprima,
                            },
                            ...sobreprimasAfter.map((sp, idx) =>
                              idx === 0
                                ? {
                                    ...sp,
                                    limite_inferior: limiteSuperior + 0.1,
                                  }
                                : sp,
                            ),
                          ],
                        },
                      }
                    : opc,
                ),
              }
            : cond,
        ),
      },
    },
    CP_FS_CLAUSULAS,
  );
};

const toggleVB = (state, action) => ({
  ...state,
  form: {
    ...state.form,
    sections: {
      ...state.form.sections,
      [action.payload]: {
        ...state.form.sections[action.payload],
        vb: !state.form.sections[action.payload].vb,
      },
    },
  },
});

const detailsChangeVigencia = (state, action) => ({
  ...state,
  detail: {
    selectedVigencia: state.policy.endosos.find((endoso) => endoso.id === action.payload),
  },
});

const resetCurrentClienteEspecial = (state) => ({
  ...state,
  form: {
    ...state.form,
    data: {
      ...state.form.data,
      endoso: {
        ...state.form.data.endoso,
        currentClienteEspecial: {
          ...initialClienteEspecial,
          cliente: state.form.data.endoso.currentClienteEspecial.cliente,
        },
      },
    },
  },
});

const cleanError = (state) => ({
  ...state,
  error: null,
});

function creditPolicyReducer(state = initialState, action) {
  switch (action.type) {
    case startType(CP_GET_LIST):
      return initLoading(state);
    case CP_GET_LIST:
      return getList(state, action);
    case errorType(CP_GET_LIST):
      return error(state, action);
    case errorType(CP_EMIT):
      return error(state, action);
    case CP_OPEN_DELETE:
      return openDelete(state, action);
    case CP_OPEN_DELETE_ENDOSO:
      return openDeleteEndoso(state, action);
    case startType(CP_DELETE):
      return initConfirmModalLoading(state, action);
    case CP_DELETE:
      return deletePolicy(state, action);
    case CP_DELETE_ENDOSO:
      return deleteEndoso(state, action);
    case CP_TOGGLE_DELETE:
      return toggleDelete(state);
    case CP_TOGGLE_DELETE_ENDOSO:
      return toggleDeleteEndoso(state);
    case startType(CP_GET):
      return initLoading(state);
    case CP_GET:
      return getPolicy(state, action);
    case CP_GET_POLICY:
      return getCurrentPolicy(state, action);
    case CP_HANDLE_FORM_ELEMENT_CHANGE:
      return handleFormElementChange(state, action);
    case CP_HANDLE_RAMO_FECU_SELECT_CHANGE:
      return handleRamoFecuSelectChange(state, action);
    case CP_HANDLE_GENERAL_CONDITIONED_SELECT_CHANGE:
      return handleGeneralConditionedSelectChange(state, action);
    case startType(CP_CREATE):
      return initLoading(state);
    case CP_CREATE:
      return internalGetPolicy(state, action);
    case startType(CP_EDIT):
      return initLoading(state);
    case CP_EDIT:
      return editPolicy(state, action);
    case startType(CP_EDIT_AND_CONTINUE):
      return initLoading(state);
    case CP_EDIT_AND_CONTINUE:
      return editAndContinue(state, action);
    case startType(CP_EMIT):
      return initLoading(state);
    case CP_EMIT:
      return emitPolicy(state, action);
    case CP_CLEAR_FORM:
      return clearForm(state);
    case CP_HANDLE_INICIO_VIGENCIA_CHANGE:
      return handleInicioVigenciaChange(state, action);
    case CP_HANDLE_INICIO_VIGENCIA_ENDOSO_CHANGE:
      return handleInicioVigenciaEndosoChange(state, action);
    case CP_HANDLE_TIPO_VIGENCIA_CHANGE:
      return handleTipoVigenciaChange(state, action);
    case CP_HANDLE_TERMINO_VIGENCIA_CHANGE:
      return handleTerminoVigenciaChange(state, action);
    case CP_HANDLE_BALANCE_CALCULATED_FIELDS_CHANGE:
      return handleBalanceCalculatedFieldsChange(state, action);
    case CP_HANDLE_TASA_COSTO_SEGURO_ADD:
      return handleTasaCostoSeguroAdd(state);
    case CP_HANDLE_TASA_COSTO_SEGURO_DELETE:
      return handleTasaCostoSeguroDelete(state, action);
    case CP_GET_PLAZOS_CREDITO:
      return getPlazosCredito(state, action);
    case CP_SELECT_PARTICULAR_CONDITION:
      return handleSelectParticularCondition(state, action);
    case CP_DESELECT_PARTICULAR_CONDITION:
      return handleDeselectParticularCondition(state, action);
    case CP_SET_NO_PARTICULAR_CONDITION_SELECTED:
      return handleSetNoParticularCondition(state, action);
    case CP_GET_PARTICULAR_CONDITIONS:
      return handleGetParticularConditions(state, action);
    case CP_GET_PORCENTAJES_COBERTURA_CREDITO:
      return getPorcentajesCoberturaCredito(state, action);
    case CP_GET_TIPOS_VIGENCIA:
      return getTiposVigencia(state, action);
    case CP_GET_GASTOS_ADQUISICION:
      return getGastosAdquisicion(state, action);
    case CP_HANDLE_CLIENTE_ESPECIAL_ADD:
      return handleClienteEspecialAdd(state);
    case CP_OPEN_CLIENTE_ESPECIAL_DELETE:
      return openClienteEspecialDelete(state, action);
    case CP_TOGGLE_CLIENTE_ESPECIAL_DELETE_MODAL:
      return toggleClienteEspecialDeleteModal(state);
    case CP_DELETE_CLIENTE_ESPECIAL:
      return deleteClienteEspecial(state);
    case CP_EDIT_CLIENTE_ESPECIAL:
      return editClienteEspecial(state, action);
    case CP_HANDLE_CANCEL_CLIENTE_ESPECIAL_EDITION:
      return handleCancelClienteEspecialEdition(state);
    case CP_HANDLE_CLIENTE_ESPECIAL_SAVE_EDITION:
      return handleClienteEspecialSaveEdition(state);
    case CP_GET_PARAMETERIZED_CONDITIONS:
      return handleGetParameterizedConditions(state, action);
    case CP_ADD_CLIENTE_ENDOSO:
      return handleAddClienteEndoso(state, action);
    case CP_DELETE_CLIENTE_ENDOSO:
      return handleDeleteClienteEndoso(state, action);
    case CP_TOGGLE_CLIENTE_ENDOSO_CHECK:
      return toggleClienteEndosoCheck(state, action);
    case CP_TOGGLE_NO_PARAMETERIZED_CONDITION_SELECTED:
      return handleToggleNoParameterizedCondition(state);
    case CP_ADD_ADJUNTO:
      return handleAddAdjunto(state, action);
    case CP_DELETE_ADJUNTO:
      return handleDeleteAdjunto(state, action);
    case CP_DELETE_ADJUNTO_NUEVO:
      return handleDeleteAdjuntoNuevo(state, action);
    case startType(CP_SAVE_POLICY_ATTACHMENTS):
      return initRefreshing(state);
    case CP_SAVE_POLICY_ATTACHMENTS:
      return handleSavePolicyAttachments(state, action);
    case CP_CHANGE_PLAN_PAGO_DOCUMENT:
      return changePlanPagoDocument(state, action);
    case CP_ADD_PLAN_PAGO_CUOTA:
      return addPlanPagoCuota(state, action);
    case CP_DELETE_PLAN_PAGO_CUOTA:
      return deletePlanPagoCuota(state, action);
    case CP_EDIT_PLAN_PAGO_CUOTA:
      return editPlanPagoCuota(state, action);
    case CP_TOGGLE_PARAMETERIZED_CONDITION_CHECKBOX:
      return toggleParameterizedConditionCheckbox(state, action);
    case CP_CHANGE_PARAMETERIZED_CONDITION_VALUE:
      return changeParameterizedConditionValue(state, action);
    case CP_HANDLE_PARAMETERIZED_CONDITIONED_OPTION_CHANGE:
      return handleParameterizedConditionedOptionChange(state, action);
    case CP_CHANGE_TIPO_ENDOSO:
      return handleChangeTipoEndoso(state, action);
    case CP_SET_LAST_STEP:
      return setLastStep(state, action);
    case CP_SET_SECTION_VALIDATION:
      return setStepValidation(state, action);
    case CP_HANDLE_FILIAL_ACTIVIDAD_CHANGE:
      return handleFilialActividadChange(state, action);
    case CP_ADD_ADJUNTO_FORM:
      return handleAddAdjuntoForm(state, action);
    case CP_DELETE_ADJUNTO_FORM:
      return handleDeleteAdjuntoForm(state, action);
    case CP_DELETE_ADJUNTO_NUEVO_FORM:
      return handleDeleteAdjuntoNuevoForm(state, action);
    case CP_REMOVE_SOBREPRIMA:
      return removeSobreprima(state, action);
    case CP_ADD_SOBREPRIMA:
      return addSobreprima(state, action);
    case CP_SELECT_SOBREPRIMA:
      return selectSobreprima(state, action);
    case CP_EDIT_SOBREPRIMA:
      return addSobreprima(removeSobreprima(state, action), action);
    case CP_TOGGLE_VB:
      return toggleVB(state, action);
    case CP_DETAILS_CHANGE_VIGENCIA:
      return detailsChangeVigencia(state, action);
    case CP_RESET_CURRENT_CLIENTE_ESPECIAL:
      return resetCurrentClienteEspecial(state);
    case CLEAN_ERROR:
      return cleanError(state);
    default:
      return state;
  }
}

export default creditPolicyReducer;
