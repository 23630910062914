import React, { useEffect, useState } from 'react';
import {
  API,
  BASE_URL,
  ESTADO_POLIZA,
  CONDICIONADO_GENERAL_VENTA_EN_VERDE,
} from 'CONST';
import {
  get,
  put,
  post,
} from 'helpers/requests';
import WarningModal from 'components/UI/WarningModal';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageContainer from '../PageContainer';
import ProjectPolicyForm from './ProjectPolicyForm';
import { objectToOption } from '../../helpers/selects';


const ProjectPolicyCreate = ({history}) => {
  const { idProyecto, idPoliza } = useParams();
  const [isEditable, setIsEditable] = useState(true);
  const [polizaExists, setPolizaExists] = useState(false);
  const [proyecto, setProyecto] = useState(null);

  // Información Básica
  const [moneda, setMoneda] = useState({});
  const [condicionadoGeneral, setCondicionadoGeneral] = useState({});
  const [producto, setProducto] = useState({});
  const [tipoCobertura, setTipoCobertura] = useState({});
  const [tipologiaEjecucion, setTipologiaEjecucion] = useState({});
  const [tipoInstitucion, setTipoInstitucion] = useState({});
  const [actividadAsegurada, setActividadAsegurada] = useState({});
  const [tipoIntermediario, setTipoIntermediario] = useState({});
  const [intermediario, setIntermediario] = useState({});

  const [activo, setActivo] = useState(false);
  const [dataEdition, setDataEdition] = useState({});
  const [nameBreadcrumb, setNameBreadcrumb] = useState('CREAR PÓLIZA');
  const [submitMessage, setSubmitMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [created, setCreated] = useState(false);
  const [showNotFoundModal, setShowNotFoundModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const updatePolizaDataEdition = () => {
    setNameBreadcrumb('EDITAR PÓLIZA')
    get(`${BASE_URL}${API.polizaProyecto}${idPoliza}`)
    .then(response => {
      setDataEdition(response);
      if (response && [ESTADO_POLIZA.construccion, ESTADO_POLIZA.borrador].includes(response.estado)) {
        setIsEditable(true);
      } else {
        setNameBreadcrumb('DETALLE PÓLIZA');
        setIsEditable(false);
      }
    })
    .catch((response) => {
      if (response.status === 404) {
        setErrorMsg('Póliza no encontrada.');
        setShowNotFoundModal(true);
      }
    });
  }

  useEffect(() => setPolizaExists(idPoliza !== undefined), []);

  useEffect(() => {
    // Se resetea el scroll al principio de la página.
    window.scrollTo(0, 0);

    // Se obtiene la info del proyecto.
    get(`${BASE_URL}${API.proyectos}${idProyecto}`)
    .then((response) => {
      if (response.estado === 'activo') {
        setProyecto(response);
        setTipoIntermediario(objectToOption(response.tipo_intermediario));
        setIntermediario(objectToOption(response.intermediario, 'nombre'));
      }
    })
    .catch((response) => {
      if (response.status === 404) {
        setErrorMsg('Proyecto no encontrado.');
        setShowNotFoundModal(true);
      }
    });
  }, []);

  useEffect(() => {
    if (polizaExists) {
      updatePolizaDataEdition();
    }
  }, [polizaExists]);

  useEffect(() => {
    setActivo(!isEditable);
  }, [isEditable]);

  useEffect(() => {
    // UF
    get(`${BASE_URL}${API.monedas}?id=3`)
    .then((response) => {
      if (response.length > 0) {
        setMoneda(objectToOption(response[0]));
      }
    });
    // pol120131810
    get(`
      ${BASE_URL}${API.maestras}?
      is_main=0&paginate=0&activa=true&ramo_asegurado__id=1&nemotecnico=${CONDICIONADO_GENERAL_VENTA_EN_VERDE}
    `).then((response) => {
      if (response.length > 0) {
        setCondicionadoGeneral(objectToOption(response[0]));
        // Inmobiliaria
        setProducto(objectToOption(response[0].productos.find(obj => { return obj.id === 3 })));
        // Venta en Verde
        setTipoCobertura(objectToOption(response[0].tipos_cobertura.find(obj => { return obj.id === 15 })))
        // Sujeto a Liquidación
        setTipologiaEjecucion(objectToOption(response[0].tipologias_ejecucion.find(obj => { return obj.id === 3 })))
      }
    });
    // Institución privada
    get(`${BASE_URL}${API.tipoInstitucion}?id=2`)
    .then((response) => {
      if (response.length > 0) {
        setTipoInstitucion(objectToOption(response[0]));
      }
    });
    // Terciario  - Construcción
    get(`${BASE_URL}${API.actividadEconomica}?id=3`)
    .then((response) => {
      if (response.length > 0) {
        setActividadAsegurada(objectToOption(response[0]));
      }
    });
  }, [])

  const handleCancel = () => history.push(`/garantia/proyectos/${idProyecto}/detalle`);

  const handleSubmit = (
    bodyPolizaProyecto,
  ) => {
    if (idPoliza) {
      put(`${BASE_URL}${API.polizaProyecto}${idPoliza}/`, bodyPolizaProyecto)
      .then(response => {
        if (response) {
          setSubmitMessage('Póliza actualizada satisfactoriamente.');
          setCreated(true);
          setShowModal(true);
        }
      })
      .catch(() => {
        setSubmitMessage('Error al actualizar póliza.');
        setShowModal(true);
      })
    } else {
      post(`${BASE_URL}${API.polizaProyecto}`, bodyPolizaProyecto)
      .then(response => {
        if (response) {
          setSubmitMessage('Póliza creada satisfactoriamente.');
          setCreated(true);
          setShowModal(true);
        }
      })
      .catch(() => {
        setSubmitMessage('Error al crear póliza.');
        setShowModal(true);
      })
    }
  };

  return (
    <PageContainer
      breadcrumbs={[
        { id: 0, name: 'GARANTÍA', url: '/' },
        { id: 1, name: 'VENTA EN VERDE', url: '/garantia/proyectos'},
        { id: 2, name: `PROYECTO ${idProyecto}`, url: `/garantia/proyectos/${idProyecto}/detalle`},
        { id: 3, name: nameBreadcrumb},
      ]}
      style={{ padding: '0 20px' }}
    >
      <ProjectPolicyForm
        moneda={moneda}
        condicionadoGeneral={condicionadoGeneral}
        producto={producto}
        tipoCobertura={tipoCobertura}
        tipologiaEjecucion={tipologiaEjecucion}
        tipoInstitucion={tipoInstitucion}
        actividadAsegurada={actividadAsegurada}
        tipoIntermediario={tipoIntermediario}
        intermediario={intermediario}
        dataEdition={dataEdition}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        activo={activo}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        idPoliza={idPoliza}
        proyecto={proyecto}
        updatePolizaDataEdition={updatePolizaDataEdition}
      />
      <WarningModal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        handleOk={() => {}}
        handleClearError={() => {
          if (created) {
            history.push(`/garantia/proyectos/${idProyecto}/detalle`)
          } else {
            setShowModal(false)
          }
        }}
        errorMsg={submitMessage}
        confirmMsg=''
      />
      <WarningModal
        isOpen={showNotFoundModal}
        toggle={() => {}}
        handleOk={() => {}}
        handleClearError={() => {
          history.push("/garantia/proyectos/")
        }}
        errorMsg={errorMsg}
        confirmMsg=""
      />
    </PageContainer>
  );
};

ProjectPolicyCreate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default ProjectPolicyCreate;
