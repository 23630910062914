import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { API, BASE_URL, ESTADO_PROYECTO, FRONT_DF_1 } from 'CONST';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faFileContract } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { fdate, fileDownload } from 'helpers/helpers';
import { rDelete } from 'helpers/requests';
import { headerOperation, cellNumOperation, cellOperation, headerDoubleRowOperation } from 'helpers/tables';
import PageContainer from 'components/PageContainer';
import WarningModal from 'components/UI/WarningModal';
import { connect } from 'react-redux';
import useTableFilters from '../../helpers/useTableFilters';
import BaseTable from '../UI/BaseTable';
import isUserHasProfile from '../../helpers/profileValidator';
import {
  PROYECTO_VV_READ,
  PROYECTO_VV_WRITE,
} from '../../helpers/profilePermission';
import FilterSelect from '../shared/FilterSelect';
import DateTableFilter from '../shared/DateTableFilter';

const NO_FILTERED_ID = -1;

const ProjectList = ({ loginIdToken }) => {
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [eliminarProyecto, setEliminarProyecto] = useState(null);

  const [onFilteredChange, onFetchData, loading, data, getData] = useTableFilters(
    `${BASE_URL}${API.proyectos}`,
    false,
    '',
    [{ id: 'inicio_vigencia', format: FRONT_DF_1 }, { id: 'termino_vigencia', format: FRONT_DF_1 }],
    0,
  );

  const xlsxUrl = `${BASE_URL}${API.proyectos}download_xlsx/`;
  const VVerdeUrl = `${BASE_URL}${API.proyectos}download_libro_prod_vverde/`;

  const columns = [
    {
      Header: () => headerOperation('Razón Social'),
      id: 'organizacion',
      accessor: 'organizacion',
      Cell: (row) => cellOperation(`${row.value.rut} - ${row.value.nombre}`),
      sortable: false,
      minWidth: 120,
    },
    {
      Header: () => headerOperation('Nombre Proyecto'),
      id: 'nombre__icontains',
      accessor: 'nombre',
      Cell: (row) => cellOperation(row.value),
      sortable: false,
      minWidth: 120,
    },
    {
      Header: () => headerOperation('Comuna'),
      id: 'comuna',
      accessor: 'comuna.name',
      Cell: (row) => cellOperation(row.value),
      sortable: false,
      minWidth: 100,
    },
    {
      Header: () => headerOperation('Fecha Inicio'),
      id: 'inicio_vigencia',
      accessor: 'inicio_vigencia',
      Cell: (row) => cellOperation(fdate(row.value)),
      Filter: (obj) => (
        <DateTableFilter
          filter={obj.filter}
          onChange={obj.onChange}
          format={FRONT_DF_1}
        />
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      Header: () => headerOperation('Fecha Término'),
      id: 'termino_vigencia',
      accessor: 'termino_vigencia',
      Cell: (row) => cellOperation(fdate(row.value)),
      Filter: (obj) => (
        <DateTableFilter
          filter={obj.filter}
          onChange={obj.onChange}
          format={FRONT_DF_1}
        />
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      Header: () => headerDoubleRowOperation('Monto', 'Máximo (UF)'),
      id: 'monto_maximo',
      accessor: 'monto_maximo',
      Cell: (row) => cellNumOperation(row.value),
      sortable: false,
      minWidth: 90,
    },
    {
      Header: () => headerDoubleRowOperation('Monto', 'Restante (UF)'),
      id: 'monto_restante',
      accessor: 'monto_restante',
      Cell: (row) => cellNumOperation(row.value),
      sortable: false,
      minWidth: 90,
    },
    {
      Header: () => headerOperation('Intermediario'),
      id: 'intermediario',
      accessor: 'intermediario.nombre',
      Cell: (row) => cellOperation(row.value),
      sortable: false,
      minWidth: 120,
    },
    {
      Header: () => headerOperation('Comisión (%)'),
      id: 'comision',
      accessor: 'comision',
      Cell: (row) => cellNumOperation(row.value, 'right', 2, '%'),
      sortable: false,
      minWidth: 80,
    },
    {
      Header: () => headerOperation('Tasa Anual (%)'),
      id: 'tasa_anual',
      accessor: 'tasa_anual',
      Cell: (row) => cellNumOperation(row.value, 'right', 2, '%'),
      sortable: false,
      minWidth: 80,
    },
    {
      Header: () => headerOperation('Pólizas Emitidas'),
      id: 'polizas_emitidas',
      accessor: 'polizas_emitidas',
      Cell: (row) => cellOperation(row.value, 'right'),
      sortable: false,
      minWidth: 90,
    },
    {
      Header: () => headerDoubleRowOperation('Prima Neta', 'Acumulada'),
      id: 'prima_neta_acumulada',
      accessor: 'prima_neta_acumulada',
      Cell: (row) => cellNumOperation(row.value),
      sortable: false,
      minWidth: 90,
    },
    {
      Header: () => headerOperation('Estado'),
      id: 'estado',
      accessor: 'estado_data',
      Cell: (row) => {
        let alertClass = 'alert-warning';
        if (row.value.nemotecnico === ESTADO_PROYECTO.borrador) {
          alertClass = 'alert-primary';
        } else if (row.value.nemotecnico === ESTADO_PROYECTO.activo) {
          alertClass = 'alert-success';
        } else if (row.value.nemotecnico === ESTADO_PROYECTO.caducado) {
          alertClass = 'alert-danger';
        }
        return cellOperation(
          <div className={`alert ${alertClass} text-center`} role="alert">
            {row.value.nemotecnico.charAt(0).toUpperCase() + row.value.nemotecnico.slice(1)}
          </div>,
        );
      },
      Filter: (obj) => (
        <FilterSelect
          onFilterChange={obj.onChange}
          filter={obj.filter}
          options={[
            { label: 'Caducado', value: 3 },
            { label: 'Activo', value: 2 },
            { label: 'Borrador', value: 1 },
          ]}
          defaultOption={{ label: 'Todos', value: NO_FILTERED_ID }}
        />
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      Header: () => headerOperation('Acciones'),
      id: 'acciones',
      accessor: item => (
        isUserHasProfile(PROYECTO_VV_READ) && (
          <div
            style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '5px' }}
            className="table-cell"
          >
            {(
              ![ESTADO_PROYECTO.borrador].includes(item.estado)
              || (
                [ESTADO_PROYECTO.borrador].includes(item.estado)
                && !isUserHasProfile(PROYECTO_VV_WRITE)
              )
            ) ? (
              <div style={{ textAlign: 'center' }} title="Ver">
                {/* Ver detalle */}
                <Link
                  to={`/garantia/proyectos/${item.id}/detalle`}
                  className="table-link policy"
                >
                  <FontAwesomeIcon icon={faEye} />
                </Link>
                {/* Descargar Libro Prod. */}
                <button
                  type="button"
                  className="table-link policy"
                  onClick={() => {
                    fileDownload(`${VVerdeUrl}?project=${item.id}`, loginIdToken);
                  }}
                  title="Descargar Libro Prod. Operaciones VVerde"
                  style={{ marginLeft: '5px' }}
                >
                  <FontAwesomeIcon icon={faFileContract} />
                </button>
              </div>
            ) : (
              isUserHasProfile(PROYECTO_VV_WRITE) && (
                <div style={{ textAlign: 'center' }} title="Editar">
                  {/* Editar */}
                  <Link
                    to={`/garantia/proyectos/${item.id}/detalle`}
                    className="table-link policy"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Link>
                  {/* Eliminar */}
                  <button
                    type="button"
                    className="table-link policy"
                    onClick={() => {
                      setEliminarProyecto(item.id);
                      setShowDeleteWarning(true);
                    }}
                    title="Eliminar"
                    style={{ marginLeft: '5px' }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              )
            )}
          </div>
        )
      ),
      width: 85,
      sortable: false,
      filterable: false,
    },
  ];

  const handleDelete = () => {
    setShowDeleteWarning(false);
    rDelete(`${BASE_URL}${API.proyectos}${eliminarProyecto}`)
    .then((response) => {
      if (response && response.operation_id) {
        getData('');
      }
    });
  };

  return (
    <PageContainer
      breadcrumbs={[
        { id: 0, name: 'GARANTÍA', url: '/' },
        { id: 1, name: 'VENTA EN VERDE'},
        { id: 2, name: 'LISTADO PROYECTOS'},
      ]}
      mainAction={
        isUserHasProfile(PROYECTO_VV_WRITE) && {
          url: '/garantia/proyectos/crear',
          caption: 'Crear nuevo proyecto',
        }
      }
      downloadXlsxUrl={xlsxUrl}
      downloadProdVVerdeUrl={VVerdeUrl}
    >
      <BaseTable
        className="client-list"
        columns={columns}
        data={data.data}
        filterable
        loading={loading}
        onFetchData={onFetchData}
        onFilteredChange={onFilteredChange}
        pages={data.pages}
        resizable={false}
        resultCount={data.count}
        showPagination
        style={{ height: '63vh' }}
        tableRef={React.createRef()}
      />

      <WarningModal
        isOpen={showDeleteWarning}
        toggle={() => {setShowDeleteWarning(false)}}
        handleOk={() => handleDelete()}
        okBtnText="Aceptar"
        confirmMsg="¿Está seguro que quiere eliminar este proyecto en estado borrador?"
      />
    </PageContainer>
  );
};

ProjectList.propTypes = {
  loginIdToken: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  loginIdToken: state.login.id_token,
});

export default connect(mapStateToProps)(ProjectList);
