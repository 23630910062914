import React, { useState } from 'react';
import { Button, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import isUserHasProfile from 'helpers/profileValidator';
import { ENDOSO_CREDITO_EMITIR } from 'helpers/profilePermission';
import {
  BACKEND_DF,
  CP_FS_BASICOS,
  CP_FS_CLAUSULAS,
  CP_FS_CLIENTES,
  CP_FS_CONDICIONES,
  CP_FS_DETALLES,
  CONSTRUCTION_LABEL,
  TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID,
} from '../../../../CONST';
import {
  createCreditPolicy,
  editCreditPolicy,
  emitCreditPolicy,
  setLastStep,
} from '../../../../redux-store/actions/creditPolicyActions';
import WarningModal from '../../../UI/WarningModal';
import SafeBtn from '../../../shared/SafeBtn';
import { isValidCreditPolicyForm } from '../../../../helpers/validation';
import { sectionsType } from '../../../../redux-store/types';
import { CLEAN_ERROR } from '../../../../redux-store/constants/action-types';

const CreditPolicyFormActions = ({
  isValidForm,
  isLastStep,
  creditPolicy,
  dispatch,
  setValidateInputs,
  readOnly,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [continuing, setContinuing] = useState(false);
  const isEditing = !!creditPolicy.form.currentPolicyId;

  const cancelLink =
    creditPolicy.policy && creditPolicy.policy.balance
      ? `/credit/policies/detail/${creditPolicy.form.currentPolicyId}`
      : '/credit/policies/';

  const savePolicy = (emit, saveAndContinue) => {
    if (saveAndContinue && !isValidForm()) {
      setValidateInputs(true);
      return;
    }

    setValidateInputs(false);
    setContinuing(saveAndContinue);

    const form = creditPolicy.form.data;
    const { sections } = creditPolicy.form;
    const { endoso } = form;

    const policy = {
      adjuntos_ids: form.adjuntos ? form.adjuntos.map((file) => file.id) : [],
      adjuntos_uuids: form.adjuntos_uuids,
      vb_basicos: sections[CP_FS_BASICOS].vb,
      vb_detalles: sections[CP_FS_DETALLES].vb,
      vb_clientes: sections[CP_FS_CLIENTES].vb,
      vb_condiciones: sections[CP_FS_CONDICIONES].vb,
      vb_clausulas: sections[CP_FS_CLAUSULAS].vb,
      borrador: {
        tipo: endoso.tipoEndosoId,
        numero_vigencia: endoso.numeroVigencia,
        emitido: emit,
        tipo_cobertura_id: form.endoso.tipoCobertura.value.id,
        tipo_intermediario_id: form.endoso.tipoIntermediario.value.id,
        intermediario_id: form.endoso.intermediario ? form.endoso.intermediario.value.id : null,
        actividad_economica_id: form.endoso.actividadAsegurada.value.id,
        actividad_id: form.endoso.aseguradoActividad.value,
        inicio_vigencia: endoso.inicioVigencia ? endoso.inicioVigencia.format(BACKEND_DF) : null,
        inicio_vigencia_endoso: endoso.inicioVigenciaEndoso
          ? endoso.inicioVigenciaEndoso.format(BACKEND_DF)
          : null,
        termino_vigencia: endoso.terminoVigencia ? endoso.terminoVigencia.format(BACKEND_DF) : null,
        tipo_vigencia: endoso.tipoVigenciaId,
        estimacion_ventas_amt: endoso.estimacionVentasAmt,
        ventas_pct: endoso.ventasPct,
        monto_asegurado_estimado_amt: endoso.montoAseguradoEstimadoAmt,
        prima_minima_anual_amt: endoso.primaMinimaAnualAmt,
        delta_prima_minima_anual_amt: endoso.deltaPrimaMinimaAnualAmt,
        prorroga_dias: endoso.prorrogaDias,
        comision_pct: endoso.comisionPct,
        prima_minima_mensual_amt: endoso.primaMinimaMensualAmt,
        glosa: endoso.materiaAsegurada,
        tipo_indemnizacion_max: endoso.tipoIndemnizacionMaxId,
        indemnizacion_max_amt: endoso.indemnizacionMaxAmt,
        tipo_costo_aviso_impago: endoso.tipoCostoAvisoImpagoId,
        costo_aviso_impago: endoso.costoAvisoImpago,
        plan_pago: {
          id: endoso.planPago.id,
          tipo: endoso.planPago.tipoId,
          cuotas: endoso.planPago.cuotas,
          cuotas_plan: endoso.planPago.cuotasPlan.map((cuota) => ({
            ...cuota,
            fecha_pago: cuota.fecha_pago.format(BACKEND_DF),
          })),
        },
        tipo_provision: endoso.tipoProvisionId,
        tasa_costo_seguro_defecto_pct: endoso.tasaCostoSeguroDefectoPct,
        tasa_costo_seguro: endoso.tasasCostoSeguro.map((tasa) => ({
          id: tasa.id,
          desde_dias: tasa.desdeDias,
          hasta_dias: tasa.hastaDias,
          tasa_costo_seguro_pct: tasa.tasaCostoSeguroPct,
        })),
        configuracion_siniestro_dias: endoso.configuracionSiniestroDias,
        tipo_gestiones_propias: endoso.tipoGestionesPropiasId,
        plazo_gestiones_propias_dias: endoso.plazoGestionesPropiasDiasId,
        plazo_maximo_credito_dias: endoso.plazoMaximoCreditoDiasId,
        clientes_innominados: endoso.clientesInnominados,
        cobertura_nominados_pct: endoso.coberturaNominadosPctId,
        cobertura_innominados_pct: endoso.coberturaInnominadosPctId,
        maximo_innominados_amt: endoso.maximoInnominadosAmt,
        indemnizacion_max_innominado:
          endoso.clientesInnominados &&
          endoso.tipoIndemnizacionMaxId === TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID
            ? endoso.indemnizacionMaxInnominado
            : 0,
        umbral_cobertura_amt: endoso.umbralCoberturaAmt,
        costo_prorroga_nominado_pct: endoso.costoProrrogaNominadoPct,
        costo_prorroga_innominado_pct: endoso.costoProrrogaInnominadoPct,
        deducible_nominado_amt: endoso.deducibleNominadoAmt,
        deducible_innominado_amt: endoso.deducibleInnominadoAmt,
        costo_estudio_nominado_aprobado_amt: endoso.costoEstudioNominadoAprobadoAmt,
        costo_estudio_nominado_rechazado_amt: endoso.costoEstudioNominadoRechazadoAmt,
        costo_estudio_innominado_aprobado_amt: endoso.costoEstudioInnominadoAprobadoAmt,
        costo_estudio_innominado_rechazado_amt: endoso.costoEstudioInnominadoRechazadoAmt,
        condiciones_particulares_ids: endoso.noCondicionesParticulares
          ? []
          : endoso.condicionesParticulares,
        no_agregar_condiciones_particulares: endoso.noCondicionesParticulares,
        clientes_especiales: endoso.clientesEspeciales.map((cliente) => ({
          deudor_id: cliente.cliente.id,
          tasa_costo_seguro_pct_sobrescribir: cliente.tasaCostoSeguroPctSobrescribir,
          tasa_costo_seguro_pct: cliente.tasaCostoSeguroPct,
          indemnizacion_max_sobrescribir: cliente.indemnizacionMaxSobrescribir,
          tipo_indemnizacion_max: cliente.tipoIndemnizacionMaxId,
          indemnizacion_max_amt: cliente.indemnizacionMaxAmt,
          plazo_gestiones_propias_dias_sobrescribir: cliente.plazoGestionesPropiasDiasSobrescribir,
          plazo_gestiones_propias_dias: cliente.plazoGestionesPropiasDiasId,
          configuracion_siniestro_dias_sobrescribir: cliente.configuracionSiniestroDiasSobrescribir,
          configuracion_siniestro_dias: cliente.configuracionSiniestroDias,
          plazo_maximo_credito_dias_sobrescribir: cliente.plazoMaximoCreditoDiasSobrescribir,
          plazo_maximo_credito_dias: cliente.plazoMaximoCreditoDiasId,
          cobertura_pct_sobrescribir: cliente.coberturaPctSobrescribir,
          cobertura_pct: cliente.coberturaPctId,
          umbral_cobertura_amt_sobrescribir: cliente.umbralCoberturaAmtSobrescribir,
          umbral_cobertura_amt: cliente.umbralCoberturaAmt,
          costo_prorroga_pct_sobrescribir: cliente.costoProrrogaPctSobrescribir,
          costo_prorroga_pct: cliente.costoProrrogaPct,
          deducible_amt_sobrescribir: cliente.deducibleAmtSobrescribir,
          deducible_amt: cliente.deducibleAmt,
        })),
        condiciones_parametrizadas_data: endoso.noCondicionesParametrizadas
          ? []
          : creditPolicy.form.condicionesParametrizadasList.filter((cond) => cond.selected),
        no_agregar_condiciones_parametrizadas: endoso.noCondicionesParametrizadas,
        clienteendoso_data: endoso.clientesEndoso,
      },
    };

    if (creditPolicy.form.estado === CONSTRUCTION_LABEL) {
      policy.base = {
        moneda_asegurada_id: form.moneda.value.id,
        poliza_maestra_id: form.condicionadoGeneral.value.id,
        producto_id: form.producto.value.id,
        tipologia_ejecucion_id: form.tipologiaEjecucion.value.id,
        asegurado_id: form.asegurado.value.id,
      };
    }

    if (form.endoso.aseguradoAlias) {
      policy.borrador.asegurado_alias_id = form.endoso.aseguradoAlias.value;
    }

    if (isEditing) {
      policy.id = creditPolicy.form.currentPolicyId;
      policy.last_step = isLastStep;
      if (emit) {
        dispatch(emitCreditPolicy(policy));
      } else {
        dispatch(editCreditPolicy(policy, saveAndContinue));
      }
    } else {
      dispatch(createCreditPolicy(policy));
    }
  };

  const save = () => savePolicy(false, false);
  const saveAndContinue = () => savePolicy(false, true);
  const emit = () => savePolicy(true, true);

  const toggleModal = () => setOpenModal(!openModal);

  const {
    form,
    form: {
      data: {
        endoso: { cambios },
      },
    },
  } = creditPolicy;
  const hasChanges = Object.keys(cambios).length > 0;
  const validForm = isValidCreditPolicyForm(form);
  const canEmit = hasChanges && validForm;
  const handleClear = () => {
    setOpenModal(!openModal);
    dispatch({ type: CLEAN_ERROR });
  };
  return (
    <Col md={{ offset: 1, size: 10 }}>
      <div className="cp-form-feedback">
        <p>
          {isLastStep &&
            !canEmit &&
            (!hasChanges
              ? 'La póliza no puede ser emitida porque no se han realizado cambios sobre la misma'
              : 'Existen errores en el formulario. Vuelva al paso anterior para corregirlos.')}
        </p>
      </div>
      {readOnly && !isLastStep ? (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 3fr 1fr 1fr 170px',
            gridGap: '20px',
            marginTop: '40px',
          }}
        >
          <Button
            outline
            color="orsan-primary"
            style={{ gridColumn: '5 / 5' }}
            tag={Link}
            to={cancelLink}
          >
            Volver
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns:
              isLastStep && !isUserHasProfile(ENDOSO_CREDITO_EMITIR)
                ? '1fr 3fr 1fr 1fr'
                : '1fr 3fr 1fr 1fr 170px',
            gridGap: '20px',
            marginTop: '40px',
          }}
        >
          <div>
            {isLastStep && (
              <Button
                color="orsan-link"
                className="btn-full-width"
                onClick={() => dispatch(setLastStep(false))}
              >
                Volver
              </Button>
            )}
          </div>
          <div />
          <div>
            <Button
              outline
              color="orsan-primary"
              className="btn-full-width"
              tag={Link}
              to={cancelLink}
            >
              Cancelar
            </Button>
          </div>
          {isEditing && (
            <div>
              <SafeBtn
                color="orsan-secondary"
                className="btn-full-width"
                disabled={!isEditing || creditPolicy.loading}
                onClick={save}
                loading={creditPolicy.loading && !continuing}
              >
                {isLastStep ? 'Crear Borrador' : 'Guardar'}
              </SafeBtn>
            </div>
          )}

          {isLastStep ? (
            isUserHasProfile(ENDOSO_CREDITO_EMITIR) && (
              <div>
                <SafeBtn
                  color="orsan-primary"
                  className="btn-full-width"
                  disabled={creditPolicy.loading || (isLastStep && !(canEmit && isValidForm()))}
                  onClick={toggleModal}
                  loading={creditPolicy.loading && continuing}
                >
                  Emitir
                </SafeBtn>
              </div>
            )
          ) : (
            <div>
              <SafeBtn
                color="orsan-primary"
                className="btn-full-width"
                disabled={creditPolicy.loading || (isLastStep && !(canEmit && isValidForm()))}
                onClick={saveAndContinue}
                loading={creditPolicy.loading && continuing}
              >
                Guardar y Continuar
              </SafeBtn>
            </div>
          )}
        </div>
      )}
      <WarningModal
        isOpen={openModal}
        toggle={toggleModal}
        handleOk={emit}
        okBtnText="Confirmar"
        confirmMsg="Está a un paso de emitir la póliza con los cambios más recientes"
        errorMsg={creditPolicy.error && creditPolicy.error[Object.keys(creditPolicy.error)[0]]}
        loading={creditPolicy.loading}
        handleClearError={handleClear}
      />
    </Col>
  );
};

CreditPolicyFormActions.propTypes = {
  isValidForm: PropTypes.func,
  isLastStep: PropTypes.bool,
  creditPolicy: PropTypes.shape({
    loading: PropTypes.bool,
    policy: PropTypes.shape({
      balance: PropTypes.object,
    }),
    error: PropTypes.object,
    form: PropTypes.shape({
      condicionesParametrizadasList: PropTypes.array,
      sections: sectionsType,
      estado: PropTypes.string,
      data: PropTypes.shape({
        moneda: PropTypes.object,
        ramoFecu: PropTypes.object,
        condicionadoGeneral: PropTypes.object,
        producto: PropTypes.object,
        tipologiaEjecucion: PropTypes.object,
        asegurado: PropTypes.object,
        adjuntos: PropTypes.array,
        adjuntos_uuids: PropTypes.array,
        endoso: PropTypes.object,
      }),
      currentPolicyId: PropTypes.number,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  setValidateInputs: PropTypes.func,
  readOnly: PropTypes.bool,
};

CreditPolicyFormActions.defaultProps = {
  isLastStep: false,
  setValidateInputs: () => {},
  isValidForm: () => true,
  readOnly: false,
};

export default CreditPolicyFormActions;
