/* eslint-disable camelcase,jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import WarningModal from 'components/UI/WarningModal';
import { get } from 'helpers/requests';
import { Button, Col, FormGroup } from 'reactstrap';
import FormSection from '../UI/FormSection';
import { objectsToOptions } from '../../helpers/selects';
import {
  cleanEndoso,
  cleanPolicy,
  deleteData,
  getData,
  patchData,
  postData,
  resetError,
} from '../../redux-store/actions';
import {
  CLEAR_PUBLISH_ENDOSO_ERROR,
  CREATE_ENDOSO,
  DELETE_ENDOSO,
  GET_ACTIVE_GENERAL_CONDITIONED_LIST,
  GET_ACTIVIDAD_LIST,
  GET_BALANCE,
  GET_ENDOSO_LIST,
  GET_INTERMEDIARIO_LIST,
  GET_MONEDAS,
  GET_POLICY,
  GET_TIPOS_ENDOSO,
  GET_TIPOS_INSTITUCION,
  PUBLISH_ENDOSO,
  SAVE_ENDOSO,
} from '../../redux-store/constants/action-types';

import {
  API,
  BASE_URL,
  ESTADO_POLIZA,
  ENDOSO_CANCELACION,
  ENDOSO_PRORROGA,
} from '../../CONST';
import isUserHasProfile from '../../helpers/profileValidator';
import { POLIZA_VV_WRITE } from '../../helpers/profilePermission';
import EndosoCreate from '../endosos/EndosoCreate';
import EndosoForm from '../endosos/EndosoForm';
import InsureUnits from './InsureUnits';
import Beneficiarios from './Beneficiarios';
import DeprecatedWarningModal from '../UI/DeprecatedWarningModal';


const ProjectPolicyFormView = ({
  moneda,
  condicionadoGeneral,
  producto,
  tipoCobertura,
  tipologiaEjecucion,
  tipoInstitucion,
  actividadAsegurada,
  tipoIntermediario,
  intermediario,
  activo,
  dataEdition,
  isEditable,
  setIsEditable,
  handleCancel,
  handleSubmit,
  idPoliza,
  dispatchCleanEndoso,
  dispatchCleanPolicy,
  dispatchClearPublishEndosoError,
  dispatchDeleteData,
  dispatchGetData,
  dispatchPatchData,
  dispatchPostData,
  dispatchResetError,
  policies,
  proyecto,
  updatePolizaDataEdition,
}) => {
  const sectionTitle = 'Información Básica';
  const [savePolicyModalWarning, setSavePolicyModalWarning] = useState(false);

  // Endosos
  const [loading, setLoading] = useState(false);
  const [dropdownEndosoOpen, setDropdownEndosoOpen] = useState(false);
  const [warningEndosoOpen, setWarningEndosoOpen] = useState(false);
  const [deleteEndosoWarningOpen, setDeleteEndosoWarningOpen] = useState(false);
  const [publishEndoso, setPublishEndoso] = useState(undefined);
  const [endosoModalOpen, setEndosoModalOpen] = useState(false);
  const [activeEndosoEditModal, setActiveEndosoEditModal] = useState(undefined);
  const [activeEndosoModal, setActiveEndosoModal] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState(null);

  // Beneficiarios
  const [beneficiario, setBeneficiario] = useState({});
  const [participacion, setParticipacion] = useState(null);

  // Beneficiarios Tabla
  const [tableDataBeneficiario, setTableDataBeneficiario] = useState([]);

  // Unidades Form
  const [unitOptions, setUnitOptions] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [detail, setDetail] = useState('');

  // Unidades Tabla
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (!(Object.keys(dataEdition).length === 0 && dataEdition.constructor === Object)) {
      if (dataEdition.unidades) {
        setTableData(dataEdition.unidades.map((unidad) => ({
          unitValue: {
            id: unidad.tipo_unidad_extendida.id,
            nemotecnico: unidad.tipo_unidad_extendida.nemotecnico,
            descripcion: unidad.tipo_unidad_extendida.descripcion,
          },
          unitDetail: unidad.detalle
        })))
      }
      if (dataEdition.beneficiarios) {
        setTableDataBeneficiario(dataEdition.beneficiarios.map((item_beneficiario) => ({
          beneficiarioValue: {
            ...item_beneficiario.organizacion
          },
          beneficiarioParticipacion: item_beneficiario.porcentaje_participacion,
        })))
      }
    }
  }, [dataEdition]);

  // Unidades Handle
  const handleUnitOptions = (units) => {
    setUnitOptions(objectsToOptions(units))
  }

  useEffect(() => {
    get(`${BASE_URL}${API.unidadAsegurada}`)
    .then((response) => {
      if (response) {
        handleUnitOptions(response.results)
      }
    })

    dispatchCleanPolicy();
    // Datos que utiliza en endosos.
    if (idPoliza) {
      dispatchGetData({
        url: `${BASE_URL}${API.endosos}/?poliza_base_id=${idPoliza}`,
        type: GET_ENDOSO_LIST,
      });
      dispatchGetData({
        url: `${BASE_URL}${API.tipos_endoso_garantia}?garantia=${idPoliza}`,
        type: GET_TIPOS_ENDOSO,
      });
      dispatchGetData({
        url: `${BASE_URL}${API.balance}?poliza_base_id=${idPoliza}`,
        type: GET_BALANCE,
      });
      dispatchGetData({
        url: `${BASE_URL}${API.polizaProyecto}${idPoliza}`,
        type: GET_POLICY,
      });
    }
    dispatchGetData({
      url: `${BASE_URL}${API.maestras}/?is_main=0&paginate=0&activa=true&ramo_asegurado__id=1`,
      type: GET_ACTIVE_GENERAL_CONDITIONED_LIST,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.tipos_institucion}`,
      type: GET_TIPOS_INSTITUCION,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.monedas}`,
      type: GET_MONEDAS,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.actividad}`,
      type: GET_ACTIVIDAD_LIST,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.intermediario}`,
      type: GET_INTERMEDIARIO_LIST,
    });
  }, [])

  useEffect(() => {
    if (policies.successEndosoMessage) {
      dispatchCleanEndoso();
      if (idPoliza) {
        dispatchGetData({
          url: `${BASE_URL}${API.polizaProyecto}${idPoliza}`,
          type: GET_POLICY,
        });
        dispatchGetData({
          url: `${BASE_URL}${API.tipos_endoso_garantia}?garantia=${idPoliza}`,
          type: GET_TIPOS_ENDOSO,
        });
        dispatchGetData({
          url: `${BASE_URL}${API.balance}?poliza_base_id=${idPoliza}`,
          type: GET_BALANCE,
        });
      }
      setLoading(false);
      setEndosoModalOpen(false);
      setActiveEndosoModal(undefined);
      setActiveEndosoEditModal(undefined);
      setWarningEndosoOpen(policies && policies.error);
      setDeleteEndosoWarningOpen(false);
    }
    if (policies.errorEndosoMessage) {
      dispatchResetError();
      setLoading(false);
    }
    if (policies.error) {
      if (policies.error.errors.includes('CupoDisponibleProyectoInsuficiente')) {
        setErrorMsg(`Cupo disponible del proyecto insuficiente (quedan ${policies.error.cupo_disponible} UF).`);
      }
      else if (policies.error.errors.includes('ClienteBloqueado')) {
        setErrorMsg('El Cliente se encuentra bloqueado. Vuelva a intentarlo más tarde.');
      }
      else if (policies.error.errors.includes('VigenciaEndosoFueraDeVigenciaProyecto')) {
        setErrorMsg('La vigencia del endoso no está dentro de la vigencia del proyecto.');
      }
      else if (policies.error.errors.includes('InicioEndosoDespuesDeTerminoProyecto')) {
        setErrorMsg('No se puede emitir pólizas venta en verde posterior a la fecha de cierre del proyecto.');
      }
      else if (policies.error.errors.length > 0) {
        setErrorMsg(`Hubo un error. (${policies.error.errors[0]})`);
      }
    }
  }, [policies])

  const handleCleanUnits = () => {
    setSelectedUnit(null)
    setDetail('')
  }

  const handleDeleteUnit = (unitToDelete) => {
    setTableData(
      tableData.filter(
        (item) => item !== unitToDelete,
      ),
    );
  };

  const handleEditUnit = (unitToEdit) => {
    handleDeleteUnit(unitToEdit)
    setDetail(unitToEdit.unitDetail)
    setSelectedUnit({label: unitToEdit.unitValue.descripcion, value: unitToEdit.unitValue})
  };

  const handleAddTableData = () => {
    if (selectedUnit) {
      setTableData([...tableData, {unitValue: selectedUnit.value, unitDetail: detail}])
      handleCleanUnits()
    }
  }

  const handleOnChangeDetail = (textarea) => {
    setDetail(textarea.target.value)
  }

  const handleOnChangeSelect = (selected) => {
    setSelectedUnit(selected)
  }

  // Beneficiario Handle
  const isParticipacionFulled = () => {
    const participacionPrev = tableDataBeneficiario.reduce(
      (previo, nuevo) => previo + nuevo.beneficiarioParticipacion, 0
    );
    return participacionPrev === 100;
  }

  // Form
  const isDisabledByProfile = () => {
    const validRole = isUserHasProfile(POLIZA_VV_WRITE);
    return !validRole;
  };

  // Validación crear póliza
  const isPolicyFormValid = () => {
    return moneda
    && condicionadoGeneral
    && producto
    && tipoCobertura
    && tipologiaEjecucion
    && (intermediario || (tipoIntermediario && tipoIntermediario.value.id === 1))
    && tipoInstitucion
    && actividadAsegurada
    && (tableData && tableData.length > 0)
    && isParticipacionFulled()
  }

  const handleCreate = () => {
    setLoading(true);
    const polizaProyecto = {
      proyecto_garantia_id: proyecto.id,
      estado: dataEdition ? dataEdition.estado : ESTADO_POLIZA.construccion,
      emision: null,
      posee_endoso: false,
      emitido: false,
      cancelado: false,
      fecha_emision: null,
      fecha_inicio: null,
      fecha_termino: null,
      actividad_id: proyecto.actividad.id,
      afianzado_id: proyecto.organizacion.id,
      beneficiarios: tableDataBeneficiario.map((beneficiarioItem) => ({
        organizacion_id: beneficiarioItem.beneficiarioValue.id,
        porcentaje_participacion: beneficiarioItem.beneficiarioParticipacion
      })),
      asegurados: tableDataBeneficiario.map((beneficiarioItem) => ({
        organizacion_id: beneficiarioItem.beneficiarioValue.id,
        porcentaje_participacion: beneficiarioItem.beneficiarioParticipacion
      })),
      unidades: tableData.map((unidad) => ({
        tipo_unidad: unidad.unitValue.nemotecnico,
        detalle: unidad.unitDetail
      })),
      base: {
        nemotecnico: 'nemotecnico',
        moneda_asegurada_id: moneda.value.id,
        poliza_maestra_id: condicionadoGeneral.value.id,
        actividad_economica_id: actividadAsegurada.value.id,
        tipo_intermediario_id: tipoIntermediario.value.id,
        intermediario_id: intermediario && intermediario.value && intermediario.value.id,
        asegurado_id: tableDataBeneficiario.reduce(
          (max, curr) => max.beneficiarioParticipacion > curr.beneficiarioParticipacion ? max : curr
          ).beneficiarioValue.id,
        producto_id: producto.value.id,
        tipo_cobertura_id: tipoCobertura.value.id,
        tipo_institucion_id: tipoInstitucion.value.id,
        tipologia_ejecucion_id: tipologiaEjecucion.value.id,
      },
    }
    handleSubmit(polizaProyecto);
  }

  const handleDeleteEndosoWarning = (id) => {
    setDeleteEndosoWarningOpen(!deleteEndosoWarningOpen);
    setPublishEndoso(!deleteEndosoWarningOpen ? id : undefined);
  };

  const handleEndosoEdit = (i) => {
    setEndosoModalOpen(true);
    setActiveEndosoEditModal(i);
    setActiveEndosoModal(undefined);
  };

  const handleEndosoDropdown = () => {
    setDropdownEndosoOpen(!dropdownEndosoOpen);
  };

  const handleEndosoModal = (e, node) => {
    setEndosoModalOpen(!endosoModalOpen);
    setActiveEndosoModal(node && { tipo_endoso: node });
    setActiveEndosoEditModal(undefined);
  };

  const handleEndosoWarning = (e, i) => {
    setWarningEndosoOpen(!warningEndosoOpen);
    setPublishEndoso(!warningEndosoOpen ? i : undefined);
  };

  const toggleEndosoWarningOpen = () => {
    setWarningEndosoOpen(!warningEndosoOpen);
  }

  const lastTerminoVigencia = () => {
    const { endosoList }  = policies;
    if (endosoList.length === 0) {
      return null;
    }
    const emision = endosoList[0];
    const prorrogas = endosoList.filter(
      (endoso) => endoso.tipo_endoso.nemotecnico === ENDOSO_PRORROGA,
    );
    return prorrogas.length ? prorrogas.pop().termino_vigencia : emision.termino_vigencia;
  };

  const lastCancelacion = () => {
    const { endosoList }  = policies;
    const cancelaciones = endosoList.filter(
      (endoso) => endoso.tipo_endoso.nemotecnico === ENDOSO_CANCELACION,
    );
    return cancelaciones.pop();
  };

  const handleEndosoSubmit = (config, onFailedReq = () => {}) => {
    dispatchPostData({
      url: `${BASE_URL}${API.endosos}/`,
      type: CREATE_ENDOSO,
      onFailedReq,
      config,
    }).then(() => {
      updatePolizaDataEdition();
    });
    dispatchGetData({
      url: `${BASE_URL}${API.endosos}/?poliza_base_id=${idPoliza}`,
      type: GET_ENDOSO_LIST,
    });
    setLoading(true);
  };

  const handleEndosoEditSubmit = (id, config, onFailedReq = () => {}) => {
    dispatchPatchData({
      url: `${BASE_URL}${API.endosos}/${id}/`,
      type: SAVE_ENDOSO,
      onFailedReq,
      config,
    }).then(() => {
      updatePolizaDataEdition();
    });
    setLoading(true);
  };

  const handlePublish = (i, onFailedReq = () => {}) => {
    dispatchPostData({
      url: `${BASE_URL}${API.polizaProyecto}${idPoliza}/endosos/${i}/emitir/`,
      type: PUBLISH_ENDOSO,
      onFailedReq,
    }).then(() => {
      setIsEditable(false)
      updatePolizaDataEdition();
    });
  };

  const handlePublishEndoso = (i) => {
    return handlePublish(i);
  };

  const handleEndosoDelete = (i) => {
    dispatchDeleteData({
      url: `${BASE_URL}${API.endosos}/${i}/`,
      type: DELETE_ENDOSO,
      id: i,
    }).then(() => {
      updatePolizaDataEdition();
    });
  };

  return (
    <div>
      <Col md={{ offset: 2, size: 8 }}>
        {policies.selectedPolicy && (
          <EndosoCreate
            dropdownOpen={dropdownEndosoOpen}
            warningOpen={warningEndosoOpen}
            deleteWarningOpen={deleteEndosoWarningOpen}
            handleDelete={handleDeleteEndosoWarning}
            handleEdit={handleEndosoEdit}
            handleDropdown={handleEndosoDropdown}
            handleModal={handleEndosoModal}
            handleWarning={handleEndosoWarning}
          />
        )}
        {activeEndosoModal && (
          <EndosoForm
            ventaEnVerde
            activeModal={activeEndosoModal}
            handleModal={handleEndosoModal}
            handleSubmit={handleEndosoSubmit}
            loading={loading}
            modalOpen={endosoModalOpen}
            lastTerminoVigencia={lastTerminoVigencia()}
            lastCancelacion={lastCancelacion()}
          />
        )}
        {activeEndosoEditModal && (
          <EndosoForm
            ventaEnVerde
            edit
            activeModal={activeEndosoEditModal}
            handleModal={handleEndosoModal}
            handleSubmit={handleEndosoEditSubmit}
            loading={loading}
            modalOpen={endosoModalOpen}
            lastTerminoVigencia={lastTerminoVigencia()}
            lastCancelacion={lastCancelacion()}
          />
        )}
        <WarningModal
          confirmMsg="Está a un paso de emitir un Endoso"
          okBtnText="Emitir Endoso"
          handleOk={() => handlePublishEndoso(publishEndoso)}
          toggle={toggleEndosoWarningOpen}
          isOpen={warningEndosoOpen}
          loading={policies.loading}
          errorMsg={errorMsg}
          handleClearError={() => {
            toggleEndosoWarningOpen();
            setErrorMsg(null);
            // avoid rendering submit button while disappearing
            setTimeout(dispatchClearPublishEndosoError, 500);
          }}
        />
        <DeprecatedWarningModal
          handleModal={handleDeleteEndosoWarning}
          handleSubmit={handleEndosoDelete}
          objectId={publishEndoso}
          modalOpen={deleteEndosoWarningOpen}
          warningMessage="Está a un paso de eliminar un Endoso"
          confirmText="Eliminar Endoso"
        />

        {/* Información Básica */}
        <FormSection title={sectionTitle}>
          <div
            style={{
              display: 'grid',
              gridGap: '20px',
              gridTemplateColumns: '1fr 1fr 1fr',
            }}
          >
            <div>
              <label htmlFor="ramo_fecu">Ramo FECU</label>
              <p id="ramo_fecu" className="form-values">
                Garantía
              </p>
            </div>
            <FormGroup>
              <label htmlFor="moneda">Moneda</label>
              <Select
                id="moneda"
                name="moneda"
                className="custom-policy-select"
                isDisabled
                value={moneda}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="condicionado_general">Condicionado General</label>
              <Select
                id="condicionado_general"
                name="condicionado_general"
                className="custom-policy-select"
                isClearable
                isDisabled
                value={condicionadoGeneral}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="productos">Producto</label>
              <Select
                id="producto"
                name="producto"
                className="custom-policy-select"
                isDisabled
                value={producto}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="tipo_cobertura">Tipo de Cobertura</label>
              <Select
                id="tipo_cobertura"
                name="tipo_cobertura"
                className="custom-policy-select"
                isDisabled
                value={tipoCobertura}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="tipo_cobertura">Tipología de Ejecución</label>
              <Select
                id="tipologia_ejecucion"
                name="tipologia_ejecucion"
                className="custom-policy-select"
                isDisabled
                value={tipologiaEjecucion}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="tipos_intermediario">Tipo de Intermediario</label>
              <Select
                id="tipos_intermediario"
                name="tipo_intermediario"
                className="custom-policy-select"
                isDisabled
                value={tipoIntermediario}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="intermediario">Intermediario</label>
              <Select
                id="intermediario"
                name="intermediario"
                className="custom-policy-select"
                isDisabled
                value={intermediario}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="tipo_institucion">Tipo de Institución</label>
              <Select
                id="tipo_institucion"
                name="tipo_institucion"
                className="custom-policy-select"
                isDisabled
                value={tipoInstitucion}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="actividad_asegurada">Actividad Asegurada</label>
              <Select
                id="actividad_asegurada"
                name="actividad_asegurada"
                className="custom-policy-select"
                isDisabled
                value={actividadAsegurada}
              />
            </FormGroup>
          </div>
        </FormSection>
        {/* Beneficiarios */}
        <Beneficiarios
          isEditable={isEditable && !isDisabledByProfile()}
          beneficiario={beneficiario}
          setBeneficiario={setBeneficiario}
          participacion={participacion}
          setParticipacion={setParticipacion}
          tableDataBeneficiario={tableDataBeneficiario}
          setTableDataBeneficiario={setTableDataBeneficiario}
          isParticipacionFulled={isParticipacionFulled}
        />
        {/* Unidades */}
        <InsureUnits
          isEditable={isEditable && !isDisabledByProfile()}
          unitOptions={unitOptions}
          selectedUnit={selectedUnit}
          detail={detail}
          tableData={tableData}
          handleEditUnit={handleEditUnit}
          handleDeleteUnit={handleDeleteUnit}
          handleOnChangeSelect={handleOnChangeSelect}
          handleOnChangeDetail={handleOnChangeDetail}
          handleCleanUnits={handleCleanUnits}
          handleAddTableData={handleAddTableData}
        />
        {!activo && (
          <Col md={{ offset: 8 }}>
            <div
              style={{
                display: 'grid',
                gridGap: '10px',
                gridTemplateColumns: '1fr 1fr',
                margin: '30px 0 20px',
              }}
            >
              <Button
                style={{
                  background: 'transparent',
                  border: '1px solid #F98724',
                  color: '#F98724',
                  fontSize: '14px',
                  width: '100%',
                }}
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                style={{
                  fontSize: '14px',
                }}
                color="orsan"
                onClick={() => setSavePolicyModalWarning(true)}
                disabled={!isPolicyFormValid() && !isDisabledByProfile()}
              >
                Guardar Póliza
              </Button>
            </div>
          </Col>
        )}
      </Col>
      <WarningModal
        loading={loading}
        isOpen={savePolicyModalWarning}
        toggle={() => setSavePolicyModalWarning(false)}
        handleOk={() => handleCreate()}
        okBtnText="Aceptar"
        confirmMsg="¿Está seguro que quiere guardar esta póliza?"
      />
    </div>
  )
}

ProjectPolicyFormView.propTypes = {
  proyecto: PropTypes.shape({
    id: PropTypes.number,
    actividad: PropTypes.object,
    organizacion: PropTypes.object,
  }),
  idPoliza: PropTypes.string,
  moneda: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
  }).isRequired,
  condicionadoGeneral: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
  }).isRequired,
  producto: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
  }).isRequired,
  tipoCobertura: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
  }).isRequired,
  tipologiaEjecucion: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
  }).isRequired,
  tipoInstitucion: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
  }).isRequired,
  actividadAsegurada: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
  }).isRequired,
  tipoIntermediario: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
  }).isRequired,
  intermediario: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
  }).isRequired,
  isEditable: PropTypes.bool.isRequired,
  setIsEditable: PropTypes.func.isRequired,
  activo: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dataEdition: PropTypes.shape({
    actividad_id: PropTypes.number,
    adjuntos_ids: PropTypes.array,
    base: PropTypes.object,
    beneficiarios: PropTypes.array,
    emision: PropTypes.string,
    emitido: PropTypes.bool,
    estado: PropTypes.string,
    glosa: PropTypes.string,
    has_endoso: PropTypes.bool,
    id: PropTypes.number,
    is_canceled: PropTypes.bool,
    proyecto_garantia: PropTypes.shape({
      id: PropTypes.number,
    }),
    unidades: PropTypes.array,
  }),
  policies: PropTypes.shape({
    endoso_type_list: PropTypes.array,
    endosoList: PropTypes.array,
    error: PropTypes.object,
    errorEndosoMessage: PropTypes.bool,
    loading: PropTypes.bool,
    selectedPolicy: PropTypes.shape({
      estado: PropTypes.string,
      emitido: PropTypes.bool,
      base: PropTypes.shape({
        intermediario: PropTypes.object,
      }),
      balance: PropTypes.object,
    }),
    successEndosoMessage: PropTypes.bool,
  }).isRequired,
  dispatchCleanEndoso: PropTypes.func.isRequired,
  dispatchCleanPolicy: PropTypes.func.isRequired,
  dispatchClearPublishEndosoError: PropTypes.func.isRequired,
  dispatchDeleteData: PropTypes.func.isRequired,
  dispatchGetData: PropTypes.func.isRequired,
  dispatchPatchData: PropTypes.func.isRequired,
  dispatchPostData: PropTypes.func.isRequired,
  dispatchResetError: PropTypes.func.isRequired,
  updatePolizaDataEdition: PropTypes.func.isRequired,
}

ProjectPolicyFormView.defaultProps = {
  dataEdition: {},
  idPoliza: null,
  proyecto: null,
}

const mapStateToProps = (state) => ({
  policies: state.policies,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchCleanEndoso: (data) => dispatch(cleanEndoso(data)),
  dispatchCleanPolicy: (data) => dispatch(cleanPolicy(data)),
  dispatchClearPublishEndosoError: () => dispatch({ type: CLEAR_PUBLISH_ENDOSO_ERROR }),
  dispatchDeleteData: (data) => dispatch(deleteData(data)),
  dispatchGetData: (data) => dispatch(getData(data)),
  dispatchPatchData: (data) => dispatch(patchData(data)),
  dispatchPostData: (data) => dispatch(postData(data)),
  dispatchResetError: (data) => dispatch(resetError(data)),
});

const ProjectPolicyForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectPolicyFormView);

export default ProjectPolicyForm;
