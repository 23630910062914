import { PROFILES } from 'CONST';

// archivo con los permisos de lectura/escritura
export const CREDIT_POLICY_WRITE = [
  PROFILES.ceo,
  PROFILES.comercialCredito,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
];

export const CREDIT_POLICY_READ = [
  PROFILES.ceo,
  PROFILES.consultaEspecial,
  PROFILES.comercialCredito,
  PROFILES.riesgoCredito,
  PROFILES.fiscalia,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.recuperoSiniestros,
  PROFILES.consultaBasico,
];

export const POLICY_WRITE = [
  PROFILES.ceo,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteOperaciones,
];

export const EMIT_WRITE = [
  PROFILES.ceo,
  PROFILES.comercialGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteOperaciones,
  PROFILES.externoOrganizacion,
  PROFILES.externoProyecto,
];

export const EMIT_READ = [
  PROFILES.ceo,
  PROFILES.consultaEspecial,
  PROFILES.comercialGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
  PROFILES.fiscalia,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.recuperoSiniestros,
  PROFILES.consultaBasico,
];

export const POLICY_READ = [
  PROFILES.ceo,
  PROFILES.consultaEspecial,
  PROFILES.comercialGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
  PROFILES.fiscalia,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.recuperoSiniestros,
  PROFILES.consultaBasico,
];

export const GENERAL_CONDITIONED_WRITE = [
  PROFILES.ceo,
  PROFILES.gerenteGarantia,
  PROFILES.fiscalia,
  PROFILES.actuariado,
  PROFILES.riesgoOperacional,
];

export const GENERAL_CONDITIONED_READ = [
  PROFILES.ceo,
  PROFILES.comercialGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
  PROFILES.comercialCredito,
  PROFILES.riesgoCredito,
  PROFILES.fiscalia,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.recuperoSiniestros,
];

export const ENDOSO_COMMERCIAL_WRITE = [
  PROFILES.ceo,
  PROFILES.comercialGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteOperaciones,
];

export const ENDOSO_COMMERCIAL_READ = [
  PROFILES.ceo,
  PROFILES.consultaEspecial,
  PROFILES.comercialGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
  PROFILES.fiscalia,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.recuperoSiniestros,
  PROFILES.consultaBasico,
];

export const ENDOSO_OTHERS_WRITE = [
  PROFILES.ceo,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteOperaciones,
];

export const ENDOSO_OTHERS_READ = [
  PROFILES.ceo,
  PROFILES.consultaEspecial,
  PROFILES.comercialGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
  PROFILES.fiscalia,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.recuperoSiniestros,
  PROFILES.consultaBasico,
];

export const ALL = [
  PROFILES.ceo,
  PROFILES.consultaEspecial,
  PROFILES.comercialGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
  PROFILES.comercialCredito,
  PROFILES.riesgoCredito,
  PROFILES.fiscalia,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.recuperoSiniestros,
  PROFILES.consultaBasico,
  PROFILES.administrador,
];

export const ENDOSO_COMMERCIAL = ['tp-eg-cambio-comision', 'tp-eg-cambio-intermediario'];

export const ENDOSO_OTHERS = [
  'tp-eg-anulacion',
  'tp-eg-cambio-beneficiario',
  'tp-eg-cambio-glosa',
  'tp-eg-cambio-suma',
  'tp-eg-cancelacion',
  'tp-eg-prorroga',
  'tp-eg-reduccion',
  'tp-eg-reinstalacion',
];

export const ENDOSO_EMISION = ['tp-eg-emision'];

export const ENDOSO_CREDITO_WRITE = CREDIT_POLICY_WRITE;
export const ENDOSO_CREDITO_READ = CREDIT_POLICY_READ;

export const ENDOSO_CREDITO_EMITIR = [
  PROFILES.ceo,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
];

export const CREDITO_NAVBAR = [
  PROFILES.ceo,
  PROFILES.consultaEspecial,
  PROFILES.comercialCredito,
  PROFILES.riesgoCredito,
  PROFILES.fiscalia,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.recuperoSiniestros,
  PROFILES.consultaBasico,
];

export const PERFILES_ESCRITURA_OPERACIONES_CONDICIONADOS = [PROFILES.riesgoOperacional];
export const PERFILES_ESCRITURA_COMERCIAL_CONDICIONADOS = [];
export const PERFILES_ESCRITURA_CEO_CONDICIONADOS = [PROFILES.ceo];
export const PERFIL_ESCRITURA_OTRO_CONDICIONADOS = [
  PROFILES.gerenteGarantia,
  PROFILES.fiscalia,
  PROFILES.actuariado,
];

export const PERFILES_ESCRITURA_OPERACIONES_GARANTIA = [PROFILES.gerenteOperaciones];
export const PERFILES_ESCRITURA_GARANTIA_GARANTIA = [
  PROFILES.comercialGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
];
export const PERFILES_ESCRITURA_CEO_GARANTIA = [PROFILES.ceo];
export const PERFIL_ESCRITURA_OTRO_GARANTIA = [];

// Tipos de escritura
export const PERFILES_INICIO_VIGENCIA_COMERCIAL = [
  PROFILES.comercialCredito,
  PROFILES.analistaOperaciones,
];
export const PERFILES_INICIO_VIGENCIA_OPERACIONES = [
  PROFILES.gerenteOperaciones,
  PROFILES.riesgoOperacional,
];
export const PERFILES_INICIO_VIGENCIA_CEO = [PROFILES.ceo];
export const INDEMNIZACION_MAXIMA_VECES_CEO = [PROFILES.ceo];

export const CP_COBERTURA_ESPECIAL_CEO = [PROFILES.ceo];
export const CP_COBERTURA_ESPECIAL_COMERCIAL = [
  PROFILES.comercialCredito,
  PROFILES.analistaOperaciones,
];
export const CP_COBERTURA_ESPECIAL_OPERACIONES = [
  PROFILES.riesgoOperacional,
  PROFILES.gerenteOperaciones,
];

export const CP_COBERTURA_OC_SIMPLE_CEO = [PROFILES.ceo];
export const CP_COBERTURA_OC_SIMPLE_OPERACIONES = [
  PROFILES.riesgoOperacional,
  PROFILES.gerenteOperaciones,
  PROFILES.actuariado,
];

export const CP_CONTROVERSIAS_COMERCIALES_CEO = [PROFILES.ceo];
export const CP_CONTROVERSIAS_COMERCIALES_OPERACIONES = [
  PROFILES.riesgoOperacional,
  PROFILES.gerenteOperaciones,
];

export const GARANTIA_WRITE = [
  PROFILES.ceo,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
];

export const GARANTIA_READ = [
  PROFILES.ceo,
  PROFILES.comercialGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
];

// Venta en Verde

export const EXTERNO_VV = [
  PROFILES.externoOrganizacion,
  PROFILES.externoProyecto,
]

export const PROYECTO_VV_WRITE = [
  PROFILES.ceo,
  PROFILES.comercialGarantia,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
  PROFILES.gerenteOperaciones,
];

export const PROYECTO_VV_READ = [
  ...PROYECTO_VV_WRITE,
  ...EXTERNO_VV,
  PROFILES.consultaEspecial,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.fiscalia,
  PROFILES.actuariado,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.recuperoSiniestros,
  PROFILES.consultaBasico,
];

export const POLIZA_VV_WRITE = [
  ...EXTERNO_VV,
  PROFILES.ceo,
  PROFILES.comercialGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteOperaciones,
];

export const POLIZA_VV_READ = [
  ...POLIZA_VV_WRITE,
  PROFILES.consultaEspecial,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
  PROFILES.fiscalia,
  PROFILES.actuariado,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.recuperoSiniestros,
  PROFILES.consultaBasico,
];
