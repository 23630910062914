import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { toggleNoParameterizedConditionSelected } from '../../../redux-store/actions/creditPolicyActions';
import { clienteEndosoType, parameterizedConditionType } from '../../../redux-store/types';
import ParameterizedCondition from './helpers/ParameterizedCondition';
import { CP_SET_SECTION_VALIDATION } from '../../../redux-store/constants/action-types';
import { isValidParameterizedConditionsStep } from '../../../helpers/validation';
import VistoBuenoSection from './helpers/VistoBuenoSection';
import { CP_FS_CLAUSULAS } from '../../../CONST';

class CreditPolicyParameterizedConditionsStep extends Component {
  state = {
    validateInputs: false,
  };

  componentDidUpdate() {
    const {
      dispatch,
      creditPolicy: {
        form,
        form: { sections },
      },
    } = this.props;
    const isValid = isValidParameterizedConditionsStep(form);
    if (isValid !== sections[CP_FS_CLAUSULAS].valid) {
      dispatch({
        type: CP_SET_SECTION_VALIDATION,
        payload: { stepName: CP_FS_CLAUSULAS, isValid },
      });
    }
  }

  setValidateInputs = (validateInputs) => this.setState({ validateInputs });

  render() {
    const {
      dispatch,
      creditPolicy: {
        form,
        form: {
          data: { ramoFecu },
        },
      },
      readOnly,
    } = this.props;
    const { validateInputs } = this.state;
    const noneSelected = form.data.endoso.noCondicionesParametrizadas;
    const parameterizedConditionsList = form.condicionesParametrizadasList.filter((cond) =>
      cond.ramos.includes(ramoFecu ? ramoFecu.value.id : ''),
    );

    const selectedConditions = noneSelected
      ? []
      : parameterizedConditionsList.filter((cond) => cond.selected);
    const somethingSelected = noneSelected || selectedConditions.length > 0;

    const moneda = form.data.moneda ? form.data.moneda.value.nombre_corto : '';
    return (
      <>
        {parameterizedConditionsList.map((node) => (
          <ParameterizedCondition
            condition={node}
            disabled={noneSelected || readOnly}
            key={node.id}
            dispatch={dispatch}
            moneda={moneda}
            validate={validateInputs}
          />
        ))}
        <div style={{ marginTop: '60px' }}>
          <label
            htmlFor="no-agregar-clausulas"
            style={{ fontSize: '16px', fontWeight: 'normal', marginBottom: 0 }}
          >
            <input
              id="no-agregar-clausulas"
              type="checkbox"
              style={{ marginRight: '20px' }}
              checked={noneSelected}
              onChange={() => dispatch(toggleNoParameterizedConditionSelected(!noneSelected))}
              disabled={readOnly}
            />
            No agregar cláusulas parametrizadas
          </label>
        </div>
        <VistoBuenoSection sectionName={CP_FS_CLAUSULAS} validate={validateInputs} />
        <div className="cp-form-feedback">
          <p>
            {validateInputs &&
              !somethingSelected &&
              '*Es necesario seleccionar al menos una alternativa. Si no desea agregar Cláusulas' +
                ' Parametrizadas, seleccione dicha opción.'}
            {validateInputs &&
              !isValidParameterizedConditionsStep(form) &&
              '*Existen errores en el formulario. Por favor, revise los campos marcados en rojo.'}
          </p>
        </div>
      </>
    );
  }
}

CreditPolicyParameterizedConditionsStep.propTypes = {
  dispatch: PropTypes.func.isRequired,
  creditPolicy: PropTypes.shape({
    form: PropTypes.shape({
      sections: PropTypes.object,
      condicionesParametrizadasList: PropTypes.arrayOf(parameterizedConditionType).isRequired,
      data: PropTypes.shape({
        ramoFecu: PropTypes.object,
        endoso: PropTypes.shape({
          clientesEndoso: PropTypes.arrayOf(clienteEndosoType),
          clientesEndosoSelected: PropTypes.bool,
          noCondicionesParametrizadas: PropTypes.bool,
        }),
        moneda: PropTypes.object,
      }),
    }),
  }).isRequired,
  readOnly: PropTypes.bool,
};

CreditPolicyParameterizedConditionsStep.defaultProps = {
  readOnly: false,
};

export default CreditPolicyParameterizedConditionsStep;
