import React from 'react';
import PropTypes from 'prop-types';
import { BASE_URL, API } from 'CONST';
import { Label, Col, Row } from 'reactstrap';
import NewFileTable from 'components/shared/NewFileTable';

const SubSectionAdjuntos = ({
  newAntecedentes,
  oldAntecedentes,
  setNewAntecedentes,
  setOldAntecedentes,
  removeAdjunto,
  editableSection,
  dataAdjuntos,
  setIsFormSaved,
}) => {
  return (
    <div>
      {Object.keys(dataAdjuntos).map((key) => {
        return (
          <div key={key}>
            <Row>
              <Col>
                <Label>{dataAdjuntos[key].label}</Label>
              </Col>
              <Col>
                <NewFileTable
                  handleAdd={(file) => {
                    setIsFormSaved(false);
                    setNewAntecedentes((prevState) => ({
                      ...prevState,
                      [key]: [...prevState[key], file],
                    }));
                  }}
                  handleDeleteNew={(file) => {
                    setIsFormSaved(false);
                    setNewAntecedentes((prevState) => ({
                      ...prevState,
                      [key]: prevState[key].filter((doc) => doc.uuid !== file.uuid),
                    }));
                  }}
                  handleDelete={(event) => {
                    setIsFormSaved(false);
                    removeAdjunto(event.id, key);
                    setOldAntecedentes((prevState) => ({
                      ...prevState,
                      [dataAdjuntos[key].adjunto_docs]: prevState[
                        dataAdjuntos[key].adjunto_docs
                      ].filter((doc) => doc.id !== event.id),
                    }));
                  }}
                  uploadUrl={`${BASE_URL}${API.upload}/`}
                  downloadUrl={`${BASE_URL}${API.download}/`}
                  newFiles={newAntecedentes[key]}
                  fileList={oldAntecedentes[dataAdjuntos[key].adjunto_docs]}
                  individualMaxFileSize={13}
                  globalMaxFileSize={20}
                  loginIdToken=""
                  readOnly={!editableSection}
                />
              </Col>
            </Row>
            <br />
          </div>
        );
      })}
    </div>
  );
};

SubSectionAdjuntos.propTypes = {
  newAntecedentes: PropTypes.shape().isRequired,
  oldAntecedentes: PropTypes.shape().isRequired,
  setNewAntecedentes: PropTypes.func.isRequired,
  setOldAntecedentes: PropTypes.func.isRequired,
  removeAdjunto: PropTypes.func.isRequired,
  editableSection: PropTypes.bool,
  dataAdjuntos: PropTypes.shape().isRequired,
  setIsFormSaved: PropTypes.func.isRequired,
};

SubSectionAdjuntos.defaultProps = {
  editableSection: false,
};

export default SubSectionAdjuntos;
