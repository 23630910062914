import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faExclamation, faEye } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import {
  cellDateOperation,
  cellDobleRowOperation,
  cellNumOperation,
  cellOperation,
  headerDoubleRowOperation,
  headerOperation,
} from '../../helpers/tables';
import BaseTable from '../UI/BaseTable';
import DeprecatedWarningModal from '../UI/DeprecatedWarningModal';
import FilterSelect from '../shared/FilterSelect';
import {
  CANCELED_LABEL,
  CONSTRUCTION_LABEL,
  DRAFT_LABEL,
  EMITTED_LABEL,
  FRONT_DF_1,
} from '../../CONST';
import { fdate } from '../../helpers/helpers';
import isUserHasProfile from '../../helpers/profileValidator';
import { POLICY_WRITE } from '../../helpers/profilePermission';

const NO_FILTERED_ID = -1;

const renderPolicyIsVigente = (balance) => {
  if (balance) {
    const date = moment(balance.termino_vigencia);
    const hoy = moment();
    return date.isBefore(hoy, 'date') ? 'No' : 'Sí';
  }
  return '';
};

const PolicyListTable = (props) => {
  const {
    policies,
    handleDelete,
    loading,
    onFetchData,
    onFilteredChange,
    // eslint-disable-next-line camelcase
    tipos_cobertura,
    deleteWarningOpen,
    handleDeleteWarning,
    deletePolicyId,
  } = props;
  const { listData, tiposMoneda } = policies;
  const columns = [
    {
      Header: headerOperation('Código'),
      id: 'base__nemotecnico__icontains',
      accessor: (i) => cellOperation(i.base.nemotecnico),
      width: 115,
      fixed: 'left',
    },
    {
      Header: headerOperation('Afianzado'),
      id: 'afianzado',
      accessor: (i) => cellDobleRowOperation(i.afianzado.rut, i.afianzado.nombre),
      minWidth: 110,
      sortable: false,
      fixed: 'left',
    },
    {
      Header: headerOperation('Asegurado'),
      id: 'base__asegurado',
      accessor: (i) => cellDobleRowOperation(i.base.asegurado.rut, i.base.asegurado.nombre),
      minWidth: 110,
      sortable: false,
      fixed: 'left',
    },
    {
      Header: headerOperation('Cobertura'),
      id: 'base__tipo_cobertura__id',
      accessor: (i) => cellOperation(i.base.tipo_cobertura.descripcion, 'left'),
      Filter: (obj) => (
        <FilterSelect
          filter={obj.filter}
          defaultOption={{ label: 'Todos', value: NO_FILTERED_ID }}
          onFilterChange={obj.onChange}
          options={tipos_cobertura.map((elem) => ({ label: elem.descripcion, value: elem.id }))}
        />
      ),
      minWidth: 115,
      fixed: 'left',
    },
    {
      Header: headerOperation('Moneda'),
      id: 'base__moneda_asegurada__id',
      accessor: (i) => cellOperation(i.base.moneda_asegurada.nombre_corto),
      Filter: (obj) => (
        <FilterSelect
          onFilterChange={obj.onChange}
          filter={obj.filter}
          options={tiposMoneda.map((elem) => ({
            label: elem.value.nombre_corto,
            value: elem.value.id,
          }))}
          defaultOption={{ label: 'Todas', value: NO_FILTERED_ID }}
        />
      ),
      minWidth: 110,
    },
    {
      Header: headerDoubleRowOperation('Monto', 'Garantizado'),
      id: 'monto_garantizado',
      accessor: (i) => cellNumOperation(i.balance_cobertura_amt),
      minWidth: 100,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Prima'),
      id: 'prima__icontains',
      accessor: (i) => cellNumOperation(i.balance_prima_neta_amt),
      minWidth: 58,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Emisión'),
      id: 'emision',
      accessor: (i) => cellDateOperation(i.emision),
      width: 100,
    },
    {
      Header: headerOperation('Inicio Vigencia'),
      id: 'balancegarantia__inicio_vigencia',
      accessor: (i) =>
        cellOperation(i.balance ? fdate(i.balance.inicio_vigencia, FRONT_DF_1) : '-'),
      width: 100,
    },
    {
      Header: headerOperation('Término Vigencia'),
      id: 'balancegarantia__termino_vigencia',
      accessor: (i) =>
        cellOperation(i.balance ? fdate(i.balance.termino_vigencia, FRONT_DF_1) : '-'),
      width: 100,
    },
    {
      Header: headerOperation('Vigente'),
      id: 'vigentes',
      accessor: (i) => cellOperation(renderPolicyIsVigente(i.balance)),
      Filter: (obj) => (
        <FilterSelect
          onFilterChange={obj.onChange}
          filter={obj.filter}
          options={[{ label: 'Sí', value: '1' }, { label: 'No', value: '0' }]}
          defaultOption={{ label: '', value: NO_FILTERED_ID }}
        />
      ),
      width: 100,
      sortable: false,
    },
    {
      Header: headerOperation('Estado'),
      id: 'estado__icontains',
      accessor: (i) => {
        let alertClass = 'alert-success';
        if (i.estado === DRAFT_LABEL) {
          alertClass = 'alert-primary';
        } else if (i.estado === CONSTRUCTION_LABEL) {
          alertClass = 'alert-warning';
        } else if (i.estado === CANCELED_LABEL) {
          alertClass = 'alert-danger';
        }
        return cellOperation(
          <div className={`alert ${alertClass} text-center`} role="alert">
            {i.estado}
          </div>,
        );
      },
      Filter: (obj) => (
        <FilterSelect
          onFilterChange={obj.onChange}
          filter={obj.filter}
          options={[
            { label: EMITTED_LABEL, value: 'emitida' },
            { label: DRAFT_LABEL, value: 'borrador' },
            { label: CONSTRUCTION_LABEL, value: 'construccion' },
            { label: CANCELED_LABEL, value: 'cancelada' },
          ]}
          defaultOption={{ label: 'Todos', value: NO_FILTERED_ID }}
        />
      ),
      minWidth: 120,
    },
    {
      Header: headerOperation('Acciones'),
      id: 'actions',
      accessor: (i) => (
        <div
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '5px' }}
          className="table-cell"
        >
          <div style={{ textAlign: 'center' }}>
            {i.emitido ? (
              <></>
            ) : (
              <>
                {deleteWarningOpen && i.id === deletePolicyId ? (
                  <div className="warning-button">
                    <FontAwesomeIcon icon={faExclamation} />
                  </div>
                ) : (
                  isUserHasProfile(POLICY_WRITE) && (
                    <button
                      type="button"
                      className={`table-link policy ${
                        i.estado !== DRAFT_LABEL && i.estado !== CONSTRUCTION_LABEL
                          ? 'disabled'
                          : ''
                      }`}
                      onClick={() => handleDeleteWarning(i.id)}
                      title="eliminar"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  )
                )}
              </>
            )}
          </div>
          <div style={{ textAlign: 'left' }} title="editar">
            <Link to={`/guarantee/policies/edit/${i.id}`} className="table-link policy">
              <FontAwesomeIcon icon={isUserHasProfile(POLICY_WRITE) ? faEdit : faEye} />
            </Link>
          </div>
        </div>
      ),
      filterable: false,
      sortable: false,
      minWidth: 88,
    },
  ];
  return (
    <div>
      <div style={{ margin: '0 0 10px 0' }} className="policy-list-table">
        <BaseTable
          columns={columns}
          data={listData.data}
          filterable
          loading={loading}
          onFetchData={onFetchData}
          onFilteredChange={onFilteredChange}
          pages={listData.pages}
          resultCount={listData.count}
          showPagination
          hasFixedColumns
          style={{ height: '63vh' }}
        />
      </div>
      <DeprecatedWarningModal
        handleModal={handleDeleteWarning}
        handleSubmit={handleDelete}
        objectId={deletePolicyId}
        modalOpen={deleteWarningOpen}
        warningMessage="Está a un paso de eliminar una Póliza"
        confirmText="Eliminar Póliza"
      />
    </div>
  );
};

PolicyListTable.propTypes = {
  deletePolicyId: PropTypes.number,
  deleteWarningOpen: PropTypes.bool.isRequired,
  handleDeleteWarning: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onFetchData: PropTypes.func.isRequired,
  onFilteredChange: PropTypes.func.isRequired,
  policies: PropTypes.shape({
    listData: PropTypes.shape({
      data: PropTypes.array,
      pages: PropTypes.number,
      count: PropTypes.number,
    }),
    tiposMoneda: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.shape({
          nombre_corto: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
  tipos_cobertura: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      descripcion: PropTypes.string,
    }),
  ).isRequired,
};

PolicyListTable.defaultProps = {
  deletePolicyId: null,
};

const mapStateToProps = (state) => ({
  tipos_cobertura: state.conditioned.tipos_cobertura,
  policies: state.policies,
});

export default connect(mapStateToProps)(PolicyListTable);
