/* eslint-disable camelcase,jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import Datetime from 'react-datetime';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { FormGroup, FormText } from 'reactstrap';
import { getData } from '../../redux-store/actions';
import { GET_ALIASES } from '../../redux-store/constants/action-types';
import ModalForm from '../UI/ModalForm';
import { fdate, ivaCalculation, primaBrutaCalculation, areClose } from '../../helpers/helpers';
import {
  API,
  BACKEND_DF,
  BASE_URL,
  ENDOSO_ANULACION,
  ENDOSO_CAMBIO_BENEFICIARIO,
  ENDOSO_CAMBIO_COMISION,
  ENDOSO_CAMBIO_INTERMEDIARIO,
  ENDOSO_CAMBIO_GLOSA,
  ENDOSO_CAMBIO_SUMA,
  ENDOSO_CANCELACION,
  ENDOSO_EMISION,
  ENDOSO_PRORROGA,
  ENDOSO_REDUCCION,
  ENDOSO_REINSTALACION,
  FRONT_DF_1,
  ROL_BENEFICIARIO_GARANTIA,
  TIPOS_COBERTURA_SIN_BENEFICIARIO,
  ROL_INTERMEDIARIO_GARANTIA,
  TIPO_INTERMEDIARIO_DIRECTO_ID,
} from '../../CONST';
import isUserHasProfile from '../../helpers/profileValidator';
import {
  PERFILES_ESCRITURA_OPERACIONES_GARANTIA,
  PERFILES_ESCRITURA_GARANTIA_GARANTIA,
  PERFILES_ESCRITURA_CEO_GARANTIA,
  PERFIL_ESCRITURA_OTRO_GARANTIA,
} from '../../helpers/profilePermission';

import { loadClientsOptions } from '../../helpers/clients';
import { isIntermediarioDirecto } from '../../helpers/validation';
import PlanPagoVentaEnVerde from '../projectPoliciesComponents/PlanPagoVentaEnVerde';

const moment = require('moment');

class EndosoForm extends Component {
  constructor(props) {
    super(props);
    const { balance, activeModal } = this.props;
    this.balance = !balance.length ? null : balance[0];
    this.tipoEndoso = activeModal.tipo_endoso;
    this.state = {
      fecha_emision: moment().format(FRONT_DF_1),
      inicio_vigencia: moment().format(FRONT_DF_1),
      inicio_endoso: moment().format(FRONT_DF_1),
      termino_vigencia: moment().add(1, 'years').subtract(1, 'days').format(FRONT_DF_1),
      comision: undefined,
      beneficiario: undefined,
      beneficiario_alias: '',
      beneficiario_search: undefined,
      tipo_intermediario: undefined,
      intermediario: undefined,
      intermediario_search: undefined,
      glosa: '',
      glosa_endoso: '',
      iva: 0,
      monto_garantizado: undefined,
      prima_bruta: 0,
      prima_exenta: 0,
      prima_neta: undefined,
      awaitingResponse: false,
      numCuotas: null,
      cuotasPlan: [],
    };
    this.isDisabled = this.isDisabled.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePrimaNeta = this.handlePrimaNeta.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.setInitialDate = this.setInitialDate.bind(this);
    this.setInitialEndosoDate = this.setInitialEndosoDate.bind(this);
    this.setFinalDate = this.setFinalDate.bind(this);
    this.submit = this.submit.bind(this);
    this.calculatedPrimaNeta = this.calculatedPrimaNeta.bind(this);
    this.isCuotasSumEqualToMontoGarantizado = this.isCuotasSumEqualToMontoGarantizado.bind(this);
    this.checkInvalidRangeDate = this.checkInvalidRangeDate.bind(this);
    this.checkInvalidFirstCuota = this.checkInvalidFirstCuota.bind(this);
  }

  componentDidMount() {
    const {
      activeModal,
      edit,
      lastTerminoVigencia,
      lastCancelacion,
      tipoIntermediarioList,
      balance,
      policy,
      ventaEnVerde,
    } = this.props;
    const today = moment().format(BACKEND_DF);
    if (edit) {
      const prima_neta = activeModal.cambio_prima_neta_amt;
      this.setState({
        fecha_emision: fdate(activeModal.emision, FRONT_DF_1),
        inicio_vigencia: fdate(activeModal.inicio_vigencia, FRONT_DF_1),
        inicio_endoso: fdate(activeModal.inicio_endoso, FRONT_DF_1),
        termino_vigencia: fdate(activeModal.termino_vigencia, FRONT_DF_1),
        comision:
          activeModal.intermediario ||
          (balance && balance[0].intermediario) ||
          (this.inTypes([ENDOSO_EMISION]) && policy.base.intermediario)
            ? activeModal.cambio_comision_pct
            : 0,
        glosa: activeModal.glosa || '',
        glosa_endoso: activeModal.glosa_endoso || '',
        iva: ivaCalculation(prima_neta),
        monto_garantizado: activeModal.cambio_cobertura_amt,
        prima_bruta: primaBrutaCalculation(prima_neta),
        prima_exenta: 0,
        prima_neta,
      });
      if (this.inTypes([ENDOSO_EMISION, ENDOSO_CAMBIO_BENEFICIARIO])) {
        const saved_beneficiario = activeModal.beneficiario
          ? `${activeModal.beneficiario.rut} - ${activeModal.beneficiario.nombre}`
          : undefined;

        this.setState({
          beneficiario: { label: saved_beneficiario, value: activeModal.beneficiario },
          beneficiario_alias: activeModal.beneficiario_alias
            ? {
                label: activeModal.beneficiario_alias.alias,
                value: activeModal.beneficiario_alias.id,
              }
            : '',
          cuotasPlan: activeModal.plan_pago ? activeModal.plan_pago.cuotas_plan : [],
          numCuotas: activeModal.plan_pago ? activeModal.plan_pago.numero_cuotas : null,
        });
      }
      if (this.inTypes([ENDOSO_CAMBIO_INTERMEDIARIO])) {
        const data_intermediario =
          activeModal.intermediario &&
          activeModal.tipo_intermediario !== TIPO_INTERMEDIARIO_DIRECTO_ID
            ? {
                label: `${activeModal.intermediario.rut} - ${activeModal.intermediario.nombre}`,
                value: { id: activeModal.intermediario.id },
              }
            : undefined;

        this.setState({
          intermediario: data_intermediario,
          tipo_intermediario: tipoIntermediarioList.find(
            (node) => node.value.id === activeModal.tipo_intermediario,
          ),
        });
      }
    } else {
      const stateForm = { ...this.state };
      if (ventaEnVerde && policy.proyecto_garantia) {
        stateForm.inicio_vigencia = moment(fdate(policy.proyecto_garantia.inicio_vigencia), FRONT_DF_1);
        stateForm.termino_vigencia = moment(fdate(policy.proyecto_garantia.termino_vigencia), FRONT_DF_1);
      }
      if (this.balance) {
        stateForm.inicio_vigencia = fdate(this.balance.inicio_vigencia || today, FRONT_DF_1);
        stateForm.inicio_endoso = fdate(this.balance.inicio_vigencia || today, FRONT_DF_1);
        stateForm.termino_vigencia = fdate(this.balance.termino_vigencia || today, FRONT_DF_1);
        stateForm.glosa = this.balance.glosa || '';
        stateForm.monto_garantizado = this.balance.balance_cobertura_amt;
      }
      if (this.tipoEndoso.nemotecnico === ENDOSO_PRORROGA) {
        stateForm.inicio_vigencia = moment(this.balance ? this.balance.termino_vigencia : today)
          .add(1, 'days')
          .format(FRONT_DF_1);
        stateForm.comision = 0;
        stateForm.monto_garantizado = 0;
        stateForm.inicio_endoso = stateForm.inicio_vigencia;
      }
      if (this.tipoEndoso.nemotecnico === ENDOSO_CANCELACION) {
        stateForm.monto_garantizado = this.balance ? this.balance.balance_cobertura_amt * -1 : 0;
        stateForm.comision = 0;
        stateForm.inicio_vigencia = fdate(today, FRONT_DF_1);
      }
      if (this.tipoEndoso.nemotecnico === ENDOSO_ANULACION) {
        stateForm.monto_garantizado = this.balance ? this.balance.balance_cobertura_amt * -1 : 0;
        stateForm.comision = 0;
        const prima_neta = this.balance ? this.balance.balance_prima_neta_amt * -1 : 0;
        stateForm.iva = ivaCalculation(prima_neta);
        stateForm.prima_neta = this.balance ? this.balance.balance_prima_neta_amt * -1 : 0;
        stateForm.prima_bruta = primaBrutaCalculation(prima_neta);
      }
      if (this.tipoEndoso.nemotecnico === ENDOSO_CAMBIO_BENEFICIARIO) {
        stateForm.monto_garantizado = 0;
        stateForm.prima_neta = 0;
        stateForm.comision = 0;
      }
      if (this.tipoEndoso.nemotecnico === ENDOSO_CAMBIO_INTERMEDIARIO) {
        stateForm.monto_garantizado = 0;
        stateForm.prima_neta = 0;
      }
      if (this.tipoEndoso.nemotecnico === ENDOSO_CAMBIO_COMISION) {
        stateForm.monto_garantizado = 0;
        stateForm.prima_neta = 0;
      }
      if (this.tipoEndoso.nemotecnico === ENDOSO_REDUCCION) {
        stateForm.monto_garantizado = 0;
        stateForm.comision = 0;
        stateForm.inicio_vigencia = fdate(today, FRONT_DF_1);
      }
      if (this.tipoEndoso.nemotecnico === ENDOSO_REINSTALACION) {
        stateForm.monto_garantizado = -lastCancelacion.cambio_cobertura_amt;
        stateForm.prima_neta = -lastCancelacion.cambio_prima_neta_amt;
        stateForm.iva = ivaCalculation(stateForm.prima_neta);
        stateForm.prima_bruta = primaBrutaCalculation(stateForm.prima_neta);
        stateForm.comision = -lastCancelacion.cambio_comision_pct;
        stateForm.termino_vigencia = lastTerminoVigencia;
      }
      if (this.tipoEndoso.nemotecnico === ENDOSO_CAMBIO_SUMA) {
        stateForm.comision = 0;
      }
      if (!activeModal.intermediario) {
        stateForm.comision = 0;
      }
      if (ventaEnVerde) {
        stateForm.comision = policy.proyecto_garantia.comision;
        stateForm.prima_neta = 0;
      }
      this.setState(stateForm, () => {
        if (ventaEnVerde) {
          this.calculatedPrimaNeta();
        }
      });
    }
  }

  setInitialDate(date) {
    this.setState(
      { inicio_vigencia: date, inicio_endoso: date },
      () => this.calculatedPrimaNeta()
    );
  }

  setInitialEndosoDate(date) {
    this.setState({ inicio_endoso: date });
  }

  setFinalDate(date) {
    this.setState(
      { termino_vigencia: date },
      () => this.calculatedPrimaNeta()
    );
  }

  inTypes = (validTypes) => validTypes.includes(this.tipoEndoso.nemotecnico);

  isMontoEditable = () => {
    return this.inTypes([ENDOSO_EMISION, ENDOSO_CAMBIO_SUMA]);
  };

  isPrimaNetaEditable = (ventaEnVerde) => {
    // Si es venta en verde, no se puede editar el campo, a menos que sea endoso cancelación.
    return !(ventaEnVerde && !this.inTypes([ENDOSO_CANCELACION])) && this.inTypes([
      ENDOSO_EMISION,
      ENDOSO_PRORROGA,
      ENDOSO_REDUCCION,
      ENDOSO_CAMBIO_SUMA,
      ENDOSO_CANCELACION,
    ]);
  };

  isComisionEditable = () => {
    return this.inTypes([ENDOSO_EMISION, ENDOSO_CAMBIO_COMISION]);
  };

  isAliasDisabled = () => {
    const { aliasList } = this.props;
    return !(aliasList.length > 0);
  };

  isTipoIntermediarioDisabled = () => {
    const { tipoIntermediarioList } = this.props;
    return !(tipoIntermediarioList.length > 0);
  };

  handleBeneficiario = (newValue) => {
    this.setState({
      beneficiario_search: newValue,
    });
  };

  handleTipoIntermediario = (tipo_intermediario) => {
    if (isIntermediarioDirecto(tipo_intermediario) || tipo_intermediario === null) {
      this.setState({
        intermediario: null,
        intermediario_search: '',
      });
    }
    this.setState({
      tipo_intermediario,
    });
  };

  handleIntermediario = (newValue) => {
    this.setState({
      intermediario_search: newValue,
    });
  };

  isValidInicioEndoso = (date) => {
    const { inicio_vigencia, termino_vigencia } = this.state;

    const esCeo = isUserHasProfile(PERFILES_ESCRITURA_CEO_GARANTIA);
    if (esCeo) {
      // en caso de ser CEO no tiene restriccion
      return (
        moment(date, FRONT_DF_1).isSameOrBefore(moment(termino_vigencia, FRONT_DF_1), 'date') &&
        moment(date, FRONT_DF_1).isSameOrAfter(moment(inicio_vigencia, FRONT_DF_1), 'date')
      );
    }

    const esGarantia = isUserHasProfile(PERFILES_ESCRITURA_GARANTIA_GARANTIA);
    const esOperaciones = isUserHasProfile(PERFILES_ESCRITURA_OPERACIONES_GARANTIA);
    const esOtro = isUserHasProfile(PERFIL_ESCRITURA_OTRO_GARANTIA);
    let fechaMinima;

    if (esGarantia) {
      fechaMinima = moment().subtract(180, 'days');
    } else if (esOperaciones) {
      fechaMinima = moment().subtract(1, 'year');
    } else if (esOtro) {
      fechaMinima = moment();
    }

    return (
      moment(date, FRONT_DF_1).isSameOrBefore(moment(termino_vigencia, FRONT_DF_1), 'date') &&
      moment(date, FRONT_DF_1).isSameOrAfter(moment(inicio_vigencia, FRONT_DF_1), 'date') &&
      fechaMinima &&
      moment(date, FRONT_DF_1).isSameOrAfter(fechaMinima, 'date')
    );
  };

  isValidInicioVigencia = (date) => {
    const { termino_vigencia } = this.state;
    const { policy, ventaEnVerde } = this.props;
    // En caso de ser venta en verde, no puede ser menor a la fecha de inicio de vigencia del proyecto.
    if (ventaEnVerde) {
      return (
        moment(date, FRONT_DF_1).isSameOrBefore(moment(termino_vigencia, FRONT_DF_1), 'date') &&
        moment(date, FRONT_DF_1).isSameOrAfter(moment(fdate(policy.proyecto_garantia.inicio_vigencia), FRONT_DF_1), 'date')
      )
    }

    const esCeo = isUserHasProfile(PERFILES_ESCRITURA_CEO_GARANTIA);
    if (esCeo) {
      // En caso de ser CEO no tiene restriccion
      return moment(date, FRONT_DF_1).isBefore(moment(termino_vigencia, FRONT_DF_1), 'date');
    }

    const esGarantia = isUserHasProfile(PERFILES_ESCRITURA_GARANTIA_GARANTIA);
    const esOperaciones = isUserHasProfile(PERFILES_ESCRITURA_OPERACIONES_GARANTIA);
    const esOtro = isUserHasProfile(PERFIL_ESCRITURA_OTRO_GARANTIA);
    let fechaMinima;

    if (esGarantia) {
      fechaMinima = moment().subtract(180, 'days');
    } else if (esOperaciones) {
      fechaMinima = moment().subtract(1, 'year');
    } else if (esOtro) {
      fechaMinima = moment();
    }

    return (
      moment(date, FRONT_DF_1).isSameOrBefore(moment(termino_vigencia, FRONT_DF_1), 'date') &&
      fechaMinima &&
      moment(date, FRONT_DF_1).isSameOrAfter(fechaMinima, 'date')
    );
  };

  isValidTerminoVigencia = (date) => {
    const { inicio_vigencia, fecha_emision } = this.state;
    const { policy, ventaEnVerde } = this.props;
    // En caso de ser venta en verde, no puede ser mayor a la fecha de término de vigencia del proyecto.
    if (ventaEnVerde) {
      return (
        moment(date, FRONT_DF_1).isSameOrAfter(moment(inicio_vigencia, FRONT_DF_1), 'date') &&
        moment(date, FRONT_DF_1).isSameOrAfter(moment(fecha_emision, FRONT_DF_1), 'date') &&
        moment(date, FRONT_DF_1).isSameOrBefore(moment(fdate(policy.proyecto_garantia.termino_vigencia), FRONT_DF_1), 'date')
      )
    }

    return (
      moment(date, FRONT_DF_1).isSameOrAfter(moment(inicio_vigencia, FRONT_DF_1), 'date') &&
      moment(date, FRONT_DF_1).isSameOrAfter(moment(fecha_emision, FRONT_DF_1), 'date')
    )
  };

  calculatedPrimaNeta() {
    const { policy, ventaEnVerde } = this.props;
    const { inicio_vigencia, termino_vigencia, monto_garantizado } = this.state;
    if (ventaEnVerde && monto_garantizado) {
      const start = moment(inicio_vigencia, FRONT_DF_1);
      const end = moment(termino_vigencia, FRONT_DF_1);
      const diff_dias = end.diff(start, 'days');
      const calculated = (policy.proyecto_garantia.tasa_anual / 100) * monto_garantizado * (diff_dias/365)
      if (Math.abs(calculated) > policy.proyecto_garantia.prima_minima_neta) {
        this.handlePrimaNeta(calculated);
      }
      else {
        this.handlePrimaNeta(policy.proyecto_garantia.prima_minima_neta);
      }
    }
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleValueChange(values, name) {
    this.setState({
      [name]: values.floatValue,
    });
  }

  handlePrimaNeta(value) {
    this.setState({
      iva: ivaCalculation(value),
      prima_bruta: primaBrutaCalculation(value),
      prima_neta: value,
    });
  }

  handleSelectChange(option, e) {
    const { dispatchGetData } = this.props;
    this.setState({
      [e.name]: option,
    });
    if (e.name === 'beneficiario') {
      this.setState({
        beneficiario_alias: '',
      });
      dispatchGetData({
        url: `${BASE_URL}${API.alias}?organization_id=${option.value.id}`,
        type: GET_ALIASES,
      });
    }
  }

  isCuotasSumEqualToMontoGarantizado() {
    const {
      monto_garantizado,
      cuotasPlan,
    } = this.state;
    return areClose(monto_garantizado, cuotasPlan.reduce((acc, curr) => acc + curr.monto_uf, 0));
  }

  isDisabled() {
    const {
      inicio_vigencia,
      termino_vigencia,
      beneficiario,
      comision,
      glosa,
      glosa_endoso,
      monto_garantizado,
      prima_neta,
      intermediario,
      tipo_intermediario,
      numCuotas,
      cuotasPlan,
    } = this.state;
    const { policy, ventaEnVerde } = this.props;

    const dates_validation = (
      moment(inicio_vigencia, FRONT_DF_1).isValid() &&
      moment(termino_vigencia, FRONT_DF_1).isValid()
    )

    const plan_pago_validation = (
      !ventaEnVerde ||
      (!this.inTypes([ENDOSO_EMISION])) ||
      (
        this.inTypes([ENDOSO_EMISION]) &&
        numCuotas &&
        numCuotas === cuotasPlan.length &&
        this.isCuotasSumEqualToMontoGarantizado() &&
        !this.checkInvalidRangeDate() &&
        !this.checkInvalidFirstCuota()
      )
    )

    const beneficiario_validation = (
      ventaEnVerde ||
      !this.inTypes([ENDOSO_EMISION, ENDOSO_CAMBIO_BENEFICIARIO]) ||
      beneficiario ||
      TIPOS_COBERTURA_SIN_BENEFICIARIO.includes(policy.base.tipo_cobertura.nemotecnico)
    );

    const intermediarioValidation = (
      !this.inTypes([ENDOSO_CAMBIO_INTERMEDIARIO]) ||
      intermediario ||
      (tipo_intermediario && tipo_intermediario.value.id === TIPO_INTERMEDIARIO_DIRECTO_ID)
    );

    const glosa_validation = (
      this.inTypes([ENDOSO_EMISION, ENDOSO_CAMBIO_GLOSA])
      ? !!glosa
      : !!glosa_endoso
    );
    const cambio_glosa = !!glosa;
    if (this.tipoEndoso.nemotecnico === ENDOSO_CAMBIO_GLOSA) {
      return !cambio_glosa;
    }

    const comision_validation = (comision !== undefined);
    const monto_validation = (monto_garantizado !== undefined);
    const prima_validation = (prima_neta !== undefined);

    return !(
      dates_validation &&
      plan_pago_validation &&
      beneficiario_validation &&
      intermediarioValidation &&
      glosa_validation &&
      comision_validation &&
      monto_validation &&
      prima_validation
    );
  }

  submit() {
    const {
      beneficiario,
      beneficiario_alias,
      comision,
      glosa,
      glosa_endoso,
      inicio_vigencia,
      inicio_endoso,
      monto_garantizado,
      prima_neta,
      termino_vigencia,
      tipo_intermediario,
      intermediario,
      cuotasPlan,
    } = this.state;
    const { activeModal, handleSubmit, edit, policy, ventaEnVerde } = this.props;
    const form = {
      inicio_vigencia: moment(inicio_vigencia, FRONT_DF_1).format(BACKEND_DF),
      inicio_endoso: moment(inicio_endoso, FRONT_DF_1).format(BACKEND_DF),
      termino_vigencia: moment(termino_vigencia, FRONT_DF_1).format(BACKEND_DF),
      cambio_cobertura_amt: monto_garantizado,
      cambio_comision_pct: comision,
      cambio_prima_neta_amt: prima_neta,
      poliza_base_id: policy.id,
      tipo_endoso_id: this.tipoEndoso.id,
    };
    if (
      this.tipoEndoso.nemotecnico === ENDOSO_EMISION ||
      this.tipoEndoso.nemotecnico === ENDOSO_CAMBIO_GLOSA
    ) {
      form.glosa = glosa;
    } else {
      form.glosa_endoso = glosa_endoso;
    }

    if (edit && this.inTypes([ENDOSO_EMISION, ENDOSO_CAMBIO_BENEFICIARIO])) {
      form.beneficiario_id = undefined;
      if (beneficiario && beneficiario.value) {
        form.beneficiario_id = beneficiario.value.id
      }
      else if (activeModal && activeModal.beneficiario) {
        form.beneficiario_id = activeModal.beneficiario.id
      }
      form.beneficiario_alias_id = beneficiario_alias ? beneficiario_alias.value : undefined;
    }

    if (edit && this.inTypes([ENDOSO_CAMBIO_INTERMEDIARIO])) {
      form.tipo_intermediario = tipo_intermediario ? tipo_intermediario.value.id : undefined;
      form.intermediario_id = intermediario ? intermediario.value.id : undefined;
    }

    if (!edit) {
      form.beneficiario_id = beneficiario ? beneficiario.value.id : undefined;
      form.beneficiario_alias_id = beneficiario_alias ? beneficiario_alias.value : undefined;
      form.tipo_intermediario = tipo_intermediario ? tipo_intermediario.value.id : undefined;
      form.intermediario_id = intermediario ? intermediario.value.id : undefined;
    }

    if (this.tipoEndoso.nemotecnico === ENDOSO_EMISION && ventaEnVerde) {
      form.cuotas = cuotasPlan
      form.beneficiario_id = policy.base.asegurado.id
    }

    this.setState({ awaitingResponse: true });
    if (edit) {
      return handleSubmit(activeModal.id, form, () => this.setState({ awaitingResponse: false }));
    }
    return handleSubmit(form, () => this.setState({ awaitingResponse: false }));
  }

  isComisionDisabled() {
    const { policy, ventaEnVerde } = this.props
    const intermediarioBase = policy.base ? policy.base.intermediario : null;
    const intermediarioBalance = policy.balance ? policy.balance.intermediario : null;

    return (
      ventaEnVerde ||
      !((this.tipoEndoso.nemotecnico === ENDOSO_EMISION && intermediarioBase) || (
      this.tipoEndoso.nemotecnico === ENDOSO_CAMBIO_COMISION &&  intermediarioBalance ))
    )
  }

  checkInvalidRangeDate() {
    const { termino_vigencia, cuotasPlan } = this.state;
    if (cuotasPlan.length > 0) {
      return moment(cuotasPlan[cuotasPlan.length - 1].fecha_pago, BACKEND_DF).isAfter(moment(termino_vigencia, FRONT_DF_1), 'date');
    }
    return false;
  }

  checkInvalidFirstCuota() {
    const { inicio_vigencia, cuotasPlan } = this.state;
    if (cuotasPlan.length > 0) {
      return !moment(cuotasPlan[0].fecha_pago, BACKEND_DF).isSame(moment(inicio_vigencia, FRONT_DF_1), 'date')
    }
    return false
  }

  render() {
    const { aliasList, tipoIntermediarioList, handleModal, modalOpen, policy, edit, ventaEnVerde } = this.props;
    const {
      beneficiario,
      beneficiario_alias,
      beneficiario_search,
      comision,
      fecha_emision,
      glosa,
      glosa_endoso,
      inicio_vigencia,
      inicio_endoso,
      iva,
      monto_garantizado,
      prima_bruta,
      prima_exenta,
      prima_neta,
      termino_vigencia,
      awaitingResponse,
      intermediario,
      tipo_intermediario,
      intermediario_search,
      numCuotas,
      cuotasPlan,
    } = this.state;
    let labelFechaInicio = 'Inicio Vigencia';
    if (this.tipoEndoso.nemotecnico === ENDOSO_CANCELACION)
      labelFechaInicio = 'Fecha de Cancelación';
    else if (this.tipoEndoso.nemotecnico === ENDOSO_REDUCCION)
      labelFechaInicio = 'Nuevo Término Vigencia';
    return (
      <div>
        <ModalForm
          disabled={this.isDisabled() || awaitingResponse}
          handleModal={!awaitingResponse ? handleModal : null}
          handleSubmit={this.submit}
          modalOpen={modalOpen}
          title={this.tipoEndoso.descripcion}
          edit={edit}
          xl={ventaEnVerde}
        >
          {/* Fecha Emisión y Moneda */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridGap: '0 20px',
            }}
          >
            <FormGroup>
              <label htmlFor="fecha_emision">Fecha Emisión</label>
              <p className="form-values" id="fecha_emision">
                {fecha_emision}
              </p>
            </FormGroup>
            <FormGroup>
              <label htmlFor="moneda">Moneda</label>
              <p className="form-values" id="moneda">
                {policy.base.moneda_asegurada.nombre_corto}
              </p>
            </FormGroup>
          </div>
          {/* Inicio Vigencia, Término Vigencia, Monto Garantizado, Prima Neta, IVA y Prima Bruta */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridGap: '15px 20px',
              marginBottom: '5px',
            }}
          >
            <FormGroup>
              <label htmlFor="inicio_vigencia">{labelFechaInicio}</label>
              {!this.inTypes([ENDOSO_EMISION, ENDOSO_CANCELACION, ENDOSO_REDUCCION]) ? (
                <p className="form-values" id="inicio_vigencia">
                  {inicio_vigencia}
                </p>
              ) : (
                <Datetime
                  closeOnSelect
                  dateFormat={FRONT_DF_1}
                  id="inicio_vigencia"
                  locale="es"
                  onChange={this.setInitialDate}
                  renderInput={(props) => <input {...props} className="rs-input" />}
                  timeFormat={false}
                  value={inicio_vigencia}
                  isValidDate={this.isValidInicioVigencia}
                />
              )}
            </FormGroup>
            <FormGroup>
              <label htmlFor="termino_vigencia">Término Vigencia</label>
              {!this.inTypes([ENDOSO_EMISION, ENDOSO_PRORROGA]) ? (
                <p className="form-values" id="termino_vigencia">
                  {termino_vigencia}
                </p>
              ) : (
                <Datetime
                  closeOnSelect
                  dateFormat={FRONT_DF_1}
                  id="termino_vigencia"
                  locale="es"
                  onChange={this.setFinalDate}
                  renderInput={(props) => <input {...props} className="rs-input" />}
                  timeFormat={false}
                  value={termino_vigencia}
                  isValidDate={this.isValidTerminoVigencia}
                />
              )}
            </FormGroup>
            {this.tipoEndoso.nemotecnico !== ENDOSO_CAMBIO_GLOSA && (
              <>
                <FormGroup>
                  <label htmlFor="monto_garantizado">&Delta; Monto Garantizado</label>
                  {this.isMontoEditable() ? (
                    <NumberFormat
                      id="monto_garantizado"
                      name="monto_garantizado"
                      className="form-control"
                      onValueChange={(values) =>
                        this.setState(
                          { monto_garantizado: values.floatValue},
                          () => this.calculatedPrimaNeta()
                        )
                      }
                      placeholder="Ingrese Monto"
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale="2"
                      fixedDecimalScale
                      value={monto_garantizado}
                    />
                  ) : (
                    <p className="form-values" id="monto_garantizado">
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale
                        decimalSeparator=","
                        displayType="text"
                        value={monto_garantizado}
                        thousandSeparator="."
                      />
                    </p>
                  )}
                </FormGroup>
                <FormGroup>
                  <label htmlFor="prima_neta">&Delta; Prima Neta</label>
                  {this.isPrimaNetaEditable(ventaEnVerde) ? (
                    <NumberFormat
                      id="prima_neta"
                      name="prima_neta"
                      className="form-control"
                      onValueChange={({floatValue}) => this.handlePrimaNeta(floatValue)}
                      placeholder="Ingrese Prima Neta"
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale="2"
                      fixedDecimalScale
                      value={prima_neta}
                    />
                  ) : (
                    <p className="form-values" id="prima_neta">
                      <NumberFormat
                        decimalScale="2"
                        fixedDecimalScale
                        decimalSeparator=","
                        displayType="text"
                        value={prima_neta}
                        thousandSeparator="."
                      />
                    </p>
                  )}
                </FormGroup>
                <FormGroup>
                  <label htmlFor="iva">&Delta; IVA</label>
                  <p className="form-values" id="iva">
                    <NumberFormat
                      decimalScale="2"
                      fixedDecimalScale
                      decimalSeparator=","
                      displayType="text"
                      value={iva}
                      thousandSeparator="."
                    />
                  </p>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="prima_bruta">&Delta; Prima Bruta</label>
                  <p className="form-values" id="prima_bruta">
                    <NumberFormat
                      decimalScale="2"
                      fixedDecimalScale
                      decimalSeparator=","
                      displayType="text"
                      value={prima_bruta}
                      thousandSeparator="."
                    />
                  </p>
                </FormGroup>
              </>
            )}
          </div>
          {/* Prima Exenta, Comisión y Fecha Inicio de Endoso */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridGap: '0 20px',
            }}
          >
            {this.tipoEndoso.nemotecnico !== ENDOSO_CAMBIO_GLOSA && (
              <>
                <FormGroup>
                  <label htmlFor="prima_exenta">&Delta; Prima Exenta</label>
                  <p className="form-values" id="prima_exenta">
                    {prima_exenta}
                  </p>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="comision">&Delta; Comisión</label>
                  {this.isComisionEditable() ? (
                    <NumberFormat
                      id="comision"
                      name="comision"
                      className="form-control"
                      onValueChange={(values) => this.handleValueChange(values, 'comision')}
                      placeholder="Ingrese Comisión"
                      suffix="%"
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale="2"
                      fixedDecimalScale
                      value={comision}
                      disabled={this.isComisionDisabled()}
                    />
                  ) : (
                    <p className="form-values" id="comision">
                      {comision}
                    </p>
                  )}
                </FormGroup>
                {this.inTypes([ENDOSO_CAMBIO_COMISION, ENDOSO_CAMBIO_INTERMEDIARIO]) && (
                  <FormGroup>
                    <label htmlFor="inicio_endoso">Fecha Inicio de Endoso</label>
                    <Datetime
                      closeOnSelect
                      dateFormat={FRONT_DF_1}
                      id="inicio_endoso"
                      locale="es"
                      onChange={this.setInitialEndosoDate}
                      renderInput={(props) => <input {...props} className="rs-input" />}
                      timeFormat={false}
                      value={inicio_endoso}
                      isValidDate={this.isValidInicioEndoso}
                    />
                  </FormGroup>
                )}
              </>
            )}
            <div />
          </div>
          {/* Beneficiario y Alias Beneficiario */}
          {!ventaEnVerde && (
            <div
              style={{
                display: 'grid',
                gridGap: '0 20px',
                gridTemplateColumns: '1fr 1fr',
                marginBottom: '15px',
              }}
            >
              {this.inTypes([ENDOSO_EMISION, ENDOSO_CAMBIO_BENEFICIARIO]) && (
                <>
                  <FormGroup>
                    <div>
                      <label htmlFor="beneficiario">Beneficiario</label>
                      <AsyncSelect
                        id="beneficiario"
                        name="beneficiario"
                        className="custom-policy-select"
                        cacheOptions
                        defaultOptions
                        inputValue={beneficiario_search || ''}
                        loadOptions={(inputValue) =>
                          loadClientsOptions(inputValue, ROL_BENEFICIARIO_GARANTIA)
                        }
                        onChange={this.handleSelectChange}
                        onInputChange={this.handleBeneficiario}
                        placeholder="Seleccione Beneficiario"
                        isDisabled={TIPOS_COBERTURA_SIN_BENEFICIARIO.includes(
                          policy.base.tipo_cobertura.nemotecnico,
                        )}
                        value={beneficiario}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div>
                      <label htmlFor="alias">Alias Beneficiario</label>
                      <Select
                        id="alias"
                        name="beneficiario_alias"
                        className="custom-policy-select"
                        isClearable
                        isDisabled={this.isAliasDisabled()}
                        onChange={this.handleSelectChange}
                        options={aliasList}
                        placeholder="Seleccione Alias"
                        value={beneficiario_alias}
                      />
                    </div>
                  </FormGroup>
                </>
              )}
            </div>
          )}
          {/* Tipo Intermediario e Intermediario */}
          <div
            style={{
              display: 'grid',
              gridGap: '0 20px',
              gridTemplateColumns: '1fr 1fr',
              marginBottom: '15px',
            }}
          >
            {this.inTypes([ENDOSO_CAMBIO_INTERMEDIARIO]) && (
              <>
                <FormGroup>
                  <div>
                    <label htmlFor="tipo_intermediario">Tipo Intermediario</label>
                    <Select
                      id="tipo_intermediario"
                      name="tipo_intermediario"
                      className="custom-policy-select"
                      isClearable
                      isDisabled={this.isTipoIntermediarioDisabled()}
                      onChange={this.handleTipoIntermediario}
                      options={tipoIntermediarioList}
                      placeholder="Seleccione Tipo Intermediario"
                      value={tipo_intermediario}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div>
                    <label htmlFor="intermediario">Intermediario</label>
                    <AsyncSelect
                      id="intermediario"
                      name="intermediario"
                      className="custom-policy-select"
                      loadOptions={(inputValue) =>
                        loadClientsOptions(inputValue, ROL_INTERMEDIARIO_GARANTIA)
                      }
                      defaultOptions
                      isClearable
                      inputValue={intermediario_search || ''}
                      value={intermediario}
                      onInputChange={this.handleIntermediario}
                      onChange={this.handleSelectChange}
                      placeholder="Seleccione Intermediario"
                      isDisabled={
                        !tipo_intermediario ||
                        (tipo_intermediario && tipo_intermediario.value.id === 1)
                      }
                    />
                  </div>
                </FormGroup>
              </>
            )}
          </div>
          {/* Glosa */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr',
              gridGap: '0 20px',
            }}
          >
            {this.inTypes([ENDOSO_EMISION, ENDOSO_CAMBIO_GLOSA]) && (
              <FormGroup>
                <label htmlFor="abstracto">
                  {this.tipoEndoso.nemotecnico === ENDOSO_CAMBIO_GLOSA && 'Nueva '}
                  Glosa
                </label>
                <textarea
                  maxLength={ventaEnVerde ? "600": "-1"}
                  className="form-control"
                  id="glosa"
                  name="glosa"
                  onChange={this.handleChange}
                  placeholder="Escribir glosa"
                  rows="3"
                  value={glosa}
                />
                <FormText className="font-italic">Formato Markdown soportado</FormText>
                <div className="plan-pago-feedback">
                  {(ventaEnVerde && glosa.length === 600) && (
                    <p>Glosa no puede superar los 600 caracteres.</p>
                  )}
                </div>
              </FormGroup>
            )}
            {this.tipoEndoso.nemotecnico !== ENDOSO_EMISION &&
              this.tipoEndoso.nemotecnico !== ENDOSO_CAMBIO_GLOSA && (
                <FormGroup>
                  <label htmlFor="abstracto">
                    Glosa Endoso
                    {this.tipoEndoso.nemotecnico === ENDOSO_CAMBIO_GLOSA &&
                      ' (justificación de este cambio)'}
                  </label>
                  <textarea
                    className="form-control"
                    id="glosa_endoso"
                    name="glosa_endoso"
                    onChange={this.handleChange}
                    placeholder="Escribir glosa"
                    rows="3"
                    value={glosa_endoso}
                  />
                  <FormText className="font-italic">Formato Markdown soportado</FormText>
                </FormGroup>
              )}
          </div>
          {/* Plan de Pago Venta en Verde */}
          {ventaEnVerde && this.tipoEndoso.nemotecnico === ENDOSO_EMISION && (
            <PlanPagoVentaEnVerde
              edit={edit}
              inicioVigencia={moment(inicio_vigencia, FRONT_DF_1)}
              terminoVigencia={moment(termino_vigencia, FRONT_DF_1)}
              numCuotas={numCuotas}
              setNumCuotas={(num) => {this.setState({numCuotas: num})}}
              cuotasPlan={cuotasPlan}
              setCuotasPlan={(cuotas) => {this.setState({cuotasPlan: cuotas})}}
            />
          )}
          <div className="plan-pago-feedback">
            {ventaEnVerde && this.inTypes([ENDOSO_EMISION]) && (
              <>
                {!monto_garantizado && (
                  <p>Debe ingresar un monto garantizado.</p>
                )}
                {monto_garantizado && numCuotas === cuotasPlan.length
                  && !this.isCuotasSumEqualToMontoGarantizado() && (
                  <p>
                    {`El total neto de las cuotas debe sumar ${monto_garantizado} UF.
                    (Total neto actual: ${cuotasPlan.reduce((acc, curr) => acc + curr.monto_uf, 0)} UF)`}
                  </p>
                )}
                {this.checkInvalidFirstCuota() && (
                  <p>La primera cuota no corresponde con la fecha de inicio de vigencia.</p>
                )}
                {this.checkInvalidRangeDate() && (
                  <p>Fecha cuota fuera de rango vigencia póliza.</p>
                )}
              </>
            )}
          </div>
        </ModalForm>
      </div>
    );
  }
}

EndosoForm.propTypes = {
  activeModal: PropTypes.shape({
    id: PropTypes.number,
    cambio_prima_neta_amt: PropTypes.number,
    cambio_comision_pct: PropTypes.number,
    emision: PropTypes.string,
    glosa: PropTypes.string,
    glosa_endoso: PropTypes.string,
    inicio_vigencia: PropTypes.string,
    inicio_endoso: PropTypes.string,
    termino_vigencia: PropTypes.string,
    cambio_cobertura_amt: PropTypes.number,
    tipo_endoso: PropTypes.shape({
      id: PropTypes.number,
      nemotecnico: PropTypes.string,
      descripcion: PropTypes.string,
    }),
    beneficiario: PropTypes.shape({
      id: PropTypes.number,
      rut: PropTypes.string,
      nombre: PropTypes.string,
    }),
    beneficiario_alias: PropTypes.shape({
      id: PropTypes.number,
      alias: PropTypes.string,
    }),
    intermediario: PropTypes.shape({
      rut: PropTypes.string,
      nombre: PropTypes.string,
      id: PropTypes.number,
    }),
    tipo_intermediario: PropTypes.number,
    descripcion: PropTypes.string,
    plan_pago: PropTypes.shape({
      cuotas_plan: PropTypes.arrayOf(
        PropTypes.shape({
          secuencia: PropTypes.number,
          monto_uf: PropTypes.number,
          fecha_pago: PropTypes.string,
        })
      ),
      numero_cuotas: PropTypes.number,
    }),
  }).isRequired,
  aliasList: PropTypes.arrayOf(PropTypes.object).isRequired,
  tipoIntermediarioList: PropTypes.arrayOf(PropTypes.object).isRequired,
  balance: PropTypes.arrayOf(
    PropTypes.shape({
      inicio_vigencia: PropTypes.shape,
      termino_vigencia: PropTypes.shape,
      intermediario: PropTypes.object,
    }),
  ).isRequired,
  edit: PropTypes.bool,
  ventaEnVerde: PropTypes.bool,
  handleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  policy: PropTypes.shape({
    id: PropTypes.number,
    base: PropTypes.shape({
      intermediario: PropTypes.object,
      moneda_asegurada: PropTypes.shape({
        nombre_corto: PropTypes.string,
      }),
      tipo_cobertura: PropTypes.shape({
        nemotecnico: PropTypes.string,
      }),
      asegurado: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
    balance: PropTypes.shape({
      intermediario: PropTypes.object,
    }),
    proyecto_garantia: PropTypes.shape({
      comision: PropTypes.number,
      prima_minima_neta: PropTypes.number,
      tasa_anual: PropTypes.number,
      inicio_vigencia: PropTypes.string,
      termino_vigencia: PropTypes.string,
    }),
  }).isRequired,
  dispatchGetData: PropTypes.func.isRequired,
  lastTerminoVigencia: PropTypes.string,
  lastCancelacion: PropTypes.shape({
    cambio_cobertura_amt: PropTypes.number,
    cambio_prima_neta_amt: PropTypes.number,
    prima_exenta: PropTypes.number,
    cambio_comision_pct: PropTypes.number,
  }),
};

EndosoForm.defaultProps = {
  edit: false,
  ventaEnVerde: false,
  lastTerminoVigencia: '',
  lastCancelacion: {},
};

const mapStateToProps = (state) => ({
  aliasList: state.policies.aliasList,
  tipoIntermediarioList: state.policies.tiposIntermediario,
  balance: state.policies.balance,
  policy: state.policies.selectedPolicy,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetData: (data) => dispatch(getData(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EndosoForm);
