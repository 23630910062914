import React from 'react'
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const SafeBtn = ({ loading, children, ...btnProps }) => (
  loading ? (
    <Button {...btnProps} disabled>
      <FontAwesomeIcon
        icon={faSpinner}
        pulse
      />
    </Button>
  ) : (
    <Button {...btnProps}>
      {children}
    </Button>
  )
);

SafeBtn.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

SafeBtn.defaultProps = {
  loading: false,
};

export default SafeBtn;
