import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PageContainer from '../PageContainer';
import PolicyForm from './PolicyForm';
import {
  cleanEndoso,
  cleanGeneralConditioned,
  cleanPolicy,
  deleteData,
  getData,
  patchData,
  postData,
  resetError,
} from '../../redux-store/actions';
import {
  CLEAR_PUBLISH_ENDOSO_ERROR,
  CREATE_ENDOSO,
  DELETE_ENDOSO,
  GET_ACTIVE_GENERAL_CONDITIONED_LIST,
  GET_ACTIVIDAD_LIST,
  GET_BALANCE,
  GET_ENDOSO_LIST,
  GET_INTERMEDIARIO_LIST,
  GET_MONEDAS,
  GET_POLICY,
  GET_TIPOS_ENDOSO,
  GET_TIPOS_INSTITUCION,
  PUBLISH_ENDOSO,
  SAVE_ENDOSO,
  SAVE_POLICY,
} from '../../redux-store/constants/action-types';
import {
  API,
  BASE_URL,
  ENDOSO_CANCELACION,
  ENDOSO_PRORROGA,
  ENDOSO_CAMBIO_INTERMEDIARIO,
} from '../../CONST';
import { isEditable, UFFormatter } from '../../helpers/helpers';
import DeprecatedWarningModal from '../UI/DeprecatedWarningModal';
import EndosoForm from '../endosos/EndosoForm';
import EndosoCreate from '../endosos/EndosoCreate';
import WarningModal from '../UI/WarningModal';

const filterGeneralConditionedOption = (inputValue, options, policy) => {
  const activeOptions = options.filter((node) => node.value.activa);
  const selectedOption = options.find((node) => node.value.id === policy.base.poliza_maestra.id);
  if (!selectedOption.value.activa) {
    activeOptions.push(selectedOption);
  }
  return activeOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
};

class PolicyEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      loading: false,
      activeModal: undefined,
      editModal: false,
      modalOpen: false,
      warningOpen: false,
      deleteWarningOpen: false,
      publishEndoso: undefined,
    };
  }

  componentDidMount() {
    const {
      dispatchCleanGeneralConditioned,
      dispatchCleanPolicy,
      dispatchGetData,
      match,
    } = this.props;
    dispatchCleanGeneralConditioned();
    dispatchCleanPolicy();
    dispatchGetData({
      url: `${BASE_URL}${API.endosos}/?poliza_base_id=${match.params.id}`,
      type: GET_ENDOSO_LIST,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.maestras}/?is_main=0&paginate=0&activa=true&ramo_asegurado__id=1`,
      type: GET_ACTIVE_GENERAL_CONDITIONED_LIST,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.tipos_institucion}`,
      type: GET_TIPOS_INSTITUCION,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.monedas}`,
      type: GET_MONEDAS,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.actividad}`,
      type: GET_ACTIVIDAD_LIST,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.intermediario}`,
      type: GET_INTERMEDIARIO_LIST,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.tipos_endoso_garantia}?garantia=${match.params.id}`,
      type: GET_TIPOS_ENDOSO,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.balance}?poliza_base_id=${match.params.id}`,
      type: GET_BALANCE,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.garantias}/${match.params.id}`,
      type: GET_POLICY,
    });
  }

  handleSubmit = (config, onFailedReq = () => {}) => {
    const { dispatchPatchData, match } = this.props;
    dispatchPatchData({
      url: `${BASE_URL}${API.garantias}/${match.params.id}/`,
      type: SAVE_POLICY,
      onFailedReq,
      config,
    });
  };

  handleEndosoSubmit = (config, onFailedReq = () => {}) => {
    const { dispatchPostData, dispatchGetData, match } = this.props;
    dispatchPostData({
      url: `${BASE_URL}${API.endosos}/`,
      type: CREATE_ENDOSO,
      onFailedReq,
      config,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.endosos}/?poliza_base_id=${match.params.id}`,
      type: GET_ENDOSO_LIST,
    });
    this.setState({ loading: true });
  };

  handleEndosoEdit = (id, config, onFailedReq = () => {}) => {
    const { dispatchPatchData } = this.props;
    dispatchPatchData({
      url: `${BASE_URL}${API.endosos}/${id}/`,
      type: SAVE_ENDOSO,
      onFailedReq,
      config,
    });
    this.setState({ loading: true });
  };

  handleDelete = (i) => {
    const { dispatchDeleteData } = this.props;
    dispatchDeleteData({
      url: `${BASE_URL}${API.endosos}/${i}/`,
      type: DELETE_ENDOSO,
      id: i,
    });
  };

  handlePublish = (i, onFailedReq = () => {}) => {
    const { dispatchPostData, match } = this.props;
    dispatchPostData({
      url: `${BASE_URL}${API.garantias}/${match.params.id}/endosos/${i}/emitir/`,
      type: PUBLISH_ENDOSO,
      onFailedReq,
    });
  };

  handlePublishEndoso = (i) => {
    return this.handlePublish(
      i,
      // eslint-disable-next-line no-console
      (error) => console.log(error),
    );
  };

  loadGeneralConditionedOptions = (inputValue) =>
    new Promise((resolve) => {
      const { policies } = this.props;
      setTimeout(() => {
        resolve(
          filterGeneralConditionedOption(
            inputValue,
            policies.condicionadosGenerales,
            policies.selectedPolicy,
          ),
        );
      }, 1000);
    });

  handleModal = (e, node) => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
      activeModal: node && { tipo_endoso: node },
      editModal: undefined,
    }));
  };

  handleWarning = (e, i) => {
    this.setState((prevState) => ({
      warningOpen: !prevState.warningOpen,
      publishEndoso: !prevState.warningOpen ? i : undefined,
    }));
  };

  toggleWarningOpen = () => this.setState((prevState) => ({ warningOpen: !prevState.warningOpen }));

  handleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  handleDeleteWarning = (id) => {
    this.setState((prevState) => ({
      deleteWarningOpen: !prevState.deleteWarningOpen,
      publishEndoso: !prevState.deleteWarningOpen ? id : undefined,
    }));
  };

  handleEdit = (i) => {
    this.setState({
      modalOpen: true,
      editModal: i,
      activeModal: undefined,
    });
  };

  lastTerminoVigencia = () => {
    const {
      policies: { endosoList },
    } = this.props;
    if (endosoList.length === 0) {
      return null;
    }
    const emision = endosoList[0];
    const prorrogas = endosoList.filter(
      (endoso) => endoso.tipo_endoso.nemotecnico === ENDOSO_PRORROGA,
    );
    return prorrogas.length ? prorrogas.pop().termino_vigencia : emision.termino_vigencia;
  };

  lastCancelacion = () => {
    const {
      policies: { endosoList },
    } = this.props;
    const cancelaciones = endosoList.filter(
      (endoso) => endoso.tipo_endoso.nemotecnico === ENDOSO_CANCELACION,
    );
    return cancelaciones.pop();
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const {
      dispatchCleanPolicy,
      history,
      dispatchCleanEndoso,
      dispatchGetData,
      match,
      dispatchResetError,
    } = this.props;

    if (nextProps.policies.successMessage) {
      dispatchCleanPolicy();
      history.push('/guarantee/policies/');
    }
    if (nextProps.policies.successEndosoMessage) {
      dispatchCleanEndoso();
      dispatchGetData({
        url: `${BASE_URL}${API.garantias}/${match.params.id}`,
        type: GET_POLICY,
      });
      dispatchGetData({
        url: `${BASE_URL}${API.tipos_endoso_garantia}?garantia=${match.params.id}`,
        type: GET_TIPOS_ENDOSO,
      });
      dispatchGetData({
        url: `${BASE_URL}${API.balance}?poliza_base_id=${match.params.id}`,
        type: GET_BALANCE,
      });
      this.setState({ loading: false });
    }
    if (nextProps.policies.errorEndosoMessage) {
      dispatchResetError();
      this.setState({ loading: false });
    }
    if (nextProps.policies.successEndosoMessage) {
      this.setState({
        modalOpen: false,
        activeModal: undefined,
        editModal: undefined,
        warningOpen: nextProps.policies && nextProps.policies.error,
        deleteWarningOpen: false,
      });
    }
  }

  render() {
    const { policies, dispatchClearPublishEndosoError } = this.props;
    const {
      activeModal,
      deleteWarningOpen,
      dropdownOpen,
      editModal,
      loading,
      modalOpen,
      publishEndoso,
      warningOpen,
    } = this.state;
    const { loading: loadingData, error, selectedPolicy } = policies;
    let errorMsg;
    if (selectedPolicy && publishEndoso) {
      const endoso = policies.endosoList.find((e) => e.id === publishEndoso);
      const intermediario =
        selectedPolicy.balance && selectedPolicy.emitido
          ? selectedPolicy.balance.intermediario
          : selectedPolicy.base.intermediario;
      if (
        endoso &&
        endoso.tipo_endoso.nemotecnico !== ENDOSO_CAMBIO_INTERMEDIARIO &&
        !intermediario &&
        endoso.cambio_comision_pct > 0
      ) {
        errorMsg =
          'No se puede emitir endoso porque tiene intermediario directo con comisión mayor a cero.';
      }
    }
    if (error) {
      errorMsg = 'Error inesperado';
      if (error.errors) {
        if (error.errors.includes('LineaGarantiaInsuficiente')) {
          const saldo = UFFormatter.format(error.linea_uf);
          errorMsg = `Línea de Garantía insuficiente (quedan ${saldo} UF).`;
        } else if (error.errors.includes('LineaGarantia.DoesNotExist')) {
          errorMsg = 'No se encontró la Línea de Garantía para el afianzado.';
        } else if (error.errors.includes('FalloActualizacionLineaGarantia')) {
          errorMsg = 'No se pudo actualizar la línea de garantía del afianzado.';
          if (error.errors.includes('ClienteBloqueado')) {
            errorMsg += ' El cliente ha sido bloquedo temporalmente.';
          }
        } else if (error.errors.includes('ClienteBloqueado')) {
          errorMsg =
            'Por el momento no es posible emitir el endoso dado que el cliente está bloquedo. Vuelva a intentarlo más tarde.';
        }
      }
    }
    return (
      <PageContainer
        breadcrumbs={[
          { name: 'GARANTÍA', url: '/guarantee/policies/' },
          { name: 'PÓLIZAS', url: '/guarantee/policies/' },
          { name: 'EDITAR PÓLIZA' },
        ]}
        style={{ padding: '0 20px' }}
      >
        {policies.selectedPolicy && (
          <PolicyForm
            edit
            endosos={
              // eslint-disable-next-line
              <EndosoCreate
                dropdownOpen={dropdownOpen}
                handleDelete={this.handleDeleteWarning}
                handleEdit={this.handleEdit}
                handleDropdown={this.handleDropdown}
                handleModal={this.handleModal}
                handleWarning={this.handleWarning}
                warningOpen={warningOpen}
                deleteWarningOpen={deleteWarningOpen}
              />
            }
            handleDelete={this.handleDelete}
            handlePublish={this.handlePublish}
            handleEndosoSubmit={this.handleEndosoSubmit}
            handleEndosoEdit={this.handleEndosoEdit}
            handleSubmit={this.handleSubmit}
            isEditable={
              isEditable(policies.selectedPolicy.estado) && !policies.selectedPolicy.emitido
            }
            loading={loading}
            loadGeneralConditionedOptions={this.loadGeneralConditionedOptions}
          />
        )}
        {activeModal && (
          <EndosoForm
            activeModal={activeModal}
            handleModal={this.handleModal}
            handleSubmit={this.handleEndosoSubmit}
            loading={loading}
            modalOpen={modalOpen}
            lastTerminoVigencia={this.lastTerminoVigencia()}
            lastCancelacion={this.lastCancelacion()}
          />
        )}
        {editModal && (
          <EndosoForm
            edit
            activeModal={editModal}
            handleModal={this.handleModal}
            handleSubmit={this.handleEndosoEdit}
            loading={loading}
            modalOpen={modalOpen}
            lastTerminoVigencia={this.lastTerminoVigencia()}
            lastCancelacion={this.lastCancelacion()}
          />
        )}
        <WarningModal
          confirmMsg="Está a un paso de emitir un Endoso"
          okBtnText="Emitir Endoso"
          handleOk={() => this.handlePublishEndoso(publishEndoso)}
          toggle={this.toggleWarningOpen}
          isOpen={warningOpen}
          loading={loadingData}
          errorMsg={errorMsg}
          handleClearError={() => {
            this.toggleWarningOpen();
            // avoid rendering submit button while disappearing
            setTimeout(dispatchClearPublishEndosoError, 500);
          }}
        />
        <DeprecatedWarningModal
          handleModal={this.handleDeleteWarning}
          handleSubmit={this.handleDelete}
          objectId={publishEndoso}
          modalOpen={deleteWarningOpen}
          warningMessage="Está a un paso de eliminar un Endoso"
          confirmText="Eliminar Endoso"
        />
      </PageContainer>
    );
  }
}

PolicyEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  policies: PropTypes.shape({
    condicionadosGenerales: PropTypes.array,
    successMessage: PropTypes.bool,
    selectedPolicy: PropTypes.shape({
      estado: PropTypes.string,
      emitido: PropTypes.bool,
      base: PropTypes.shape({
        intermediario: PropTypes.object,
      }),
      balance: PropTypes.object,
    }),
    successEndosoMessage: PropTypes.bool,
    errorEndosoMessage: PropTypes.bool,
    endosoList: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  dispatchCleanEndoso: PropTypes.func.isRequired,
  dispatchCleanGeneralConditioned: PropTypes.func.isRequired,
  dispatchCleanPolicy: PropTypes.func.isRequired,
  dispatchDeleteData: PropTypes.func.isRequired,
  dispatchGetData: PropTypes.func.isRequired,
  dispatchPatchData: PropTypes.func.isRequired,
  dispatchPostData: PropTypes.func.isRequired,
  dispatchResetError: PropTypes.func.isRequired,
  dispatchClearPublishEndosoError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  policies: state.policies,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchCleanEndoso: (data) => dispatch(cleanEndoso(data)),
  dispatchCleanGeneralConditioned: (data) => dispatch(cleanGeneralConditioned(data)),
  dispatchCleanPolicy: (data) => dispatch(cleanPolicy(data)),
  dispatchDeleteData: (data) => dispatch(deleteData(data)),
  dispatchGetData: (data) => dispatch(getData(data)),
  dispatchPatchData: (data) => dispatch(patchData(data)),
  dispatchPostData: (data) => dispatch(postData(data)),
  dispatchResetError: (data) => dispatch(resetError(data)),
  dispatchClearPublishEndosoError: () => dispatch({ type: CLEAR_PUBLISH_ENDOSO_ERROR }),
});

const PolicyEditView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PolicyEdit);
export default PolicyEditView;
