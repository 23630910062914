import React from 'react';

const OportunidadEmitidaSection = () => {
  return (
    <div>
      <p>OportunidadEmitidaSection</p>
    </div>
  );
};

OportunidadEmitidaSection.propTypes = {};

OportunidadEmitidaSection.defaultProps = {};

export default OportunidadEmitidaSection;
