import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClientesEspecialesSection from './ClientesEspecialesSection';
import FilialesOrBeneficiarios from './helpers/FilialesOrBeneficiarios';
import { CP_SET_SECTION_VALIDATION } from '../../../redux-store/constants/action-types';
import { isValidClientesYDedudoresStep } from '../../../helpers/validation';
import VistoBuenoSection from './helpers/VistoBuenoSection';
import { CP_FS_CLIENTES } from '../../../CONST';

class ClientesYDeudoresStep extends Component {
  state = {
    validateInputs: false,
  };

  componentDidUpdate() {
    const {
      dispatch,
      creditPolicy: {
        form,
        form: { sections },
      },
    } = this.props;
    const isValid = isValidClientesYDedudoresStep(form);
    if (isValid !== sections[CP_FS_CLIENTES].valid) {
      dispatch({
        type: CP_SET_SECTION_VALIDATION,
        payload: { stepName: CP_FS_CLIENTES, isValid },
      });
    }
  }

  setValidateInputs = validateInputs => this.setState({ validateInputs });

  render() {
    const { creditPolicy, dispatch, readOnly } = this.props;
    const { validateInputs } = this.state;
    return (
      <>
        <FilialesOrBeneficiarios
          form={creditPolicy.form}
          dispatch={dispatch}
          validate={validateInputs}
          disabled={readOnly}
        />
        <ClientesEspecialesSection
          form={creditPolicy.form}
          dispatch={dispatch}
          style={{ marginTop: '35px' }}
          disabled={readOnly}
        />
        <VistoBuenoSection
          sectionName={CP_FS_CLIENTES}
          validate={validateInputs}
          disabled={readOnly}
        />
      </>
    );
  }
}

ClientesYDeudoresStep.propTypes = {
  dispatch: PropTypes.func.isRequired,
  creditPolicy: PropTypes.shape({
    form: PropTypes.shape({
      sections: PropTypes.object,
      data: PropTypes.shape({
        moneda: PropTypes.object,
        endoso: PropTypes.shape({
          clientesEndoso: PropTypes.array,
        }),
      }),
    }),
  }).isRequired,
  readOnly: PropTypes.bool,
};

ClientesYDeudoresStep.defaultProps = {
  readOnly: false,
};

export default ClientesYDeudoresStep;
