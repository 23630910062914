import React from 'react';
import {
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import PropTypes from 'prop-types';

const ModalForm = ({
  bottom,
  children,
  disabled,
  edit,
  handleModal,
  handleSubmit,
  modalOpen,
  title,
  xl,
}) => (
  <Modal className={bottom && 'modal-bottom' || ''} isOpen={modalOpen} size={xl && 'xl' || 'lg'}>
    <ModalHeader className="endoso-form" toggle={handleModal}>{`Endoso ${title}`}</ModalHeader>
    <ModalBody className={`endoso-form ${bottom&&('warning-container')}`}>
      {children}
    </ModalBody>
    <ModalFooter className="endoso-form text-right">
      <Col md={{ offset: 6}}>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '20px' }}>
          <Button
            style={{
              background: 'transparent',
              border: '1px solid #F98724',
              color: '#F98724',
              fontSize: '14px',
              width: '100%',
            }}
            onClick={handleModal}
          >
            Cancelar
          </Button>
          <Button
            style={{
              fontSize: '14px',
            }}
            color="orsan"
            onClick={handleSubmit}
            disabled={disabled}
          >
            {edit ? 'Guardar Borrador' : 'Crear Borrador'}
          </Button>
        </div>
      </Col>
    </ModalFooter>
  </Modal>
);

ModalForm.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
  bottom: PropTypes.bool,
  handleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  xl: PropTypes.bool,
};

ModalForm.defaultProps = {
  edit: false,
  bottom: false,
  xl: false,
};

export default ModalForm;
