import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { FormGroup, Label, Col, Input, Button } from 'reactstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ESTADOS_OPORTUNIDAD, API, BASE_URL, OPORTUNIDADES_STATE_SECTION_MAP } from 'CONST';
import { patch, post } from 'helpers/requests';
import CheckModal from 'components/shared/CheckModal';
import WarningModal from 'components/UI/WarningModal';
import AvanzarOportunidadConfirmationModal from './AvanzarOportunidadConfirmationModal';

const RevisarRequisitosEmisionSection = ({
  fullData,
  setFullData,
  setInitialSection,
  id,
  estadosOportunidad,
  observaciones,
}) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [observacion, setObservacion] = useState('');
  const [observacionesEstado, setObservacionesEstado] = useState([]);
  const [editableSection, setEditableSection] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [mensajeCheckModal, setMensajeCheckModal] = useState('');
  const [mensajeWarningModal, setMensajeWarningModal] = useState('');

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [revisionCompletaModal, setRevisionCompletaModal] = useState(false);
  const [revisionComercialModal, setRevisionComercialModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const editableStates = [ESTADOS_OPORTUNIDAD.borradorAceptado];

  useEffect(() => {
    if (observaciones) {
      setObservacionesEstado(
        observaciones.filter(
          (obs) => obs.estado.nemotecnico === ESTADOS_OPORTUNIDAD.requisitosEmisionValidados,
        ),
      );
    }
    if (fullData && fullData.estado && editableStates.includes(fullData.estado.nemotecnico)) {
      setEditableSection(true);
    }
  }, [observaciones, fullData]);

  const postObservacion = () => {
    if (observacion) {
      const body = {
        oportunidad_id: id,
        estado_id: estadosOportunidad.find(
          (e) => e.nemotecnico === ESTADOS_OPORTUNIDAD.requisitosEmisionValidados,
        ).id,
        observacion,
      };
      post(`${BASE_URL}${API.observaciones}/`, body).catch(() => {
        setShowErrorModal(true);
      });
    }
  };

  const avanzarOportunidad = (mensajeCheck, mensajeWarning, estadoFinal, isRefreshParam) => {
    setIsRefresh(isRefreshParam);
    setLoading(true);
    setMensajeCheckModal(mensajeCheck);
    setMensajeWarningModal(mensajeWarning);
    const body = {
      estado_id: estadosOportunidad.find((e) => e.nemotecnico === estadoFinal).id,
    };
    patch(`${BASE_URL}${API.oportunidades_garantia}/${id}/`, body)
      .then((response) => {
        postObservacion();
        if (!isRefreshParam) {
          setFullData(response);
          setInitialSection(OPORTUNIDADES_STATE_SECTION_MAP[response.estado.nemotecnico]);
          setEditableSection(false);
        }
        setShowUpdateModal(true);
      })
      .catch(() => {
        setShowErrorModal(true);
      })
      .finally(() => {
        setRevisionCompletaModal(false);
        setRevisionComercialModal(false);
        setConfirmationModal(false);
        setLoading(false);
      });
  };

  const checkModalToggle = () => {
    if (isRefresh) {
      return history.push('/guarantee/oportunities');
    }
    return setShowUpdateModal(false);
  };

  return (
    <div>
      <FormGroup row>
        <Col sm={2}>
          <Label>Observaciones</Label>
        </Col>
        <Col>
          <Input
            id="observacion-info-inicial"
            type="textarea"
            value={
              !editableSection && observacionesEstado.length > 0
                ? observacionesEstado[0].observacion
                : observacion || ''
            }
            onChange={(event) => setObservacion(event.target.value)}
            rows="3"
            disabled={!editableSection}
          />
        </Col>
      </FormGroup>
      {observacionesEstado.slice(!editableSection).map((obs) => {
        return (
          <FormGroup row key={obs.id}>
            <Col sm={2} />
            <Col>
              <Input type="textarea" value={obs.observacion} rows="3" disabled />
            </Col>
          </FormGroup>
        );
      })}
      {editableSection && (
        <div className="button-container d-flex justify-content-end">
          <Button
            className="btn btn-secondary white"
            style={{ marginRight: 25 }}
            onClick={() => setRevisionCompletaModal(true)}
            disabled={revisionCompletaModal || revisionComercialModal || confirmationModal}
          >
            {loading && revisionCompletaModal ? (
              <FontAwesomeIcon spin={loading} icon={faSpinner} />
            ) : (
              'Solicitar Revisión Completa'
            )}
          </Button>
          <Button
            className="btn btn-secondary white"
            style={{ marginRight: 25 }}
            onClick={() => setRevisionComercialModal(true)}
            disabled={revisionCompletaModal || revisionComercialModal || confirmationModal}
          >
            {loading && revisionComercialModal ? (
              <FontAwesomeIcon spin={loading} icon={faSpinner} />
            ) : (
              'Solicitar Revisión Comercial'
            )}
          </Button>
          <Button
            className="btn btn-secondary orange"
            onClick={() => setConfirmationModal(true)}
            disabled={revisionCompletaModal || revisionComercialModal || confirmationModal}
          >
            {loading && confirmationModal ? (
              <FontAwesomeIcon spin={loading} icon={faSpinner} />
            ) : (
              'Validar Requisitos de Emisión'
            )}
          </Button>
        </div>
      )}
      <CheckModal
        isOpen={showUpdateModal}
        toggle={() => checkModalToggle()}
        handleOk={() => checkModalToggle()}
        okBtnText="OK"
        confirmMsg={mensajeCheckModal}
      />
      <AvanzarOportunidadConfirmationModal
        openModal={revisionCompletaModal}
        setOpenModal={setRevisionCompletaModal}
        avanzarOportunidad={() =>
          avanzarOportunidad(
            'Se solicitó revisión completa.',
            'No se pudo solicitar revisión completa.',
            ESTADOS_OPORTUNIDAD.suscriptorAsignado,
            true,
          )
        }
        loading={loading}
        mensajeModal="¿Está seguro que desea SOLICITAR REVISIÓN COMPLETA para esta oportunidad?"
      />
      <AvanzarOportunidadConfirmationModal
        openModal={revisionComercialModal}
        setOpenModal={setRevisionComercialModal}
        avanzarOportunidad={() =>
          avanzarOportunidad(
            'Se solicitó revisión comercial.',
            'No se pudo solicitar revisión comercial.',
            ESTADOS_OPORTUNIDAD.evaluacionAprobada,
            true,
          )
        }
        loading={loading}
        mensajeModal="¿Está seguro que desea SOLICITAR REVISIÓN COMERCIAL para esta oportunidad?"
      />
      <AvanzarOportunidadConfirmationModal
        openModal={confirmationModal}
        setOpenModal={setConfirmationModal}
        avanzarOportunidad={() =>
          avanzarOportunidad(
            'Requisitos de emisión validados exitosamente.',
            'No se pudo validar los requisitos de emisión.',
            ESTADOS_OPORTUNIDAD.requisitosEmisionValidados,
            false,
          )
        }
        loading={loading}
        mensajeModal="¿Está seguro que desea VALIDAR los requisitos de emisión de esta oportunidad?"
      />
      <WarningModal
        isOpen={showErrorModal}
        toggle={() => setShowErrorModal(false)}
        handleOk={() => setShowErrorModal(false)}
        okBtnText="Aceptar"
        confirmMsg={mensajeWarningModal}
        loading={false}
        onlyCancelButton
      />
    </div>
  );
};

RevisarRequisitosEmisionSection.propTypes = {
  fullData: PropTypes.shape({
    estado: PropTypes.shape({
      nemotecnico: PropTypes.string,
    }),
  }),
  setFullData: PropTypes.func.isRequired,
  setInitialSection: PropTypes.func.isRequired,
  id: PropTypes.string,
  estadosOportunidad: PropTypes.arrayOf(PropTypes.shape({})),
  observaciones: PropTypes.arrayOf(PropTypes.shape({})),
};

RevisarRequisitosEmisionSection.defaultProps = {
  fullData: null,
  id: null,
  estadosOportunidad: [],
  observaciones: [],
};

export default RevisarRequisitosEmisionSection;
