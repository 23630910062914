/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import {
  API,
  BASE_URL,
  FRONT_DF_2,
  TIPO_ENDOSO_MODIFICACION_ID,
  TIPO_PLAN_CUOTAS_ID,
  TIPO_PLAN_LIBRO_VENTAS_ID,
  TIPO_PLAN_PLAN_PAGOS_ID,
} from '../../../CONST';
import FormSection from '../../UI/FormSection';
import { pluralize, roundFloat } from '../../../helpers/helpers';
import FileTable from '../../shared/FileTable';
import {
  addAdjunto,
  attachFiles,
  deleteAdjunto,
  deleteAdjuntoNuevo,
} from '../../../redux-store/actions/creditPolicyActions';

// eslint-disable-next-line react/prefer-stateless-function
class CreditPolicySummaryStep extends Component {
  render() {
    const {
      loginIdToken,
      creditPolicy: { policy, form },
      dispatchAddAdjunto,
      dispatchDeleteAdjunto,
      dispatchDeleteAdjuntoNuevo,
      dispatchSaveFiles,
      loading,
    } = this.props;

    const {
      data: {
        endoso: { tipoEndosoId },
      },
    } = form;
    const isEndosoModificacion = tipoEndosoId === TIPO_ENDOSO_MODIFICACION_ID;

    return (
      <Col md={{ offset: 1, size: 10 }}>
        <FormSection title="Información básica" style={{ marginTop: '20px' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              gridGap: '20px',
            }}
          >
            <div>
              <label htmlFor="ramo-fecu">Ramo FECU</label>
              <p id="ramo-fecu" className="small-form-value">
                {form.data.ramoFecu.label}
              </p>
            </div>
            <div>
              <label htmlFor="moneda">Moneda</label>
              <p id="moneda" className="small-form-value">
                {form.data.moneda.label}
              </p>
            </div>
            <div>
              <label htmlFor="condicionado-general">Condicionado general</label>
              <p id="condicionado-general" className="small-form-value">
                {form.data.condicionadoGeneral.label}
              </p>
            </div>
            <div>
              <label htmlFor="producto">Producto</label>
              <p id="producto" className="small-form-value">
                {form.data.producto.label}
              </p>
            </div>
            <div>
              <label htmlFor="tipo-cobertura">Tipo de cobertura</label>
              <p id="tipo-cobertura" className="small-form-value">
                {form.data.endoso.tipoCobertura.label}
              </p>
            </div>
            <div>
              <label htmlFor="tipologia-ejecucion">
                Tipología de ejecución
              </label>
              <p id="tipologia-ejecucion" className="small-form-value">
                {form.data.tipologiaEjecucion.label}
              </p>
            </div>
            <div>
              <label htmlFor="tipo-intermediario">Tipo de intermediario</label>
              <p id="tipo-intermediario" className="small-form-value">
                {form.data.endoso.tipoIntermediario.label}
              </p>
            </div>
            <div>
              <label htmlFor="intermediario">Intermediario</label>
              <p id="intermediario" className="small-form-value">
                {form.data.endoso.intermediario
                  ? form.data.endoso.intermediario.label
                  : ''}
              </p>
            </div>
            <div>
              <label htmlFor="asegurado">Asegurado</label>
              <p id="asegurado" className="small-form-value">
                {form.data.asegurado.label}
              </p>
            </div>
            <div>
              <label htmlFor="alias-asegurado">Alias asegurado</label>
              <p id="alias-asegurado" className="small-form-value">
                {form.data.endoso.aseguradoAlias
                  ? form.data.endoso.aseguradoAlias.label
                  : ''}
              </p>
            </div>
            <div style={{ gridColumn: '3 / 5', gridRow: '3 / 5' }}>
              <label htmlFor="materia-asegurada">Materia asegurada</label>
              <p id="materia-asegurada" className="small-form-value">
                {form.data.endoso.materiaAsegurada}
              </p>
            </div>
            <div>
              <label htmlFor="materia-asegurada">Número de documento</label>
              {form.data.endoso.documento && (
                <p id="materia-asegurada" className="small-form-value">
                  {form.data.endoso.documento.id}
                  <a
                    className="reactstrap-table-link"
                    href={form.data.endoso.documento.file}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: '10px' }}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </p>
              )}
            </div>
            <div>
              <label htmlFor="document">Adjuntos</label>
              <FileTable
                fileList={policy ? policy.adjuntos : []}
                newFiles={policy ? policy.adjuntos_uuids : []}
                handleAdd={dispatchAddAdjunto}
                handleDelete={dispatchDeleteAdjunto}
                handleDeleteNew={dispatchDeleteAdjuntoNuevo}
                uploadUrl={`${BASE_URL}${API.upload}/`}
                downloadUrl={`${BASE_URL}${API.download}/`}
                loginIdToken={loginIdToken}
                showSave
                handleSave={() => dispatchSaveFiles(policy)}
                loading={loading}
              />
            </div>
          </div>
        </FormSection>
        <FormSection title="Detalles" style={{ marginTop: '20px' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
              gridGap: '20px',
            }}
          >
            <div>
              <label htmlFor="inicio-vigencia-endoso">
                Inicio vigencia endoso
              </label>
              <p id="inicio-vigencia-endoso" className="small-form-value">
                {isEndosoModificacion && form.data.endoso.inicioVigenciaEndoso
                  ? form.data.endoso.inicioVigenciaEndoso.format(FRONT_DF_2)
                  : form.data.endoso.inicioVigencia.format(FRONT_DF_2)}
              </p>
            </div>
            <div>
              <label htmlFor="inicio-vigencia">Inicio vigencia poliza</label>
              <p id="inicio-vigencia" className="small-form-value">
                {form.data.endoso.inicioVigencia.format(FRONT_DF_2)}
              </p>
            </div>
            <div>
              <label htmlFor="fin-vigencia">Fin de vigencia</label>
              <p id="fin-vigencia" className="small-form-value">
                {form.data.endoso.terminoVigencia.format(FRONT_DF_2)}
              </p>
            </div>
            <div>
              <label htmlFor="forma-pago">Forma de pago</label>
              <p id="forma-pago" className="small-form-value">
                {form.data.endoso.planPago.tipoId ===
                  TIPO_PLAN_LIBRO_VENTAS_ID && 'Libro de Ventas'}
                {form.data.endoso.planPago.tipoId === TIPO_PLAN_CUOTAS_ID &&
                  `En ${pluralize('cuota', form.data.endoso.planPago.cuotas)}`}
                {form.data.endoso.planPago.tipoId === TIPO_PLAN_PLAN_PAGOS_ID &&
                  'Plan de pago'}
              </p>
            </div>
            <div>
              <label htmlFor="estimacion-ventas">Estimación de ventas</label>
              <NumberFormat
                id="estimacion-ventas"
                name="estimacionVentasAmt"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                suffix={` ${form.data.moneda.value.nombre_corto}`}
                value={form.data.endoso.estimacionVentasAmt}
                displayType="text"
                renderText={value => (
                  <p className="small-form-value">{value}</p>
                )}
              />
            </div>
            <div>
              <label htmlFor="tasa-costo-seguro-defecto">
                Tasa costo seguro
              </label>
              <NumberFormat
                id="tasa-costo-seguro-defecto"
                name="tasaCostoSeguroDefectoPct"
                decimalSeparator=","
                decimalScale="3"
                fixedDecimalScale
                suffix="%"
                value={form.data.endoso.tasaCostoSeguroDefectoPct}
                displayType="text"
                renderText={value => (
                  <p className="small-form-value">{value}</p>
                )}
              />
            </div>
            <div>
              <label htmlFor="prima-minima-anual">Prima mínima anual</label>
              <NumberFormat
                id="prima-minima-anual"
                name="primaMinimaAnualAmt"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                suffix={` ${form.data.moneda.value.nombre_corto}`}
                value={roundFloat(form.data.endoso.primaMinimaAnualAmt)}
                displayType="text"
                renderText={value => (
                  <p className="small-form-value">{value}</p>
                )}
              />
            </div>
            <div>
              <label htmlFor="delta-prima-minima-anual">Cambio en prima</label>
              <NumberFormat
                id="delta-prima-minima-anual"
                name="deltaPrimaMinimaAnualAmt"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                suffix={` ${form.data.moneda.value.nombre_corto}`}
                value={roundFloat(form.data.endoso.deltaPrimaMinimaAnualAmt)}
                displayType="text"
                renderText={value => (
                  <p className="small-form-value">{value}</p>
                )}
              />
            </div>
            <div>
              <label htmlFor="comision">Comisión</label>
              <NumberFormat
                id="comision"
                name="tasaCostoSeguroDefectoPct"
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                suffix="%"
                value={form.data.endoso.comisionPct}
                displayType="text"
                renderText={value => (
                  <p className="small-form-value">{value}</p>
                )}
              />
            </div>
            <div style={{ display: 'grid', gridColumnStart: 1 }}>
              <label htmlFor="filiales">Filiales</label>
              <p id="filiales" className="small-form-value">
                {form.data.endoso.clientesEndoso
                  .filter(cliente => cliente.is_filial)
                  .map(cliente => (
                    <span
                      key={cliente.cliente.rut}
                      style={{ display: 'block' }}
                    >
                      {`${cliente.cliente.rut} - ${cliente.cliente.nombre}`}
                    </span>
                  ))}
              </p>
            </div>
          </div>
        </FormSection>
      </Col>
    );
  }
}

CreditPolicySummaryStep.propTypes = {
  loginIdToken: PropTypes.string.isRequired,
  creditPolicy: PropTypes.shape({
    loading: PropTypes.bool,
    policy: PropTypes.shape({
      adjuntos: PropTypes.array,
      adjuntos_uuids: PropTypes.array,
      borrador: PropTypes.object,
    }),
    form: PropTypes.shape({
      condicionesParametrizadasList: PropTypes.array,
      data: PropTypes.shape({
        moneda: PropTypes.object,
        ramoFecu: PropTypes.object,
        condicionadoGeneral: PropTypes.object,
        producto: PropTypes.object,
        tipologiaEjecucion: PropTypes.object,
        intermediario: PropTypes.object,
        asegurado: PropTypes.object,
        endoso: PropTypes.object,
      }),
      currentPolicyId: PropTypes.number,
    }),
  }).isRequired,
  dispatchAddAdjunto: PropTypes.func.isRequired,
  dispatchDeleteAdjunto: PropTypes.func.isRequired,
  dispatchDeleteAdjuntoNuevo: PropTypes.func.isRequired,
  dispatchSaveFiles: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  loginIdToken: state.login.id_token,
  creditPolicy: state.creditPolicy,
  loading: state.creditPolicy.loading,
});

const mapDispatchToProps = dispatch => ({
  dispatchAddAdjunto: uuid => dispatch(addAdjunto(uuid)),
  dispatchDeleteAdjunto: adjunto => dispatch(deleteAdjunto(adjunto)),
  dispatchDeleteAdjuntoNuevo : uuid => dispatch(deleteAdjuntoNuevo(uuid)),
  dispatchSaveFiles: policy => dispatch(attachFiles(policy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditPolicySummaryStep);
