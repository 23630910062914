import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SafeBtn from '../shared/SafeBtn';

const AvanzarOportunidadConfirmationModal = ({
  openModal,
  setOpenModal,
  avanzarOportunidad,
  mensajeModal,
  loading,
}) => (
  <Modal style={{ maxWidth: '600px' }} isOpen={openModal} type="check-modal">
    <ModalHeader cssModule={{ 'modal-title': 'w-100 text-center modal-title pl-0' }}>
      {mensajeModal}
    </ModalHeader>
    <ModalFooter style={{ padding: '1rem' }}>
      <Button
        className="warning-cancel mr-auto"
        onClick={() => setOpenModal(false)}
        style={{ marginLeft: 15 }}
        disabled={loading}
      >
        Cancelar
      </Button>
      <SafeBtn
        className="warning-accept"
        onClick={() => avanzarOportunidad()}
        style={{ marginRight: 25 }}
        disabled={loading}
      >
        {loading ? <FontAwesomeIcon spin={loading} icon={faSpinner} /> : 'Confirmar'}
      </SafeBtn>
    </ModalFooter>
  </Modal>
);

AvanzarOportunidadConfirmationModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  avanzarOportunidad: PropTypes.func.isRequired,
  mensajeModal: PropTypes.string,
  loading: PropTypes.bool,
};

AvanzarOportunidadConfirmationModal.defaultProps = {
  mensajeModal: '¿Está seguro que desea AVANZAR esta oportunidad?',
  loading: false,
};

export default AvanzarOportunidadConfirmationModal;
