import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChevronRight, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, UncontrolledTooltip } from 'reactstrap';

const OportunitiesFormAccordionSection = ({
  title,
  children,
  toggle,
  collapsed,
  isValid,
  hasIcon,
  expansible,
  responsable,
}) => (
  <Col
    className="form-section"
    md={{ offset: 1, size: 10 }}
    style={{ opacity: expansible ? 1 : 0.5 }}
  >
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
    <div
      className={`accordion-section-header${expansible ? ' expansible' : ''}`}
      style={{
        marginTop: '20px',
        display: 'grid',
        gridTemplateColumns: '20px auto 20px',
        gridGap: '2px',
        alignItems: 'center',
        cursor: expansible ? 'pointer' : undefined,
      }}
      onClick={expansible ? toggle : () => {}}
    >
      {(hasIcon && (
        <div>
          <FontAwesomeIcon
            id={`${title.replaceAll(' ', '')}-accordion-section`}
            icon={isValid ? faCheckCircle : faQuestionCircle}
            style={{ marginBottom: '5px', color: isValid ? '#315e90' : '#fcba03' }}
          />
          <UncontrolledTooltip target={`${title.replaceAll(' ', '')}-accordion-section`}>
            {responsable}
          </UncontrolledTooltip>
        </div>
      )) || <div style={{ marginBottom: '5px' }} />}
      <h5 className="form-title">{title}</h5>
      <FontAwesomeIcon
        icon={faChevronRight}
        style={{ cursor: expansible ? 'pointer' : undefined, marginBottom: '5px' }}
        transform={collapsed ? {} : { rotate: 90 }}
      />
    </div>
    <hr className="form-separator" />
    {children}
  </Col>
);

OportunitiesFormAccordionSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  toggle: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  expansible: PropTypes.bool.isRequired,
  hasIcon: PropTypes.bool,
  responsable: PropTypes.string,
};

OportunitiesFormAccordionSection.defaultProps = {
  hasIcon: true,
  responsable: '',
};

export default OportunitiesFormAccordionSection;
