import React, { useEffect, useState } from 'react';
import {
  API,
  BASE_URL,
  ESTADO_POLIZA,
  FRONT_DF_1,
  PROFILES,
} from 'CONST';
import { get, post, put, rDelete } from 'helpers/requests';
import { Col, Button } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import {
  PROYECTO_VV_WRITE,
  POLIZA_VV_READ,
  POLIZA_VV_WRITE,
} from 'helpers/profilePermission';
import isUserHasProfile from 'helpers/profileValidator';
import PropTypes from 'prop-types';
import PageContainer from 'components/PageContainer';
import WarningModal from 'components/UI/WarningModal';
import { headerOperation, cellOperation, cellNumOperation, headerDoubleRowOperation } from 'helpers/tables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { fdate } from 'helpers/helpers';
import FormSection from '../UI/FormSection';
import ProjectFormAccordion from './ProjectFormAccordion';
import ProjectContratanteSection from './ProjectContratanteSection';
import ProjectDescripcionSection from './ProjectDescripcionSection';
import ProjectComercialSection from './ProjectComercialSection';
import useTableFilters from '../../helpers/useTableFilters';
import BaseTable from '../UI/BaseTable';
import FilterSelect from '../shared/FilterSelect';
import DateTableFilter from '../shared/DateTableFilter';

const moment = require('moment');

const ProjectForm = ({ history }) => {
  const { id } = useParams();
  const [linea, setLinea] = useState({});

  const [hasOrganization, setHasOrganization] = useState(false);
  const [hasGiro, setHasGiro] = useState(false);
  const [organizacion, setOrganizacion] = useState(null);
  const [giro, setGiro] = useState(null);
  const [actividadesList, setActividadesList] = useState([]);

  const [hasProjectDescription, setHasProjectDescription] = useState(false);
  const [hasTotalDescription, setHasTotalDescription] = useState(false);
  const [description, setDescription] = useState({});

  const [hasParametrosComerciales, setHasParametrosComerciales] = useState(false);
  const [parametrosComerciales, setParametrosComerciales] = useState({});
  const [displayModalWarning, setDisplayModalWarning] = useState(false);
  const [showNotFoundModal, setShowNotFoundModal] = useState(false);

  const [edit, setEdit] = useState(false);
  const [activo, setActivo] = useState(false);
  const [dataEdition, setDataEdition] = useState({});
  const [nameBreadcrumb, setNameBreadcrumb] = useState('CREAR PROYECTO');
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [tiposMoneda, setTiposMoneda] = useState([]);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [eliminarPoliza, setEliminarPoliza] = useState(null);
  const [deleteErrorWarning, setDeleteErrorWarning] = useState(false);
  const [createPolicyWarning, setCreatePolicyWarning] = useState(false);

  const [onFilteredChange, onFetchData, loading, data, getData] = useTableFilters(
    `${BASE_URL}${API.polizaProyecto}`,
    false,
    `proyecto_garantia=${id}`,
    [
      { id: 'emision', format: FRONT_DF_1 },
      { id: 'inicio_vigencia', format: FRONT_DF_1 },
      { id: 'termino_vigencia', format: FRONT_DF_1 }
    ],
    0,
  );

  const NO_FILTERED_ID = -1;

  const handleDelete = () => {
    setShowDeleteWarning(false);
    rDelete(`${BASE_URL}${API.polizaProyecto}${eliminarPoliza}`)
    .then((response) => {
      if (response && response.operation_id) {
        getData(`proyecto_garantia=${id}`);
      }
    })
    .catch(() => {
      setDeleteErrorWarning(true);
    })
  };

  const columns = [
    {
      Header: () => headerOperation('Código'),
      id: 'codigo',
      accessor: 'base.nemotecnico',
      Cell: (row) => cellOperation(row.value),
      sortable: true,
      minWidth: 70,
    },
    {
      Header: () => headerOperation('Moneda'),
      id: 'moneda',
      accessor: 'base.moneda_asegurada',
      Cell: (row) => cellOperation(row.value.nombre_corto),
      Filter: (obj) => (
        <FilterSelect
          onFilterChange={obj.onChange}
          filter={obj.filter}
          options={tiposMoneda.map((elem) => ({
            label: elem.nombre_corto,
            value: elem.id,
          }))}
          defaultOption={{ label: 'Todas', value: NO_FILTERED_ID }}
        />
      ),
      sortable: false,
      minWidth: 50,
    },
    {
      Header: headerOperation('Emisión'),
      id: 'emision',
      accessor: 'emision',
      Cell: (row) => cellOperation(fdate(row.value)),
      Filter: (obj) => (
        <DateTableFilter
          filter={obj.filter}
          onChange={obj.onChange}
          format={FRONT_DF_1}
        />
      ),
      sortable: false,
      width: 120,
    },
    {
      Header: headerDoubleRowOperation('Inicio', 'Vigencia'),
      id: 'inicio_vigencia',
      accessor: 'balance.inicio_vigencia',
      Cell: (row) => cellOperation(fdate(row.value)),
      Filter: (obj) => (
        <DateTableFilter
          filter={obj.filter}
          onChange={obj.onChange}
          format={FRONT_DF_1}
        />
      ),
      sortable: false,
      width: 120,
    },
    {
      Header: headerDoubleRowOperation('Término', 'Vigencia'),
      id: 'termino_vigencia',
      accessor: 'balance.termino_vigencia',
      Cell: (row) => cellOperation(fdate(row.value)),
      Filter: (obj) => (
        <DateTableFilter
          filter={obj.filter}
          onChange={obj.onChange}
          format={FRONT_DF_1}
        />
      ),
      sortable: false,
      width: 120,
    },
    {
      Header: headerDoubleRowOperation('Monto', 'Garantizado'),
      id: 'monto_garantizado',
      accessor: (row) => (row.balance && cellNumOperation(row.balance.balance_cobertura_amt)),
      minWidth: 50,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Prima Neta'),
      id: 'prima_neta',
      accessor: (row) => (row.balance && cellNumOperation(row.balance.balance_prima_neta_amt)),
      minWidth: 50,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Estado'),
      id: 'estado',
      accessor: (i) => {
        let alertClass = 'alert-warning';
        let { estado } = i
        if (i.estado === ESTADO_POLIZA.emitida) {
          alertClass = 'alert-success';
          estado = 'Emitida';
        } else if (i.estado === ESTADO_POLIZA.borrador) {
          alertClass = 'alert-primary';
          estado = 'Borrador';
        } else if (i.estado === ESTADO_POLIZA.construccion) {
          estado = 'Construcción';
        } else if (i.estado === ESTADO_POLIZA.cancelada) {
          alertClass = 'alert-danger';
          estado = 'Cancelada';
        }
        return cellOperation(
          <div className={`alert ${alertClass} text-center`} role="alert">
            {estado}
          </div>,
        );
      },
      Filter: (obj) => (
        <FilterSelect
          onFilterChange={obj.onChange}
          filter={obj.filter}
          options={[
            { label: 'Emitida', value: ESTADO_POLIZA.emitida },
            { label: 'Borrador', value: ESTADO_POLIZA.borrador },
            { label: 'Construcción', value: ESTADO_POLIZA.construccion },
            { label: 'Cancelada', value: ESTADO_POLIZA.cancelada },
          ]}
          defaultOption={{ label: 'Todos', value: NO_FILTERED_ID }}
        />
      ),
      minWidth: 50,
    },
    {
      Header: () => headerOperation('Acciones'),
      id: 'acciones',
      accessor: item => (
        isUserHasProfile(POLIZA_VV_READ) && (
          <div
            style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '5px' }}
            className="table-cell"
          >
            {(
              ![ESTADO_POLIZA.construccion, ESTADO_POLIZA.borrador].includes(item.estado)
              || (
                [ESTADO_POLIZA.construccion, ESTADO_POLIZA.borrador].includes(item.estado)
                && !isUserHasProfile(POLIZA_VV_WRITE)
              )
            ) ? (
              <div style={{ textAlign: 'center' }} title="Ver">
                <Link
                  to={`/garantia/proyectos/${id}/poliza/${item.id}/detalle`}
                  className="table-link policy"
                >
                  <FontAwesomeIcon icon={faEye} />
                </Link>
              </div>
            ) : (
              isUserHasProfile(POLIZA_VV_WRITE) && (
                <div style={{ textAlign: 'center' }} title="Editar">
                  <Link
                    to={`/garantia/proyectos/${id}/poliza/${item.id}/detalle`}
                    className="table-link policy"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Link>
                  <button
                    type="button"
                    className="table-link policy"
                    onClick={() => {
                      setEliminarPoliza(item.id);
                      setShowDeleteWarning(true);
                    }}
                    title="Eliminar"
                    style={{ marginLeft: '5px' }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              )
            )}
          </div>
        )
      ),
      width: 85,
      sortable: false,
      filterable: false,
    },
  ];

  useEffect(() => {
    get(`${BASE_URL}${API.monedas}`)
    .then((response) => {
      setTiposMoneda(response);
    });
  }, []);

  useEffect(() => setEdit(id !== undefined), []);

  useEffect(() => {
    if (edit) {
      setNameBreadcrumb('EDITAR PROYECTO')
      get(`${BASE_URL}${API.proyectos}${id}`)
      .then((response) => {
        setDataEdition(response);
        if (response.estado === 'activo'){
          setActivo(true);
          setNameBreadcrumb('VER DETALLE');
        }
      })
      .catch((response) => {
        if (response.status === 404) {
          setShowNotFoundModal(true);
        }
      });
    }
  }, [edit]);

  useEffect(() => {
    if (organizacion && organizacion.value && organizacion.value.rut) {
      get(`${BASE_URL}${API.lineasGarantia}?organizacion=${organizacion.value.rut}`)
      .then(({ results }) => {
        setLinea(results.length ? results[0] : {balance: {cupo_disponible_proyectos: 0}})
        setHasOrganization(organizacion && Object.keys(organizacion).length !== 0);
      });

      get(`${BASE_URL}${API.actividades}?organization_id=${organizacion.value.id}`)
      .then((response) => {
        const options = response.map(node => ({
          label: node.nombre,
          value: node,
        }));
        setActividadesList(options);
      });
    }
  }, [organizacion]);

  useEffect(() => {
    if (giro && giro.value && giro.value.id) {
      setHasGiro(true);
    }
  }, [giro]);

  const handleSubmit = (estado) => {
    setLoadingSubmit(true);
    const submitData = {
      organizacion_id: organizacion && organizacion.value && organizacion.value.id,
      actividad_id: giro && giro.value && giro.value.id,
      estado,
      ...description,
      ...parametrosComerciales,
    }

    if (!edit){
      post(`${BASE_URL}${API.proyectos}`, submitData)
        .then(response => {
          if (response) {
            history.push('/garantia/proyectos/')
          }
        })
    } else {
      put(`${BASE_URL}${API.proyectos}${id}/`, submitData)
        .then(response => {
          if (response) {
            history.push('/garantia/proyectos/')
          }
        })
    }
  }

  const handleCancel = () => history.push('/garantia/proyectos/')

  const handleCrearPoliza = () => {
    const fechaTermino = moment(dataEdition.termino_vigencia)
    const fechaToday = moment()
    if (fechaTermino.isBefore(fechaToday)) {
      setCreatePolicyWarning(true);
    }
    else {
      history.push(`/garantia/proyectos/${id}/poliza/crear`);
    }
  }

  return (
    <PageContainer
      breadcrumbs={[
        { id: 0, name: 'GARANTÍA', url: '/' },
        { id: 1, name: 'VENTA EN VERDE', url: '/garantia/proyectos'},
        { id: 2, name: nameBreadcrumb},
      ]}
    >
      <ProjectFormAccordion
        isAllOpen
        sections={[
          {
            name: 'Organización',
            component: (
              <ProjectContratanteSection
                organizacion={organizacion}
                setOrganizacion={setOrganizacion}
                linea={linea}
                dataEdition={dataEdition}
                edit={edit}
                isDisabled={!isUserHasProfile(PROFILES.comercialGarantia)}
                giro={giro}
                setGiro={setGiro}
                setHasGiro={setHasGiro}
                actividadesList={actividadesList}
              />
            ),
            isValid: (hasOrganization && hasGiro),
            expansible: true,
          },
          {
            name: 'Descripción del Proyecto',
            component: (
              <ProjectDescripcionSection
                setHasProjectDescription={setHasProjectDescription}
                setHasTotalDescription={setHasTotalDescription}
                setDescription={setDescription}
                cupoRestanteProyectos={linea && linea.balance && linea.balance.cupo_disponible_proyectos}
                dataEdition={dataEdition}
                edit={edit}
                activo={activo}
                isDisabled={
                  hasOrganization
                  && hasGiro
                  && isUserHasProfile(PROYECTO_VV_WRITE)
                  && !isUserHasProfile(PROFILES.comercialGarantia)
                }
              />
            ),
            isValid: hasProjectDescription,
            expansible: (hasOrganization && hasGiro),
          },
          {
            name: 'Parámetros Comerciales del Proyecto',
            component: (
              <ProjectComercialSection
                setParametrosComerciales={setParametrosComerciales}
                setHasParametrosComerciales={setHasParametrosComerciales}
                dataEdition={dataEdition}
                edit={edit}
                activo={activo}
                isDisabled={
                  hasOrganization
                  && hasGiro
                  && isUserHasProfile([
                    PROFILES.comercialGarantia,
                    PROFILES.actuariado,
                    PROFILES.ceo,
                  ])
                }
              />
            ),
            isValid: hasParametrosComerciales,
            expansible: (hasOrganization && hasGiro),
          },
        ]}
      />
      {!activo && (
        <Col md={{ offset: 6 }}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '20px', marginRight: '20%'}}>
            <Button
              style={{
                background: 'transparent',
                border: '1px solid #F98724',
                color: '#F98724',
                fontSize: '14px',
                width: '100%',
              }}
              onClick={handleCancel}
              disabled={loadingSubmit}
            >
              Cancelar
            </Button>
            <Button
              style={{
                background: '#315e90',
                color: '#FFFFFF',
                border: '1px solid #315e90',
                fontSize: '14px',
              }}
              onClick={() => handleSubmit('borrador')}
              disabled={
                !(hasOrganization && hasGiro && hasProjectDescription && isUserHasProfile(PROYECTO_VV_WRITE))
                || loadingSubmit
              }
            >
              {edit ? 'Guardar Borrador' : 'Crear Borrador'}
            </Button>
            <Button
              style={{
                fontSize: '14px',
              }}
              color="orsan"
              onClick={() => setDisplayModalWarning(true)}
              disabled={
                !(
                  hasOrganization
                  && hasGiro
                  && hasTotalDescription
                  && hasParametrosComerciales
                  && isUserHasProfile(PROYECTO_VV_WRITE)
                )
                || loadingSubmit
              }
            >
              Crear Proyecto
            </Button>
          </div>
        </Col>
      )}

      {id && ![ESTADO_POLIZA.borrador].includes(dataEdition.estado) && (
        <FormSection
          title='Pólizas Venta en Verde'
          style={{
            margin: '0 10% 30px 10%'
          }}
        >
          <Col md={{ offset: 10 }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr', marginBottom: '15px' }}>
              {isUserHasProfile(POLIZA_VV_WRITE) && (
                <>
                  <Button
                    color="orsan"
                    onClick={handleCrearPoliza}
                    style={{ fontSize: '14px'}}
                  >
                    Crear Póliza
                  </Button>
                  {createPolicyWarning && (
                    <span className='error-msg-small'>No se pueden crear pólizas después de la fecha de término del proyecto.</span>
                  )}
                </>
              )}
            </div>
          </Col>
          <BaseTable
            className="client-list"
            columns={columns}
            data={data.data}
            filterable
            loading={loading}
            onFetchData={onFetchData}
            onFilteredChange={onFilteredChange}
            pages={data.pages}
            resizable={false}
            resultCount={data.count}
            showPagination
            style={{ height: '55vh' }}
            tableRef={React.createRef()}
          />
        </FormSection>
      )}

      <WarningModal
        loading={loadingSubmit}
        isOpen={displayModalWarning}
        toggle={() => setDisplayModalWarning(false)}
        handleOk={() => handleSubmit('activo')}
        okBtnText="Aceptar"
        confirmMsg="¿Está seguro que quiere crear este proyecto?"
      />
      <WarningModal
        isOpen={showDeleteWarning}
        toggle={() => {setShowDeleteWarning(false)}}
        handleOk={() => handleDelete()}
        okBtnText="Aceptar"
        confirmMsg="¿Está seguro que quiere eliminar esta póliza?"
      />
      <WarningModal
        isOpen={deleteErrorWarning}
        toggle={() => setDeleteErrorWarning(false)}
        handleOk={() => {}}
        handleClearError={() => setDeleteErrorWarning(false)}
        errorMsg='Póliza no pudo ser eliminada.'
        confirmMsg=''
      />
      <WarningModal
        isOpen={showNotFoundModal}
        toggle={() => {}}
        handleOk={() => {}}
        handleClearError={() => {
          history.push("/garantia/proyectos/")
        }}
        errorMsg="Proyecto no encontrado"
        confirmMsg=""
      />
    </PageContainer>
  );
};

ProjectForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default ProjectForm;
