import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import FormSection from '../../../UI/FormSection';
import { ID_RAMOFECU_CREDITO_EXPORTACION } from '../../../../CONST';

const ConfiguracionDeSiniestroSection = ({
  configuracionSiniestroDias,
  dispatch,
  style,
  validate,
  handleChange,
  disabled,
  ramoFecuId,
  currentPolicyId,
}) => {
  const [ramoFecuSelected, setRamoFecuSelected] = useState(undefined);

  if (currentPolicyId && ramoFecuId && ramoFecuSelected !== ramoFecuId && !disabled) {
    // solo cuando el formulario es editable
    const defaultDias = ramoFecuId === ID_RAMOFECU_CREDITO_EXPORTACION ? 120 : 75;
    dispatch(handleChange('endoso.configuracionSiniestroDias', defaultDias));
    setRamoFecuSelected(ramoFecuId);
  }
  return (
    <FormSection title="Configuración de siniestro" style={style}>
      <div className="inline-text-with-input">
        <NumberFormat
          id="configuracion-siniestro-dias"
          name="configuracionSiniestroDias"
          className={`form-control${validate && !configuracionSiniestroDias ? ' is-invalid' : ''}`}
          onValueChange={(values) => {
            const newValue = values.floatValue;
            dispatch(handleChange('endoso.configuracionSiniestroDias', newValue));
          }}
          allowNegative={false}
          decimalSeparator={false}
          value={configuracionSiniestroDias}
          style={{ width: '54px' }}
          disabled={disabled}
        />
        <span className="small-form-value">&nbsp;&nbsp; días.</span>
      </div>
    </FormSection>
  );
};

ConfiguracionDeSiniestroSection.propTypes = {
  configuracionSiniestroDias: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  ramoFecuId: PropTypes.number.isRequired,
  currentPolicyId: PropTypes.number.isRequired,
};

ConfiguracionDeSiniestroSection.defaultProps = {
  configuracionSiniestroDias: null,
  style: {},
  disabled: false,
};

export default ConfiguracionDeSiniestroSection;
