
export const startType = type => `${type}_START`;
export const errorType = type => `${type}_ERROR`;

// todo: add namespace to action types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const TOKEN_ERROR = 'TOKEN_ERROR';

export const GET_GENERAL_CONDITIONED_LIST = 'GET_GENERAL_CONDITIONED_LIST';
export const GET_GENERAL_CONDITIONED = 'GET_GENERAL_CONDITIONED';
export const CREATE_GENERAL_CONDITIONED = 'CREATE_GENERAL_CONDITIONED';
export const SAVE_GENERAL_CONDITIONED = 'SAVE_GENERAL_CONDITIONED';
export const CLEAN_GENERAL_CONDITIONED = 'CLEAN_GENERAL_CONDITIONED';
export const ACTIVATE_GENERAL_CONDITIONED = 'ACTIVATE_GENERAL_CONDITIONED';
export const ACTIVATE_GENERAL_CONDITIONED_ERROR = 'ACTIVATE_GENERAL_CONDITIONED_ERROR';
export const SAVE_GENERAL_CONDITIONED_ERROR = 'SAVE_GENERAL_CONDITIONED_ERROR';
export const UPLOAD_FILE = 'UPLOAD_FILE';

export const GET_PRODUCTOS = 'GET_PRODUCTOS';
export const GET_TIPOLOGIAS_EJECUCION = 'GET_TIPOLOGIAS_EJECUCION';
export const GET_TIPOS_COBERTURA = 'GET_TIPOS_COBERTURA';
export const GET_RAMOS_FECU = 'GET_RAMOS_FECU';
export const GET_TIPOS_INSTITUCION = 'GET_TIPOS_INSTITUCION';

export const GET_POLICY_LIST = 'GET_POLICY_LIST';
export const GET_POLICY = 'GET_POLICY';
export const SAVE_POLICY = 'SAVE_POLICY';
export const CREATE_POLICY = 'CREATE_POLICY';
export const DELETE_POLICY = 'DELETE_POLICY';
export const SAVE_POLICY_ERROR = 'SAVE_POLICY_ERROR';
export const SAVE_ENDOSO_ERROR = 'SAVE_ENDOSO_ERROR';
export const POLICY_DELETE_ADJUNTO = "POLICY_DELETE_ADJUNTO";

export const GET_ENDOSO_LIST = 'GET_ENDOSO_LIST';
export const PUBLISH_ENDOSO = 'PUBLISH_ENDOSO';
export const CLEAR_PUBLISH_ENDOSO_ERROR = 'CLEAR_PUBLISH_ENDOSO_ERROR';
export const DELETE_ENDOSO = 'DELETE_ENDOSO';
export const GET_TIPOS_ENDOSO = 'GET_TIPOS_ENDOSO';
export const CREATE_ENDOSO = 'CREATE_ENDOSO';
export const SAVE_ENDOSO = 'SAVE_ENDOSO';

export const GET_MONEDAS = 'GET_MONEDAS';
export const GET_ACTIVE_GENERAL_CONDITIONED_LIST = 'GET_ACTIVE_GENERAL_CONDITIONED_LIST';
export const GET_ACTIVIDAD_LIST = 'GET_ACTIVIDAD_LIST';
export const GET_ALIASES = 'GET_ALIASES';
export const GET_ACTIVIDADES = 'GET_ACTIVIDADES';
export const GET_INTERMEDIARIO_LIST = 'GET_INTERMEDIARIO_LIST';
export const GET_BALANCE = 'GET_BALANCE';

export const CLEAN_POLICY = 'CLEAN_POLICY';
export const CLEAN_ENDOSO = 'CLEAN_ENDOSO';
export const RESET_ERROR = 'RESET_ERROR';

// Credit policy (CP)
export const CP_GET_LIST = 'CP_GET_LIST';
export const CP_DELETE = 'CP_DELETE';
export const CP_DELETE_ENDOSO = 'CP_DELETE_ENDOSO';
export const CP_TOGGLE_DELETE = 'CP_TOGGLE_DELETE';
export const CP_TOGGLE_DELETE_ENDOSO = 'CP_TOGGLE_DELETE_ENDOSO';
export const CP_OPEN_DELETE = 'CP_OPEN_DELETE';
export const CP_OPEN_DELETE_ENDOSO = 'CP_OPEN_DELETE_ENDOSO';
export const CP_GET = 'CP_GET';
export const CP_GET_POLICY = 'CP_GET_POLICY';
export const CP_SAVE_POLICY_ATTACHMENTS = 'CP_SAVE_POLICY_ATTACHMENTS';
export const CP_HANDLE_FORM_ELEMENT_CHANGE = 'CP_HANDLE_FORM_ELEMENT_CHANGE';
export const CP_RESET_CURRENT_CLIENTE_ESPECIAL = 'CP_RESET_CURRENT_CLIENTE_ESPECIAL';
export const CP_HANDLE_RAMO_FECU_SELECT_CHANGE = 'CP_HANDLE_RAMO_FECU_SELECT_CHANGE';
export const CP_HANDLE_GENERAL_CONDITIONED_SELECT_CHANGE = 'CP_HANDLE_GENERAL_CONDITIONED_SELECT_CHANGE';
export const CP_CREATE = 'CP_CREATE';
export const CP_EDIT = 'CP_EDIT';
export const CP_EDIT_AND_CONTINUE = 'CP_EDIT_AND_CONTINUE';
export const CP_EMIT = 'CP_EMIT';
export const CP_CLEAR_FORM = 'CP_CLEAR_FORM';
export const CP_HANDLE_INICIO_VIGENCIA_CHANGE = 'CP_HANDLE_INICIO_VIGENCIA_CHANGE';
export const CP_HANDLE_INICIO_VIGENCIA_ENDOSO_CHANGE = 'CP_HANDLE_INICIO_VIGENCIA_ENDOSO_CHANGE';
export const CP_HANDLE_BALANCE_CALCULATED_FIELDS_CHANGE = 'CP_HANDLE_BALANCE_CALCULATED_FIELDS_CHANGE';
export const CP_HANDLE_TASA_COSTO_SEGURO_ADD = 'CP_HANDLE_TASA_COSTO_SEGURO_ADD';
export const CP_HANDLE_TASA_COSTO_SEGURO_DELETE = 'CP_HANDLE_TASA_COSTO_SEGURO_DELETE';
export const CP_GET_PLAZOS_CREDITO = 'CP_GET_PLAZOS_CREDITO';
export const CP_GET_PARTICULAR_CONDITIONS = 'CP_GET_PARTICULAR_CONDITIONS';
export const CP_GET_PARAMETERIZED_CONDITIONS = 'CP_GET_PARAMETERIZED_CONDITIONS';
export const CP_SELECT_PARTICULAR_CONDITION = 'SELECTE_PARTICULAR_CONDITION';
export const CP_DESELECT_PARTICULAR_CONDITION = 'CP_DESELECT_PARTICULAR_CONDITION';
export const CP_SET_NO_PARTICULAR_CONDITION_SELECTED = 'CP_SET_NO_PARTICULAR_CONDITION_SELECTED';
export const CP_GET_PORCENTAJES_COBERTURA_CREDITO = 'CP_GET_PORCENTAJES_COBERTURA_CREDITO';
export const CP_GET_TIPOS_VIGENCIA = 'CP_GET_TIPOS_VIGENCIA';
export const CP_HANDLE_TIPO_VIGENCIA_CHANGE = 'CP_HANDLE_TIPO_VIGENCIA_CHANGE';
export const CP_HANDLE_TERMINO_VIGENCIA_CHANGE = 'CP_HANDLE_TERMINO_VIGENCIA_CHANGE';
export const CP_GET_GASTOS_ADQUISICION = 'CP_GET_GASTOS_ADQUISICION';
export const CP_HANDLE_CLIENTE_ESPECIAL_ADD = 'CP_HANDLE_CLIENTE_ESPECIAL_ADD';
export const CP_HANDLE_CLIENTE_ESPECIAL_SAVE_EDITION = 'CP_HANDLE_CLIENTE_ESPECIAL_SAVE_EDITION';
export const CP_DELETE_CLIENTE_ESPECIAL = 'CP_DELETE_CLIENTE_ESPECIAL';
export const CP_TOGGLE_CLIENTE_ESPECIAL_DELETE_MODAL = 'CP_TOGGLE_CLIENTE_ESPECIAL_DELETE_MODAL';
export const CP_OPEN_CLIENTE_ESPECIAL_DELETE = 'CP_OPEN_CLIENTE_ESPECIAL_DELETE';
export const CP_EDIT_CLIENTE_ESPECIAL = 'CP_EDIT_CLIENTE_ESPECIAL';
export const CP_ADD_CLIENTE_ENDOSO = 'CP_ADD_CLIENTE_ENDOSO';
export const CP_DELETE_CLIENTE_ENDOSO = 'CP_DELETE_CLIENTE_ENDOSO';
export const CP_TOGGLE_CLIENTE_ENDOSO_CHECK = 'CP_TOGGLE_CLIENTE_ENDOSO_CHECK';
export const CP_TOGGLE_NO_PARAMETERIZED_CONDITION_SELECTED = 'CP_TOGGLE_NO_PARAMETERIZED_CONDITION_SELECTED';
export const CP_HANDLE_CANCEL_CLIENTE_ESPECIAL_EDITION = 'CP_HANDLE_CANCEL_CLIENTE_ESPECIAL_EDITION';
export const CP_ADD_ADJUNTO = 'CP_ADD_ADJUNTO';
export const CP_DELETE_ADJUNTO = 'CP_DELETE_ADJUNTO';
export const CP_DELETE_ADJUNTO_NUEVO = 'CP_DELETE_ADJUNTO_NUEVO';
export const CP_CHANGE_PLAN_PAGO_DOCUMENT = 'CP_CHANGE_PLAN_PAGO_DOCUMENT';
export const CP_ADD_PLAN_PAGO_CUOTA = 'CP_ADD_PLAN_PAGO_CUOTA';
export const CP_DELETE_PLAN_PAGO_CUOTA = 'CP_DELETE_PLAN_PAGO_CUOTA';
export const CP_EDIT_PLAN_PAGO_CUOTA = 'CP_EDIT_PLAN_PAGO_CUOTA';
export const CP_TOGGLE_PARAMETERIZED_CONDITION_CHECKBOX = 'CP_TOGGLE_PARAMETERIZED_CONDITION_CHECKBOX';
export const CP_CHANGE_PARAMETERIZED_CONDITION_VALUE = 'CP_CHANGE_PARAMETERIZED_CONDITION_VALUE';
export const CP_HANDLE_PARAMETERIZED_CONDITIONED_OPTION_CHANGE = 'CP_HANDLE_PARAMETERIZED_CONDITIONED_OPTION_CHANGE';
export const CP_CHANGE_TIPO_ENDOSO = 'CP_CHANGE_TIPO_ENDOSO';
export const CP_SET_LAST_STEP = 'CP_SET_LAST_STEP';
export const CP_SET_SECTION_VALIDATION = 'CP_SET_SECTION_VALIDATION';
export const CP_HANDLE_FILIAL_ACTIVIDAD_CHANGE = 'CP_HANDLE_FILIAL_ACTIVIDAD_CHANGE';
export const CP_ADD_ADJUNTO_FORM = 'CP_ADD_ADJUNTO_FORM';
export const CP_DELETE_ADJUNTO_FORM = 'CP_DELETE_ADJUNTO_FORM';
export const CP_DELETE_ADJUNTO_NUEVO_FORM = 'CP_DELETE_ADJUNTO_NUEVO_FORM';
export const CP_REMOVE_SOBREPRIMA = 'CP_REMOVE_SOBREPRIMA';
export const CP_SELECT_SOBREPRIMA = 'CP_SELECT_SOBREPRIMA';
export const CP_EDIT_SOBREPRIMA = 'CP_EDIT_SOBREPRIMA';
export const CP_ADD_SOBREPRIMA = 'CP_ADD_SOBREPRIMA';
export const CP_TOGGLE_VB = 'CP_TOGGLE_VB';
export const CP_DETAILS_CHANGE_VIGENCIA = 'CP_DETAILS_CHANGE_VIGENCIA';

export const CLEAN_ERROR = 'CLEAN_ERROR';
