import React from 'react';
import PropTypes from 'prop-types';
import FormSection from '../../../UI/FormSection';

const ClientesInnominadosSection = ({
  clientesInnominados,
  dispatch,
  handleChange,
  style,
  disabled,
}) => {
  return (
    <FormSection title="Clientes innominados" style={style}>
      <label htmlFor="clientesInnominados">
        <input
          id="clientesInnominados"
          name="clientesInnominados"
          type="checkbox"
          checked={clientesInnominados}
          onChange={() =>
            dispatch(
              handleChange('endoso.clientesInnominados', !clientesInnominados),
            )
          }
          disabled={disabled}
        />
        <span className="small-form-value">
          &nbsp;&nbsp; Póliza considera la cobertura de clientes innominados.
        </span>
      </label>
    </FormSection>
  );
};

ClientesInnominadosSection.propTypes = {
  clientesInnominados: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

ClientesInnominadosSection.defaultProps = {
  style: {},
  disabled: false,
};

export default ClientesInnominadosSection;
