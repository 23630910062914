import React from 'react';

const OportunidadRechazadaSection = () => {
  return (
    <div>
      <p>OportunidadRechazadaSection</p>
    </div>
  );
};

OportunidadRechazadaSection.propTypes = {};

OportunidadRechazadaSection.defaultProps = {};

export default OportunidadRechazadaSection;
