import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import NumberFormat from 'react-number-format';
import FormSection from '../../UI/FormSection';
import { loadClientsOptions } from '../../../helpers/clients';
import {
  handleCancelClienteEspecialEdition,
  handleCECoberturaChange,
  handleCEPeriodoGestionesPpiasChange,
  handleCEPlazoMaximoCreditoChange,
  handleCheckboxChange,
  handleClienteEspecialAdd,
  handleClienteEspecialSaveEdition,
  handleFormElementChange,
  handleSelectedClientChange,
} from '../../../redux-store/actions/creditPolicyActions';
import { clientToOption, objectsToOptions } from '../../../helpers/selects';
import IndemnizacionMaximaSection from './details-step-sections/IndemnizacionMaximaSection';
import ClientesEspecialesList from './ClientesEspecialesList';
import { CP_FS_CLIENTES, ROL_DEUDOR_CREDITO } from '../../../CONST';

const ClientesEspecialesSection = ({ form, dispatch, style, disabled }) => {
  const {
    currentClienteEspecial: cliente,
    editingClienteEspecial,
  } = form.data.endoso;
  const clienteOption = clientToOption(cliente.cliente);
  const plazosOptions = objectsToOptions(
    form.plazosGestionesPropiasDias,
    false,
    false,
    'dias',
  );
  const plazoMaximoOption = plazosOptions.find(
    plazo => plazo.value.id === cliente.plazoMaximoCreditoDiasId,
  );
  const plazoGestionesOption = plazosOptions.find(
    plazo => plazo.value.id === cliente.plazoGestionesPropiasDiasId,
  );
  const porcentagesOptions = objectsToOptions(
    form.porcentajesCoberturaCredito,
    false,
    false,
    'cobertura_pct',
  );
  const coberturaOption = porcentagesOptions.find(
    porc => porc.value.id === cliente.coberturaPctId,
  );
  const moneda = form.data.moneda ? form.data.moneda.value.nombre_corto : '';

  const handleCBChange = event => handleCheckboxChange(CP_FS_CLIENTES, event);
  const handleChange = (name, value) =>
    handleFormElementChange(CP_FS_CLIENTES, name, value);
  const disableParams =
    cliente.coberturaPctSobrescribir &&
    coberturaOption &&
    coberturaOption.value.cobertura_pct === 0;

  return (
    <FormSection title="Deudores especiales" style={style}>
      {!disabled && (
        <>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridGap: '40px',
              marginBottom: '30px',
            }}
          >
            <div style={{ gridColumn: '1 / 3' }}>
              <AsyncSelect
                id="cliente-especial"
                name="endoso.currentClienteEspecial.cliente"
                className="custom-policy-select"
                loadOptions={inputValue =>
                  loadClientsOptions(inputValue, ROL_DEUDOR_CREDITO)
                }
                defaultOptions
                cacheOptions
                value={clienteOption || null}
                onChange={selectedOption =>
                  dispatch(
                    handleSelectedClientChange(CP_FS_CLIENTES, selectedOption),
                  )
                }
                isOptionDisabled={option => {
                  const alreadySelected = form.data.endoso.clientesEspeciales.find(
                    elem => elem.cliente.id === option.value.id,
                  );
                  return (
                    alreadySelected ||
                    option.value.id === form.base.asegurado.id
                  );
                }}
                placeholder="Seleccione cliente especial"
              />
            </div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridGap: '6px 40px',
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 80px 25px',
                gridGap: '6px 8px',
                alignItems: 'center',
              }}
            >
              <FormGroup check>
                <Label check style={{ fontWeight: 'normal' }}>
                  <Input
                    name="endoso.currentClienteEspecial.plazoMaximoCreditoDiasSobrescribir"
                    type="checkbox"
                    checked={cliente.plazoMaximoCreditoDiasSobrescribir}
                    onChange={event => dispatch(handleCBChange(event))}
                    style={{ marginTop: '4px' }}
                    disabled={disableParams}
                  />
                  Plazo máximo de crédito
                </Label>
              </FormGroup>
              <div>
                <Select
                  id="ce-plazo-maximo-credito-dias"
                  name="endoso.currentClienteEspecial.plazoMaximoCreditoDiasId"
                  className="custom-policy-select"
                  options={plazosOptions}
                  onChange={selectedOption =>
                    dispatch(
                      handleCEPlazoMaximoCreditoChange(selectedOption.value),
                    )
                  }
                  value={plazoMaximoOption || null}
                  placeholder=""
                  isDisabled={!cliente.plazoMaximoCreditoDiasSobrescribir}
                />
              </div>
              <div className="small-form-value no-margin-top">días</div>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 110px',
                gridGap: '6px 8px',
                alignItems: 'center',
              }}
            >
              <FormGroup check>
                <Label check style={{ fontWeight: 'normal' }}>
                  <Input
                    name="endoso.currentClienteEspecial.costoProrrogaPctSobrescribir"
                    type="checkbox"
                    checked={cliente.costoProrrogaPctSobrescribir}
                    onChange={event => dispatch(handleCBChange(event))}
                    style={{ marginTop: '4px' }}
                    disabled={disableParams}
                  />
                  Costo de prorroga
                </Label>
              </FormGroup>
              <div>
                <NumberFormat
                  id="ce-costo-prorroga"
                  name="costoProrrogaPct"
                  className="form-control"
                  onValueChange={values => {
                    const newValue = values.floatValue;
                    if (newValue !== undefined) {
                      dispatch(
                        handleChange(
                          'endoso.currentClienteEspecial.costoProrrogaPct',
                          newValue,
                        ),
                      );
                    }
                  }}
                  allowNegative={false}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale="2"
                  fixedDecimalScale
                  value={cliente.costoProrrogaPct}
                  suffix="%"
                  disabled={!cliente.costoProrrogaPctSobrescribir}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 80px 11px',
                gridGap: '6px 8px',
                alignItems: 'center',
              }}
            >
              <FormGroup check>
                <Label check style={{ fontWeight: 'normal' }}>
                  <Input
                    name="endoso.currentClienteEspecial.coberturaPctSobrescribir"
                    type="checkbox"
                    checked={cliente.coberturaPctSobrescribir}
                    onChange={event => {
                      dispatch(handleCBChange(event));
                      if (!event.target.checked) {
                        handleCECoberturaChange({ id: null, value: null });
                      }
                    }}
                    style={{ marginTop: '4px' }}
                  />
                  Cobertura
                </Label>
              </FormGroup>
              <div>
                <Select
                  id="ce-cobertura-pct"
                  name="endoso.currentClienteEspecial.coberturaPctId"
                  className="custom-policy-select"
                  options={porcentagesOptions}
                  onChange={selectedOption =>
                    dispatch(handleCECoberturaChange(selectedOption.value))
                  }
                  value={coberturaOption || null}
                  placeholder=""
                  isDisabled={!cliente.coberturaPctSobrescribir}
                />
              </div>
              <div className="small-form-value no-margin-top">%</div>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 80px 25px',
                gridGap: '6px 8px',
                alignItems: 'center',
              }}
            >
              <FormGroup check>
                <Label check style={{ fontWeight: 'normal' }}>
                  <Input
                    name="endoso.currentClienteEspecial.plazoGestionesPropiasDiasSobrescribir"
                    type="checkbox"
                    checked={cliente.plazoGestionesPropiasDiasSobrescribir}
                    onChange={event => dispatch(handleCBChange(event))}
                    style={{ marginTop: '4px' }}
                    disabled={disableParams}
                  />
                  Período de gestiones propias
                </Label>
              </FormGroup>
              <div>
                <Select
                  id="ce-plazo-gestiones-propias-dias"
                  name="endoso.currentClienteEspecial.plazoGestionesPropiasDiasId"
                  className="custom-policy-select"
                  options={plazosOptions}
                  onChange={selectedOption =>
                    dispatch(
                      handleCEPeriodoGestionesPpiasChange(selectedOption.value),
                    )
                  }
                  value={plazoGestionesOption || null}
                  placeholder=""
                  isDisabled={!cliente.plazoGestionesPropiasDiasSobrescribir}
                />
              </div>
              <div className="small-form-value no-margin-top">días</div>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 110px',
                gridGap: '6px 8px',
                alignItems: 'center',
              }}
            >
              <FormGroup check>
                <Label check style={{ fontWeight: 'normal' }}>
                  <Input
                    name="endoso.currentClienteEspecial.umbralCoberturaAmtSobrescribir"
                    type="checkbox"
                    checked={cliente.umbralCoberturaAmtSobrescribir}
                    onChange={event => dispatch(handleCBChange(event))}
                    style={{ marginTop: '4px' }}
                    disabled={disableParams}
                  />
                  Umbral
                </Label>
              </FormGroup>
              <div>
                <NumberFormat
                  id="ce-umbral-cobertura"
                  name="ceUmbralCoberturaAmt"
                  className="form-control"
                  onValueChange={values => {
                    const newValue = values.floatValue;
                    if (newValue !== undefined) {
                      dispatch(
                        handleChange(
                          'endoso.currentClienteEspecial.umbralCoberturaAmt',
                          newValue,
                        ),
                      );
                    }
                  }}
                  allowNegative={false}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale="2"
                  fixedDecimalScale
                  value={cliente.umbralCoberturaAmt}
                  suffix={` ${moneda}`}
                  disabled={!cliente.umbralCoberturaAmtSobrescribir}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 80px 11px',
                gridGap: '6px 8px',
                alignItems: 'center',
              }}
            >
              <FormGroup check>
                <Label check style={{ fontWeight: 'normal' }}>
                  <Input
                    name="endoso.currentClienteEspecial.tasaCostoSeguroPctSobrescribir"
                    type="checkbox"
                    checked={cliente.tasaCostoSeguroPctSobrescribir}
                    onChange={event => dispatch(handleCBChange(event))}
                    style={{ marginTop: '4px' }}
                    disabled={disableParams}
                  />
                  Tasa costo seguro
                </Label>
              </FormGroup>
              <div>
                <NumberFormat
                  id="ce-tasa-costo-seguro"
                  name="configuracionSiniestroDias"
                  className="form-control"
                  onValueChange={values => {
                    const newValue = values.floatValue;
                    if (
                      newValue !== undefined &&
                      newValue !== cliente.tasaCostoSeguroPct
                    ) {
                      dispatch(
                        handleChange(
                          'endoso.currentClienteEspecial.tasaCostoSeguroPct',
                          newValue,
                        ),
                      );
                    }
                  }}
                  decimalSeparator=","
                  decimalScale="3"
                  fixedDecimalScale
                  allowNegative={false}
                  isAllowed={values =>
                    !values.value || values.floatValue <= 100
                  }
                  value={cliente.tasaCostoSeguroPct}
                  disabled={!cliente.tasaCostoSeguroPctSobrescribir}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="small-form-value no-margin-top">%</div>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 80px 25px',
                gridGap: '6px 8px',
                alignItems: 'center',
              }}
            >
              <FormGroup check>
                <Label check style={{ fontWeight: 'normal' }}>
                  <Input
                    name="endoso.currentClienteEspecial.configuracionSiniestroDiasSobrescribir"
                    type="checkbox"
                    checked={cliente.configuracionSiniestroDiasSobrescribir}
                    onChange={event => dispatch(handleCBChange(event))}
                    style={{ marginTop: '4px' }}
                    disabled={disableParams}
                  />
                  Configuración de siniestro
                </Label>
              </FormGroup>
              <div>
                <NumberFormat
                  id="ce-configuracion-siniestro-dias"
                  name="configuracionSiniestroDias"
                  className="form-control"
                  onValueChange={values => {
                    const newValue = values.floatValue;
                    if (newValue !== undefined) {
                      dispatch(
                        handleChange(
                          'endoso.currentClienteEspecial.configuracionSiniestroDias',
                          newValue,
                        ),
                      );
                    }
                  }}
                  allowNegative={false}
                  decimalSeparator={false}
                  value={cliente.configuracionSiniestroDias}
                  disabled={!cliente.configuracionSiniestroDiasSobrescribir}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="small-form-value no-margin-top">días</div>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 110px',
                gridGap: '6px 8px',
                alignItems: 'center',
              }}
            >
              <FormGroup check>
                <Label check style={{ fontWeight: 'normal' }}>
                  <Input
                    name="endoso.currentClienteEspecial.deducibleAmtSobrescribir"
                    type="checkbox"
                    checked={cliente.deducibleAmtSobrescribir}
                    onChange={event => dispatch(handleCBChange(event))}
                    style={{ marginTop: '4px' }}
                    disabled={disableParams}
                  />
                  Deducibles
                </Label>
              </FormGroup>
              <div>
                <NumberFormat
                  id="ce-deducible"
                  name="deducibleAmt"
                  className="form-control"
                  onValueChange={values => {
                    const newValue = values.floatValue;
                    if (newValue !== undefined) {
                      dispatch(
                        handleChange(
                          'endoso.currentClienteEspecial.deducibleAmt',
                          newValue,
                        ),
                      );
                    }
                  }}
                  allowNegative={false}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale="2"
                  fixedDecimalScale
                  value={cliente.deducibleAmt}
                  suffix={` ${moneda}`}
                  disabled={!cliente.deducibleAmtSobrescribir}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
            <div />
            <div style={{ gridColumn: '1 / 3' }}>
              <FormGroup check>
                <Label check style={{ fontWeight: 'normal' }}>
                  <Input
                    name="endoso.currentClienteEspecial.indemnizacionMaxSobrescribir"
                    type="checkbox"
                    checked={cliente.indemnizacionMaxSobrescribir}
                    onChange={event => dispatch(handleCBChange(event))}
                    style={{ marginTop: '4px' }}
                    disabled={disableParams}
                  />
                  Indemnización máxima
                </Label>
              </FormGroup>
              <div style={{ marginLeft: '20px', width: '380px' }}>
                <IndemnizacionMaximaSection
                  tipoIndemnizacionMaxId={cliente.tipoIndemnizacionMaxId}
                  indemnizacionMaxAmt={cliente.indemnizacionMaxAmt}
                  moneda={moneda}
                  fieldsBasePath="currentClienteEspecial."
                  disabled={!cliente.indemnizacionMaxSobrescribir}
                  labelStyle={{ fontWeight: 'normal' }}
                  dispatch={dispatch}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'right' }}>
            {editingClienteEspecial && (
              <Button
                color="orsan-link"
                style={{ fontSize: '14px' }}
                onClick={() => dispatch(handleCancelClienteEspecialEdition())}
              >
                Cancelar
              </Button>
            )}
            <Button
              color="orsan-secondary"
              style={{ fontSize: '14px', marginLeft: '10px' }}
              disabled={
                !cliente.cliente ||
                (cliente.coberturaPctSobrescribir &&
                  cliente.coberturaPctValue === null)
              }
              onClick={() =>
                editingClienteEspecial
                  ? dispatch(handleClienteEspecialSaveEdition())
                  : dispatch(handleClienteEspecialAdd())
              }
            >
              {editingClienteEspecial ? 'Guardar cambios' : 'Agregar cliente'}
            </Button>
          </div>
        </>
      )}
      <div style={{ marginTop: '10px' }}>
        <ClientesEspecialesList
          clientesEspeciales={form.data.endoso.clientesEspeciales}
          editingClienteEspecial={editingClienteEspecial}
          moneda={moneda}
          clienteEspecialDeleteModal={
            form.data.endoso.clienteEspecialDeleteModal
          }
          dispatch={dispatch}
          disabled={disabled}
        />
      </div>
    </FormSection>
  );
};

ClientesEspecialesSection.propTypes = {
  form: PropTypes.shape({
    plazosGestionesPropiasDias: PropTypes.array,
    porcentajesCoberturaCredito: PropTypes.array,
    base: PropTypes.object,
    data: PropTypes.shape({
      moneda: PropTypes.object,
      endoso: PropTypes.shape({
        currentClienteEspecial: PropTypes.shape({
          searchText: PropTypes.string,
          cliente: PropTypes.object,
        }),
        clientesEspeciales: PropTypes.array,
        clienteEspecialDeleteModal: PropTypes.shape({
          isOpen: PropTypes.bool,
          loading: PropTypes.bool,
        }),
        editingClienteEspecial: PropTypes.bool,
      }),
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

ClientesEspecialesSection.defaultProps = {
  style: {},
  disabled: false,
};

export default ClientesEspecialesSection;
