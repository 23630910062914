import React, { useState } from 'react';
import { Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { API, BASE_URL } from '../../../../CONST';
import auth from '../../../../helpers/auth';

const Parameter = ({
  paramKey,
  spec,
  val,
  onChange,
  moneda,
  required,
  validate,
  disabled,
  isAllowed,
  allowNegative,
}) => {
  const [filename, setFilename] = useState('');

  const handleFile = (e) => {
    const { files } = e.target;
    const reader = new FileReader();
    if (files.length === 0) {
      setFilename('');
      onChange(undefined);
      return;
    }
    const { name } = files[0];
    reader.readAsArrayBuffer(files[0]);
    reader.onload = (error) => {
      const body = error.target.result;
      auth
        .fetch(`${BASE_URL}${API.upload}/`, {
          method: 'PUT',
          body,
          headers: {
            'Content-Disposition': `attachment; filename="${encodeURI(name)}"`,
          },
        })
        .then(onChange)
        .then(setFilename(name));
    };
  };

  const invalid = validate && required && (val === undefined || val === '');
  const labelWidth = spec.type === 'text' ? '150px' : '430px';

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `${labelWidth} 60px 60px auto`,
        gridGap: '15px',
        marginTop: '10px',
        alignItems: 'center',
      }}
    >
      <Label style={{ marginBottom: 0 }}>{spec.label}</Label>
      {['int', 'float'].includes(spec.type) && (
        <>
          <NumberFormat
            className={`form-control${invalid ? ' is-invalid' : ''}`}
            value={val}
            onValueChange={(values) => onChange(values.floatValue)}
            disabled={!required || disabled}
            decimalSeparator={spec.type === 'float' && ','}
            isAllowed={isAllowed}
            allowNegative={allowNegative}
          />
          <span className="small-text">{spec.unit === 'CURRENCY' ? moneda : spec.unit}</span>
        </>
      )}
      {spec.type === 'str' && (
        <>
          <Input
            value={val || ''}
            onChange={(e) => onChange(e.target.value)}
            disabled={!required || disabled}
            invalid={invalid}
            style={{ gridColumn: '2/4' }}
          />
          <span />
        </>
      )}
      {spec.type === 'text' && (
        <>
          <textarea
            value={val}
            onChange={(e) => onChange(e.target.value)}
            className={`form-control${validate && invalid ? ' is-invalid' : ''}`}
            disabled={!required || disabled}
            style={{ gridColumn: '2/5' }}
          />
        </>
      )}
      {spec.type === 'file' && (
        <div style={{ gridColumn: '2/5', display: 'flex' }}>
          {val && val.file && (
            <a
              className="reactstrap-table-link"
              href={val.file.file}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginLeft: '-25px',
                marginRight: '10px',
                alignSelf: 'center',
              }}
            >
              <FontAwesomeIcon icon={faDownload} />
            </a>
          )}
          <div className="custom-file" style={{ width: '350px' }}>
            <Input
              type="file"
              className="custom-file-input"
              name={paramKey}
              id={paramKey}
              onChange={handleFile}
              disabled={!required || disabled}
              invalid={invalid}
            />
            <Label className="custom-file-label">
              {filename || `Seleccione ${val ? 'otro' : 'un'} archivo`}
            </Label>
          </div>
        </div>
      )}
    </div>
  );
};

Parameter.propTypes = {
  paramKey: PropTypes.string.isRequired,
  spec: PropTypes.shape({
    label: PropTypes.string,
    unit: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  val: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  moneda: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  isAllowed: PropTypes.func,
  allowNegative: PropTypes.bool,
};

Parameter.defaultProps = {
  val: undefined,
  disabled: false,
  isAllowed: () => true,
  allowNegative: true,
};

export default Parameter;
