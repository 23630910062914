import React, { useEffect, useState } from 'react';
import { get, getAll } from 'helpers/requests';
import { Col, Row, Label } from 'reactstrap';
import { API, BASE_URL, ROLES, TIPO_INTERMEDIARIO_DIRECTO_ID } from 'CONST';
import PropTypes from 'prop-types';

import NumberFormat from "react-number-format";
import Select from 'react-select';
import { objectToOption } from '../../helpers/selects';

const ProjectCommercialSection = ({
  setParametrosComerciales,
  setHasParametrosComerciales,
  edit,
  activo,
  dataEdition,
  isDisabled,
}) => {
  // Opciones
  const [intermediarios, setIntermediarios] = useState([]);
  const [periodicidades, setPeriodicidades] = useState([]);
  const [tipoCobranzas, setTipoCobranzas] = useState([]);
  const [formaPolizas, setFormaPolizas] = useState(null);
  const [opcionesTipoIntermediario, setOpcionesTipoIntermediario] = useState({});

  // Parámetros comerciales
  const [intermediario, setIntermediario] = useState(null);
  const [comision, setComision] = useState(undefined);
  const [tasaAnual, setTasaAnual] = useState(undefined);
  const [primaMinima, setPrimaMinima] = useState(undefined);
  const [periodicidad, setPeriodicidad] = useState(null);
  const [tipoCobranza, setTipoCobranza] = useState(null);
  const [formaPoliza, setFormaPoliza] = useState(null);
  const [tipoIntermediario, setTipoIntermediario] = useState(null);

  const getOption = (node) => ({label: node && node.descripcion, value: node})

  const isIntermediarioDirecto = () => {
    return tipoIntermediario && tipoIntermediario.value.id === TIPO_INTERMEDIARIO_DIRECTO_ID;
  };

  const isValidParametrosComerciales = () => (
    !!(tipoIntermediario
    && (intermediario || isIntermediarioDirecto())
    && (comision >= 0)
    && (tasaAnual > 0)
    && (primaMinima >= 0)
    && periodicidad
    && tipoCobranza
    && formaPoliza)
  );

  const setData = (data) => {
    setIntermediario(data.intermediario && {label: `${data.intermediario.rut} - ${data.intermediario.nombre}`, value: data.intermediario});
    setComision(data.comision);
    setTasaAnual(data.tasa_anual);
    setPrimaMinima(data.prima_minima_neta);
    setPeriodicidad(objectToOption(data.frecuencia_facturacion_data));
    setTipoCobranza(objectToOption(data.tipo_cobranza_data))
    setFormaPoliza(objectToOption(data.forma_poliza_data));
    setTipoIntermediario(data.tipo_intermediario && {label: data.tipo_intermediario.descripcion, value: data.tipo_intermediario})
  }

  useEffect(() => {
    getAll(`${BASE_URL}${API.clientes}?roles__term=${ROLES.intermediarioGarantia}`, 'next').then((response) => {
      setIntermediarios(response !== undefined && response.map(node => ({label: `${node.rut} - ${node.nombre}`, value: node})));
    });

    get(`${BASE_URL}${API.facturacion}`).then((response) => {
      setPeriodicidades(response !== undefined && response.map(node => getOption(node)));
      setPeriodicidad(response !== undefined && getOption(response[0]));
    });

    get(`${BASE_URL}${API.tipoCobranza}`).then((response) => {
      setTipoCobranzas(response !== undefined && response.map(node => getOption(node)));
      setTipoCobranza(response !== undefined && getOption(response[0]));
    });

    get(`${BASE_URL}${API.formaPoliza}`).then((response) => {
      setFormaPolizas(response !== undefined && response.map(node => getOption(node)));
      setFormaPoliza(response !== undefined && getOption(response[0]));
    });
    get(`${BASE_URL}${API.tipoIntermediario}`).then((response) => {
      setOpcionesTipoIntermediario(response && response.map(node => ({label: node.descripcion, value: node})))
    })
  }, [])

  useEffect(() => {
    if (edit && Object.keys(dataEdition).length !== 0){
      setData(dataEdition);
    }
  }, [edit, dataEdition]);

  useEffect(() => {
    if (isIntermediarioDirecto()) {
      setIntermediario(null);
      setComision(0);
    }
  }, [tipoIntermediario])

  useEffect(() => {
    setHasParametrosComerciales(isValidParametrosComerciales());
    setParametrosComerciales({
      intermediario_id: intermediario && intermediario.value && intermediario.value.id,
      tasa_anual: tasaAnual,
      prima_minima_neta: primaMinima,
      frecuencia_facturacion: periodicidad && periodicidad.value && periodicidad.value.nemotecnico,
      tipo_cobranza: tipoCobranza && tipoCobranza.value && tipoCobranza.value.nemotecnico,
      forma_poliza: formaPoliza && formaPoliza.value && formaPoliza.value.nemotecnico,
      comision,
      tipo_intermediario_id: tipoIntermediario && tipoIntermediario.value && tipoIntermediario.value.id,
    });
  }, [
    tipoIntermediario,
    intermediario,
    comision,
    tasaAnual,
    primaMinima,
    periodicidad,
    tipoCobranza,
    formaPoliza,
  ]);

  return (
    <div>
      <Row>
        <Col sm={4}>
          <Label>
            Tipo Intermediario
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px'}}>
            {!activo ? (
              <Select
                id="tipoIntermediario"
                name="tipoIntermediario"
                className="custom-policy-select"
                options={opcionesTipoIntermediario}
                onChange={(event) => setTipoIntermediario(event)}
                value={tipoIntermediario}
                placeholder="Seleccione Tipo Intermediario"
                isDisabled={!isDisabled}
              />
            ) : (
              <p className="form-values">{tipoIntermediario && tipoIntermediario.label}</p>
            )}
          </Col>
        </Col>
        <Col sm={8}>
          <Label for="intermediario">
            Intermediario
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            {!activo ? (
              <Select
                id="intermediario"
                name="intermediario"
                className="custom-policy-select"
                options={intermediarios}
                onChange={(event) => setIntermediario(event)}
                value={intermediario}
                placeholder="Seleccione Intermediario"
                isDisabled={!isDisabled || isIntermediarioDirecto()}
              />
            ) : (
              <p className="form-values">{intermediario && intermediario.label}</p>
            )}
          </Col>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Label for="comision">
            % Comisión
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            <NumberFormat
              className="form-control"
              decimalScale="2"
              decimalSeparator=","
              value={comision}
              displayType={activo ? 'text' : ''}
              onValueChange={({floatValue}) => setComision(floatValue)}
              placeholder="Ingrese comisión del proyecto"
              thousandSeparator="."
              allowNegative={false}
              renderText={(value) => <p className="small-form-value">{value || 0}</p>}
              disabled={!isDisabled || isIntermediarioDirecto()}
            />
          </Col>
        </Col>
        <Col sm={4}>
          <Label for="tasaAnual">
            Tasa Anual (%)
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            <NumberFormat
              className="form-control"
              decimalScale="5"
              decimalSeparator=","
              value={tasaAnual}
              displayType={activo ? 'text' : ''}
              onValueChange={({floatValue}) => setTasaAnual(floatValue)}
              placeholder="Ingrese tasa anual del proyecto"
              thousandSeparator="."
              allowNegative={false}
              renderText={(value) => <p className="small-form-value">{value || 0}</p>}
              disabled={!isDisabled}
            />
          </Col>
        </Col>
        <Col sm={4}>
          <Label for="primaMinima">
            Prima Mínima Neta (UF)
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            <NumberFormat
              className="form-control"
              decimalScale="5"
              decimalSeparator=","
              value={primaMinima}
              displayType={activo ? 'text' : ''}
              onValueChange={({floatValue}) => setPrimaMinima(floatValue)}
              placeholder="Ingrese prima mínima del proyecto"
              thousandSeparator="."
              allowNegative={false}
              renderText={(value) => <p className="small-form-value">{value || 0}</p>}
              disabled={!isDisabled}
            />
          </Col>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Label for="periodicidadFacturacion">
            Periodicidad Facturación
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            {!activo ? (
              <Select
                id="periodicidad"
                name="periodicidad"
                className="custom-policy-select"
                options={periodicidades}
                onChange={(event) => setPeriodicidad(event)}
                value={periodicidad}
                placeholder="Seleccione Periodicidad"
                isDisabled={!isDisabled}
              />
            ) : (
              <p className="form-values">{periodicidad && periodicidad.label}</p>
            )}
          </Col>
        </Col>
        <Col sm={4}>
          <Label for="tipoCobranza">
            Tipo Cobranza
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            {!activo ? (
              <Select
                id="tipoCobranza"
                name="tipoCobranza"
                className="custom-policy-select"
                options={tipoCobranzas}
                onChange={(event) => setTipoCobranza(event)}
                value={tipoCobranza}
                placeholder="Seleccione Tipo Cobranza"
                isDisabled={!isDisabled}
              />
            ) : (
              <p className="form-values">{tipoCobranza && tipoCobranza.label}</p>
            )}
          </Col>
        </Col>
        <Col sm={4}>
          <Label for="formaPoliza">
            Forma Póliza
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            {!activo ? (
              <Select
                id="formaPoliza"
                name="formaPoliza"
                className="custom-policy-select"
                options={formaPolizas}
                onChange={(event) => setFormaPoliza(event)}
                value={formaPoliza}
                placeholder="Seleccione forma de póliza"
                isDisabled={!isDisabled}
              />
            ) : (
              <p className="form-values">{formaPoliza && formaPoliza.label}</p>
            )}
          </Col>
        </Col>
      </Row>
    </div>
  );
}

ProjectCommercialSection.propTypes = {
  setParametrosComerciales: PropTypes.func.isRequired,
  setHasParametrosComerciales: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  activo: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  dataEdition: PropTypes.shape({
    organizacion: PropTypes.shape({
      rut: PropTypes.string,
      nombre: PropTypes.string,
    }),
  }),
};

ProjectCommercialSection.defaultProps = {
  dataEdition: PropTypes.shape({
    organizacion: null,
  })
};

export default ProjectCommercialSection;
