import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import BaseTable from '../../UI/BaseTable';
import {
  cellOperation,
  headerOperation,
  enabledCellOperation,
} from '../../../helpers/tables';
import {
  TIPO_INDEMNIZACION_MAXIMA_MONTO_UF,
  TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID,
} from '../../../CONST';
import WarningModal from '../../UI/WarningModal';
import {
  CP_DELETE_CLIENTE_ESPECIAL,
  CP_EDIT_CLIENTE_ESPECIAL,
  CP_OPEN_CLIENTE_ESPECIAL_DELETE,
  CP_TOGGLE_CLIENTE_ESPECIAL_DELETE_MODAL,
} from '../../../redux-store/constants/action-types';

const ClientesEspecialesList = ({
  clientesEspeciales,
  editingClienteEspecial,
  moneda,
  clienteEspecialDeleteModal,
  dispatch,
  disabled,
}) => {
  const columns = [
    {
      id: 'clienteNombre',
      Header: headerOperation('Nombre cliente'),
      accessor: data => cellOperation(data.cliente.nombre),
      minWidth: 200,
      fixed: 'left',
    },
    {
      id: 'clienteRut',
      Header: headerOperation('RUT'),
      accessor: data => cellOperation(data.cliente.rut),
      minWidth: 100,
      fixed: 'left',
    },
    {
      id: 'plazoMaximoCredito',
      Header: headerOperation('Plazo máximo crédito (días)'),
      accessor: data =>
        enabledCellOperation(
          data.plazoMaximoCreditoDiasSobrescribir,
          data.plazoMaximoCreditoDiasValue,
        ),
      minWidth: 150,
    },
    {
      id: 'costoProrroga',
      Header: headerOperation(`Costo prórroga (%)`),
      accessor: data =>
        enabledCellOperation(
          data.costoProrrogaPctSobrescribir,
          <NumberFormat
            id="costo-prorroga"
            name="costo-prorroga"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale="2"
            fixedDecimalScale
            value={data.costoProrrogaPct}
            displayType="text"
            suffix="%"
          />,
        ),
      minWidth: 150,
    },
    {
      id: 'cobertura',
      Header: headerOperation(`Cobertura (%)`),
      accessor: data =>
        enabledCellOperation(
          data.coberturaPctSobrescribir,
          data.coberturaPctValue,
        ),
      minWidth: 150,
    },
    {
      id: 'periodoGestionesPropias',
      Header: headerOperation('Período gestiones propias (días)'),
      accessor: data =>
        enabledCellOperation(
          data.plazoGestionesPropiasDiasSobrescribir,
          data.plazoGestionesPropiasDiasValue,
        ),
      minWidth: 150,
    },
    {
      id: 'umbral',
      Header: headerOperation(`Umbral (${moneda})`),
      accessor: data =>
        enabledCellOperation(
          data.umbralCoberturaAmtSobrescribir,
          <NumberFormat
            id="umbral"
            name="umbral"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale="2"
            fixedDecimalScale
            value={data.umbralCoberturaAmt}
            displayType="text"
          />,
        ),
      minWidth: 150,
    },
    {
      id: 'tasaCostoSeguro',
      Header: headerOperation('Tasa costo seguro (%)'),
      accessor: data =>
        enabledCellOperation(
          data.tasaCostoSeguroPctSobrescribir,
          <NumberFormat
            id="tasa-costo-seguro"
            name="tasa-costo-seguro"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale="3"
            fixedDecimalScale
            value={data.tasaCostoSeguroPct}
            displayType="text"
          />,
        ),
      minWidth: 150,
    },
    {
      id: 'configuracionSiniestro',
      Header: headerOperation('Configuración siniestro (días)'),
      accessor: data =>
        enabledCellOperation(
          data.configuracionSiniestroDiasSobrescribir,
          data.configuracionSiniestroDias,
        ),
      minWidth: 150,
    },
    {
      id: 'deducibles',
      Header: headerOperation(`Deducibles (${moneda})`),
      accessor: data =>
        enabledCellOperation(
          data.deducibleAmtSobrescribir,
          <NumberFormat
            id="deducibles"
            name="deducibles"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale="2"
            fixedDecimalScale
            value={data.deducibleAmt}
            displayType="text"
          />,
        ),
      minWidth: 150,
    },
    {
      id: 'indemnizacionMaximaNumeroVeces',
      Header: headerOperation(`Indemnización máxima (veces prima devengada)`),
      accessor: data =>
        enabledCellOperation(
          data.indemnizacionMaxSobrescribir,
          data.tipoIndemnizacionMaxId ===
            TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID
            ? data.indemnizacionMaxAmt
            : '-',
        ),
      minWidth: 200,
    },
    {
      id: 'indemnizacionMaximaMonto',
      Header: headerOperation(`Indemnización máxima (monto ${moneda})`),
      accessor: data =>
        enabledCellOperation(
          data.indemnizacionMaxSobrescribir,
          <NumberFormat
            id="indemnizacion-maxima"
            name="indemnizacion-maxima"
            thousandSeparator="."
            decimalSeparator=","
            decimalScale="2"
            fixedDecimalScale
            value={
              data.tipoIndemnizacionMaxId === TIPO_INDEMNIZACION_MAXIMA_MONTO_UF
                ? data.indemnizacionMaxAmt
                : '-'
            }
            displayType="text"
          />,
        ),
      minWidth: 200,
    },
  ];

  if (!disabled) {
    const actionColumn = {
      id: 'actions',
      Header: headerOperation('Acciones'),
      Cell: row =>
        cellOperation(
          <>
            <button
              type="button"
              className="table-link"
              onClick={() =>
                dispatch({ type: CP_EDIT_CLIENTE_ESPECIAL, payload: row.index })
              }
              title="editar"
              disabled={editingClienteEspecial}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button
              type="button"
              className="table-link"
              onClick={() =>
                dispatch({
                  type: CP_OPEN_CLIENTE_ESPECIAL_DELETE,
                  payload: row.index,
                })
              }
              title="eliminar"
              style={{ marginLeft: '10px' }}
              disabled={editingClienteEspecial}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </>,
        ),
      minWidth: 90,
      fixed: 'right',
    };

    columns.push(actionColumn);
  }
  return (
    <>
      <BaseTable
        columns={columns}
        data={clientesEspeciales}
        sortable={false}
        resizable={false}
        hasFixedColumns
      />
      <WarningModal
        isOpen={clienteEspecialDeleteModal.isOpen}
        toggle={() =>
          dispatch({ type: CP_TOGGLE_CLIENTE_ESPECIAL_DELETE_MODAL })
        }
        handleOk={() => dispatch({ type: CP_DELETE_CLIENTE_ESPECIAL })}
        loading={clienteEspecialDeleteModal.loading}
        okBtnText="Eliminar"
        confirmMsg="Está a un paso de eliminar un cliente especial"
      />
    </>
  );
};

ClientesEspecialesList.propTypes = {
  clientesEspeciales: PropTypes.arrayOf(PropTypes.object).isRequired,
  editingClienteEspecial: PropTypes.bool.isRequired,
  moneda: PropTypes.string.isRequired,
  clienteEspecialDeleteModal: PropTypes.shape({
    isOpen: PropTypes.bool,
    loading: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ClientesEspecialesList.defaultProps = {
  disabled: false,
};

export default ClientesEspecialesList;
