import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { handleBalanceCalculatedFieldsChange } from '../../../../redux-store/actions/creditPolicyActions';
import {
  TIPO_INDEMNIZACION_MAXIMA_MONTO_UF,
  TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID,
} from '../../../../CONST';
import isUserHasProfile from '../../../../helpers/profileValidator';
import { INDEMNIZACION_MAXIMA_VECES_CEO } from '../../../../helpers/profilePermission';

class IndemnizacionMaximaSection extends Component {
  handleOptionChange = (event) => {
    const { calculate, fieldsBasePath, endoso, moneda, dispatch, handleChange } = this.props;
    const tipoId = parseInt(event.target.value, 10);

    if (calculate) {
      dispatch(
        handleBalanceCalculatedFieldsChange(
          'tipo_indemnizacion_max_id',
          `${fieldsBasePath}tipoIndemnizacionMaxId`,
          tipoId,
          endoso,
          moneda,
        ),
      );
    } else {
      dispatch(handleChange(`endoso.${fieldsBasePath}tipoIndemnizacionMaxId`, tipoId));
    }
  };

  render() {
    const {
      tipoIndemnizacionMaxId,
      indemnizacionMaxAmt,
      calculate,
      handleChange,
      fieldsBasePath,
      disabled,
      endoso,
      moneda,
      labelStyle,
      dispatch,
      validate,
    } = this.props;

    const validationClassName = `form-control${
      validate && !isUserHasProfile(INDEMNIZACION_MAXIMA_VECES_CEO) && indemnizacionMaxAmt > 35
        ? ' is-invalid'
        : ''
    }`;

    return (
      <>
        <FormGroup check className="radio-with-input-at-the-end">
          <Label check style={labelStyle}>
            <Input
              type="radio"
              value={TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID}
              checked={tipoIndemnizacionMaxId === TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID}
              onChange={this.handleOptionChange}
              disabled={disabled}
            />
            Número de veces la prima devengada
          </Label>
          <NumberFormat
            name="indemnizacionMaximaVecesPrimaMensual"
            className={
              tipoIndemnizacionMaxId === TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID
                ? validationClassName
                : 'form-control'
            }
            onValueChange={(values) => {
              const newValue = values.floatValue;
              if (calculate && newValue !== undefined && newValue !== indemnizacionMaxAmt) {
                dispatch(
                  handleBalanceCalculatedFieldsChange(
                    'indemnizacion_max_amt',
                    `${fieldsBasePath}indemnizacionMaxAmt`,
                    newValue,
                    endoso,
                    moneda,
                  ),
                );
              } else {
                dispatch(handleChange(`endoso.${fieldsBasePath}indemnizacionMaxAmt`, newValue));
              }
            }}
            thousandSeparator="."
            decimalSeparator={false}
            allowNegative={false}
            value={
              tipoIndemnizacionMaxId === TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID
                ? indemnizacionMaxAmt
                : 0
            }
            disabled={
              tipoIndemnizacionMaxId !== TIPO_INDEMNIZACION_MAXIMA_VECES_PRIMA_INICIAL_ID ||
              disabled
            }
            style={{ width: '110px' }}
            isAllowed={(values) =>
              !values.value ||
              isUserHasProfile(INDEMNIZACION_MAXIMA_VECES_CEO) ||
              values.floatValue <= 35
            }
          />
        </FormGroup>
        <FormGroup check className="radio-with-input-at-the-end">
          <Label check style={labelStyle}>
            <Input
              type="radio"
              value={TIPO_INDEMNIZACION_MAXIMA_MONTO_UF}
              checked={tipoIndemnizacionMaxId === TIPO_INDEMNIZACION_MAXIMA_MONTO_UF}
              onChange={this.handleOptionChange}
              disabled={disabled}
            />
            Monto
          </Label>
          <NumberFormat
            name="indemnizacionMaximaMontoUf"
            className={
              tipoIndemnizacionMaxId === TIPO_INDEMNIZACION_MAXIMA_MONTO_UF
                ? validationClassName
                : 'form-control'
            }
            onValueChange={(values) => {
              const newValue = values.floatValue;
              if (calculate && newValue !== undefined && newValue !== indemnizacionMaxAmt) {
                dispatch(
                  handleBalanceCalculatedFieldsChange(
                    'indemnizacion_max_amt',
                    `${fieldsBasePath}indemnizacionMaxAmt`,
                    newValue,
                    endoso,
                    moneda,
                  ),
                );
              } else {
                dispatch(handleChange(`endoso.${fieldsBasePath}indemnizacionMaxAmt`, newValue));
              }
            }}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale="2"
            fixedDecimalScale
            allowNegative={false}
            value={
              tipoIndemnizacionMaxId === TIPO_INDEMNIZACION_MAXIMA_MONTO_UF
                ? indemnizacionMaxAmt
                : 0
            }
            disabled={tipoIndemnizacionMaxId !== TIPO_INDEMNIZACION_MAXIMA_MONTO_UF || disabled}
            suffix={` ${moneda}`}
            style={{ width: '110px' }}
          />
        </FormGroup>
      </>
    );
  }
}

IndemnizacionMaximaSection.propTypes = {
  tipoIndemnizacionMaxId: PropTypes.number.isRequired,
  indemnizacionMaxAmt: PropTypes.number,
  endoso: PropTypes.shape({
    estimacionVentasAmt: PropTypes.number,
    ventasPct: PropTypes.number,
    tasaCostoSeguroDefectoPct: PropTypes.number,
    tipoIndemnizacionMaxId: PropTypes.number,
    indemnizacionMaxAmt: PropTypes.number,
  }),
  moneda: PropTypes.string.isRequired,
  fieldsBasePath: PropTypes.string,
  calculate: PropTypes.bool,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  labelStyle: PropTypes.object,
  validate: PropTypes.bool,
};

IndemnizacionMaximaSection.defaultProps = {
  indemnizacionMaxAmt: null,
  fieldsBasePath: '',
  endoso: null,
  calculate: false,
  disabled: false,
  labelStyle: {},
  validate: false,
};

export default IndemnizacionMaximaSection;
