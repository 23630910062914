import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BASE_URL,
  ESTADOS_OPORTUNIDAD,
  API,
  OPORTUNIDADES_STATE_SECTION_MAP,
  TIPO_INTERMEDIARIO_DIRECTO_ID,
} from 'CONST';
import { FormGroup, Col, Label, Input, Button, FormFeedback } from 'reactstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import { patch, post } from 'helpers/requests';
import CheckModal from 'components/shared/CheckModal';
import WarningModal from 'components/UI/WarningModal';
import AvanzarOportunidadConfirmationModal from './AvanzarOportunidadConfirmationModal';

const ParametrosComercialesSection = ({
  fullData,
  setFullData,
  setInitialSection,
  id,
  estadosOportunidad,
  observaciones,
}) => {
  const [primaNeta, setPrimaNeta] = useState();
  const [comision, setComision] = useState();
  const [editableSection, setEditableSection] = useState(false);

  const [observacion, setObservacion] = useState('');
  const [observacionesEstado, setObservacionesEstado] = useState([]);

  const editableStates = [ESTADOS_OPORTUNIDAD.evaluacionAprobada];

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (fullData) {
      if (fullData.prima_neta != null) {
        setPrimaNeta(fullData.prima_neta);
      }
      if (fullData.comision !== null) {
        setComision(fullData.comision);
      }
      if (fullData.estado && editableStates.includes(fullData.estado.nemotecnico)) {
        setEditableSection(true);
      }
    }
  }, [fullData]);

  useEffect(() => {
    if (fullData && fullData.estado && editableStates.includes(fullData.estado.nemotecnico)) {
      // Si es intermediario directo, la comisión es 0.
      if (
        fullData.tipo_intermediario &&
        fullData.tipo_intermediario.id === TIPO_INTERMEDIARIO_DIRECTO_ID
      ) {
        setComision(0);
      }
    }
  }, []);

  useEffect(() => {
    if (observaciones) {
      setObservacionesEstado(
        observaciones.filter(
          (obs) => obs.estado.nemotecnico === ESTADOS_OPORTUNIDAD.parametrosIngresados,
        ),
      );
    }
  }, [observaciones, fullData]);

  const postObservacion = () => {
    if (observacion) {
      const bodyObservacion = {
        oportunidad_id: id,
        estado_id: estadosOportunidad.find(
          (e) => e.nemotecnico === ESTADOS_OPORTUNIDAD.parametrosIngresados,
        ).id,
        observacion,
      };
      post(`${BASE_URL}${API.observaciones}/`, bodyObservacion).catch(() => {
        setShowErrorModal(true);
      });
    }
  };

  const guardarParametrosComerciales = () => {
    setLoading(true);
    const body = {
      prima_neta: primaNeta,
      comision,
      estado_id: estadosOportunidad.find(
        (e) => e.nemotecnico === ESTADOS_OPORTUNIDAD.parametrosIngresados,
      ).id,
    };
    patch(`${BASE_URL}${API.oportunidades_garantia}/${id}/`, body)
      .then((responseOportunidad) => {
        postObservacion();
        setFullData(responseOportunidad);
        setInitialSection(OPORTUNIDADES_STATE_SECTION_MAP[responseOportunidad.estado.nemotecnico]);
        setEditableSection(false);
        setShowUpdateModal(true);
      })
      .catch(() => {
        setShowErrorModal(true);
      })
      .finally(() => {
        setConfirmationModal(false);
        setLoading(false);
      });
  };

  const isValidInfo = () => {
    return primaNeta != null && primaNeta > 0 && comision != null && comision >= 0;
  };

  return (
    <div>
      <FormGroup row>
        <Col sm={2}>
          <Label>
            Prima Neta
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col sm={5}>
          <NumberFormat
            className="form-control"
            decimalScale="2"
            decimalSeparator="."
            value={primaNeta}
            onValueChange={(values) => setPrimaNeta(values.floatValue)}
            allowNegative={false}
            disabled={!editableSection}
          />
          {primaNeta !== undefined && primaNeta <= 0 && (
            <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px', display: 'block' }}>
              Prima neta debe ser mayor a 0
            </FormFeedback>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={2}>
          <Label>
            % Comisión
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col sm={5}>
          <NumberFormat
            className="form-control"
            decimalScale="2"
            decimalSeparator="."
            value={comision}
            onValueChange={(values) => setComision(values.floatValue)}
            allowNegative={false}
            disabled={
              !editableSection ||
              (fullData && fullData.tipo_intermediario.id === TIPO_INTERMEDIARIO_DIRECTO_ID)
            }
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={2}>
          <Label>Observaciones</Label>
        </Col>
        <Col>
          <Input
            id="observacion-info-inicial"
            type="textarea"
            value={
              !editableSection && observacionesEstado.length > 0
                ? observacionesEstado[0].observacion
                : observacion || ''
            }
            onChange={(event) => setObservacion(event.target.value)}
            rows="3"
            disabled={!editableSection}
          />
        </Col>
      </FormGroup>
      {observacionesEstado.slice(!editableSection).map((obs) => {
        return (
          <FormGroup row key={obs.id}>
            <Col sm={2} />
            <Col>
              <Input type="textarea" value={obs.observacion} rows="3" disabled />
            </Col>
          </FormGroup>
        );
      })}
      {editableSection && (
        <div className="button-container d-flex justify-content-end">
          <Button
            className="btn btn-secondary orange"
            onClick={() => setConfirmationModal(true)}
            disabled={!isValidInfo() || confirmationModal}
          >
            {loading ? (
              <FontAwesomeIcon spin={loading} icon={faSpinner} />
            ) : (
              'Validar y Enviar a Generar Borrador'
            )}
          </Button>
        </div>
      )}
      <AvanzarOportunidadConfirmationModal
        openModal={confirmationModal}
        setOpenModal={setConfirmationModal}
        avanzarOportunidad={guardarParametrosComerciales}
        loading={loading}
      />
      <CheckModal
        isOpen={showUpdateModal}
        toggle={() => setShowUpdateModal(false)}
        handleOk={() => setShowUpdateModal(false)}
        okBtnText="OK"
        confirmMsg="Parámetros comerciales cargados exitosamente."
      />
      <WarningModal
        isOpen={showErrorModal}
        toggle={() => setShowErrorModal(false)}
        handleOk={() => setShowErrorModal(false)}
        okBtnText="Aceptar"
        confirmMsg="Ha ocurrido un error al cargar los parámetros comerciales."
        loading={false}
        onlyCancelButton
      />
    </div>
  );
};

ParametrosComercialesSection.propTypes = {
  id: PropTypes.string,
  fullData: PropTypes.shape({
    estado: PropTypes.shape({
      nemotecnico: PropTypes.string,
    }),
    prima_neta: PropTypes.number,
    comision: PropTypes.number,
    tipo_intermediario: PropTypes.shape({
      id: PropTypes.number,
      descripcion: PropTypes.string,
      nemotecnico: PropTypes.string,
    }),
  }),
  estadosOportunidad: PropTypes.arrayOf(PropTypes.shape({})),
  observaciones: PropTypes.arrayOf(PropTypes.shape({})),
  setFullData: PropTypes.func.isRequired,
  setInitialSection: PropTypes.func.isRequired,
};

ParametrosComercialesSection.defaultProps = {
  fullData: null,
  id: null,
  estadosOportunidad: [],
  observaciones: [],
};

export default ParametrosComercialesSection;
