import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faExclamation, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
  cellOperation,
  headerOperation,
  headerDoubleRowOperation,
  cellNumOperation,
  cellDateOperation,
} from '../../helpers/tables';
import { fileDownload } from '../../helpers/helpers';
import BaseTable from '../UI/BaseTable';
import { API, BASE_URL, DRAFT_LABEL, EMITTED_LABEL } from '../../CONST';
import isUserHasProfile from '../../helpers/profileValidator';
import { EMIT_WRITE } from '../../helpers/profilePermission';

const EndosoListTable = (props) => {
  const {
    data,
    handleDelete,
    handleEdit,
    handleWarning,
    warningOpen,
    deleteWarningOpen,
    loginIdToken,
  } = props;
  const columns = [
    {
      Header: headerOperation('#'),
      id: 'id',
      accessor: (i) => cellOperation(i.secuencia_endoso),
      width: 50,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Emisión'),
      id: 'emision',
      accessor: (i) => cellDateOperation(i.emision),
      width: 95,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Tipo'),
      id: 'tipo_endoso',
      accessor: (i) => {
        return cellOperation(
          <div className='alert alert-dark-blue text-center' role="alert">
            {i.tipo_endoso.descripcion}
          </div>,
        );
      },
      width: 160,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Inicio Vigencia'),
      id: 'inicio_vigencia',
      accessor: (i) => cellDateOperation(i.inicio_endoso),
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Fin Vigencia'),
      id: 'fin_vigencia',
      accessor: (i) => cellDateOperation(i.termino_vigencia),
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation(<span>&Delta; Monto</span>, 'Garantizado'),
      id: 'monto_garantizado',
      accessor: (i) => cellNumOperation(i.cambio_cobertura_amt),
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation(<span>&Delta; Comisión</span>),
      id: 'comision',
      accessor: (i) => cellNumOperation(i.cambio_comision_pct),
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation(<span>&Delta; Prima</span>, 'Neta'),
      id: 'prima_neta',
      accessor: (i) => cellNumOperation(i.cambio_prima_neta_amt),
      filterable: false,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation(<span>&Delta; Prima</span>, 'Exenta'),
      id: 'prima_exenta',
      accessor: (i) => cellNumOperation(i.cambio_prima_exenta_neta),
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Estado'),
      id: 'estado',
      accessor: (i) => {
        let alertClass = 'alert-success';
        let alertText = EMITTED_LABEL;
        if (!i.emitido) {
          alertClass = 'alert-primary';
          alertText = DRAFT_LABEL;
        }
        return cellOperation(
          <div className={`alert ${alertClass} text-center`} role="alert">
            {alertText}
          </div>,
        );
      },
      width: 110,
      filterable: false,
      sortable: false,
    },
    {
      Header: headerOperation('Acciones'),
      id: 'acciones',
      accessor: (i) => (
        <div
          style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', gridGap: '5px' }}
          className="table-cel"
        >
          <div style={{ textAlign: 'right' }}>
            <Button
              className="table-link policy"
              title="descargar"
              onClick={() =>
                fileDownload(`${BASE_URL}${API.endosos}/${i.id}/download/`, loginIdToken)
              }
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </div>
          {i.emitido ? (
            ''
          ) : (
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: '5px' }}>
              {i.can_edit && (
                <div style={{ textAlign: 'center', margin: '1px 0 0 1px' }} title="editar">
                  <Button
                    className="table-link policy"
                    title="editar"
                    onClick={() => handleEdit(i)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </div>
              )}
              {isUserHasProfile(EMIT_WRITE) && (
                <div style={{ textAlign: 'center' }}>
                  {!warningOpen ? (
                    <Button
                      className="table-link policy"
                      title="emitir"
                      onClick={(e) => handleWarning(e, i.id)}
                    >
                      <FontAwesomeIcon icon={faFileExport} />
                    </Button>
                  ) : (
                    <div className="warning-button">
                      <FontAwesomeIcon icon={faExclamation} />
                    </div>
                  )}
                </div>
              )}
              {i.can_edit && (
                <div style={{ textAlign: 'left' }}>
                  {!deleteWarningOpen ? (
                    <Button
                      className="table-link policy"
                      title="eliminar"
                      onClick={() => handleDelete(i.id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  ) : (
                    <div className="warning-button">
                      <FontAwesomeIcon icon={faExclamation} />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      ),
      width: 110,
      filterable: false,
      sortable: false,
    },
  ];
  return (
    <div style={{ margin: '25px 0 40px 0' }}>
      <BaseTable columns={columns} data={data} resizable={false} showPagination noBorders />
    </div>
  );
};

EndosoListTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleWarning: PropTypes.func.isRequired,
  warningOpen: PropTypes.bool.isRequired,
  deleteWarningOpen: PropTypes.bool.isRequired,
  loginIdToken: PropTypes.string.isRequired,
};

export default EndosoListTable;
