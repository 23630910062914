import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Col, Input, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ESTADOS_OPORTUNIDAD, API, BASE_URL } from 'CONST';
import { patch, post } from 'helpers/requests';
import CheckModal from 'components/shared/CheckModal';
import WarningModal from 'components/UI/WarningModal';
import AvanzarOportunidadConfirmationModal from './AvanzarOportunidadConfirmationModal';

const EmitirPolizaSection = ({ fullData, id, estadosOportunidad, observaciones }) => {
  const history = useHistory();
  const [observacion, setObservacion] = useState('');
  const [observacionesEstado, setObservacionesEstado] = useState([]);
  const [editableSection, setEditableSection] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [confirmationGoodModal, setConfirmationGoodModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const editableStates = [ESTADOS_OPORTUNIDAD.requisitosEmisionValidados];

  useEffect(() => {
    if (observaciones) {
      setObservacionesEstado(
        observaciones.filter((obs) => obs.estado.nemotecnico === ESTADOS_OPORTUNIDAD.emitida),
      );
    }
    if (fullData && fullData.estado && editableStates.includes(fullData.estado.nemotecnico)) {
      setEditableSection(true);
    }
  }, [observaciones, fullData]);

  const postObservacion = () => {
    if (observacion) {
      const body = {
        oportunidad_id: id,
        estado_id: estadosOportunidad.find((e) => e.nemotecnico === ESTADOS_OPORTUNIDAD.emitida).id,
        observacion,
      };
      post(`${BASE_URL}${API.observaciones}/`, body).catch(() => {
        setShowErrorModal(true);
      });
    }
  };

  const aceptarBorradorOportunidad = () => {
    setLoading(true);
    const body = {
      estado_id: estadosOportunidad.find((e) => e.nemotecnico === ESTADOS_OPORTUNIDAD.emitida).id,
    };
    post(`${BASE_URL}${API.oportunidades_garantia}/${id}/emitir/`)
      .then(() => {
        patch(`${BASE_URL}${API.oportunidades_garantia}/${id}/`, body)
          .then(() => {
            postObservacion();
            setShowUpdateModal(true);
          })
          .catch(() => {
            setShowErrorModal(true);
          });
      })
      .catch(() => {
        setShowErrorModal(true);
      })
      .finally(() => {
        setConfirmationGoodModal(false);
        setLoading(false);
      });
  };

  return (
    <div>
      <FormGroup row>
        <Col sm={2}>
          <Label>Observaciones</Label>
        </Col>
        <Col>
          <Input
            id="observacion-info-inicial"
            type="textarea"
            value={
              !editableSection && observacionesEstado.length > 0
                ? observacionesEstado[0].observacion
                : observacion || ''
            }
            onChange={(event) => setObservacion(event.target.value)}
            rows="3"
            disabled={!editableSection}
          />
        </Col>
      </FormGroup>
      {observacionesEstado.slice(!editableSection).map((obs) => {
        return (
          <FormGroup row key={obs.id}>
            <Col sm={2} />
            <Col>
              <Input type="textarea" value={obs.observacion} rows="3" disabled />
            </Col>
          </FormGroup>
        );
      })}
      {editableSection && (
        <div className="button-container d-flex justify-content-end">
          <Button
            className="btn btn-secondary orange"
            onClick={() => setConfirmationGoodModal(true)}
            disabled={loading}
          >
            {loading ? <FontAwesomeIcon spin={loading} icon={faSpinner} /> : 'Emitir póliza'}
          </Button>
        </div>
      )}
      <CheckModal
        isOpen={showUpdateModal}
        toggle={() => history.push('/guarantee/oportunities')}
        handleOk={() => history.push('/guarantee/oportunities')}
        okBtnText="OK"
        confirmMsg="Oportunidad emitida exitosamente."
      />
      <AvanzarOportunidadConfirmationModal
        openModal={confirmationGoodModal}
        setOpenModal={setConfirmationGoodModal}
        avanzarOportunidad={aceptarBorradorOportunidad}
        loading={loading}
        mensajeModal="¿Está seguro que desea EMITIR esta oportunidad?"
      />
      <WarningModal
        isOpen={showErrorModal}
        toggle={() => setShowErrorModal(false)}
        handleOk={() => setShowErrorModal(false)}
        okBtnText="Aceptar"
        confirmMsg="Ha ocurrido un error al emitir la oportunidad."
        loading={false}
        onlyCancelButton
      />
    </div>
  );
};

EmitirPolizaSection.propTypes = {
  fullData: PropTypes.shape({
    estado: PropTypes.shape({
      nemotecnico: PropTypes.string,
    }),
  }),
  id: PropTypes.string,
  estadosOportunidad: PropTypes.arrayOf(PropTypes.shape({})),
  observaciones: PropTypes.arrayOf(PropTypes.shape({})),
};

EmitirPolizaSection.defaultProps = {
  fullData: null,
  id: null,
  estadosOportunidad: [],
  observaciones: [],
};

export default EmitirPolizaSection;
