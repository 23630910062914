import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import FormSection from '../../../UI/FormSection';

const DeduciblesSection = ({
  clientesInnominados,
  deducibleNominadoAmt,
  deducibleInnominadoAmt,
  moneda,
  dispatch,
  handleChange,
  style,
  validate,
  disabled,
}) => {
  return (
    <FormSection title="Deducibles" style={style}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '110px auto',
          gridGap: '6px 8px',
          alignItems: 'center',
        }}
      >
        <div>
          <NumberFormat
            id="deducible-nominado"
            name="deducibleNominadoAmt"
            className={`form-control${
              validate && deducibleNominadoAmt === null ? ' is-invalid' : ''
            }`}
            onValueChange={values => {
              const newValue = values.floatValue;
              dispatch(handleChange('endoso.deducibleNominadoAmt', newValue));
            }}
            allowNegative={false}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale="2"
            fixedDecimalScale
            value={deducibleNominadoAmt}
            suffix={` ${moneda}`}
            style={{ width: '110px' }}
            disabled={disabled}
          />
        </div>
        <div className="small-form-value">Clientes nominados</div>
        <div>
          <NumberFormat
            id="deducible-innominado"
            name="deducibleInnominadoAmt"
            className={`form-control${
              validate && clientesInnominados && deducibleInnominadoAmt === null
                ? ' is-invalid'
                : ''
            }`}
            onValueChange={values => {
              const newValue = values.floatValue;
              dispatch(handleChange('endoso.deducibleInnominadoAmt', newValue));
            }}
            allowNegative={false}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale="2"
            fixedDecimalScale
            value={deducibleInnominadoAmt}
            suffix={` ${moneda}`}
            style={{ width: '110px' }}
            disabled={!clientesInnominados || disabled}
          />
        </div>
        <div className="small-form-value">Clientes innominados</div>
      </div>
    </FormSection>
  );
};

DeduciblesSection.propTypes = {
  clientesInnominados: PropTypes.bool.isRequired,
  deducibleNominadoAmt: PropTypes.number,
  deducibleInnominadoAmt: PropTypes.number,
  moneda: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

DeduciblesSection.defaultProps = {
  deducibleNominadoAmt: null,
  deducibleInnominadoAmt: null,
  style: {},
  disabled: false,
};

export default DeduciblesSection;
