import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { BASE_URL, ESTADOS_OPORTUNIDAD, API, SEARCH_DELAY_MS } from 'CONST';
import { FormGroup, Col, Label, Input, Button, FormFeedback } from 'reactstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { get, patch, post } from 'helpers/requests';
import CheckModal from 'components/shared/CheckModal';
import WarningModal from 'components/UI/WarningModal';
import AvanzarOportunidadConfirmationModal from './AvanzarOportunidadConfirmationModal';

const InformacionInicialSection = ({ fullData, id, estadosOportunidad, observaciones }) => {
  const history = useHistory();

  const [monto, setMonto] = useState();
  const [prima, setPrima] = useState();
  const [editableSection, setEditableSection] = useState(false);
  const [monedaOptions, setMonedaOptions] = useState([]);
  const [moneda, setMoneda] = useState(null);

  const [intermediarioOptions, setIntermediarioOptions] = useState([]);
  const [intermediarioSearch, setIntermediarioSearch] = useState('');
  const [intermediario, setIntermediario] = useState(null);

  const [tipoIntermediarioOptions, setTipoIntermediarioOptions] = useState([]);
  const [tipoIntermediario, setTipoIntermediario] = useState(null);

  const [observacion, setObservacion] = useState('');
  const [observacionesEstado, setObservacionesEstado] = useState([]);

  const editableStates = [ESTADOS_OPORTUNIDAD.suscriptorAsignado];

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Sólo si es edición se hacen requests para obtener la data.
    // Se evita hacer tanto request innecesario.
    if (fullData && fullData.estado && editableStates.includes(fullData.estado.nemotecnico)) {
      get(`${BASE_URL}${API.monedas}`).then((response) => {
        setMonedaOptions(
          response.map((e) => ({
            value: e.id,
            label: e.nombre_corto,
          })),
        );
      });
      get(`${BASE_URL}${API.intermediario}`).then((response) => {
        setTipoIntermediarioOptions(
          response.map((e) => ({
            value: e.id,
            label: e.descripcion,
          })),
        );
      });
      get(`${BASE_URL}${API.clientes}?roles__term=intermediariogarantia`).then((response) => {
        setIntermediarioOptions(
          response.results.map((e) => ({
            value: e.id,
            label: `${e.rut} - ${e.nombre}`,
          })),
        );
      });
    }
  }, []);

  useEffect(() => {
    if (fullData) {
      if (fullData.prima) {
        setPrima(fullData.prima);
      }
      if (fullData.moneda) {
        setMoneda({
          label: fullData.moneda.nombre_corto,
          value: fullData.moneda.id,
        });
      }
      if (fullData.tipo_intermediario) {
        setTipoIntermediario({
          label: fullData.tipo_intermediario.descripcion,
          value: fullData.tipo_intermediario.id,
        });
      }
      if (fullData.intermediario) {
        setIntermediario({
          label: `${fullData.intermediario.rut} - ${fullData.intermediario.nombre}`,
          value: fullData.intermediario.id,
        });
      }
      if (fullData.estado && editableStates.includes(fullData.estado.nemotecnico)) {
        setEditableSection(true);
      }
      if (fullData.monto) {
        setMonto(fullData.monto);
      }
    }
  }, [fullData]);

  useEffect(() => {
    if (fullData.tipo_intermediario && tipoIntermediarioOptions.length > 0) {
      setTipoIntermediario({
        label: tipoIntermediarioOptions.find((e) => e.value === fullData.tipo_intermediario.id)
          .label,
        value: fullData.tipo_intermediario.id,
      });
    }
  }, [fullData, tipoIntermediarioOptions]);

  useEffect(() => {
    // CASO INTERMEDIARIO DIRECTO => NO SE DEBE ELEGIR INTERMEDIARIO.
    if (tipoIntermediario && tipoIntermediario.label === 'Directo') {
      setIntermediario({ label: '', value: null });
    } else if (intermediario && !intermediario.value) {
      setIntermediario(null);
    }
  }, [tipoIntermediario]);

  const searchIntermediario = (search) => {
    const searchURL = search ? `&search=${search}` : '';
    get(`${BASE_URL}${API.clientes}?roles__term=intermediariogarantia${searchURL}`).then(
      (response) => {
        setIntermediarioOptions(
          response.results.map((e) => ({
            value: e.id,
            label: `${e.rut} - ${e.nombre}`,
          })),
        );
      },
    );
  };

  const searchIntermediarioDebounced = useCallback(
    _.debounce((search) => searchIntermediario(search), SEARCH_DELAY_MS),
    [],
  );

  useEffect(() => {
    if (intermediarioSearch) {
      searchIntermediarioDebounced(intermediarioSearch);
    }
  }, [intermediarioSearch]);

  useEffect(() => {
    if (observaciones) {
      setObservacionesEstado(
        observaciones.filter(
          (obs) => obs.estado.nemotecnico === ESTADOS_OPORTUNIDAD.informacionInicialCargada,
        ),
      );
    }
  }, [observaciones, fullData]);

  const postObservacion = () => {
    if (observacion) {
      const bodyObservacion = {
        oportunidad_id: id,
        estado_id: estadosOportunidad.find(
          (e) => e.nemotecnico === ESTADOS_OPORTUNIDAD.informacionInicialCargada,
        ).id,
        observacion,
      };
      post(`${BASE_URL}${API.observaciones}/`, bodyObservacion).catch(() => {
        setShowErrorModal(true);
      });
    }
  };

  const guardarInfoInicial = () => {
    setLoading(true);
    const body = {
      monto,
      prima,
      estado_id: estadosOportunidad.find(
        (e) => e.nemotecnico === ESTADOS_OPORTUNIDAD.informacionInicialCargada,
      ).id,
      tipo_intermediario_id: tipoIntermediario.value,
      intermediario_id: intermediario.value,
      moneda_id: moneda.value,
    };
    patch(`${BASE_URL}${API.oportunidades_garantia}/${id}/`, body)
      .then(() => {
        postObservacion();
        setShowUpdateModal(true);
      })
      .catch(() => {
        setShowErrorModal(true);
      })
      .finally(() => {
        setConfirmationModal(false);
        setLoading(false);
      });
  };

  const isValidInfo = () => {
    return monto && monto > 0 && prima && prima > 0 && moneda && intermediario && tipoIntermediario;
  };

  return (
    <div>
      <FormGroup row>
        <Col sm={2}>
          <Label>
            Moneda
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col sm={5}>
          <Select
            className="custom-policy-select"
            options={monedaOptions}
            value={moneda}
            onChange={(event) => setMoneda(event)}
            isDisabled={!editableSection}
            placeholder="Seleccione una moneda"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={2}>
          <Label>
            Monto
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col sm={5}>
          <NumberFormat
            className="form-control"
            decimalScale="2"
            decimalSeparator="."
            value={monto}
            onValueChange={(values) => setMonto(values.floatValue)}
            allowNegative={false}
            disabled={!editableSection}
          />
          {monto !== undefined && monto <= 0 && (
            <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px', display: 'block' }}>
              Monto debe ser mayor a 0
            </FormFeedback>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={2}>
          <Label>
            Prima
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col sm={5}>
          <NumberFormat
            className="form-control"
            decimalScale="2"
            decimalSeparator="."
            value={prima}
            onValueChange={(values) => setPrima(values.floatValue)}
            allowNegative={false}
            disabled={!editableSection}
          />
          {prima !== undefined && prima <= 0 && (
            <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px', display: 'block' }}>
              Prima debe ser mayor a 0
            </FormFeedback>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={2}>
          <Label>
            Tipo Intermediario
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col sm={5}>
          <Select
            className="custom-policy-select"
            options={tipoIntermediarioOptions}
            value={tipoIntermediario}
            isDisabled={!editableSection}
            onChange={(event) => setTipoIntermediario(event)}
            placeholder="Seleccione un tipo de intermediario"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={2}>
          <Label>
            Intermediario
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col sm={5}>
          <Select
            className="custom-policy-select"
            options={intermediarioOptions}
            value={intermediario}
            isDisabled={
              !editableSection || (tipoIntermediario && tipoIntermediario.label === 'Directo')
            }
            onChange={(event) => setIntermediario(event)}
            onInputChange={(event) => {
              setIntermediarioSearch(event);
            }}
            placeholder="Seleccione un intermediario"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={2}>
          <Label>Observaciones</Label>
        </Col>
        <Col>
          <Input
            id="observacion-info-inicial"
            type="textarea"
            value={
              !editableSection && observacionesEstado.length > 0
                ? observacionesEstado[0].observacion
                : observacion || ''
            }
            onChange={(event) => setObservacion(event.target.value)}
            rows="3"
            disabled={!editableSection}
          />
        </Col>
      </FormGroup>
      {observacionesEstado.slice(!editableSection).map((obs) => {
        return (
          <FormGroup row key={obs.id}>
            <Col sm={2} />
            <Col>
              <Input type="textarea" value={obs.observacion} rows="3" disabled />
            </Col>
          </FormGroup>
        );
      })}

      {editableSection && (
        <div className="button-container d-flex justify-content-end">
          <Button
            className="btn btn-secondary orange"
            onClick={() => setConfirmationModal(true)}
            disabled={!isValidInfo() || confirmationModal}
          >
            {loading ? (
              <FontAwesomeIcon spin={loading} icon={faSpinner} />
            ) : (
              'Guardar Información Inicial'
            )}
          </Button>
        </div>
      )}
      <AvanzarOportunidadConfirmationModal
        openModal={confirmationModal}
        setOpenModal={setConfirmationModal}
        avanzarOportunidad={guardarInfoInicial}
        loading={loading}
      />
      <CheckModal
        isOpen={showUpdateModal}
        toggle={() => history.push('/guarantee/oportunities')}
        handleOk={() => history.push('/guarantee/oportunities')}
        okBtnText="OK"
        confirmMsg="Información inicial cargada exitosamente."
      />
      <WarningModal
        isOpen={showErrorModal}
        toggle={() => setShowErrorModal(false)}
        handleOk={() => setShowErrorModal(false)}
        okBtnText="Aceptar"
        confirmMsg="Ha ocurrido un error al cargar la información inicial."
        loading={false}
        onlyCancelButton
      />
    </div>
  );
};

InformacionInicialSection.propTypes = {
  id: PropTypes.string,
  fullData: PropTypes.shape({
    estado: PropTypes.shape({
      nemotecnico: PropTypes.string,
    }),
    monto: PropTypes.number,
    prima: PropTypes.number,
    moneda: PropTypes.shape({
      id: PropTypes.number,
      nombre_corto: PropTypes.string,
    }),
    intermediario: PropTypes.shape({
      id: PropTypes.number,
      nombre: PropTypes.string,
      rut: PropTypes.string,
    }),
    tipo_intermediario: PropTypes.shape({
      id: PropTypes.number,
      descripcion: PropTypes.string,
      nemotecnico: PropTypes.string,
    }),
  }),
  estadosOportunidad: PropTypes.arrayOf(PropTypes.shape({})),
  observaciones: PropTypes.arrayOf(PropTypes.shape({})),
};

InformacionInicialSection.defaultProps = {
  fullData: null,
  id: null,
  estadosOportunidad: [],
  observaciones: [],
};

export default InformacionInicialSection;
