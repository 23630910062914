import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreditPolicyBasicInfoStep from './CreditPolicyBasicInfoStep';
import PageContainer from '../../PageContainer';
import CreditPolicyDetailsStep from './CreditPolicyDetailsStep';
import {
  clearCreditPolicyForm,
  getOptionsForSelects,
} from '../../../redux-store/actions/creditPolicyActions';
import CreditPolicyParticularConditionsStep from './CreditPolicyParticularConditionsStep';
import ClientesYDeudoresStep from './ClientesYDeudoresStep';
import CreditPolicyParameterizedConditionsStep from './CreditPolicyParameterizedConditionsStep';
import { CP_CHANGE_TIPO_ENDOSO } from '../../../redux-store/constants/action-types';
import {
  CP_FS_BASICOS,
  CP_FS_CLAUSULAS,
  CP_FS_CLIENTES,
  CP_FS_CONDICIONES,
  CP_FS_DETALLES,
  EMITTED_LABEL,
  TIPO_ENDOSO_RENOVACION_ID,
} from '../../../CONST';
import CreditPolicyFormActions from './helpers/CreditPolicyFormActions';
import CreditPolicySummaryStep from './CreditPolicySummaryStep';
import CreditPolicyFormAccordion from './CreditPolicyFormAccordion';
import {
  isValidClientesYDedudoresStep,
  isValidCreditPolicyBasicInfoStep,
  isValidCreditPolicyDetailsStep,
  isValidParameterizedConditionsStep,
  isValidParticularConditionsStep,
} from '../../../helpers/validation';
import isUserHasProfile from '../../../helpers/profileValidator';
import { ENDOSO_CREDITO_WRITE } from '../../../helpers/profilePermission';

class CreditPolicyForm extends Component {
  constructor(props) {
    super(props);
    this.basicInfoStep = React.createRef();
    this.detailsStep = React.createRef();
    this.clientesYDeudoresStep = React.createRef();
    this.particularConditionsStep = React.createRef();
    this.parameterizedConditionsStep = React.createRef();
  }

  componentDidMount() {
    const {
      match: {
        params: { id: policyId, numeroVigencia },
      },
      dispatch,
      tipoEndosoId,
    } = this.props;
    dispatch(clearCreditPolicyForm());
    dispatch({ type: CP_CHANGE_TIPO_ENDOSO, payload: tipoEndosoId });
    dispatch(getOptionsForSelects(policyId, numeroVigencia));
  }

  componentDidUpdate() {
    const {
      history,
      creditPolicy: {
        policy,
        form: {
          estado,
          data: {
            endoso: { empty, emitted },
          },
        },
      },
    } = this.props;
    if (empty || emitted) {
      history.push(`/credit/policies/detail/${policy.id}`);
    }
    if (estado === EMITTED_LABEL) {
      this.setValidateInputsEdition(true);
    }
  }

  // eslint-disable-next-line react/destructuring-assignment
  isValidCreationForm = () => isValidCreditPolicyBasicInfoStep(this.props.creditPolicy.form);

  isValidEditionForm = () => {
    const {
      creditPolicy: { form },
    } = this.props;
    return (
      isValidCreditPolicyBasicInfoStep(form) &&
      isValidCreditPolicyDetailsStep(form) &&
      isValidClientesYDedudoresStep(form) &&
      isValidParticularConditionsStep(form) &&
      isValidParameterizedConditionsStep(form)
    );
  };

  setValidateInputsCreation = (validateInputs) => {
    this.basicInfoStep.current.setValidateInputs(validateInputs);
  };

  setValidateInputsEdition = (validateInputs) => {
    if (this.basicInfoStep.current) {
      this.basicInfoStep.current.setValidateInputs(validateInputs);
    }
    if (this.detailsStep.current) {
      this.detailsStep.current.setValidateInputs(validateInputs);
    }
    if (this.clientesYDeudoresStep.current) {
      this.clientesYDeudoresStep.current.setValidateInputs(validateInputs);
    }
    if (this.particularConditionsStep.current) {
      this.particularConditionsStep.current.setValidateInputs(validateInputs);
    }
    if (this.parameterizedConditionsStep.current) {
      this.parameterizedConditionsStep.current.setValidateInputs(validateInputs);
    }
  };

  isEdition = () => {
    const {
      match: {
        params: { id: policyId },
      },
    } = this.props;
    return !!policyId;
  };

  render() {
    const {
      dispatch,
      creditPolicy,
      creditPolicy: {
        form: {
          emitted,
          estado,
          data: { endoso },
          base,
          currentPolicyId,
          isLastStep,
          sections,
        },
        loaded,
      },
      match: {
        params: { id: policyId },
      },
    } = this.props;

    if (policyId && !emitted && !loaded) {
      return null;
    }

    const breadcrumbs = [
      { name: 'CRÉDITO', url: '/credit/policies/' },
      { name: 'PÓLIZAS', url: '/credit/policies/' },
    ];

    if (estado === EMITTED_LABEL && base && base.nemotecnico) {
      breadcrumbs.push({
        name: base.nemotecnico,
        url: `/credit/policies/detail/${currentPolicyId}`,
      });
    }

    let title = this.isEdition() ? 'EDITAR PÓLIZA' : 'CREAR PÓLIZA';
    if (endoso.tipoEndosoId === TIPO_ENDOSO_RENOVACION_ID) {
      title = 'NUEVA VIGENCIA';
    }

    breadcrumbs.push({ name: title });

    const isReadOnly = !isUserHasProfile(ENDOSO_CREDITO_WRITE);

    return (
      <PageContainer breadcrumbs={breadcrumbs} style={{ padding: '0 20px' }}>
        {isLastStep ? (
          <>
            <CreditPolicySummaryStep />
            <CreditPolicyFormActions isLastStep dispatch={dispatch} creditPolicy={creditPolicy} />
          </>
        ) : (
          <>
            <CreditPolicyFormAccordion
              sections={[
                {
                  name: 'Información Básica',
                  component: (
                    <CreditPolicyBasicInfoStep
                      ref={this.basicInfoStep}
                      {...this.props}
                      readOnly={isReadOnly}
                    />
                  ),
                  isValid: sections[CP_FS_BASICOS].valid,
                  expansible: true,
                },
                {
                  name: 'Detalles',
                  component: (
                    <CreditPolicyDetailsStep
                      ref={this.detailsStep}
                      {...this.props}
                      readOnly={isReadOnly}
                    />
                  ),
                  isValid: sections[CP_FS_DETALLES].valid,
                  expansible: !!currentPolicyId,
                },
                {
                  name: 'Filiales y Deudores Especiales',
                  component: (
                    <ClientesYDeudoresStep
                      ref={this.clientesYDeudoresStep}
                      {...this.props}
                      readOnly={isReadOnly}
                    />
                  ),
                  isValid: sections[CP_FS_CLIENTES].valid,
                  expansible: !!currentPolicyId,
                },
                {
                  name: 'Condiciones Particulares',
                  component: (
                    <CreditPolicyParticularConditionsStep
                      ref={this.particularConditionsStep}
                      {...this.props}
                      readOnly={isReadOnly}
                    />
                  ),
                  isValid: sections[CP_FS_CONDICIONES].valid,
                  expansible: !!currentPolicyId,
                },
                {
                  name: 'Cláusulas Parametrizadas',
                  component: (
                    <CreditPolicyParameterizedConditionsStep
                      ref={this.parameterizedConditionsStep}
                      {...this.props}
                      readOnly={isReadOnly}
                    />
                  ),
                  isValid: sections[CP_FS_CLAUSULAS].valid,
                  expansible: !!currentPolicyId,
                },
              ]}
            />
            <CreditPolicyFormActions
              isValidForm={currentPolicyId ? this.isValidEditionForm : this.isValidCreationForm}
              setValidateInputs={
                currentPolicyId ? this.setValidateInputsEdition : this.setValidateInputsCreation
              }
              dispatch={dispatch}
              creditPolicy={creditPolicy}
              readOnly={isReadOnly}
            />
          </>
        )}
      </PageContainer>
    );
  }
}

CreditPolicyForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      numeroVigencia: PropTypes.string,
    }),
  }).isRequired,
  creditPolicy: PropTypes.shape({
    loaded: PropTypes.bool,
    policy: PropTypes.shape({
      id: PropTypes.number,
    }),
    form: PropTypes.shape({
      isLastStep: PropTypes.bool,
      emitted: PropTypes.bool,
      estado: PropTypes.string,
      base: PropTypes.object,
      sections: PropTypes.object,
      data: PropTypes.shape({
        moneda: PropTypes.object,
        endoso: PropTypes.shape({
          emitted: PropTypes.bool,
          empty: PropTypes.bool,
          tipoEndosoId: PropTypes.number,
        }),
      }),
      currentPolicyId: PropTypes.number,
    }),
  }).isRequired,
  policies: PropTypes.shape({
    selectedPolicy: PropTypes.shape({
      tiposMoneda: PropTypes.array,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  tipoEndosoId: PropTypes.number.isRequired,
  loginIdToken: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  creditPolicy: state.creditPolicy,
  policies: state.policies,
  ramosFecu: state.conditioned.ramos_fecu,
  loginIdToken: state.login.id_token,
});

export default connect(mapStateToProps)(CreditPolicyForm);
