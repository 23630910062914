import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Select from 'react-select';
import { FormGroup, Label, Row, Col, Input, Button, FormFeedback } from 'reactstrap';
import Datetime from 'react-datetime';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BASE_URL,
  ESTADOS_OPORTUNIDAD,
  API,
  FRONT_DF_1,
  BACKEND_DF,
  OPORTUNIDADES_STATE_SECTION_MAP,
  SEARCH_DELAY_MS,
} from 'CONST';
import { validateRut } from 'helpers/rutValidator';
import { emailValidate } from 'helpers/helpers';
import { patch, get } from 'helpers/requests';
import { Prompt } from 'react-router-dom';
import useExitPrompt from 'components/shared/useExitPrompt';
import NewFileTable from 'components/shared/NewFileTable';
import CheckModal from 'components/shared/CheckModal';
import WarningModal from 'components/UI/WarningModal';
import OportunitiesFormAccordion from './OportunitiesFormAccordion';
import SubSectionAdjuntos from './SubSectionAdjuntos';
import AvanzarOportunidadConfirmationModal from './AvanzarOportunidadConfirmationModal';

const moment = require('moment');

const CompletarInformacionSection = ({
  fullData,
  setFullData,
  setInitialSection,
  id,
  estadosOportunidad,
}) => {
  // eslint-disable-next-line
  const regexfono = new RegExp('^[+]?[0-9]+$');

  const [fullInfo, setFullInfo] = useState({});

  // MODALS
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // INPUTS
  const [nombreContacto, setNombreContacto] = useState('');
  const [rutContacto, setRutContacto] = useState('');
  const [telefonoContacto, setTelefonoContacto] = useState('');
  const [mailContacto, setMailContacto] = useState('');
  const [descripcionContrato, setDescripcionContrato] = useState('');
  const [comentarios, setComentarios] = useState('');
  const [inicioVigencia, setInicioVigencia] = useState(null);
  const [finVigencia, setFinVigencia] = useState(null);
  const [glosaPoliza, setGlosaPoliza] = useState('');

  // ADJUNTOS
  const [adjuntos, setAdjuntos] = useState([]);
  const [adjuntosDocs, setAdjuntosDocs] = useState([]);
  const [glosaAdjunta, setGlosaAdjunta] = useState([]);
  const [glosaAdjuntaDocs, setGlosaAdjuntaDocs] = useState([]);

  const [adjuntosEliminadosInfo, setAdjuntosEliminadosInfo] = useState([]);
  const [adjuntosEliminadosAntecedentes, setAdjuntosEliminadosAntecedentes] = useState([]);

  // SELECTORES
  const [giroOptions, setGiroOptions] = useState([]);
  const [giro, setGiro] = useState(null);

  const [aseguradoOptions, setAseguradoOptions] = useState([]);
  const [aseguradoSearch, setAseguradoSearch] = useState('');
  const [asegurado, setAsegurado] = useState(null);

  const [tipoInstitucionOptions, setTipoInstitucionOptions] = useState([]);
  const [tipoInstitucion, setTipoInstitucion] = useState(null);

  const [condicionadoOptions, setCondicionadoOptions] = useState([]);
  const [condicionado, setCondicionado] = useState(null);

  const [actividadOptions, setActividadOptions] = useState([]);
  const [actividad, setActividad] = useState(null);

  const [beneficiarioOptions, setBeneficiarioOptions] = useState([]);
  const [beneficiarioSearch, setBeneficiarioSearch] = useState('');
  const [beneficiario, setBeneficiario] = useState(null);

  // DEPENDIENTES DE MAESTRA
  const [productoOptions, setProductoOptions] = useState([]);
  const [producto, setProducto] = useState(null);
  const [coberturaOptions, setCoberturaOptions] = useState([]);
  const [cobertura, setCobertura] = useState(null);
  const [ejecucionOptions, setEjecucionOptions] = useState([]);
  const [ejecucion, setEjecucion] = useState(null);

  const [isFormSaved, setIsFormSaved] = useState(true);

  const [editableSection, setEditableSection] = useState(false);
  const editableStates = [ESTADOS_OPORTUNIDAD.informacionInicialValidada];

  // ADJUNTOS
  const dataAdjuntos = {
    contrato_asegurar: {
      label: 'Contrato para Asegurar',
      adjunto_docs: 'contrato_asegurar_docs',
    },
    bases_licitacion: {
      label: 'Bases de Licitación',
      adjunto_docs: 'bases_licitacion_docs',
    },
    identificador_proyecto_mercado_publico: {
      label: 'Nro Identidicador de Proyecto en Mercado Público',
      adjunto_docs: 'identificador_proyecto_mercado_publico_docs',
    },
    ordenes_de_compra: {
      label: 'Órdenes de Compra',
      adjunto_docs: 'ordenes_de_compra_docs',
    },
    cuadros_de_obras: {
      label: 'Cuadros de obras',
      adjunto_docs: 'cuadros_de_obras_docs',
    },
    balance_inmobiliario: {
      label: 'Balance Inmobiliario',
      adjunto_docs: 'balance_inmobiliario_docs',
    },
    contratos_vigentes: {
      label: 'Contratos Vigentes',
      adjunto_docs: 'contratos_vigentes_docs',
    },
    experiencia_acreditable: {
      label: 'Experiencia Acreditable',
      adjunto_docs: 'experiencia_acreditable_docs',
    },
    estado_situacion_socios: {
      label: 'Estado Situación Socios',
      adjunto_docs: 'estado_situacion_socios_docs',
    },
    consulta_unificada_poder_judicial: {
      label: 'Consulta Unificada Poder Judicial ',
      adjunto_docs: 'consulta_unificada_poder_judicial_docs',
    },
  };

  const adjuntosDS19 = {
    ds19_formulario_serviu: {
      label: 'Formulario SERVIU',
      adjunto_docs: 'ds19_formulario_serviu_docs',
    },
    ds19_resolucion_aprobacion_proyecto: {
      label: 'Resolución Aprobación del Proyecto',
      adjunto_docs: 'ds19_resolucion_aprobacion_proyecto_docs',
    },
    ds19_permiso_edificacion: {
      label: 'Permiso de Edificación',
      adjunto_docs: 'ds19_permiso_edificacion_docs',
    },
    ds19_ficha_inmobiliaria: {
      label: 'Ficha Inmobiliaria',
      adjunto_docs: 'ds19_ficha_inmobiliaria_docs',
    },
    ds19_aprobacion_bancaria: {
      label: 'Aprobación Bancaria',
      adjunto_docs: 'ds19_aprobacion_bancaria_docs',
    },
  };

  const adjuntosDS49 = {
    ds49_formulario_serviu: {
      label: 'Formulario SERVIE',
      adjunto_docs: 'ds49_formulario_serviu_docs',
    },
    ds49_contrato_construccion: {
      label: 'Contrato de Construcción',
      adjunto_docs: 'ds49_contrato_construccion_docs',
    },
    ds49_permiso_edificacion: {
      label: 'Permiso de Edificación',
      adjunto_docs: 'ds49_permiso_edificacion_docs',
    },
    ds49_ficha_inmobiliaria: {
      label: 'Ficha Inmobiliaria',
      adjunto_docs: 'ds49_ficha_inmobiliaria_docs',
    },
  };

  const adjuntosVentaEnVerde = {
    venta_en_verde_cuestionario: {
      label: 'Cuestionario',
      adjunto_docs: 'venta_en_verde_cuestionario_docs',
    },
    venta_en_verde_permiso_edificacion: {
      label: 'Permiso de Edificación',
      adjunto_docs: 'venta_en_verde_permiso_edificacion_docs',
    },
    venta_en_verde_aprobacion_bancaria: {
      label: 'Aprobación Bancaria',
      adjunto_docs: 'venta_en_verde_aprobacion_bancaria_docs',
    },
  };

  const adjuntosCapitalPreferente = {
    capital_preferente_cuestionario_ficha_inmobiliaria: {
      label: 'Cuestionario o Ficha Inmobiliaria',
      adjunto_docs: 'capital_preferente_cuestionario_ficha_inmobiliaria_docs',
    },
    capital_preferente_permiso_edificacion: {
      label: 'Permiso de Edificación',
      adjunto_docs: 'capital_preferente_permiso_edificacion_docs',
    },
    capital_preferente_aprobacion_bancaria: {
      label: 'Aprobación Bancaria',
      adjunto_docs: 'capital_preferente_aprobacion_bancaria_docs',
    },
    capital_preferente_datos_inversionista: {
      label: 'Datos del Inversionista',
      adjunto_docs: 'capital_preferente_datos_inversionista_docs',
    },
    capital_preferente_promesa_compraventa: {
      label: 'Promesa de Compraventa con Reciliación Aparte',
      adjunto_docs: 'capital_preferente_promesa_compraventa_docs',
    },
  };

  const adjuntosAduaneras = {
    aduaneras_ficha_cliente: {
      label: 'Ficha Cliente',
      adjunto_docs: 'aduaneras_ficha_cliente_docs',
    },
    aduaneras_bce: {
      label: 'BCE',
      adjunto_docs: 'aduaneras_bce_docs',
    },
  };

  const adjuntosFielDesempeno = {
    fiel_desempeño_ficha: {
      label: 'Ficha',
      adjunto_docs: 'fiel_desempeño_ficha_docs',
    },
    fiel_desempeño_bce: {
      label: 'BCE',
      adjunto_docs: 'fiel_desempeño_bce_docs',
    },
    fiel_desempeño_nombramiento_cmf: {
      label: 'Nombramiento de la CMF',
      adjunto_docs: 'fiel_desempeño_nombramiento_cmf_docs',
    },
  };

  const adjuntosVeedoresYLiquidaciones = {
    veedores_liquidaciones_ficha: {
      label: 'Ficha',
      adjunto_docs: 'veedores_liquidaciones_ficha_docs',
    },
    veedores_liquidaciones_bce: {
      label: 'BCE',
      adjunto_docs: 'veedores_liquidaciones_bce_docs',
    },
    veedores_liquidaciones_nombramiento_cmf: {
      label: 'Nombramiento de la CMF',
      adjunto_docs: 'veedores_liquidaciones_nombramiento_cmf_docs',
    },
    veedores_liquidaciones_cv: {
      label: 'CV',
      adjunto_docs: 'veedores_liquidaciones_cv_docs',
    },
    veedores_liquidaciones_estado_situacion_admin_ficha: {
      label: 'Ficha Estado de Situaciones Administradores',
      adjunto_docs: 'veedores_liquidaciones_estado_situacion_admin_ficha_docs',
    },
    veedores_liquidaciones_estado_situacion_admin_bce: {
      label: 'BCE Estado de Situaciones Administradores',
      adjunto_docs: 'veedores_liquidaciones_estado_situacion_admin_bce_docs',
    },
  };

  const requisitosEmision = {
    abono_prima: {
      label: 'Abono Prima',
      adjunto_docs: 'abono_prima_docs',
    },
    analisis_uaf: {
      label: 'Análisis UAF',
      adjunto_docs: 'analisis_uaf_docs',
    },
    constitucion_empresa: {
      label: 'Constitucion Empresa y Poderes Vigentes',
      adjunto_docs: 'constitucion_empresa_docs',
    },
    contrafianza_firmada_ante_notario: {
      label: 'Contrafianza Firmada Ante Notario',
      adjunto_docs: 'contrafianza_firmada_ante_notario_docs',
    },
    ficha_cliente: {
      label: 'Ficha Cliente',
      adjunto_docs: 'ficha_cliente_docs',
    },
    carpeta_tributaria: {
      label: 'Carpeta Tributaria',
      adjunto_docs: 'carpeta_tributaria_docs',
    },
    evaluacion_infocred_sinacofi: {
      label: 'Evaluación Infocred y Sinacofi',
      adjunto_docs: 'evaluacion_infocred_sinacofi_docs',
    },
    boletin_comercial_dicom: {
      label: 'Boletín Comercial DICOM',
      adjunto_docs: 'boletin_comercial_dicom_docs',
    },
    eerr_balance_2_ultimos_periodos: {
      label: 'EERR y Balance 2 Últimos periódos',
      adjunto_docs: 'eerr_balance_2_ultimos_periodos_docs',
    },
    prebalance: {
      label: 'Prebalance',
      adjunto_docs: 'prebalance_docs',
    },
  };

  const emptyAdjuntos = {
    contrato_asegurar: [],
    bases_licitacion: [],
    identificador_proyecto_mercado_publico: [],
    ordenes_de_compra: [],
    cuadros_de_obras: [],
    balance_inmobiliario: [],
    contratos_vigentes: [],
    experiencia_acreditable: [],
    estado_situacion_socios: [],
    consulta_unificada_poder_judicial: [],
    ds19_formulario_serviu: [],
    ds19_resolucion_aprobacion_proyecto: [],
    ds19_permiso_edificacion: [],
    ds19_ficha_inmobiliaria: [],
    ds19_aprobacion_bancaria: [],
    ds49_formulario_serviu: [],
    ds49_contrato_construccion: [],
    ds49_permiso_edificacion: [],
    ds49_ficha_inmobiliaria: [],
    venta_en_verde_cuestionario: [],
    venta_en_verde_permiso_edificacion: [],
    venta_en_verde_aprobacion_bancaria: [],
    capital_preferente_cuestionario_ficha_inmobiliaria: [],
    capital_preferente_permiso_edificacion: [],
    capital_preferente_aprobacion_bancaria: [],
    capital_preferente_datos_inversionista: [],
    capital_preferente_promesa_compraventa: [],
    aduaneras_ficha_cliente: [],
    aduaneras_bce: [],
    fiel_desempeño_ficha: [],
    fiel_desempeño_bce: [],
    fiel_desempeño_nombramiento_cmf: [],
    veedores_liquidaciones_ficha: [],
    veedores_liquidaciones_bce: [],
    veedores_liquidaciones_nombramiento_cmf: [],
    veedores_liquidaciones_cv: [],
    veedores_liquidaciones_estado_situacion_admin_ficha: [],
    veedores_liquidaciones_estado_situacion_admin_bce: [],
    abono_prima: [],
    analisis_uaf: [],
    constitucion_empresa: [],
    contrafianza_firmada_ante_notario: [],
    ficha_cliente: [],
    carpeta_tributaria: [],
    evaluacion_infocred_sinacofi: [],
    boletin_comercial_dicom: [],
    eerr_balance_2_ultimos_periodos: [],
    prebalance: [],
  };
  const emptyAdjuntosDocs = {
    contrato_asegurar_docs: [],
    bases_licitacion_docs: [],
    identificador_proyecto_mercado_publico_docs: [],
    ordenes_de_compra_docs: [],
    cuadros_de_obras_docs: [],
    balance_inmobiliario_docs: [],
    contratos_vigentes_docs: [],
    experiencia_acreditable_docs: [],
    estado_situacion_socios_docs: [],
    consulta_unificada_poder_judicial_docs: [],
    ds19_formulario_serviu_docs: [],
    ds19_resolucion_aprobacion_proyecto_docs: [],
    ds19_permiso_edificacion_docs: [],
    ds19_ficha_inmobiliaria_docs: [],
    ds19_aprobacion_bancaria_docs: [],
    ds49_formulario_serviu_docs: [],
    ds49_contrato_construccion_docs: [],
    ds49_permiso_edificacion_docs: [],
    ds49_ficha_inmobiliaria_docs: [],
    venta_en_verde_cuestionario_docs: [],
    venta_en_verde_permiso_edificacion_docs: [],
    venta_en_verde_aprobacion_bancaria_docs: [],
    capital_preferente_cuestionario_ficha_inmobiliaria_docs: [],
    capital_preferente_permiso_edificacion_docs: [],
    capital_preferente_aprobacion_bancaria_docs: [],
    capital_preferente_datos_inversionista_docs: [],
    capital_preferente_promesa_compraventa_docs: [],
    aduaneras_ficha_cliente_docs: [],
    aduaneras_bce_docs: [],
    fiel_desempeño_ficha_docs: [],
    fiel_desempeño_bce_docs: [],
    fiel_desempeño_nombramiento_cmf_docs: [],
    veedores_liquidaciones_ficha_docs: [],
    veedores_liquidaciones_bce_docs: [],
    veedores_liquidaciones_nombramiento_cmf_docs: [],
    veedores_liquidaciones_cv_docs: [],
    veedores_liquidaciones_estado_situacion_admin_ficha_docs: [],
    veedores_liquidaciones_estado_situacion_admin_bce_docs: [],
    abono_prima_docs: [],
    analisis_uaf_docs: [],
    constitucion_empresa_docs: [],
    contrafianza_firmada_ante_notario_docs: [],
    ficha_cliente_docs: [],
    carpeta_tributaria_docs: [],
    evaluacion_infocred_sinacofi_docs: [],
    boletin_comercial_dicom_docs: [],
    eerr_balance_2_ultimos_periodos_docs: [],
    prebalance_docs: [],
  };

  const [newAntecedentes, setNewAntecedentes] = useState(emptyAdjuntos);
  const [oldAntecedentes, setOldAntecedentes] = useState(emptyAdjuntosDocs);

  useEffect(() => {
    // Sólo si es edición se hacen requests para obtener la data.
    // Se evita hacer tanto request innecesario.
    if (fullData && fullData.estado && editableStates.includes(fullData.estado.nemotecnico)) {
      get(`${BASE_URL}${API.clientes}?roles__term=aseguradogarantia`).then((response) => {
        setAseguradoOptions(
          response.results.map((e) => ({
            value: e.id,
            label: `${e.rut} - ${e.nombre}`,
          })),
        );
      });
      get(`${BASE_URL}${API.clientes}?roles__term=beneficiariogarantia`).then((response) => {
        setBeneficiarioOptions(
          response.results.map((e) => ({
            value: e.id,
            label: `${e.rut} - ${e.nombre}`,
          })),
        );
      });
      get(`${BASE_URL}${API.tipos_institucion}`).then((response) => {
        setTipoInstitucionOptions(
          response.map((e) => ({
            label: e.descripcion,
            value: e.id,
          })),
        );
      });
      get(`${BASE_URL}${API.maestras}/?is_main=0&paginate=0&activa=true&ramo_asegurado__id=1`).then(
        (response) => {
          setCondicionadoOptions(
            response.map((e) => ({
              ...e,
              label: e.nemotecnico,
              value: e.id,
            })),
          );
        },
      );
      get(`${BASE_URL}${API.actividad}`).then((response) => {
        setActividadOptions(
          response
            .filter((act) => act.ramos_fecu.includes(1))
            .map((e) => ({
              label: e.descripcion,
              value: e.id,
            })),
        );
      });
    }
  }, []);

  useEffect(() => {
    if (condicionado && condicionado.productos) {
      setProductoOptions(
        condicionado.productos.map((e) => ({
          label: e.descripcion,
          value: e.id,
        })),
      );
    }
    if (condicionado && condicionado.tipos_cobertura) {
      setCoberturaOptions(
        condicionado.tipos_cobertura.map((e) => ({
          label: e.descripcion,
          value: e.id,
        })),
      );
    }
    if (condicionado && condicionado.tipologias_ejecucion) {
      setEjecucionOptions(
        condicionado.tipologias_ejecucion.map((e) => ({
          label: e.descripcion,
          value: e.id,
        })),
      );
    }
  }, [condicionado]);

  const searchAsegurado = (search) => {
    const searchURL = search ? `&search=${search}` : '';
    get(`${BASE_URL}${API.clientes}/?roles__term=aseguradogarantia${searchURL}`).then(
      (response) => {
        setAseguradoOptions(
          response.results.map((e) => ({
            value: e.id,
            label: `${e.rut} - ${e.nombre}`,
          })),
        );
      },
    );
  };

  const searchAseguradoDebounced = useCallback(
    _.debounce((search) => searchAsegurado(search), SEARCH_DELAY_MS),
    [],
  );

  useEffect(() => {
    if (aseguradoSearch) {
      searchAseguradoDebounced(aseguradoSearch);
    }
  }, [aseguradoSearch]);

  const searchBeneficiario = (search) => {
    const searchURL = search ? `&search=${search}` : '';
    get(`${BASE_URL}${API.clientes}?roles__term=beneficiariogarantia${searchURL}`).then(
      (response) => {
        setBeneficiarioOptions(
          response.results.map((e) => ({
            value: e.id,
            label: `${e.rut} - ${e.nombre}`,
          })),
        );
      },
    );
  };

  const searchBeneficiarioDebounced = useCallback(
    _.debounce((search) => searchBeneficiario(search), SEARCH_DELAY_MS),
    [],
  );

  useEffect(() => {
    if (beneficiarioSearch) {
      searchBeneficiarioDebounced(beneficiarioSearch);
    }
  }, [beneficiarioSearch]);

  useEffect(() => {
    if (fullData && fullData.info_oportunidad) {
      setFullInfo(fullData.info_oportunidad);
    }
    if (fullData && fullData.antecedentes_oportunidad) {
      setOldAntecedentes(fullData.antecedentes_oportunidad);
    }
    if (fullData && fullData.estado && editableStates.includes(fullData.estado.nemotecnico)) {
      setEditableSection(true);

      if (fullData.afianzado_id) {
        get(`${BASE_URL}${API.actividades}?organization_id=${fullData.afianzado_id}`).then(
          (response) => {
            setGiroOptions(
              response.map((e) => ({
                value: e.id,
                label: `${e.codigo} - ${e.nombre}`,
              })),
            );
          },
        );
      }
    }
  }, [fullData]);

  // CARGA DATOS FULLINFO
  useEffect(() => {
    if (fullInfo) {
      if (fullInfo.nombre_contacto) {
        setNombreContacto(fullInfo.nombre_contacto);
      }
      if (fullInfo.rut_contacto) {
        setRutContacto(fullInfo.rut_contacto);
      }
      if (fullInfo.phone_contacto) {
        setTelefonoContacto(fullInfo.phone_contacto);
      }
      if (fullInfo.email_contacto) {
        setMailContacto(fullInfo.email_contacto);
      }
      if (fullInfo.descripcion_contrato) {
        setDescripcionContrato(fullInfo.descripcion_contrato);
      }
      if (fullInfo.comentarios) {
        setComentarios(fullInfo.comentarios);
      }
      if (fullInfo.inicio_vigencia) {
        setInicioVigencia(moment(fullInfo.inicio_vigencia, BACKEND_DF));
      }
      if (fullInfo.termino_vigencia) {
        setFinVigencia(moment(fullInfo.termino_vigencia, BACKEND_DF));
      }
      if (fullInfo.glosa) {
        setGlosaPoliza(fullInfo.glosa);
      }
      if (fullInfo.documentos_adjuntos_docs) {
        setAdjuntosDocs(fullInfo.documentos_adjuntos_docs);
      }
      if (fullInfo.glosa_adjunta_docs) {
        setGlosaAdjuntaDocs(fullInfo.glosa_adjunta_docs);
      }
      if (fullInfo.actividad_id) {
        get(`${BASE_URL}${API.actividades}${fullInfo.actividad_id}/`).then((response) => {
          setGiro({
            label: `${response.codigo} - ${response.nombre}`,
            value: response.id,
          });
        });
      }
      if (fullInfo.asegurado_id) {
        get(`${BASE_URL}${API.clientes}/${fullInfo.asegurado_id}`).then((response) => {
          setAsegurado({
            label: `${response.rut} - ${response.nombre}`,
            value: response.id,
          });
        });
      }
      if (fullInfo.tipo_institucion) {
        setTipoInstitucion({
          label: fullInfo.tipo_institucion.descripcion,
          value: fullInfo.tipo_institucion.id,
        });
      }
      if (fullInfo.condicionado_general) {
        setCondicionado({
          ...fullInfo.condicionado_general,
          label: fullInfo.condicionado_general.nemotecnico,
          value: fullInfo.condicionado_general.id,
        });
      }
      if (fullInfo.producto) {
        setProducto({
          label: fullInfo.producto.descripcion,
          value: fullInfo.producto.id,
        });
      }
      if (fullInfo.tipo_cobertura) {
        setCobertura({
          label: fullInfo.tipo_cobertura.descripcion,
          value: fullInfo.tipo_cobertura.id,
        });
      }
      if (fullInfo.tipologia_ejecucion) {
        setEjecucion({
          label: fullInfo.tipologia_ejecucion.descripcion,
          value: fullInfo.tipologia_ejecucion.id,
        });
      }
      if (fullInfo.actividad_economica) {
        setActividad({
          label: fullInfo.actividad_economica.descripcion,
          value: fullInfo.actividad_economica.id,
        });
      }
      if (fullInfo.beneficiario_id) {
        get(`${BASE_URL}${API.clientes}/${fullInfo.beneficiario_id}`).then((response) => {
          setBeneficiario({
            label: `${response.rut} - ${response.nombre}`,
            value: response.id,
          });
        });
      }
    }
  }, [fullInfo]);

  const removeAdjuntoInfo = (idDoc, tipoDoc) => {
    setAdjuntosEliminadosInfo((prevState) => [...prevState, [idDoc, tipoDoc]]);
  };
  const removeAdjuntoAntecedentes = (idDoc, tipoDoc) => {
    setAdjuntosEliminadosAntecedentes((prevState) => [...prevState, [idDoc, tipoDoc]]);
  };

  const deleteAdjuntoInfo = () => {
    patch(
      `${BASE_URL}${API.info_oportunidad}/${fullInfo.id}/borrar-adjunto/`,
      adjuntosEliminadosInfo,
    ).then((response) => {
      setFullInfo(response);
      setAdjuntosEliminadosInfo([]);
    });
  };

  const deleteAdjuntoAntecedentes = () => {
    patch(
      `${BASE_URL}${API.antecedentes_oportunidad}/${oldAntecedentes.id}/borrar-adjunto/`,
      adjuntosEliminadosAntecedentes,
    ).then((response) => {
      setOldAntecedentes(response);
      setAdjuntosEliminadosAntecedentes([]);
    });
  };

  useExitPrompt((e) => {
    e.preventDefault();
    e.returnValue = '';
  }, !isFormSaved);

  // Subsecciones de adjuntos
  const subSections = [
    {
      name: 'DS19',
      component: (
        <SubSectionAdjuntos
          newAntecedentes={newAntecedentes}
          oldAntecedentes={oldAntecedentes}
          setNewAntecedentes={setNewAntecedentes}
          setOldAntecedentes={setOldAntecedentes}
          removeAdjunto={removeAdjuntoAntecedentes}
          editableSection={editableSection}
          dataAdjuntos={adjuntosDS19}
          setIsFormSaved={setIsFormSaved}
        />
      ),
      collapsed: true,
      isValid: true,
      hasIcon: false,
      expansible: true,
      isVisible: true,
    },
    {
      name: 'DS49',
      component: (
        <SubSectionAdjuntos
          newAntecedentes={newAntecedentes}
          oldAntecedentes={oldAntecedentes}
          setNewAntecedentes={setNewAntecedentes}
          setOldAntecedentes={setOldAntecedentes}
          removeAdjunto={removeAdjuntoAntecedentes}
          editableSection={editableSection}
          dataAdjuntos={adjuntosDS49}
          setIsFormSaved={setIsFormSaved}
        />
      ),
      collapsed: true,
      isValid: true,
      hasIcon: false,
      expansible: true,
      isVisible: true,
    },
    {
      name: 'Venta en Verde',
      component: (
        <SubSectionAdjuntos
          newAntecedentes={newAntecedentes}
          oldAntecedentes={oldAntecedentes}
          setNewAntecedentes={setNewAntecedentes}
          setOldAntecedentes={setOldAntecedentes}
          removeAdjunto={removeAdjuntoAntecedentes}
          editableSection={editableSection}
          dataAdjuntos={adjuntosVentaEnVerde}
          setIsFormSaved={setIsFormSaved}
        />
      ),
      collapsed: true,
      isValid: true,
      hasIcon: false,
      expansible: true,
      isVisible: true,
    },
    {
      name: 'Capital Preferente',
      component: (
        <SubSectionAdjuntos
          newAntecedentes={newAntecedentes}
          oldAntecedentes={oldAntecedentes}
          setNewAntecedentes={setNewAntecedentes}
          setOldAntecedentes={setOldAntecedentes}
          removeAdjunto={removeAdjuntoAntecedentes}
          editableSection={editableSection}
          dataAdjuntos={adjuntosCapitalPreferente}
          setIsFormSaved={setIsFormSaved}
        />
      ),
      collapsed: true,
      isValid: true,
      hasIcon: false,
      expansible: true,
      isVisible: true,
    },
    {
      name: 'Aduaneras',
      component: (
        <SubSectionAdjuntos
          newAntecedentes={newAntecedentes}
          oldAntecedentes={oldAntecedentes}
          setNewAntecedentes={setNewAntecedentes}
          setOldAntecedentes={setOldAntecedentes}
          removeAdjunto={removeAdjuntoAntecedentes}
          editableSection={editableSection}
          dataAdjuntos={adjuntosAduaneras}
          setIsFormSaved={setIsFormSaved}
        />
      ),
      collapsed: true,
      isValid: true,
      hasIcon: false,
      expansible: true,
      isVisible: true,
    },
    {
      name: 'Fiel Desempeño',
      component: (
        <SubSectionAdjuntos
          newAntecedentes={newAntecedentes}
          oldAntecedentes={oldAntecedentes}
          setNewAntecedentes={setNewAntecedentes}
          setOldAntecedentes={setOldAntecedentes}
          removeAdjunto={removeAdjuntoAntecedentes}
          editableSection={editableSection}
          dataAdjuntos={adjuntosFielDesempeno}
          setIsFormSaved={setIsFormSaved}
        />
      ),
      collapsed: true,
      isValid: true,
      hasIcon: false,
      expansible: true,
      isVisible: true,
    },
    {
      name: 'Veedores y Liquidaciones',
      component: (
        <SubSectionAdjuntos
          newAntecedentes={newAntecedentes}
          oldAntecedentes={oldAntecedentes}
          setNewAntecedentes={setNewAntecedentes}
          setOldAntecedentes={setOldAntecedentes}
          removeAdjunto={removeAdjuntoAntecedentes}
          editableSection={editableSection}
          dataAdjuntos={adjuntosVeedoresYLiquidaciones}
          setIsFormSaved={setIsFormSaved}
        />
      ),
      collapsed: true,
      isValid: true,
      hasIcon: false,
      expansible: true,
      isVisible: true,
    },
  ];

  const getBodyAntecedentes = () => {
    return {
      contrato_asegurar: newAntecedentes.contrato_asegurar.map((d) => d.uuid),
      bases_licitacion: newAntecedentes.bases_licitacion.map((d) => d.uuid),
      identificador_proyecto_mercado_publico: newAntecedentes.identificador_proyecto_mercado_publico.map(
        (d) => d.uuid,
      ),
      ordenes_de_compra: newAntecedentes.ordenes_de_compra.map((d) => d.uuid),
      cuadros_de_obras: newAntecedentes.cuadros_de_obras.map((d) => d.uuid),
      balance_inmobiliario: newAntecedentes.balance_inmobiliario.map((d) => d.uuid),
      contratos_vigentes: newAntecedentes.contratos_vigentes.map((d) => d.uuid),
      experiencia_acreditable: newAntecedentes.experiencia_acreditable.map((d) => d.uuid),
      estado_situacion_socios: newAntecedentes.estado_situacion_socios.map((d) => d.uuid),
      consulta_unificada_poder_judicial: newAntecedentes.consulta_unificada_poder_judicial.map(
        (d) => d.uuid,
      ),
      ds19_formulario_serviu: newAntecedentes.ds19_formulario_serviu.map((d) => d.uuid),
      ds19_resolucion_aprobacion_proyecto: newAntecedentes.ds19_resolucion_aprobacion_proyecto.map(
        (d) => d.uuid,
      ),
      ds19_permiso_edificacion: newAntecedentes.ds19_permiso_edificacion.map((d) => d.uuid),
      ds19_ficha_inmobiliaria: newAntecedentes.ds19_ficha_inmobiliaria.map((d) => d.uuid),
      ds19_aprobacion_bancaria: newAntecedentes.ds19_aprobacion_bancaria.map((d) => d.uuid),
      ds49_formulario_serviu: newAntecedentes.ds49_formulario_serviu.map((d) => d.uuid),
      ds49_contrato_construccion: newAntecedentes.ds49_contrato_construccion.map((d) => d.uuid),
      ds49_permiso_edificacion: newAntecedentes.ds49_permiso_edificacion.map((d) => d.uuid),
      ds49_ficha_inmobiliaria: newAntecedentes.ds49_ficha_inmobiliaria.map((d) => d.uuid),
      venta_en_verde_cuestionario: newAntecedentes.venta_en_verde_cuestionario.map((d) => d.uuid),
      venta_en_verde_permiso_edificacion: newAntecedentes.venta_en_verde_permiso_edificacion.map(
        (d) => d.uuid,
      ),
      venta_en_verde_aprobacion_bancaria: newAntecedentes.venta_en_verde_aprobacion_bancaria.map(
        (d) => d.uuid,
      ),
      capital_preferente_cuestionario_ficha_inmobiliaria: newAntecedentes.capital_preferente_cuestionario_ficha_inmobiliaria.map(
        (d) => d.uuid,
      ),
      capital_preferente_permiso_edificacion: newAntecedentes.capital_preferente_permiso_edificacion.map(
        (d) => d.uuid,
      ),
      capital_preferente_aprobacion_bancaria: newAntecedentes.capital_preferente_aprobacion_bancaria.map(
        (d) => d.uuid,
      ),
      capital_preferente_datos_inversionista: newAntecedentes.capital_preferente_datos_inversionista.map(
        (d) => d.uuid,
      ),
      capital_preferente_promesa_compraventa: newAntecedentes.capital_preferente_promesa_compraventa.map(
        (d) => d.uuid,
      ),
      aduaneras_ficha_cliente: newAntecedentes.aduaneras_ficha_cliente.map((d) => d.uuid),
      aduaneras_bce: newAntecedentes.aduaneras_bce.map((d) => d.uuid),
      fiel_desempeño_ficha: newAntecedentes.fiel_desempeño_ficha.map((d) => d.uuid),
      fiel_desempeño_bce: newAntecedentes.fiel_desempeño_bce.map((d) => d.uuid),
      fiel_desempeño_nombramiento_cmf: newAntecedentes.fiel_desempeño_nombramiento_cmf.map(
        (d) => d.uuid,
      ),
      veedores_liquidaciones_ficha: newAntecedentes.veedores_liquidaciones_ficha.map((d) => d.uuid),
      veedores_liquidaciones_bce: newAntecedentes.veedores_liquidaciones_bce.map((d) => d.uuid),
      veedores_liquidaciones_nombramiento_cmf: newAntecedentes.veedores_liquidaciones_nombramiento_cmf.map(
        (d) => d.uuid,
      ),
      veedores_liquidaciones_cv: newAntecedentes.veedores_liquidaciones_cv.map((d) => d.uuid),
      veedores_liquidaciones_estado_situacion_admin_ficha: newAntecedentes.veedores_liquidaciones_estado_situacion_admin_ficha.map(
        (d) => d.uuid,
      ),
      veedores_liquidaciones_estado_situacion_admin_bce: newAntecedentes.veedores_liquidaciones_estado_situacion_admin_bce.map(
        (d) => d.uuid,
      ),
      abono_prima: newAntecedentes.abono_prima.map((d) => d.uuid),
      analisis_uaf: newAntecedentes.analisis_uaf.map((d) => d.uuid),
      constitucion_empresa: newAntecedentes.constitucion_empresa.map((d) => d.uuid),
      contrafianza_firmada_ante_notario: newAntecedentes.contrafianza_firmada_ante_notario.map(
        (d) => d.uuid,
      ),
      ficha_cliente: newAntecedentes.ficha_cliente.map((d) => d.uuid),
      carpeta_tributaria: newAntecedentes.carpeta_tributaria.map((d) => d.uuid),
      evaluacion_infocred_sinacofi: newAntecedentes.evaluacion_infocred_sinacofi.map((d) => d.uuid),
      boletin_comercial_dicom: newAntecedentes.boletin_comercial_dicom.map((d) => d.uuid),
      eerr_balance_2_ultimos_periodos: newAntecedentes.eerr_balance_2_ultimos_periodos.map(
        (d) => d.uuid,
      ),
      prebalance: newAntecedentes.prebalance.map((d) => d.uuid),
    };
  };

  const getBodyInfo = () => {
    const body = {};
    if (nombreContacto) body.nombre_contacto = nombreContacto;
    if (rutContacto) body.rut_contacto = validateRut(rutContacto).formattedForBackend;
    if (telefonoContacto) body.phone_contacto = telefonoContacto;
    if (mailContacto) body.email_contacto = mailContacto;
    if (descripcionContrato) body.descripcion_contrato = descripcionContrato;
    if (comentarios) body.comentarios = comentarios;
    if (inicioVigencia)
      body.inicio_vigencia = moment(inicioVigencia, FRONT_DF_1).format(BACKEND_DF);
    if (finVigencia) body.termino_vigencia = moment(finVigencia, FRONT_DF_1).format(BACKEND_DF);
    if (glosaPoliza) body.glosa = glosaPoliza;
    if (adjuntos.length) body.documentos_adjuntos = adjuntos.map((d) => d.uuid);
    if (glosaAdjunta.length) body.glosa_adjunta = glosaAdjunta.map((d) => d.uuid);
    if (giro) body.actividad_id = giro.value;
    if (asegurado) body.asegurado_id = asegurado.value;
    if (tipoInstitucion) body.tipo_institucion_id = tipoInstitucion.value;
    if (producto) body.producto_id = producto.value;
    if (cobertura) body.tipo_cobertura_id = cobertura.value;
    if (ejecucion) body.tipologia_ejecucion_id = ejecucion.value;
    if (actividad) body.actividad_economica_id = actividad.value;
    if (condicionado) body.condicionado_general_id = condicionado.value;
    if (beneficiario) body.beneficiario_id = beneficiario.value;

    return body;
  };

  const [validPatchInfo, setValidPatchInfo] = useState(null);
  const [validPatchAntecedentes, setValidPatchAntecedentes] = useState(null);
  const [validPatchOportunidad, setValidPatchOportunidad] = useState(null);
  const [patchingOportunidad, setPatchingOportunidad] = useState(false);

  const patchInfo = (bodyInfo) => {
    patch(`${BASE_URL}${API.info_oportunidad}/${fullInfo.id}/`, bodyInfo)
      .then((response) => {
        setAdjuntos([]);
        setGlosaAdjunta([]);
        setFullInfo(response);
        if (adjuntosEliminadosInfo.length) deleteAdjuntoInfo();
        setValidPatchInfo(true);
      })
      .catch(() => {
        setValidPatchInfo(false);
      });
  };

  const patchAntecedentes = (bodyAntecedentes) => {
    patch(`${BASE_URL}${API.antecedentes_oportunidad}/${oldAntecedentes.id}/`, bodyAntecedentes)
      .then((responseAntecedentes) => {
        setNewAntecedentes(emptyAdjuntos);
        setOldAntecedentes(responseAntecedentes);
        setIsFormSaved(true);
        if (adjuntosEliminadosAntecedentes.length) deleteAdjuntoAntecedentes();
        setValidPatchAntecedentes(true);
      })
      .catch(() => {
        setValidPatchAntecedentes(false);
      });
  };

  const patchOportunidad = (body) => {
    patch(`${BASE_URL}${API.oportunidades_garantia}/${id}/`, body)
      .then((responseOportunidad) => {
        setFullData(responseOportunidad);
        setInitialSection(OPORTUNIDADES_STATE_SECTION_MAP[responseOportunidad.estado.nemotecnico]);
        setEditableSection(false);
        setValidPatchOportunidad(true);
      })
      .catch(() => {
        setValidPatchOportunidad(false);
      })
      .finally(() => {
        setConfirmationModal(false);
        setLoading(false);
      });
  };

  const guardarInfoOportunidad = () => {
    setLoading(true);
    const bodyInfo = getBodyInfo();
    const bodyAntecedentes = getBodyAntecedentes();

    patchInfo(bodyInfo);
    patchAntecedentes(bodyAntecedentes);
  };

  useEffect(() => {
    if (!patchingOportunidad && validPatchAntecedentes !== null && validPatchInfo !== null) {
      if (validPatchAntecedentes === true && validPatchInfo === true) {
        setShowUpdateModal(true);
      } else {
        setShowErrorModal(true);
      }
      setValidPatchAntecedentes(null);
      setValidPatchInfo(null);
      setLoading(false);
    } else if (patchingOportunidad && validPatchAntecedentes !== null && validPatchInfo !== null) {
      if (validPatchAntecedentes === true && validPatchInfo === true) {
        const body = {
          estado_id: estadosOportunidad.find(
            (e) => e.nemotecnico === ESTADOS_OPORTUNIDAD.informacionCompletadaEsperandoSuscriptor,
          ).id,
        };
        patchOportunidad(body);
      } else {
        setValidPatchAntecedentes(null);
        setValidPatchInfo(null);
        setConfirmationModal(false);
        setLoading(false);
        setShowErrorModal(true);
      }
    }
  }, [validPatchAntecedentes, validPatchInfo]);

  useEffect(() => {
    if (validPatchOportunidad !== null) {
      if (validPatchOportunidad) setShowUpdateModal(true);
      else setShowErrorModal(true);
      setValidPatchAntecedentes(null);
      setValidPatchInfo(null);
      setValidPatchOportunidad(null);
      setPatchingOportunidad(false);
    }
  }, [validPatchOportunidad]);

  const avanzarOportunidad = () => {
    setPatchingOportunidad(true);
    guardarInfoOportunidad();
  };

  const isValidInfo = () => {
    return (
      nombreContacto &&
      validateRut(rutContacto).isValid &&
      regexfono.test(telefonoContacto) &&
      emailValidate(mailContacto) &&
      descripcionContrato &&
      comentarios &&
      inicioVigencia &&
      finVigencia &&
      glosaPoliza &&
      giro &&
      asegurado &&
      tipoInstitucion &&
      producto &&
      cobertura &&
      ejecucion &&
      actividad &&
      condicionado &&
      beneficiario
    );
  };

  return (
    <div>
      <FormGroup row>
        <Col>
          <Label>
            Giro Afianzado
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Select
            className="custom-policy-select"
            options={giroOptions}
            value={giro}
            onChange={(event) => setGiro(event)}
            isDisabled={!editableSection}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Nombre de Contacto
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Input
            defaultValue={nombreContacto}
            onChange={(event) => setNombreContacto(event.target.value)}
            disabled={!editableSection}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Rut de Contacto
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Input
            defaultValue={rutContacto}
            onChange={(event) => setRutContacto(event.target.value)}
            disabled={!editableSection}
          />
          {rutContacto.length > 3 && !validateRut(rutContacto).isValid && (
            <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px', display: 'block' }}>
              Rut inválido
            </FormFeedback>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Correo de Contacto
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Input
            defaultValue={mailContacto}
            onChange={(event) => {
              setMailContacto(event.target.value);
            }}
            disabled={!editableSection}
          />
          {mailContacto.length > 0 && !emailValidate(mailContacto) && (
            <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px', display: 'block' }}>
              Correo inválido
            </FormFeedback>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Número de Contacto
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Input
            defaultValue={telefonoContacto}
            onChange={(event) => setTelefonoContacto(event.target.value)}
            disabled={!editableSection}
          />
          {telefonoContacto.length > 0 && !regexfono.test(telefonoContacto) && (
            <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px', display: 'block' }}>
              Teléfono inválido
            </FormFeedback>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Asegurado
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Select
            className="custom-policy-select"
            options={aseguradoOptions}
            value={asegurado}
            isDisabled={!editableSection}
            onChange={(event) => setAsegurado(event)}
            onInputChange={(event) => setAseguradoSearch(event)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Tipo de Institución
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Select
            className="custom-policy-select"
            options={tipoInstitucionOptions}
            value={tipoInstitucion}
            isDisabled={!editableSection}
            onChange={(event) => setTipoInstitucion(event)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Condicionado General
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Select
            className="custom-policy-select"
            options={condicionadoOptions}
            value={condicionado}
            isDisabled={!editableSection}
            onChange={(event) => {
              setProducto(null);
              setCobertura(null);
              setEjecucion(null);
              setCondicionado(event);
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Producto
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Select
            className="custom-policy-select"
            options={productoOptions}
            value={producto}
            isDisabled={!editableSection}
            onChange={(event) => setProducto(event)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Cobertura
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Select
            className="custom-policy-select"
            options={coberturaOptions}
            value={cobertura}
            isDisabled={!editableSection}
            onChange={(event) => setCobertura(event)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Tipología de Ejecución
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Select
            className="custom-policy-select"
            options={ejecucionOptions}
            value={ejecucion}
            isDisabled={!editableSection}
            onChange={(event) => setEjecucion(event)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Actividad Asegurada
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Select
            className="custom-policy-select"
            options={actividadOptions}
            value={actividad}
            isDisabled={!editableSection}
            onChange={(event) => setActividad(event)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Inicio Vigencia
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          {(editableSection && (
            <Datetime
              closeOnSelect
              dateFormat={FRONT_DF_1}
              isValidDate={(date) => {
                if (finVigencia) {
                  return moment(date, FRONT_DF_1).isSameOrBefore(
                    moment(finVigencia, FRONT_DF_1),
                    'date',
                  );
                }
                return true;
              }}
              onChange={(event) => setInicioVigencia(event)}
              renderInput={(props) => <input {...props} className="rs-input" />}
              timeFormat={false}
              value={inicioVigencia}
            />
          )) || <Input value={moment(inicioVigencia).format(FRONT_DF_1)} disabled />}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Fin Vigencia
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          {(editableSection && (
            <Datetime
              closeOnSelect
              dateFormat={FRONT_DF_1}
              isValidDate={(date) =>
                moment(date, FRONT_DF_1).isSameOrAfter(moment(inicioVigencia, FRONT_DF_1), 'date')
              }
              onChange={(event) => setFinVigencia(event)}
              renderInput={(props) => <input {...props} className="rs-input" />}
              timeFormat={false}
              value={finVigencia}
            />
          )) || <Input value={moment(finVigencia).format(FRONT_DF_1)} disabled />}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Beneficiario
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Select
            className="custom-policy-select"
            options={beneficiarioOptions}
            value={beneficiario}
            isDisabled={!editableSection}
            onChange={(event) => setBeneficiario(event)}
            onInputChange={(event) => setBeneficiarioSearch(event)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        {/* TODO: Settear opciones segun lo que mande orsan eventualmente */}
        <Col>
          <Label>Plazo</Label>
        </Col>
        <Col>
          <Select className="custom-policy-select" isDisabled />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Glosa Póliza
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Input
            defaultValue={glosaPoliza}
            onChange={(event) => setGlosaPoliza(event.target.value)}
            type="textarea"
            rows="3"
            disabled={!editableSection}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Descripción Contrato a Cubrir
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Input
            defaultValue={descripcionContrato}
            onChange={(event) => setDescripcionContrato(event.target.value)}
            type="textarea"
            rows="3"
            disabled={!editableSection}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>
            Comentarios
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col>
          <Input
            defaultValue={comentarios}
            onChange={(event) => setComentarios(event.target.value)}
            type="textarea"
            rows="3"
            disabled={!editableSection}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>Glosa Adjunta</Label>
        </Col>
        <Col>
          <NewFileTable
            handleAdd={(file) => {
              setIsFormSaved(false);
              setGlosaAdjunta((prevState) => [...prevState, file]);
            }}
            handleDeleteNew={(file) => {
              setIsFormSaved(false);
              setGlosaAdjunta((prevState) => prevState.filter((doc) => doc.uuid !== file.uuid));
            }}
            handleDelete={(event) => {
              setIsFormSaved(false);
              removeAdjuntoInfo(event.id, 'glosa_adjunta');
              setGlosaAdjuntaDocs((prevState) => prevState.filter((doc) => doc.id !== event.id));
            }}
            uploadUrl={`${BASE_URL}${API.upload}/`}
            downloadUrl={`${BASE_URL}${API.download}/`}
            newFiles={glosaAdjunta}
            fileList={glosaAdjuntaDocs}
            readOnly={!editableSection}
            loginIdToken=""
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col>
          <Label>Adjuntos</Label>
        </Col>
        <Col>
          <NewFileTable
            handleAdd={(file) => {
              setIsFormSaved(false);
              setAdjuntos((prevState) => [...prevState, file]);
            }}
            handleDeleteNew={(file) => {
              setIsFormSaved(false);
              setAdjuntos((prevState) => prevState.filter((doc) => doc.uuid !== file.uuid));
            }}
            handleDelete={(event) => {
              setIsFormSaved(false);
              removeAdjuntoInfo(event.id, 'documentos_adjuntos');
              setAdjuntosDocs((prevState) => prevState.filter((doc) => doc.id !== event.id));
            }}
            uploadUrl={`${BASE_URL}${API.upload}/`}
            downloadUrl={`${BASE_URL}${API.download}/`}
            newFiles={adjuntos}
            fileList={adjuntosDocs}
            readOnly={!editableSection}
            loginIdToken=""
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <hr style={{ marginTop: 40, marginBottom: 40 }} />
        {Object.keys(dataAdjuntos).map((key) => {
          return (
            <div key={key}>
              <Row>
                <Col>
                  <Label>{dataAdjuntos[key].label}</Label>
                </Col>
                <Col>
                  <NewFileTable
                    handleAdd={(file) => {
                      setIsFormSaved(false);
                      setNewAntecedentes((prevState) => ({
                        ...prevState,
                        [key]: [...prevState[key], file],
                      }));
                    }}
                    handleDeleteNew={(file) => {
                      setIsFormSaved(false);
                      setNewAntecedentes((prevState) => ({
                        ...prevState,
                        [key]: prevState[key].filter((doc) => doc.uuid !== file.uuid),
                      }));
                    }}
                    handleDelete={(event) => {
                      setIsFormSaved(false);
                      removeAdjuntoAntecedentes(event.id, key);
                      setOldAntecedentes((prevState) => ({
                        ...prevState,
                        [dataAdjuntos[key].adjunto_docs]: prevState[
                          dataAdjuntos[key].adjunto_docs
                        ].filter((doc) => doc.id !== event.id),
                      }));
                    }}
                    uploadUrl={`${BASE_URL}${API.upload}/`}
                    downloadUrl={`${BASE_URL}${API.download}/`}
                    newFiles={newAntecedentes[key]}
                    fileList={oldAntecedentes[dataAdjuntos[key].adjunto_docs]}
                    individualMaxFileSize={13}
                    globalMaxFileSize={20}
                    loginIdToken=""
                    readOnly={!editableSection}
                  />
                </Col>
              </Row>
              <br />
            </div>
          );
        })}
        <OportunitiesFormAccordion sections={subSections} hasIcons={false} />
        <hr style={{ marginTop: 40, marginBottom: 40 }} />
        <Label>
          <strong> Requisitos de Emisión </strong>
        </Label>
        {Object.keys(requisitosEmision).map((key) => {
          return (
            <div key={key}>
              <Row>
                <Col>
                  <Label>{requisitosEmision[key].label}</Label>
                </Col>
                <Col>
                  <NewFileTable
                    handleAdd={(file) => {
                      setIsFormSaved(false);
                      setNewAntecedentes((prevState) => ({
                        ...prevState,
                        [key]: [...prevState[key], file],
                      }));
                    }}
                    handleDeleteNew={(file) => {
                      setIsFormSaved(false);
                      setNewAntecedentes((prevState) => ({
                        ...prevState,
                        [key]: prevState[key].filter((doc) => doc.uuid !== file.uuid),
                      }));
                    }}
                    handleDelete={(event) => {
                      setIsFormSaved(false);
                      removeAdjuntoAntecedentes(event.id, key);
                      setOldAntecedentes((prevState) => ({
                        ...prevState,
                        [requisitosEmision[key].adjunto_docs]: prevState[
                          requisitosEmision[key].adjunto_docs
                        ].filter((doc) => doc.id !== event.id),
                      }));
                    }}
                    uploadUrl={`${BASE_URL}${API.upload}/`}
                    downloadUrl={`${BASE_URL}${API.download}/`}
                    newFiles={newAntecedentes[key]}
                    fileList={oldAntecedentes[requisitosEmision[key].adjunto_docs]}
                    individualMaxFileSize={13}
                    globalMaxFileSize={20}
                    loginIdToken=""
                    readOnly={!editableSection}
                  />
                </Col>
              </Row>
              <br />
            </div>
          );
        })}
      </FormGroup>
      {editableSection && (
        <div className="button-container d-flex justify-content-end">
          <Button
            className="btn btn-secondary white"
            style={{ marginRight: 25 }}
            onClick={() => guardarInfoOportunidad()}
            disabled={loading || confirmationModal}
          >
            {loading && !confirmationModal ? (
              <FontAwesomeIcon spin={loading} icon={faSpinner} />
            ) : (
              'Guardar Información Temporalmente'
            )}
          </Button>
          <Button
            className="btn btn-secondary orange"
            onClick={() => {
              setConfirmationModal(true);
            }}
            disabled={!isValidInfo() || loading || confirmationModal}
          >
            {loading && confirmationModal ? (
              <FontAwesomeIcon spin={loading} icon={faSpinner} />
            ) : (
              'Guardar Información y Avanzar'
            )}
          </Button>
        </div>
      )}
      <CheckModal
        isOpen={showUpdateModal}
        toggle={() => setShowUpdateModal(false)}
        handleOk={() => setShowUpdateModal(false)}
        okBtnText="OK"
        confirmMsg="Información guardada exitosamente."
      />
      <WarningModal
        isOpen={showErrorModal}
        toggle={() => setShowErrorModal(false)}
        handleOk={() => setShowErrorModal(false)}
        okBtnText="Aceptar"
        confirmMsg="Ha ocurrido un error al actualizar la oportunidad."
        loading={false}
        onlyCancelButton
      />
      <AvanzarOportunidadConfirmationModal
        openModal={confirmationModal}
        setOpenModal={setConfirmationModal}
        avanzarOportunidad={avanzarOportunidad}
        loading={loading}
      />
      <Prompt
        when={!isFormSaved}
        message="¿Está seguro que desea salir? Es posible que los cambios no se guarden."
      />
    </div>
  );
};

CompletarInformacionSection.propTypes = {
  id: PropTypes.string,
  fullData: PropTypes.shape({
    estado: PropTypes.shape({
      nemotecnico: PropTypes.string,
    }),
    info_oportunidad: PropTypes.shape({
      id: PropTypes.number,
    }),
    antecedentes_oportunidad: PropTypes.shape({
      id: PropTypes.number,
    }),
    afianzado_id: PropTypes.number,
  }),
  setFullData: PropTypes.func.isRequired,
  setInitialSection: PropTypes.func.isRequired,
  estadosOportunidad: PropTypes.arrayOf(PropTypes.shape({})),
};

CompletarInformacionSection.defaultProps = {
  id: null,
  fullData: null,
  estadosOportunidad: [],
};

export default CompletarInformacionSection;
