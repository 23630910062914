import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Col, FormGroup, Label, Button, Row, Input, FormFeedback } from 'reactstrap';
import NumberFormat from "react-number-format";
import { headerOperation, cellOperation, cellNumOperation } from 'helpers/tables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { get, patch } from 'helpers/requests';
import BaseTable from 'components/UI/BaseTable';
import FormSection from '../UI/FormSection';
import {
  ROL_BENEFICIARIO_VENTA_VERDE,
  API,
  BASE_URL,
} from '../../CONST';
import { formatForBackend, formatRut, validateRut } from '../../helpers/rutValidator';
import { objectsToOptions } from '../../helpers/selects';
import BeneficiarioCreateModal from './BeneficiarioCreateModal';
import CheckModal from '../shared/CheckModal';

const RUT_MIN_SIZE = 9;


const Beneficiarios = ({
	isEditable,
  beneficiario,
  setBeneficiario,
  participacion,
  setParticipacion,
  tableDataBeneficiario,
  setTableDataBeneficiario,
  isParticipacionFulled,
}) => {

  const [beneficiarioCreated, setBeneficiarioCreated] = useState(false);
  const [beneficiarioUpdated, setBeneficiarioUpdated] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [validRut, setValidRut] = useState(true);
  const [rut, setRut] = useState('');
  const [rutExist, setRutExist] = useState(true);

    // useEffect GETs
    const [organizationTypes, setOrganizationTypes] = useState([]);
    const [regiones, setRegiones] = useState([]);
    const [allRoles, setAllRoles] = useState([]);
    const [rolesVentaEnVerde, setRolesVentaEnVerde] = useState([]);

  const cleanBeneficiario = () => {
    setRut('');
    setParticipacion(null);
    setBeneficiario({});
    setRutExist(true);
  }

  useEffect(() => {
    if (rut) {
      const validatedRut = validateRut(rut);
      setValidRut(validatedRut.isValid);
      if (validRut) {
        setBeneficiario({})
      }
    }
  }, [rut]);

  useEffect(() => {
    get(`${BASE_URL}${API.organizationTypes}`)
    .then((response) => {
      const data = objectsToOptions(response, false, true, 'name');
      setOrganizationTypes(data);
    });

    get(`${BASE_URL}${API.regiones}`)
    .then((response) => {
      setRegiones(objectsToOptions(response, false, true, 'name'));
    });

    get(`${BASE_URL}${API.roles}`)
    .then((response) => {
      const data = objectsToOptions(response, false, true, 'name');
      setAllRoles(data);
      setRolesVentaEnVerde(data.filter(rol => {
        return rol.object.natural_key === 'beneficiarioventaenverde'
        || rol.object.natural_key === 'aseguradoventaenverde'
      }));
    });
  }, [])

  const handleDeleteBeneficiario= (beneficiarioToDelete) => {
    setTableDataBeneficiario(
        tableDataBeneficiario.filter(
        (item) => item !== beneficiarioToDelete,
      ),
    );
  };

  const handleCreateBeneficiario = () => {
    setShowCreateModal(false);
    setBeneficiarioCreated(true);
  }

  const handleEditBeneficiario = async (beneficiarioToEdit) => {
    await setRut(beneficiarioToEdit.beneficiarioValue.rut);
    handleDeleteBeneficiario(beneficiarioToEdit);
    setParticipacion(beneficiarioToEdit.beneficiarioParticipacion);
    setBeneficiario({
      id: beneficiarioToEdit.beneficiarioValue.id,
      nombre: beneficiarioToEdit.beneficiarioValue.nombre,
      roles: beneficiarioToEdit.beneficiarioValue.roles,
      rut: beneficiarioToEdit.beneficiarioValue.rut,
    });
  };

  const totalParticipationTable = () => {
    const total = tableDataBeneficiario.reduce(
      (previo, nuevo) => previo + nuevo.beneficiarioParticipacion, 0
    );
    return total
  }

  const isParticipacionNotExceded = () => {
    const participacionPrev = tableDataBeneficiario.reduce(
      (previo, nuevo) => previo + nuevo.beneficiarioParticipacion, 0
    );
    const participacionNew = participacionPrev + participacion;
    return participacionNew <= 100 && participacionNew > 0;
  }

  const isBeneficiarioVentaVerde = ( { roles } ) => roles && roles.includes(ROL_BENEFICIARIO_VENTA_VERDE);

  const handleRutChange = async (beneficiarioRut) => {
    const validatedRut = validateRut(beneficiarioRut);
    const backendRut = formatForBackend(validatedRut.formatted);
    setRut(beneficiarioRut);
    if (validatedRut.isValid) {
      await get(`${BASE_URL}${API.clientes}?search=${backendRut}`)
      .then(({ results }) => {
        if (results.length){
          setBeneficiario(...results)
          setRutExist(true)
        } else {
          setRutExist(false)
        }
      })
    }
  }

  const patchRolBeneficiarioVentaVerde = () => {
    const roles = allRoles.filter(rol => beneficiario.roles.includes(rol.object.natural_key))
    const rolesVV = allRoles.filter(rol => {
      return ['aseguradoventaenverde', 'beneficiarioventaenverde'].includes(rol.object.natural_key)
    })
    const body = {
      roles_set: roles.map(rol => rol.value).concat(rolesVV.map(rol => rol.value))
    };

    patch(`${BASE_URL}${API.organizaciones}${beneficiario.id}/`, body)
    .then((response) => {
      handleRutChange(response.rut);
      setBeneficiarioUpdated(true);
    });
  }

  const handleAddTableData = async () => {
    await handleRutChange(beneficiario.rut);
    if (beneficiario && isParticipacionNotExceded() && isBeneficiarioVentaVerde(beneficiario)) {
      setTableDataBeneficiario([...tableDataBeneficiario, {
        beneficiarioValue: beneficiario,
        beneficiarioParticipacion: participacion}
      ])
      cleanBeneficiario()
    }
  }

  const showCreateBeneficiarioModal = () => {
    setShowCreateModal(true)
  }

  let columns = [
    {
      Header: headerOperation('Asegurado/Beneficiario'),
      id: 'beneficiarioValue',
      accessor: 'beneficiarioValue.nombre',
      Cell: (row) => cellOperation(row.value),
      filterable: false,
      sortable: false,
      minWidth: 120,
    },
    {
      Header: headerOperation('Participacion (%)'),
      id: 'beneficiarioParticipacion',
      accessor: 'beneficiarioParticipacion',
      Cell: (row) => cellNumOperation(row.value, 'center', 2, '%'),
      filterable: false,
      sortable: false,
      minWidth: 120,
    }
  ]

  const actions = {
    Header: headerOperation('Acciones'),
    id: 'actions',
    accessor: (i) => (
      <div
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '20px' }}
        className="table-cell"
      >
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => handleEditBeneficiario(i)}
            className="table-link"
            title="Editar"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </div>
        <div style={{ textAlign: 'left' }}>
          <Button
            onClick={() => handleDeleteBeneficiario(i)}
            className="table-link"
            title="Eliminar"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </div>
      </div>
    ),
    filterable: false,
    sortable: false,
    minWidth: 60,
  }

  columns = isEditable ? [...columns, actions]: columns

  return (
    <div>
      <FormSection
        title='Asegurados y Beneficiarios'
      >
        {isEditable && (
          <>
            <div
              style={{
                display: 'grid',
                gridGap: '20px 40px',
                gridTemplateColumns: '3fr 6fr',
                marginTop: '15px',
                padding: '15px 0',
              }}
            >
              <FormGroup>
                <Label for='rutBeneficiario'>
                  Rut Asegurado/Beneficiario
                  <span className='required text-danger'>*</span>
                </Label>
                <Col style={{ padding: '0'}}>
                  <Input
                    id="rut"
                    className="orsan-form-element"
                    value={formatRut(rut)}
                    onChange={(e) => handleRutChange(e.target.value)}
                    placeholder="Ingrese rut"
                    invalid={rut.length >= RUT_MIN_SIZE && !validRut
                      || rut.length >= RUT_MIN_SIZE && validRut && !rutExist
                      || rut.length >= RUT_MIN_SIZE && validRut && rutExist
                      && !isBeneficiarioVentaVerde(beneficiario)}
                  />
                  {rut.length >= RUT_MIN_SIZE && !validRut && (
                    <FormFeedback>El RUT ingresado es inválido.</FormFeedback>
                  )}
                  {rut.length >= RUT_MIN_SIZE && validRut && !rutExist && (
                    <>
                      <Button
                        className='btn btn-orsan'
                        style={{ marginTop: '20px' }}
                        onClick={showCreateBeneficiarioModal}
                      >
                        Crear Cliente
                      </Button>
                      <FormFeedback>
                        El RUT ingresado no es Cliente Beneficiario.
                      </FormFeedback>
                    </>
                  )}
                  {rut.length >= RUT_MIN_SIZE && validRut && rutExist
                    && !isBeneficiarioVentaVerde(beneficiario) && (
                    <>
                      <Button
                        className='btn btn-orsan'
                        style={{ marginTop: '20px' }}
                        onClick={patchRolBeneficiarioVentaVerde}
                      >
                        Agregar Rol Beneficiario
                      </Button>
                      <FormFeedback>
                        El RUT ingresado no posee el rol de Beneficiario - Venta en Verde.
                      </FormFeedback>
                    </>
                  )}
                </Col>
              </FormGroup>
              <FormGroup>
                <Label for='nombreBeneficiario'>
                  Nombre
                  <span className='required text-danger'>*</span>
                </Label>
                <Col style={{ padding: '0'}}>
                  <Input
                    className='orsan-form-element'
                    name="rut"
                    id="rut"
                    value={beneficiario && beneficiario.nombre || ''}
                    disabled
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label for='participacion'>
                  Participación (%)
                  <span className='required text-danger'>*</span>
                </Label>
                <Row>
                  <Col>
                    <NumberFormat
                      className='form-control'
                      id='participacion'
                      decimalScale="2"
                      fixedDecimalScale
                      decimalSeparator=","
                      allowNegative={false}
                      value={participacion}
                      placeholder="Ingrese porcentaje de participación"
                      thousandSeparator="."
                      onValueChange={(value) => {setParticipacion(value.floatValue)}}
                      renderText={(value) => <p className="small-form-value">{value}</p>}
                    />
                    <Input
                      type="hidden"
                      invalid={participacion > 100}
                    />
                    {participacion > 100 && (
                      <FormFeedback>La participación no puede ser superior a 100</FormFeedback>
                    )}
                    <Input
                      type="hidden"
                      invalid={participacion <= 100 && participacion + totalParticipationTable() > 100}
                    />
                    {participacion <= 100 && participacion + totalParticipationTable() > 100 && (
                      <FormFeedback>La participación total no puede ser superior a 100</FormFeedback>
                    )}
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <div className="mb-3 d-flex justify-content-end">
              <Button
                style={{
                  background: 'transparent',
                  color: '#315e90',
                  border: '1px solid #315e90',
                }}
                onClick={() => cleanBeneficiario()}
                className="ml-sm-2 btn btn-outline-orsan-primary"
              >
                Limpiar
              </Button>
              <Button
                style={{
                  background: '#315e90',
                  color: '#FFFFFF',
                  border: '1px solid #315e90',
                }}
                onClick={() => beneficiario && beneficiario.id && handleAddTableData()}
                className="ml-sm-2 btn btn-orsan-secondary"
                disabled={
                  !(rut && participacion) ||
                  isParticipacionFulled() ||
                  participacion > 100 ||
                  participacion + totalParticipationTable() > 100
                }
              >
                Agregar
              </Button>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr auto auto',
                gridGap: '10px',
                marginTop: '15px',
                marginBottom: '6px',
              }}
            />
          </>
        )}
        <Input
          type="hidden"
          invalid={totalParticipationTable() > 0 && totalParticipationTable() < 100}
        />
        {totalParticipationTable() > 0 && totalParticipationTable() < 100 && (
          <FormFeedback>La participación total debe ser igual a 100</FormFeedback>
        )}
        <BaseTable
          columns={columns}
          data={tableDataBeneficiario}
          style={{ maxHeight: '63vh', minHeight: '20vh', marginBottom: '30px'}}
        />
      </FormSection>
      <BeneficiarioCreateModal
        isOpen={showCreateModal}
        toggle={() => setShowCreateModal(!showCreateModal)}
        handleOk={handleCreateBeneficiario}
        rut={formatRut(rut)}
        handleRutChange={handleRutChange}
        okBtnText="OK"
        organizationTypes={organizationTypes}
        regiones={regiones}
        allRoles={allRoles}
        rolesVentaEnVerde={rolesVentaEnVerde}
      />
      <CheckModal
        isOpen={beneficiarioCreated}
        toggle={() => setBeneficiarioCreated(false)}
        handleOk={() => setBeneficiarioCreated(false)}
        okBtnText="OK"
        confirmMsg="Beneficiario creado satisfactoriamente"
      />
      <CheckModal
        isOpen={beneficiarioUpdated}
        toggle={() => setBeneficiarioUpdated(false)}
        handleOk={() => setBeneficiarioUpdated(false)}
        okBtnText="OK"
        confirmMsg="Rol beneficiario agregado satisfactoriamente"
      />
    </div>
  )
}

Beneficiarios.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  beneficiario: PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string,
    roles: PropTypes.array,
    rut: PropTypes.string,
  }),
  setBeneficiario: PropTypes.func.isRequired,
  participacion: PropTypes.number,
  setParticipacion: PropTypes.func.isRequired,
  tableDataBeneficiario: PropTypes.arrayOf(
    PropTypes.shape({
      unitValue: PropTypes.object,
      unitDetail: PropTypes.string,
    })
  ).isRequired,
  setTableDataBeneficiario: PropTypes.func.isRequired,
  isParticipacionFulled: PropTypes.func.isRequired,
}

Beneficiarios.defaultProps = {
  beneficiario: null,
  participacion: null,
}

export default Beneficiarios;
