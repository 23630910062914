import React, { useEffect, useState } from 'react';
import { CardBody, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import OportunitiesFormAccordionSection from './OportunitiesFormAccordionSection';

const OportunitiesFormAccordion = ({ sections, isAllOpen, openSection, hasIcons }) => {
  const initialState = sections.map((obj) => obj.collapsed);
  const [accordion, setAccordion] = useState(initialState);

  useEffect(() => {
    const newAccordion = initialState;
    if (openSection !== null) {
      newAccordion[openSection] = false;
    }
    setAccordion(newAccordion);
  }, [openSection]);

  const handleToggleAccordion = (index) => () => {
    setAccordion(accordion.map((bool, idx) => (idx === index ? !bool : true)));
  };
  // TODO: Sacar isAllOpen si norsan no pide que lo usemos en la revision
  return (
    <>
      {sections.map((section, idx) =>
        section.isVisible ? (
          <OportunitiesFormAccordionSection
            key={section.name}
            title={section.name}
            toggle={handleToggleAccordion(idx)}
            collapsed={accordion[idx] || true}
            isValid={section.isValid}
            hasIcon={hasIcons}
            responsable={section.responsable}
            expansible={section.expansible}
          >
            <Collapse isOpen={isAllOpen || !accordion[idx]}>
              <CardBody>{section.component}</CardBody>
            </Collapse>
          </OportunitiesFormAccordionSection>
        ) : null,
      )}
    </>
  );
};

OportunitiesFormAccordion.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAllOpen: PropTypes.bool,
  openSection: PropTypes.number,
  hasIcons: PropTypes.bool,
};
OportunitiesFormAccordion.defaultProps = {
  isAllOpen: false,
  openSection: null,
  hasIcons: true,
};

export default OportunitiesFormAccordion;
