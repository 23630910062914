import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import FormSection from '../../../UI/FormSection';
import { objectsToOptions } from '../../../../helpers/selects';

const CoberturaSection = ({
  clientesInnominados,
  porcentajesCoberturaCredito,
  coberturaNominadosPctId,
  coberturaInnominadosPctId,
  dispatch,
  handleSelect,
  style,
  validate,
  disabled,
}) => {
  const porcentagesOptions = objectsToOptions(
    porcentajesCoberturaCredito,
    false,
    false,
    'cobertura_pct',
  );
  const coberturaNominadoOption = porcentagesOptions.find(
    porc => porc.value.id === coberturaNominadosPctId,
  );
  const coberturaInnominadoOption = porcentagesOptions.find(
    porc => porc.value.id === coberturaInnominadosPctId,
  );
  return (
    <FormSection title="Cobertura" style={style}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '80px auto',
          gridGap: '6px 8px',
          alignItems: 'center',
        }}
      >
        <div
          className={validate && !coberturaNominadoOption ? 'has-error' : ''}
        >
          <Select
            id="cobertura-nominados"
            name="endoso.coberturaNominadosPctId"
            className="custom-policy-select"
            options={porcentagesOptions}
            onChange={(selectedOption, action) =>
              dispatch(handleSelect(selectedOption.value.id, action))
            }
            value={coberturaNominadoOption}
            placeholder=""
            isDisabled={disabled}
          />
        </div>
        <div className="small-form-value">% clientes nominados.</div>
        <div
          className={
            validate && clientesInnominados && !coberturaInnominadoOption
              ? 'has-error'
              : ''
          }
        >
          <Select
            id="cobertura-innominados"
            name="endoso.coberturaInnominadosPctId"
            className="custom-policy-select"
            options={porcentagesOptions}
            onChange={(selectedOption, action) =>
              dispatch(handleSelect(selectedOption.value.id, action))
            }
            value={clientesInnominados ? coberturaInnominadoOption : null}
            placeholder=""
            isDisabled={!clientesInnominados || disabled}
          />
        </div>
        <div className="small-form-value">% clientes innominados.</div>
      </div>
    </FormSection>
  );
};

CoberturaSection.propTypes = {
  clientesInnominados: PropTypes.bool.isRequired,
  coberturaNominadosPctId: PropTypes.number,
  coberturaInnominadosPctId: PropTypes.number,
  porcentajesCoberturaCredito: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      cobertura_pct: PropTypes.number,
    }),
  ).isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CoberturaSection.defaultProps = {
  style: {},
  coberturaNominadosPctId: null,
  coberturaInnominadosPctId: null,
  disabled: false,
};

export default CoberturaSection;
