import moment from 'moment';
import {
  CP_CLEAR_FORM,
  CP_CREATE,
  CP_EDIT,
  GET_ACTIVIDAD_LIST,
  GET_ALIASES,
  CP_GET,
  GET_INTERMEDIARIO_LIST,
  GET_MONEDAS,
  GET_RAMOS_FECU,
  CP_HANDLE_FORM_ELEMENT_CHANGE,
  CP_HANDLE_GENERAL_CONDITIONED_SELECT_CHANGE,
  CP_HANDLE_RAMO_FECU_SELECT_CHANGE,
  CP_HANDLE_INICIO_VIGENCIA_CHANGE,
  CP_HANDLE_BALANCE_CALCULATED_FIELDS_CHANGE,
  CP_HANDLE_TASA_COSTO_SEGURO_ADD,
  CP_HANDLE_TASA_COSTO_SEGURO_DELETE,
  CP_GET_PLAZOS_CREDITO,
  CP_SELECT_PARTICULAR_CONDITION,
  CP_DESELECT_PARTICULAR_CONDITION,
  CP_GET_PARTICULAR_CONDITIONS,
  CP_SET_NO_PARTICULAR_CONDITION_SELECTED,
  CP_GET_PORCENTAJES_COBERTURA_CREDITO,
  CP_GET_TIPOS_VIGENCIA,
  CP_HANDLE_TIPO_VIGENCIA_CHANGE,
  CP_HANDLE_CLIENTE_ESPECIAL_ADD,
  CP_ADD_CLIENTE_ENDOSO,
  CP_TOGGLE_NO_PARAMETERIZED_CONDITION_SELECTED,
  CP_GET_PARAMETERIZED_CONDITIONS,
  CP_HANDLE_CANCEL_CLIENTE_ESPECIAL_EDITION,
  CP_HANDLE_CLIENTE_ESPECIAL_SAVE_EDITION,
  CP_EMIT,
  CP_GET_GASTOS_ADQUISICION,
  GET_ACTIVIDADES,
  CP_SAVE_POLICY_ATTACHMENTS,
  CP_CHANGE_PLAN_PAGO_DOCUMENT,
  CP_TOGGLE_PARAMETERIZED_CONDITION_CHECKBOX,
  CP_CHANGE_PARAMETERIZED_CONDITION_VALUE,
  CP_HANDLE_PARAMETERIZED_CONDITIONED_OPTION_CHANGE,
  CP_HANDLE_TERMINO_VIGENCIA_CHANGE,
  CP_EDIT_AND_CONTINUE,
  CP_SET_LAST_STEP,
  CP_TOGGLE_CLIENTE_ENDOSO_CHECK,
  CP_HANDLE_FILIAL_ACTIVIDAD_CHANGE,
  CP_REMOVE_SOBREPRIMA,
  CP_ADD_SOBREPRIMA,
  CP_EDIT_SOBREPRIMA,
  CP_SELECT_SOBREPRIMA,
  CP_ADD_ADJUNTO,
  CP_DELETE_ADJUNTO,
  CP_DELETE_ADJUNTO_NUEVO,
  CP_HANDLE_INICIO_VIGENCIA_ENDOSO_CHANGE,
  CP_DETAILS_CHANGE_VIGENCIA,
  CP_RESET_CURRENT_CLIENTE_ESPECIAL,
} from '../constants/action-types';
import { getData, patchData, postData } from './index';
import {
  API,
  BASE_URL,
  CREDITO_RAMO_FECU_FILTER,
  CP_FS_CLIENTES,
  CP_FS_DETALLES,
} from '../../CONST';
import { undefinedToZero } from '../../helpers/helpers';
import auth from '../../helpers/auth';

const getBalanceCalculatedFieldsParams = (endoso, moneda) => {
  return {
    estimacion_ventas_amt: undefinedToZero(endoso.estimacionVentasAmt),
    ventas_pct: undefinedToZero(endoso.ventasPct),
    tasa_costo_seguro_defecto_pct: undefinedToZero(endoso.tasaCostoSeguroDefectoPct),
    tipo_indemnizacion_max_id: undefinedToZero(endoso.tipoIndemnizacionMaxId),
    indemnizacion_max_amt: undefinedToZero(endoso.indemnizacionMaxAmt),
    prorroga_dias: endoso.prorrogaDias,
    tipo_vigencia_id: endoso.tipoVigenciaId,
    moneda
  };
};

const getBalanceCalculatedFieldsChange = (dispatch, paramsObj) => {
  const params = Object.keys(paramsObj).map(key => `${key}=${paramsObj[key]}`).join('&');
  dispatch(getData({
    url: `${BASE_URL}${API.endoso_calculated_fields}/?${params}`,
    type: CP_HANDLE_BALANCE_CALCULATED_FIELDS_CHANGE,
  }));
};

const getInicioVigenciaEndoso = (oldTerminoVigencia, newTerminoVigencia) => {
  let inicioVigenciaEndoso = oldTerminoVigencia.clone().add(1, 'day');
  if (newTerminoVigencia.isBefore(oldTerminoVigencia)) {
    inicioVigenciaEndoso = newTerminoVigencia.clone()
  }
  return inicioVigenciaEndoso
};

export const getParticularConditions = () => (dispatch) => {
  dispatch(getData({
    url: `${BASE_URL}${API.condiciones_particulares}`,
    type: CP_GET_PARTICULAR_CONDITIONS,
  }));
};

export const getParameterizedConditions = () => (dispatch) => {
  dispatch(getData({
    url: `${BASE_URL}${API.condiciones_parametrizadas}`,
    type: CP_GET_PARAMETERIZED_CONDITIONS,
  }));
};

export const getOptionsForSelects = (policyId, numeroVigencia) => (dispatch) => {
  dispatch(getData({
    url: `${BASE_URL}${API.ramos_fecu}?${CREDITO_RAMO_FECU_FILTER}`,
    type: GET_RAMOS_FECU,
  }));
  dispatch(getData({
    url: `${BASE_URL}${API.monedas}`,
    type: GET_MONEDAS,
  }));
  dispatch(getData({
    url: `${BASE_URL}${API.intermediario}/`,
    type: GET_INTERMEDIARIO_LIST,
  }));
  dispatch(getData({
    url: `${BASE_URL}${API.actividad}/`,
    type: GET_ACTIVIDAD_LIST,
  }));
  dispatch(getData({
    url: `${BASE_URL}${API.plazos_credito}/`,
    type: CP_GET_PLAZOS_CREDITO,
  }));
  dispatch(getData({
    url: `${BASE_URL}${API.porcentajes_cobertura_credito}/`,
    type: CP_GET_PORCENTAJES_COBERTURA_CREDITO,
  }));
  dispatch(getData({
    url: `${BASE_URL}${API.tipos_vigencia}/`,
    type: CP_GET_TIPOS_VIGENCIA,
  }));
  dispatch(getParticularConditions());
  dispatch(getParameterizedConditions());
  if (policyId) {
    let url = `${BASE_URL}${API.creditos}/${policyId}/`;
    if (numeroVigencia) {
      url += `?numero_vigencia=${numeroVigencia}`;
    }
    dispatch(getData({
      url,
      type: CP_GET,
    }));
  }
};

export const selectParticularCondition = (id) => ({
  type: CP_SELECT_PARTICULAR_CONDITION,
  payload: id,
});

export const deselectParticularCondition = (id) => ({
  type: CP_DESELECT_PARTICULAR_CONDITION,
  payload: id,
});

export const handleFormElementChange = (section, name, value) => ({
  type: CP_HANDLE_FORM_ELEMENT_CHANGE,
  payload: {section, name, value},
});

export const handleSelectChange = (section, selectedOption, action) =>
  handleFormElementChange(section, action.name, selectedOption);

export const handleTextAreaChange = (section, event) =>
  handleFormElementChange(section, event.target.name, event.target.value);

export const handleGeneralConditionedSelectChange = (selectedOption, action) => ({
  type: CP_HANDLE_GENERAL_CONDITIONED_SELECT_CHANGE,
  payload: {
    selectedOption,
    action,
  },
});

export const handleRamoFecuSelectChange = (selectedOption, action) => ({
  type: CP_HANDLE_RAMO_FECU_SELECT_CHANGE,
  payload: {
    selectedOption,
    action,
  },
});

export const handleFormSelectInputChange = (section, oldText, newText, elemName) => (dispatch) => {
  if (newText !== oldText) {
    dispatch(handleFormElementChange(section, elemName, newText));
  }
};

export const handleAseguradoSelectChange = (section, selectedOption, action) => (dispatch) => {
  dispatch(handleFormElementChange(section, action.name, selectedOption));
  dispatch(getData({
    url: `${BASE_URL}${API.alias}?organization_id=${selectedOption.value.id}`,
    type: GET_ALIASES,
  }));
  dispatch(getData({
    url: `${BASE_URL}${API.actividades}?organization_id=${selectedOption.value.id}`,
    type: GET_ACTIVIDADES,
  }));
};

export const createCreditPolicy = policy => dispatch => dispatch(postData({
  url: `${BASE_URL}${API.creditos}/`,
  type: CP_CREATE,
  config: policy,
}));

export const editCreditPolicy = (policy, saveAndContinue) => dispatch => {
  dispatch(patchData({
    url: `${BASE_URL}${API.creditos}/${policy.id}/`,
    type: saveAndContinue ? CP_EDIT_AND_CONTINUE : CP_EDIT,
    config: policy,
  }));
};

export const emitCreditPolicy = policy => dispatch => dispatch(patchData({
  url: `${BASE_URL}${API.creditos}/${policy.id}/`,
  type: CP_EMIT,
  config: policy,
}));

export const saveCreditPolicyAttachments = policy => dispatch => dispatch(patchData({
  url: `${BASE_URL}${API.creditos}/${policy.id}/`,
  type: CP_SAVE_POLICY_ATTACHMENTS,
  config: policy,
}));

export const clearCreditPolicyForm = () => ({
  type: CP_CLEAR_FORM,
});

export const handleInicioVigenciaChange = newDate => ({
  type: CP_HANDLE_INICIO_VIGENCIA_CHANGE,
  payload: newDate,
});

export const handleInicioVigenciaEndosoChange = newDate => ({
  type: CP_HANDLE_INICIO_VIGENCIA_ENDOSO_CHANGE,
  payload: newDate,
});

export const handleTerminoVigenciaChange = (oldTerminoVigencia, newTerminoVigencia, endoso, moneda) => (dispatch) => {
  if (moment.isMoment(newTerminoVigencia)) {
    const inicioVigenciaEndoso = newTerminoVigencia.isAfter(oldTerminoVigencia)
      ? getInicioVigenciaEndoso(oldTerminoVigencia, newTerminoVigencia)
      : undefined;
    const newProrrogaDias = endoso.prorrogaDiasInicial + newTerminoVigencia.diff(oldTerminoVigencia, 'days');

    dispatch({
      type: CP_HANDLE_TERMINO_VIGENCIA_CHANGE,
      payload: {
        terminoVigencia: newTerminoVigencia,
        inicioVigenciaEndoso,
        prorrogaDias: newProrrogaDias,
      }
    });

    const params = getBalanceCalculatedFieldsParams(endoso, moneda);
    params.prorroga_dias = newProrrogaDias;
    getBalanceCalculatedFieldsChange(dispatch, params);
  }
};

export const handleTipoVigenciaChange = tipoVigencia => ({
  type: CP_HANDLE_TIPO_VIGENCIA_CHANGE,
  payload: tipoVigencia,
});

export const handleBalanceCalculatedFieldsChange = (apiFieldName, fieldName, newValue, endoso, moneda) => (dispatch) => {
  dispatch(handleFormElementChange(CP_FS_DETALLES,`endoso.${fieldName}`, newValue));

  const params = getBalanceCalculatedFieldsParams(endoso, moneda);
  params[apiFieldName] = undefinedToZero(newValue);

  getBalanceCalculatedFieldsChange(dispatch, params);
};

export const handleTasaCostoSeguroAdd = () => ({
  type: CP_HANDLE_TASA_COSTO_SEGURO_ADD,
});

export const handleTasaCostoSeguroDelete = rowIndex => ({
  type: CP_HANDLE_TASA_COSTO_SEGURO_DELETE,
  payload: rowIndex,
});

export const setNoParticularConditionSelected = selected => ({
  type: CP_SET_NO_PARTICULAR_CONDITION_SELECTED,
  payload: selected,
});

export const handleCheckboxChange = (section, event) =>
  handleFormElementChange(section, event.target.name, event.target.checked);

export const handleClienteEspecialAdd = () => ({
  type: CP_HANDLE_CLIENTE_ESPECIAL_ADD,
});

export const handleClienteEspecialSaveEdition = () => ({
  type: CP_HANDLE_CLIENTE_ESPECIAL_SAVE_EDITION,
});

export const handleSelectedClientChange = (section, selectedOption) => handleFormElementChange(
  section, 'endoso.currentClienteEspecial.cliente', selectedOption.value);

export const handleCEPeriodoGestionesPpiasChange = value => (dispatch) => {
  dispatch(handleFormElementChange(
    CP_FS_CLIENTES,
    'endoso.currentClienteEspecial.plazoGestionesPropiasDiasId',
    value.id,
  ));
  dispatch(handleFormElementChange(
    CP_FS_CLIENTES,
    'endoso.currentClienteEspecial.plazoGestionesPropiasDiasValue',
    value.dias,
  ));
};

export const handleCEPlazoMaximoCreditoChange = value => (dispatch) => {
  dispatch(handleFormElementChange(
    CP_FS_CLIENTES,
    'endoso.currentClienteEspecial.plazoMaximoCreditoDiasId',
    value.id,
    ));
  dispatch(handleFormElementChange(
    CP_FS_CLIENTES,
    'endoso.currentClienteEspecial.plazoMaximoCreditoDiasValue',
    value.dias,
  ));
};

export const handleCECoberturaChange = value => (dispatch) => {
  if (value.cobertura_pct === 0) {
    dispatch({type: CP_RESET_CURRENT_CLIENTE_ESPECIAL});
    dispatch(handleFormElementChange(
      CP_FS_CLIENTES,
      'endoso.currentClienteEspecial.coberturaPctSobrescribir',
      true,
    ));
  }
  dispatch(handleFormElementChange(
    CP_FS_CLIENTES,
    'endoso.currentClienteEspecial.coberturaPctId',
    value.id,
  ));
  dispatch(handleFormElementChange(
    CP_FS_CLIENTES,
    'endoso.currentClienteEspecial.coberturaPctValue',
    value.cobertura_pct,
  ));
};

export const addClienteEndoso = cliente => dispatch => {
  auth.fetch(`${BASE_URL}${API.actividades}?organization_id=${cliente.id}`)
    .then(response =>
      dispatch({
        type: CP_ADD_CLIENTE_ENDOSO,
        payload: {
          cliente,
          actividades: response,
        }
      })
    );
};

export const toggleClienteEndosoCheck = (clienteId, fieldName) => ({
  type: CP_TOGGLE_CLIENTE_ENDOSO_CHECK,
  payload: {
    clienteId,
    fieldName
  },
});

export const toggleNoParameterizedConditionSelected = () => ({
  type: CP_TOGGLE_NO_PARAMETERIZED_CONDITION_SELECTED,
  payload: {},
});

export const handleCancelClienteEspecialEdition = () => ({
  type: CP_HANDLE_CANCEL_CLIENTE_ESPECIAL_EDITION,
});

export const getGastosAdquisicion = (ramoFecu, producto, tipoCobertura) => (dispatch) => {
  if (ramoFecu && producto && tipoCobertura) {
    const params = `ramo_id=${ramoFecu.value.id}&producto_id=${producto.value.id}&tipo_cobertura_id=${tipoCobertura.value.id}`;
    dispatch(
      getData({
        url: `${BASE_URL}${API.gasto_adquisicion}?${params}`,
        type: CP_GET_GASTOS_ADQUISICION,
      })
    );
  }
};

export const changePlanPagoDocument = (filename, uuid) => ({
  type: CP_CHANGE_PLAN_PAGO_DOCUMENT,
  payload: {
    filename,
    uuid
  },
});

export const toggleParameterizedConditionCheckbox = conditionId => ({
  type: CP_TOGGLE_PARAMETERIZED_CONDITION_CHECKBOX,
  payload: conditionId,
});

export const changeParameterizedConditionValue = (conditionId, optionId, name, value) => ({
  type: CP_CHANGE_PARAMETERIZED_CONDITION_VALUE,
  payload: {
    conditionId,
    optionId,
    name,
    value
  },
});

export const handleParameterizedConditionedOptionChange = (conditionId, optionId) => ({
  type: CP_HANDLE_PARAMETERIZED_CONDITIONED_OPTION_CHANGE,
  payload: {
    conditionId,
    optionId,
  },
});

export const setLastStep = (isLastStep) => ({
  type: CP_SET_LAST_STEP,
  payload: isLastStep,
});

export const handleFilialActividadChange = (selectedOption, clienteId) => ({
  type: CP_HANDLE_FILIAL_ACTIVIDAD_CHANGE,
  payload: {
    clienteId,
    selectedOption,
  },
});

export const removeSobreprima = (conditionId, optionId, index) => ({
  type: CP_REMOVE_SOBREPRIMA,
  payload: {
    conditionId,
    optionId,
    index,
  },
});

export const selectSobreprima = (conditionId, optionId, index) => ({
  type: CP_SELECT_SOBREPRIMA,
  payload: {
    conditionId,
    optionId,
    index,
  },
});

export const editSobreprima = (conditionId, optionId, index, limiteSuperior, sobreprima) => ({
  type: CP_EDIT_SOBREPRIMA,
  payload: {
    conditionId,
    optionId,
    index,
    limiteSuperior,
    sobreprima,
  },
});

export const addSobreprima = (conditionId, optionId, limiteSuperior, sobreprima) => ({
  type: CP_ADD_SOBREPRIMA,
  payload: {
    conditionId,
    optionId,
    limiteSuperior,
    sobreprima,
  },
});

export const addAdjunto = uuid => ({
  type: CP_ADD_ADJUNTO,
  payload: uuid,
});

export const deleteAdjunto = adjunto => ({
  type: CP_DELETE_ADJUNTO,
  payload: adjunto.id,
});

export const deleteAdjuntoNuevo = uuid => ({
  type: CP_DELETE_ADJUNTO_NUEVO,
  payload: uuid,
});

export const attachFiles = policy => dispatch => {
  const obj = {
    adjuntos_ids: policy.adjuntos ? policy.adjuntos.map(file => file.id) : [],
    adjuntos_uuids: policy.adjuntos_uuids,
    id: policy.id,
  };
  dispatch(saveCreditPolicyAttachments(obj));
};

export const detailsChangeVigencia = vigenciaOption => ({
  type: CP_DETAILS_CHANGE_VIGENCIA,
  payload: vigenciaOption.value.id,
});

export const getInitialBalanceCalculatedFields = (
  endoso,
  moneda,
) => dispatch => {
  const params = getBalanceCalculatedFieldsParams(endoso, moneda);
  getBalanceCalculatedFieldsChange(dispatch, params);
};
