import { BASE_URL, API, ESTADOS_OPORTUNIDAD, OPORTUNIDADES_STATE_SECTION_MAP } from 'CONST';
import { get, patch } from 'helpers/requests';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Button, Col, FormGroup, Label } from 'reactstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckModal from 'components/shared/CheckModal';
import WarningModal from 'components/UI/WarningModal';
import AvanzarOportunidadConfirmationModal from './AvanzarOportunidadConfirmationModal';

const AsignarSuscriptorSection = ({
  fullData,
  setFullData,
  setInitialSection,
  id,
  estadosOportunidad,
}) => {
  const [suscriptorOptions, setSuscriptorOptions] = useState([]);
  const [suscriptor, setSuscriptor] = useState(null);
  const [sectionData, setSectionData] = useState({
    suscriptor_id: null,
  });
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [editableSection, setEditableSection] = useState(false);
  const [loading, setLoading] = useState(false);

  const editableStates = [ESTADOS_OPORTUNIDAD.responsableAsignado];

  useEffect(() => {
    if (fullData && fullData.estado && editableStates.includes(fullData.estado.nemotecnico)) {
      get(`${BASE_URL}${API.suscriptores}`).then((response) => {
        setSuscriptorOptions(
          response.results.map((e) => ({
            value: e.id,
            label: `${e.rut} - ${e.user.first_name} ${e.user.last_name}`,
          })),
        );
      });
    }
  }, []);

  useEffect(() => {
    if (fullData && fullData.estado && editableStates.includes(fullData.estado.nemotecnico)) {
      setEditableSection(true);
    }
  }, [fullData]);

  useEffect(() => {
    if (fullData.suscriptor_id) {
      get(`${BASE_URL}${API.suscriptores}/${fullData.suscriptor_id}/`).then((response) => {
        setSuscriptor({
          value: response.id,
          label: `${response.rut} - ${response.user.first_name} ${response.user.last_name}`,
        });
      });
    }
  }, [fullData, suscriptorOptions]);

  const asignarSuscriptor = () => {
    setLoading(true);
    const body = {
      ...sectionData,
      estado_id: estadosOportunidad.find(
        (e) => e.nemotecnico === ESTADOS_OPORTUNIDAD.suscriptorAsignado,
      ).id,
    };
    patch(`${BASE_URL}${API.oportunidades_garantia}/${id}/`, body)
      .then((response) => {
        setFullData(response);
        setInitialSection(OPORTUNIDADES_STATE_SECTION_MAP[response.estado.nemotecnico]);
        setEditableSection(false);
        setShowUpdateModal(true);
      })
      .catch(() => {
        setShowErrorModal(true);
      })
      .finally(() => {
        setConfirmationModal(false);
        setLoading(false);
      });
  };

  return (
    <div>
      <FormGroup row>
        <Col sm={2}>
          <Label>
            Suscriptor
            <span className="required text-danger">*</span>
          </Label>
        </Col>
        <Col sm={5}>
          <Select
            className="custom-policy-select"
            options={suscriptorOptions}
            value={suscriptor}
            onChange={(event) => {
              setSectionData({ suscriptor_id: event.value });
              setSuscriptor(event);
            }}
            isDisabled={!editableSection}
            placeholder="Seleccione un suscriptor"
          />
        </Col>
      </FormGroup>
      {editableSection && (
        <div className="button-container d-flex justify-content-end">
          <Button
            className="btn btn-secondary orange"
            onClick={() => setConfirmationModal(true)}
            disabled={suscriptor === null || confirmationModal}
          >
            {loading ? <FontAwesomeIcon spin={loading} icon={faSpinner} /> : 'Asignar Suscriptor'}
          </Button>
        </div>
      )}
      <AvanzarOportunidadConfirmationModal
        openModal={confirmationModal}
        setOpenModal={setConfirmationModal}
        avanzarOportunidad={asignarSuscriptor}
        loading={loading}
      />
      <CheckModal
        isOpen={showUpdateModal}
        toggle={() => setShowUpdateModal(false)}
        handleOk={() => setShowUpdateModal(false)}
        okBtnText="OK"
        confirmMsg="Suscriptor asignado satisfactoriamente."
      />
      <WarningModal
        isOpen={showErrorModal}
        toggle={() => setShowErrorModal(false)}
        handleOk={() => setShowErrorModal(false)}
        okBtnText="Aceptar"
        confirmMsg="Ha ocurrido un error al asignar al suscriptor."
        loading={false}
        onlyCancelButton
      />
    </div>
  );
};

AsignarSuscriptorSection.propTypes = {
  id: PropTypes.string,
  fullData: PropTypes.shape({
    actualizacion_ts: PropTypes.string,
    afianzado: PropTypes.shape({
      id: PropTypes.number,
      nombre: PropTypes.string,
      rut: PropTypes.string,
    }),
    afianzado_id: PropTypes.number,
    creacion_ts: PropTypes.string,
    estado: PropTypes.shape({
      descripcion: PropTypes.string,
      id: PropTypes.number,
      nemotecnico: PropTypes.string,
    }),
    id: PropTypes.number,
    monto: PropTypes.number,
    responsable_id: PropTypes.number,
    suscriptor_id: PropTypes.number,
  }),
  setFullData: PropTypes.func.isRequired,
  setInitialSection: PropTypes.func.isRequired,
  estadosOportunidad: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      nemotecnico: PropTypes.string,
      descripcion: PropTypes.string,
    }),
  ),
};

AsignarSuscriptorSection.defaultProps = {
  fullData: null,
  id: null,
  estadosOportunidad: [],
};

export default AsignarSuscriptorSection;
