import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import FormSection from '../../../UI/FormSection';

const CostoDeEstudioSection = ({
  clientesInnominados,
  costoEstudioNominadoAprobadoAmt,
  costoEstudioNominadoRechazadoAmt,
  costoEstudioInnominadoAprobadoAmt,
  costoEstudioInnominadoRechazadoAmt,
  moneda,
  dispatch,
  handleChange,
  style,
  validate,
  disabled,
}) => {
  const invalidNominadoAprobado =
    validate && costoEstudioNominadoAprobadoAmt === null;
  const invalidNominadoRechazado =
    validate && costoEstudioNominadoRechazadoAmt === null;
  const invalidInnominadoAprobado =
    validate &&
    clientesInnominados &&
    costoEstudioInnominadoAprobadoAmt === null;
  const invalidInnominadoRechazado =
    validate &&
    clientesInnominados &&
    costoEstudioInnominadoRechazadoAmt === null;
  return (
    <FormSection title="Costo de estudio" style={style}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '110px auto',
            gridGap: '6px 8px',
            alignItems: 'center',
          }}
        >
          <div className="small-form-value" style={{ gridColumn: '1/3' }}>
            Clientes nominados
          </div>
          <div>
            <NumberFormat
              id="costo-estudio-nominado-aprobado"
              name="costoEstudioNominadoAprobadoAmt"
              className={`form-control${
                invalidNominadoAprobado ? ' is-invalid' : ''
              }`}
              onValueChange={values => {
                const newValue = values.floatValue;
                dispatch(
                  handleChange(
                    'endoso.costoEstudioNominadoAprobadoAmt',
                    newValue,
                  ),
                );
              }}
              allowNegative={false}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              value={costoEstudioNominadoAprobadoAmt}
              suffix={` ${moneda}`}
              style={{ width: '110px' }}
              disabled={disabled}
            />
          </div>
          <div className="small-form-value">Aprobado</div>
          <div>
            <NumberFormat
              id="costo-estudio-nominado-rechazado"
              name="costoEstudioNominadoRechazadoAmt"
              className={`form-control${
                invalidNominadoRechazado ? ' is-invalid' : ''
              }`}
              onValueChange={values => {
                const newValue = values.floatValue;
                dispatch(
                  handleChange(
                    'endoso.costoEstudioNominadoRechazadoAmt',
                    newValue,
                  ),
                );
              }}
              allowNegative={false}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              value={costoEstudioNominadoRechazadoAmt}
              suffix={` ${moneda}`}
              style={{ width: '110px' }}
              disabled={disabled}
            />
          </div>
          <div className="small-form-value">Rechazado</div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '110px auto',
            gridGap: '6px 8px',
            alignItems: 'center',
          }}
        >
          <div className="small-form-value" style={{ gridColumn: '1/3' }}>
            Clientes innominados
          </div>
          <div>
            <NumberFormat
              id="costo-estudio-innominado-aprobado"
              name="costoEstudioInnominadoAprobadoAmt"
              className={`form-control${
                invalidInnominadoAprobado ? ' is-invalid' : ''
              }`}
              onValueChange={values => {
                const newValue = values.floatValue;
                dispatch(
                  handleChange(
                    'endoso.costoEstudioInnominadoAprobadoAmt',
                    newValue,
                  ),
                );
              }}
              allowNegative={false}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              value={costoEstudioInnominadoAprobadoAmt}
              suffix={` ${moneda}`}
              style={{ width: '110px' }}
              disabled={!clientesInnominados || disabled}
            />
          </div>
          <div className="small-form-value">Aprobado</div>
          <div>
            <NumberFormat
              id="costo-estudio-innominado-rechazado"
              name="costoEstudioInnominadoRechazadoAmt"
              className={`form-control${
                invalidInnominadoRechazado ? ' is-invalid' : ''
              }`}
              onValueChange={values => {
                const newValue = values.floatValue;
                dispatch(
                  handleChange(
                    'endoso.costoEstudioInnominadoRechazadoAmt',
                    newValue,
                  ),
                );
              }}
              allowNegative={false}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              value={costoEstudioInnominadoRechazadoAmt}
              suffix={` ${moneda}`}
              style={{ width: '110px' }}
              disabled={!clientesInnominados || disabled}
            />
          </div>
          <div className="small-form-value">Rechazado</div>
        </div>
      </div>
    </FormSection>
  );
};

CostoDeEstudioSection.propTypes = {
  clientesInnominados: PropTypes.bool.isRequired,
  costoEstudioNominadoAprobadoAmt: PropTypes.number,
  costoEstudioNominadoRechazadoAmt: PropTypes.number,
  costoEstudioInnominadoAprobadoAmt: PropTypes.number,
  costoEstudioInnominadoRechazadoAmt: PropTypes.number,
  moneda: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CostoDeEstudioSection.defaultProps = {
  costoEstudioNominadoAprobadoAmt: null,
  costoEstudioNominadoRechazadoAmt: null,
  costoEstudioInnominadoAprobadoAmt: null,
  costoEstudioInnominadoRechazadoAmt: null,
  style: {},
  disabled: false,
};

export default CostoDeEstudioSection;
