import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API, BASE_URL } from '../../CONST';
import { getData } from '../../redux-store/actions';
import { CP_GET_POLICY } from '../../redux-store/constants/action-types';
import CreditPolicyDetailBasicInfo from './CreditPolicyDetailBasicInfo';
import PageContainer from '../PageContainer';
import CreditPolicyDetailValidities from './CreditPolicyDetailValidities';

const CreditPolicyDetail = ({
  dispatch,
  policy,
  match: {
    params: { id },
  },
}) => {
  useEffect(() => {
    dispatch(
      getData({
        url: `${BASE_URL}${API.creditos}/${id}/resumen/`,
        type: CP_GET_POLICY,
      }),
    );
  }, [id]);

  if (policy === null) {
    return null;
  }

  return (
    <PageContainer
      breadcrumbs={[
        { name: 'CRÉDITO', url: '/credit/policies/' },
        { name: 'PÓLIZAS', url: '/credit/policies/' },
        { name: 'VER PÓLIZA' },
      ]}
      style={{ padding: '0 20px' }}
    >
      <CreditPolicyDetailValidities />
      <CreditPolicyDetailBasicInfo />
    </PageContainer>
  );
};

CreditPolicyDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  policy: PropTypes.object,
};

CreditPolicyDetail.defaultProps = {
  policy: null,
};

const mapStateToProps = state => ({
  policy: state.creditPolicy.policy,
});

export default connect(mapStateToProps)(CreditPolicyDetail);
