import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormText, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import auth from '../../helpers/auth';
import './FileTable.css';
import { fileDownload } from '../../helpers/helpers';
import SafeBtn from './SafeBtn';
import isUserHasProfile from '../../helpers/profileValidator';
import { ALL } from '../../helpers/profilePermission';

class FileTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileError: false,
      newFiles: [],
      pendingDeletion: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { loading } = this.props;
    if (prevProps.loading && !loading) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        newFiles: [],
        pendingDeletion: false,
        uploading: false,
      });
    }
  }

  getNewFilesUuids = () => {
    const { newFiles } = this.state;
    return newFiles.map((file) => file.uuid);
  };

  handleFile = (e) => {
    const { uploadUrl } = this.props;
    const { files } = e.target;
    if (files.length > 0) {
      if (files[0].size <= 1e8) {
        this.setState({
          fileError: false,
        });
        const reader = new FileReader();
        const { name } = e.target.files[0];
        reader.readAsArrayBuffer(files[0]);
        reader.onload = (event) => {
          const body = event.target.result;
          this.uploadFile({
            url: uploadUrl,
            config: { body, name },
          });
        };
      } else {
        this.setState({
          fileError: true,
        });
      }
    }
  };

  uploadFile = (object) => {
    const { handleAdd } = this.props;
    this.setState({
      uploading: true,
    });
    auth
      .fetch(object.url, {
        method: 'PUT',
        body: object.config.body,
        headers: {
          'Content-Disposition': `attachment; filename="${encodeURI(object.config.name)}"`,
        },
      })
      .then((response) => {
        this.setState((prevState) => ({
          newFiles: [
            ...prevState.newFiles,
            {
              original_name: object.config.name,
              uuid: response.uuid,
            },
          ],
          uploading: false,
        }));
        handleAdd(response.uuid);
      });
  };

  handleDelete = (file, isNewFile) => {
    const { handleDelete, handleDeleteNew } = this.props;
    if (isNewFile) {
      this.setState((prevState) => ({
        newFiles: prevState.newFiles.filter((newFile) => newFile.uuid !== file.uuid),
      }));
      handleDeleteNew(file.uuid);
    } else {
      this.setState({
        pendingDeletion: true,
      });
      handleDelete(file);
    }
  };

  fileRow = (file, isNew, readOnly) => {
    const { loginIdToken, downloadUrl, validProfiles } = this.props;
    return (
      <tr key={file.id || file.uuid} className={isNew ? 'new' : ''}>
        <td className="filename">{file.original_name}</td>
        <td>
          {!isNew && (
            <Button
              className="reactstrap-table-link"
              color="link"
              onClick={() => fileDownload(`${downloadUrl}${file.id}`, loginIdToken)}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          )}
        </td>
        {!readOnly && isUserHasProfile(validProfiles) && (
          <td>
            <Button close onClick={() => this.handleDelete(file, isNew)} />
          </td>
        )}
      </tr>
    );
  };

  render() {
    const { fileList, readOnly, showSave, handleSave, loading, validProfiles } = this.props;
    const { newFiles, fileError, pendingDeletion, uploading } = this.state;
    return (
      <React.Fragment>
        <div className="file-table-container">
          <table className={`file-table ${readOnly ? 'read-only' : ''}`}>
            <tbody>
              {fileList && fileList.map((file) => this.fileRow(file, false, readOnly))}
              {newFiles.map((file) => this.fileRow(file, true, readOnly))}
            </tbody>
          </table>
        </div>
        <div style={{ display: 'flex' }}>
          {!readOnly && isUserHasProfile(validProfiles) && (
            <div className="file-upload" style={{ width: '100%' }}>
              {/* eslint-disable */}
              <label>
                {uploading ? (
                  <span>
                    <FontAwesomeIcon icon={faSpinner} pulse />
                    &nbsp;&nbsp;Subiendo...
                  </span>
                ) : (
                  'Seleccionar archivo'
                )}
                <Input
                  type="file"
                  name="file"
                  id="file"
                  invalid
                  style={{ marginTop: '15px' }}
                  onChange={this.handleFile}
                  disabled={loading || uploading}
                />
              </label>
              {/* eslint-enable */}
              <div className={!fileError ? 'd-none' : 'invalid-feedback'}>
                El archivo supera el tamaño máximo permitido
              </div>
              <FormText>Tamaño máximo de archivo 100 MB</FormText>
            </div>
          )}
          {showSave && (
            <div className="file-save" style={{ width: '100%' }}>
              <SafeBtn
                color="orsan-secondary"
                style={{ fontSize: '14px' }}
                onClick={handleSave}
                loading={loading}
                disabled={loading || (!pendingDeletion && newFiles.length === 0)}
              >
                Guardar
              </SafeBtn>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

FileTable.propTypes = {
  readOnly: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleAdd: PropTypes.func,
  handleDeleteNew: PropTypes.func,
  fileList: PropTypes.arrayOf(PropTypes.object),
  loginIdToken: PropTypes.string.isRequired,
  uploadUrl: PropTypes.string,
  downloadUrl: PropTypes.string,
  showSave: PropTypes.bool,
  handleSave: PropTypes.func,
  loading: PropTypes.bool,
  validProfiles: PropTypes.arrayOf(PropTypes.string),
};

FileTable.defaultProps = {
  readOnly: false,
  handleDelete: () => {},
  handleAdd: () => {},
  handleDeleteNew: () => {},
  fileList: [],
  uploadUrl: '',
  downloadUrl: '',
  showSave: false,
  handleSave: () => {},
  loading: false,
  validProfiles: ALL,
};

export default FileTable;
