import React from 'react';
import PropTypes from 'prop-types'
import { Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { headerOperation, cellOperation } from 'helpers/tables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import BaseTable from 'components/UI/BaseTable';
import Select from 'react-select';
import FormSection from '../UI/FormSection';

const InsureUnits = ({
  unitOptions,
  selectedUnit,
  detail,
  tableData,
	isEditable,
  handleEditUnit,
  handleDeleteUnit,
  handleOnChangeSelect,
  handleOnChangeDetail,
  handleCleanUnits,
  handleAddTableData,
}) => {

  let columns = [
    {
      Header: headerOperation('Tipo de Unidad'),
      id: 'unitValue',
      accessor: 'unitValue.descripcion',
      Cell: (row) => cellOperation(row.value),
      filterable: false,
      sortable: false,
      minWidth: 120,
    },
    {
      Header: headerOperation('Detalle Unidad'),
      id: 'unitDetail',
      accessor: 'unitDetail',
      Cell: (row) => cellOperation(row.value),
      filterable: false,
      sortable: false,
      minWidth: 120,
    },
  ]

  const actions = {
    Header: headerOperation('Acciones'),
    id: 'actions',
    accessor: (unitObj) => (
      <div
        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '20px' }}
        className="table-cell"
      >
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={() => handleEditUnit(unitObj)}
            className="table-link"
            title="Editar"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </div>
        <div style={{ textAlign: 'left' }}>
          <Button
            onClick={() => handleDeleteUnit(unitObj)}
            className="table-link"
            title="Eliminar"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </div>
      </div>
    ),
    filterable: false,
    sortable: false,
    minWidth: 60,
  }

  columns = isEditable ? [...columns, actions] : columns;

  return (
    <div>
      <FormSection
        title='Unidades a Asegurar'
      >
        {isEditable && (
          <div>
            <div
              style={{
                display: 'grid',
                gridGap: '20px 40px',
                gridTemplateColumns: '3fr 6fr',
                marginTop: '15px',
                padding: '15px 0',
              }}
            >
              <FormGroup>
                <Label for='tipoUnidad'>
                  Tipo de Unidad
                  <span className='required text-danger'>*</span>
                </Label>
                <Select
                  id='tipoUnidad'
                  name='tipoUnidad'
                  className='custom-policy-select'
                  options={unitOptions}
                  onChange={(event) => handleOnChangeSelect(event)}
                  value={selectedUnit}
                  placeholder='Seleccione tipo de unidad a asegurar'
                />
              </FormGroup>
              <FormGroup>
                <Label for='detail'>
                  Detalle
                  <span className='required text-danger'>*</span>
                </Label>
                <Col style={{ padding: 0 }}>
                  <Input
                    rows={3}
                    type='textarea'
                    name='observaciones'
                    id='observaciones'
                    value={detail}
                    onChange={(event) => handleOnChangeDetail(event)}
                    placeholder='Agregue detalle de unidad a asegurar'
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="mb-3 d-flex justify-content-end">
              <Button
                style={{
                  background: 'transparent',
                  color: '#315e90',
                  border: '1px solid #315e90',
                }}
                onClick={() => handleCleanUnits()}
                className="ml-sm-2 btn btn-outline-orsan-primary"
              >
                Limpiar
              </Button>
              <Button
                style={{
                  background: '#315e90',
                  color: '#FFFFFF',
                  border: '1px solid #315e90',
                }}
                onClick={() => handleAddTableData()}
                className="ml-sm-2 btn btn-orsan-secondary"
                disabled={!(selectedUnit && detail)}
              >
                Agregar
              </Button>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr auto auto',
                gridGap: '10px',
                marginTop: '15px',
                marginBottom: '6px',
              }}
            />
          </div>
        )}
        <BaseTable columns={columns} data={tableData} style={{ maxHeight: '63vh', minHeight: '20vh', marginBottom: '30px'}} />
      </FormSection>
    </div>
  )
}

InsureUnits.propTypes = {
  unitOptions: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired,
  selectedUnit: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.object,
  }),
  detail: PropTypes.string.isRequired,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      unitValue: PropTypes.object,
      unitDetail: PropTypes.string,
    })
  ).isRequired,
	isEditable: PropTypes.bool.isRequired,
  handleEditUnit: PropTypes.func.isRequired,
  handleDeleteUnit: PropTypes.func.isRequired,
  handleOnChangeSelect: PropTypes.func.isRequired,
  handleOnChangeDetail: PropTypes.func.isRequired,
  handleCleanUnits: PropTypes.func.isRequired,
  handleAddTableData: PropTypes.func.isRequired,
}

InsureUnits.defaultProps = {
  selectedUnit: null
}

export default InsureUnits;
