import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { get } from 'helpers/requests';
import Datetime from 'react-datetime';
import '../../styles/react-datetime.css';
import moment from 'moment';
import NumberFormat from "react-number-format";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row, Label, Input } from 'reactstrap';
import { API, BACKEND_DF, BASE_URL, FRONT_DF_1 } from 'CONST';
import { objectToOption } from '../../helpers/selects';

import FileTable from "../shared/FileTable";

const uploadUrl = `${BASE_URL}${API.upload}/`;
const downloadUrl = `${BASE_URL}${API.download}/`;

const ProjectDescripcionSection = ({
  edit,
  dataEdition,
  setDescription,
  setHasProjectDescription,
  setHasTotalDescription,
  cupoRestanteProyectos,
  loginIdToken,
  activo,
  isDisabled,
}) => {
  // Opciones
  const [comunas, setComunas] = useState(null);
  const [regiones, setRegiones] = useState(null);

  // Campos del modelo
  const [nombre, setNombre] = useState("");
  const [region, setRegion] = useState(null);
  const [comuna, setComuna] = useState(null);
  const [direccion, setDireccion] = useState("");

  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaTermino, setFechaTermino] = useState(null);
  const [fechaRecepcion, setFechaRecepcion] = useState(null);

  const [montoMaximo, setMontoMaximo] = useState(null);
  const [montoAcumulado, setMontoAcumulado] = useState(0);
  const montoRestante = useMemo(() => montoMaximo - montoAcumulado, [montoMaximo, montoAcumulado]);

  const [adjuntos, setAdjuntos] = useState([]);
  const [uuidAdjuntos, setUuidAdjuntos] = useState([]);

  const [observaciones, setObservaciones] = useState("");

  const isValidTotalDescription = () => nombre && region && comuna && direccion && fechaInicio && fechaTermino && montoMaximo && true;
  const isValidDescription = () => !!nombre;

  const setData = (data) => {
    setNombre(data.nombre);
    setMontoMaximo(data.monto_maximo);
    setMontoAcumulado(data.monto_utilizado);
    setComuna(data.comuna && objectToOption(data.comuna, 'name'));
    setRegion(data.region && objectToOption(data.region, 'name'));
    setFechaInicio(moment(data.inicio_vigencia, BACKEND_DF));
    setFechaRecepcion(data.fecha_recepcion_municipalidad && moment(data.fecha_recepcion_municipalidad, BACKEND_DF));
    setFechaTermino(moment(data.termino_vigencia, BACKEND_DF));
    setDireccion(data.direccion);
    setObservaciones(data.observaciones);
    setAdjuntos(data.adjuntos || []);
  }

  useEffect(() => {
    get(`${BASE_URL}${API.regiones}`).then((response) => {
      setRegiones(response !== undefined && response.map(node => ({
        label: node.name,
        value: node,
      })));
    });

    get(`${BASE_URL}${API.comunas}`).then((response) => {
      setComunas(response && response.map(node => ({
        label: node.name,
        value: node,
      })));
    });
  }, []);

  useEffect(() => {
    if (edit && Object.keys(dataEdition).length !== 0){
      setData(dataEdition);
    }
  }, [edit, dataEdition]);

  useEffect(() => {
    setComunas(region && region.value.comunas.map(item => ({
      label: item.name,
      value: item,
    })))
  }, [region]);

  useEffect(() => {
    setHasProjectDescription(isValidDescription());
    setHasTotalDescription(isValidTotalDescription());
    setDescription({
      nombre,
      direccion,
      observaciones,
      adjuntos,
      region_id: region && region.value && region.value.id,
      comuna_id: comuna && comuna.value && comuna.value.id,
      inicio_vigencia: fechaInicio && moment(fechaInicio).format(BACKEND_DF),
      termino_vigencia: fechaTermino && moment(fechaTermino).format(BACKEND_DF),
      monto_maximo: montoMaximo,
      monto_restante: montoRestante,
      monto_utilizado: montoAcumulado,
      fecha_recepcion_municipalidad: fechaRecepcion && moment(fechaRecepcion).format(BACKEND_DF),
      adjuntos_uuids: uuidAdjuntos,
    });
  }, [
    nombre,
    region,
    comuna,
    direccion,
    fechaInicio,
    fechaTermino,
    fechaRecepcion,
    montoMaximo,
    observaciones,
    uuidAdjuntos,
  ]);

  const isValidDate = (date) => moment(date, FRONT_DF_1).isAfter(moment(fechaInicio, FRONT_DF_1), 'date');

  const isValidDateRetroactive = (date) => moment(date, FRONT_DF_1).isAfter(moment().subtract(1, 'years'), 'date');

  const addAdjunto = (uuid) => setUuidAdjuntos([...uuidAdjuntos, uuid]);

  const deleteAdjunto = (file) => setAdjuntos(adjuntos.filter((adjunto) => adjunto !== file));

  const deleteNewAdjunto = (uuid) => setUuidAdjuntos(uuidAdjuntos.filter((adjunto) => adjunto !== uuid));

  const isAllowedMontoMaximo = (floatValue) => (
    (floatValue <= cupoRestanteProyectos && floatValue >= montoAcumulado) || floatValue === undefined
  )

  return (
    <div>
      <Row>
        <Col>
          <Label for="nombreProyecto">
            Nombre del proyecto
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            {!activo ? (
              <Input
                name="nombre"
                id="nombre"
                value={nombre}
                onChange={(event) => setNombre(event.target.value)}
                placeholder="Ingrese nombre del proyecto"
                disabled={!isDisabled}
              />
            ) : (
              <p className="form-values">{nombre}</p>
            )}
          </Col>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Label for="region">
            Región
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            {!activo ? (
              <Select
                id="region"
                name="region"
                className="custom-policy-select"
                options={regiones || undefined}
                onChange={(event) => setRegion(event)}
                value={region}
                placeholder="Seleccione una región"
                isDisabled={!isDisabled}
              />
            ) : (
              <p className="form-values">{region && region.label}</p>
            )}
          </Col>
        </Col>
        <Col sm={4}>
          <Label for="comuna">
            Comuna
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            {!activo ? (
              <Select
                id="comuna"
                name="comuna"
                className="custom-policy-select"
                options={comunas || undefined}
                onChange={(event) => setComuna(event)}
                value={comuna}
                placeholder="Seleccione una Comuna"
                isDisabled={!isDisabled || region === null}
              />
            ) : (
              <p className="form-values">{comuna && comuna.label}</p>
            )}
          </Col>
        </Col>
        <Col sm={4}>
          <Label for="direccion">
            Dirección
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            {!activo ? (
              <Input
                name="dirección"
                id="dirección"
                value={direccion}
                onChange={(event) => setDireccion(event.target.value)}
                placeholder="Ingrese dirección del proyecto"
                disabled={!isDisabled}
              />
            ) : (
              <p className="form-values">{direccion}</p>
            )}
          </Col>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Label for="fechaInicio">
            Fecha Inicio
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            {!activo ? (
              <Datetime
                id="fechaInicio"
                renderInput={(props) => <input {...props} className="rs-input" />}
                onChange={(event) => setFechaInicio(event)}
                value={fechaInicio}
                locale="es"
                dateFormat={FRONT_DF_1}
                timeFormat={false}
                isValidDate={isValidDateRetroactive}
                closeOnSelect
                inputProps={{disabled: !isDisabled}}
              />
            ) : (
              <p className="form-values">{fechaInicio && fechaInicio.format(FRONT_DF_1)}</p>
            )}
          </Col>
        </Col>
        <Col sm={4}>
          <Label for="fechaTermino">
            Fecha Término
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            {!activo ? (
              <Datetime
                id="fechaTermino"
                renderInput={(props) => <input {...props} className="rs-input" />}
                onChange={(event) => setFechaTermino(event)}
                value={fechaTermino}
                locale="es"
                dateFormat={FRONT_DF_1}
                timeFormat={false}
                isValidDate={isValidDate}
                closeOnSelect
                inputProps={{disabled: !isDisabled}}
              />
            ) : (
              <p className="form-values">{fechaTermino && fechaTermino.format(FRONT_DF_1)}</p>
            )}
          </Col>
        </Col>
        <Col sm={4}>
          <Label for="fechaRecepcionMunicipal">
            Fecha Estimada Recepción Municipal
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            {!activo ? (
              <Datetime
                id="fechaRecepcion"
                renderInput={(props) => <input {...props} className="rs-input" />}
                onChange={(event) => setFechaRecepcion(event)}
                value={fechaRecepcion}
                locale="es"
                dateFormat={FRONT_DF_1}
                timeFormat={false}
                isValidDate={isValidDateRetroactive}
                closeOnSelect
                inputProps={{disabled: !isDisabled}}
              />
            ) : (
              <p className="form-values">{fechaRecepcion && fechaRecepcion.format(FRONT_DF_1) || '-'}</p>
            )}
          </Col>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Label for="montoMaximo">
            Monto Máximo (UF)
            <span className="required text-danger">*</span>
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            <NumberFormat
              className="form-control"
              decimalScale="2"
              decimalSeparator=","
              value={montoMaximo}
              onValueChange={({floatValue}) => setMontoMaximo(floatValue)}
              placeholder="Ingrese monto máximo del proyecto"
              thousandSeparator="."
              allowNegative={false}
              displayType={activo ? 'text' : ''}
              renderText={(value) => <p className="small-form-value">{value || 0}</p>}
              isAllowed={({ floatValue }) => isAllowedMontoMaximo(floatValue)}
              disabled={!isDisabled}
            />
          </Col>
        </Col>
        <Col sm={4}>
          <Label for="montoRestante">
            Monto Restante
          </Label>
          <NumberFormat
            decimalScale="2"
            fixedDecimalScale
            decimalSeparator=","
            displayType="text"
            value={montoRestante}
            placeholder="Ingrese monto máximo del proyecto"
            thousandSeparator="."
            renderText={(value) => <p className="small-form-value">{value}</p>}
            disabled={!isDisabled}
          />
        </Col>
        <Col sm={4}>
          <Label for="documentos">
            Documentos
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            <FileTable
              fileList={adjuntos}
              newFiles={adjuntos.ids}
              handleAdd={addAdjunto}
              handleDelete={deleteAdjunto}
              handleDeleteNew={deleteNewAdjunto}
              uploadUrl={uploadUrl}
              downloadUrl={downloadUrl}
              loginIdToken={loginIdToken}
              readOnly={activo}
              disabled={!isDisabled}
            />
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label for="observacionnes">
            Observaciones
          </Label>
          <Col style={{ padding: 0, marginBottom: '15px' }}>
            {!activo ? (
              <Input
                type="textarea"
                name="observaciones"
                id="observaciones"
                value={observaciones}
                onChange={(event) => setObservaciones(event.target.value)}
                disabled={!isDisabled}
              />
            ) : (
              <p className="form-values">{observaciones || '-'}</p>
            )}
          </Col>
        </Col>
      </Row>
    </div>
  );
}

ProjectDescripcionSection.propTypes = {
  setHasProjectDescription: PropTypes.func.isRequired,
  setHasTotalDescription: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  loginIdToken: PropTypes.string.isRequired,
  cupoRestanteProyectos: PropTypes.number,
  edit: PropTypes.bool.isRequired,
  activo: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  dataEdition: PropTypes.shape({
    organizacion: PropTypes.shape({
      rut: PropTypes.string,
      nombre: PropTypes.string,
    }),
  }),
};

ProjectDescripcionSection.defaultProps = {
  cupoRestanteProyectos: null,
  dataEdition: PropTypes.shape({
    organizacion: null,
  })
};

const mapStateToProps = state => ({
  loginIdToken: state.login.id_token,
});

export default connect(
  mapStateToProps
)(ProjectDescripcionSection);
