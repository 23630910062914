import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import FormSection from '../../../UI/FormSection';

const MontoMaximoClientesInnominadosSection = ({
  clientesInnominados,
  maximoInnominadosAmt,
  umbral,
  moneda,
  dispatch,
  handleChange,
  style,
  validate,
  disabled,
}) => {
  const invalid =
    validate &&
    clientesInnominados &&
    (maximoInnominadosAmt === null ||
      (umbral !== null && maximoInnominadosAmt <= umbral));
  return (
    <FormSection title="Monto máximo clientes innominados" style={style}>
      <div className="inline-text-with-input">
        <NumberFormat
          id="maximo-innominados"
          name="maximoInnominadosAmt"
          className={`form-control${invalid ? ' is-invalid' : ''}`}
          onValueChange={values => {
            const newValue = values.floatValue;
            dispatch(handleChange('endoso.maximoInnominadosAmt', newValue));
          }}
          allowNegative={false}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale="2"
          fixedDecimalScale
          value={maximoInnominadosAmt}
          disabled={!clientesInnominados || disabled}
          suffix={` ${moneda}`}
          style={{ width: '110px' }}
        />
        <span className="small-form-value">&nbsp;&nbsp; monto máximo</span>
        {invalid && (
          <p className="error-msg-small" style={{ marginTop: '5px' }}>
            {`  Debe ser mayor que el umbral (${umbral} ${moneda})`}
          </p>
        )}
      </div>
    </FormSection>
  );
};

MontoMaximoClientesInnominadosSection.propTypes = {
  clientesInnominados: PropTypes.bool.isRequired,
  maximoInnominadosAmt: PropTypes.number,
  umbral: PropTypes.number,
  moneda: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

MontoMaximoClientesInnominadosSection.defaultProps = {
  maximoInnominadosAmt: null,
  umbral: null,
  style: {},
  disabled: false,
};

export default MontoMaximoClientesInnominadosSection;
