import React from "react";
import Numeral from "numeral";
import "numeral/locales/es";
import NumberFormat from 'react-number-format';
import { fdate } from './helpers';
import { FRONT_DF_1 } from '../CONST';


export const formatValue = (value, mo, roundingFn = Math.round) => {
  Numeral.locale('es');
  return mo ? Numeral(value).format('0,0.00', roundingFn) : value.toLocaleString('es-cl');
};

export const headerOperation = (instance, align = 'center') => (
  <div className={`text-${align} table-header`}>
    {instance}
  </div>
);

export const headerDoubleRowOperation = (instance1, instance2) => (
  headerOperation((
    <>
      <div>{instance1}</div>
      <div>{instance2}</div>
    </>
  ))
);

export const cellOperation = (instance, align = 'center', wrap = true) => (
  <div className={`text-${align} table-cell ${wrap ? 'wrap' : ''}`}>
    {(instance || instance === 0) ? instance : '-'}
  </div>
);

export const cellNumOperation = (instance, align, decimals, suffix) => {
  return (
    <div className={`text-${align || 'right'} table-cell`}>
      <NumberFormat
        decimalScale={decimals || '2'}
        fixedDecimalScale
        decimalSeparator=","
        displayType="text"
        value={instance}
        thousandSeparator="."
        suffix={suffix || ''}
      />
    </div>
  );
};

export const cellDobleRowOperation = (row1, row2, align = 'left') => cellOperation((
  <>
    <div>{row1}</div>
    <div>{row2}</div>
  </>
), align);

export const cellDateOperation = (instance, format = FRONT_DF_1) => cellOperation(
  fdate(instance, format)
);

export const cellOperationManyDesc = (objs, wrap = true) => (
  <div className={`text-left table-cell ${wrap ? 'wrap' : ''}`}>
    {objs.map(obj => <div key={obj.id}>{`- ${obj.descripcion}`}</div>)}
  </div>
)

export const enabledCellOperation = (enabled, value, align = 'right') => (
  <div className={`text-${align} table-cell`}>
    {enabled ? value : '-'}
  </div>
);
