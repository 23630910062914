/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { faEdit, faPowerOff, faEye } from '@fortawesome/free-solid-svg-icons';

import {
  cellDateOperation,
  cellOperation,
  cellOperationManyDesc,
  headerOperation,
} from '../../helpers/tables';
import BaseTable from '../UI/BaseTable';
import { API, BASE_URL, FRONT_DF_2 } from '../../CONST';
import {
  GET_PRODUCTOS,
  GET_RAMOS_FECU,
  GET_TIPOLOGIAS_EJECUCION,
  GET_TIPOS_COBERTURA,
} from '../../redux-store/constants/action-types';
import { getData } from '../../redux-store/actions';
import FilterSelect from '../shared/FilterSelect';
import isUserHasProfile from '../../helpers/profileValidator';
import { GENERAL_CONDITIONED_WRITE } from '../../helpers/profilePermission';

import './GeneralConditioned.css';

const NO_FILTERED_ID = -1;

class GeneralConditionedListTable extends Component {
  componentDidMount() {
    const { dispatchGetData } = this.props;

    dispatchGetData({
      url: `${BASE_URL}${API.tipologias_ejecucion}`,
      type: GET_TIPOLOGIAS_EJECUCION,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.productos}`,
      type: GET_PRODUCTOS,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.tipos_cobertura}`,
      type: GET_TIPOS_COBERTURA,
    });
    dispatchGetData({
      url: `${BASE_URL}${API.ramos_fecu}`,
      type: GET_RAMOS_FECU,
    });
  }

  isDisabledByProfile = () => {
    const validRole = isUserHasProfile(GENERAL_CONDITIONED_WRITE);

    return !validRole;
  };

  render() {
    const {
      listData,
      loading,
      onFetchData,
      onFilteredChange,
      handleOnClick,
      tipologias_ejecucion,
      productos,
      tipos_cobertura,
      ramos_fecu,
    } = this.props;
    const columns = [
      {
        Header: headerOperation('Ramo'),
        id: 'ramo_asegurado__id',
        accessor: (i) => cellOperation(i.ramo_asegurado.descripcion, 'left'),
        Filter: ({ filter, onChange }) => (
          <FilterSelect
            filter={filter}
            defaultOption={{ label: 'Todos', value: NO_FILTERED_ID }}
            onFilterChange={onChange}
            options={ramos_fecu.map((elem) => ({ label: elem.descripcion, value: elem.id }))}
          />
        ),
        minWidth: 80,
      },
      {
        Header: headerOperation('Código CMF'),
        id: 'nemotecnico__icontains',
        accessor: (i) => cellOperation(i.nemotecnico),
        minWidth: 80,
      },
      {
        Header: headerOperation('Producto'),
        id: 'productos__id',
        accessor: (i) => cellOperationManyDesc(i.productos),
        Filter: ({ filter, onChange }) => (
          <FilterSelect
            onFilterChange={onChange}
            filter={filter}
            options={productos.map((elem) => ({ label: elem.descripcion, value: elem.id }))}
            defaultOption={{ label: 'Todos', value: NO_FILTERED_ID }}
          />
        ),
        minWidth: 150,
      },
      {
        Header: headerOperation('Tipo Cobertura'),
        id: 'tipos_cobertura__id',
        accessor: (i) => cellOperationManyDesc(i.tipos_cobertura),
        Filter: ({ filter, onChange }) => (
          <FilterSelect
            onFilterChange={onChange}
            filter={filter}
            options={tipos_cobertura.map((elem) => ({ label: elem.descripcion, value: elem.id }))}
            defaultOption={{ label: 'Todos', value: NO_FILTERED_ID }}
          />
        ),
        minWidth: 150,
      },
      {
        Header: headerOperation('Fecha Depósito'),
        id: 'inicio_vigencia',
        accessor: (i) => cellDateOperation(i.inicio_vigencia, FRONT_DF_2),
        width: 140,
      },
      {
        Header: headerOperation('Tipología Ejecución'),
        id: 'tipologias_ejecucion__id',
        accessor: (i) => cellOperationManyDesc(i.tipologias_ejecucion),
        Filter: ({ filter, onChange }) => (
          <FilterSelect
            onFilterChange={onChange}
            filter={filter}
            options={tipologias_ejecucion.map((elem) => ({
              label: elem.descripcion,
              value: elem.id,
            }))}
            defaultOption={{ label: 'Todas', value: NO_FILTERED_ID }}
          />
        ),
        minWidth: 100,
      },
      {
        Header: headerOperation('Estado'),
        id: 'activa',
        accessor: (i) =>
          cellOperation(
            i.activa ? (
              <div className="alert alert-success text-center" role="alert">
                Activo
              </div>
            ) : (
              <div className="alert alert-secondary text-center" role="alert">
                Inactivo
              </div>
            ),
          ),
        Filter: ({ filter, onChange }) => (
          <FilterSelect
            onFilterChange={onChange}
            filter={filter}
            options={[{ label: 'Activo', value: 'true' }, { label: 'Inactivo', value: 'false' }]}
            defaultOption={{ label: 'Todos', value: '' }}
          />
        ),
        width: 120,
      },
      {
        Header: headerOperation('Acciones'),
        id: 'actions',
        accessor: (i) => (
          <div
            style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '20px' }}
            className="table-cell"
          >
            {!this.isDisabledByProfile() && (
              <div style={{ textAlign: 'right' }}>
                <Button onClick={(e) => handleOnClick(e, i)} className="table-link">
                  <FontAwesomeIcon icon={faPowerOff} />
                </Button>
              </div>
            )}
            <div style={{ textAlign: 'left' }}>
              <Link to={`/condicionados/edit/${i.id}`} className="table-link">
                <FontAwesomeIcon
                  icon={isUserHasProfile(GENERAL_CONDITIONED_WRITE) ? faEdit : faEye}
                />
              </Link>
            </div>
          </div>
        ),
        filterable: false,
        sortable: false,
        minWidth: 60,
      },
    ];
    return (
      <div style={{ margin: '25px 0 40px 0' }}>
        <BaseTable
          columns={columns}
          data={listData.data}
          filterable
          loading={loading}
          onFetchData={onFetchData}
          onFilteredChange={onFilteredChange}
          pages={listData.pages}
          resultCount={listData.count}
          resizable={false}
          showPagination
          noBorders
        />
      </div>
    );
  }
}

GeneralConditionedListTable.propTypes = {
  listData: PropTypes.shape({
    data: PropTypes.array,
    pages: PropTypes.number,
    count: PropTypes.number,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onFetchData: PropTypes.func.isRequired,
  onFilteredChange: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  tipologias_ejecucion: PropTypes.arrayOf(PropTypes.object).isRequired,
  productos: PropTypes.arrayOf(PropTypes.object).isRequired,
  tipos_cobertura: PropTypes.arrayOf(PropTypes.object).isRequired,
  ramos_fecu: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatchGetData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  listData: state.conditioned.listData,
  productos: state.conditioned.productos,
  tipologias_ejecucion: state.conditioned.tipologias_ejecucion,
  tipos_cobertura: state.conditioned.tipos_cobertura,
  ramos_fecu: state.conditioned.ramos_fecu,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchGetData: (data) => dispatch(getData(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeneralConditionedListTable);
