import React, { useState } from 'react';
import {Card, CardBody, Collapse} from 'reactstrap';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {particularConditionType} from '../../../../redux-store/types';

const ParticularCondition = ({condition, checked, onChange, disabled}) => {
  const [collapse, setCollapse] = useState(true);

  const toggle = () => setCollapse(!collapse);

  return (
    <>
      <div style={{ display: 'grid', gridTemplateColumns: 'auto 20px', alignItems: 'center' }}>
        <label
          htmlFor={condition.name}
          style={{ fontSize: '16px', fontWeight: 'normal', marginBottom: 0 }}
        >
          <input
            id={condition.name}
            type='checkbox'
            style={{ marginRight: '20px' }}
            checked={checked}
            disabled={disabled}
            onChange={() => onChange(condition)}
          />
          {condition.name}
        </label>
        <FontAwesomeIcon
          icon={faChevronRight}
          onClick={toggle}
          style={{ cursor: 'pointer' }}
          transform={collapse ? {} : {rotate: 90}}
        />
      </div>
      <div>
        <Collapse isOpen={!collapse}>
          <Card style={{ marginTop: '20px' }}>
            <CardBody className="small-text pre-wrap-white-space">
              {condition.texto}
            </CardBody>
          </Card>
        </Collapse>
        <hr />
      </div>
    </>
  )
};

ParticularCondition.propTypes = {
  condition: particularConditionType.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ParticularCondition;
