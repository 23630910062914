
function isValid(rut, body, dv) {
  let dvSum = 0;
  let multiple = 2;
  for (let i = 1; i <= body.length; i += 1) {
    const index = multiple * rut.charAt(body.length - i);
    dvSum += index;
    if (multiple < 7) {
      multiple += 1;
    } else {
      multiple = 2;
    }
  }
  const dvExpected = 11 - (dvSum % 11);

  if (dvExpected === parseInt(dv, 10)) {
    return true;
  }
  if (dvExpected === 10 && dv === 'K') {
    return true;
  }
  return dvExpected === 11 && dv === '0';
}

export function sanitizeRut(rut) {
  return rut.replace(/[^\dk]/gi, '')
}

export function formatForBackend(rut) {
  return rut.replace(/\./g, '')
}

function internalFormatRut(rawRut) {
  const sanitizedRut = sanitizeRut(rawRut);
  const body = sanitizedRut.slice(0, -1);
  const dv = sanitizedRut.slice(-1).toUpperCase();
  const formatted = `${body.replace(
    /(?!^)(\d{3})(?=(?:\d{3})*$)/g,
    '.$1',
  )}-${dv}`;
  return { sanitizedRut, body, dv, formatted }
}

export function formatRut(rawRut) {
  if (!rawRut) {
    return ''
  }
  return internalFormatRut(rawRut).formatted;
}

export function validateRut(rawRut) {
  if (!rawRut) {
    return { formatted: '', isValid: false, formattedForBackend: '' };
  }
  const { sanitizedRut, body, dv, formatted } = internalFormatRut(rawRut)
  return {
    formatted,
    isValid: isValid(sanitizedRut, body, dv),
    formattedForBackend: formatForBackend(formatted),
  };
}
