import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import FormSection from '../../../UI/FormSection';
import {
  TIPO_PROVISION_MENSUAL_ID,
  TIPO_PROVISION_CUOTAS_FORMA_PAGO_ID,
  TIPO_PLAN_LIBRO_VENTAS_ID,
  TIPO_PLAN_CUOTAS_ID,
} from '../../../../CONST';

class TipoDeProvisionSection extends Component {
  componentDidUpdate(prevProps) {
    const { planPago: prevPlanPago } = prevProps;
    const { planPago, dispatch, handleChange } = this.props;

    if (
      prevPlanPago.tipoId !== planPago.tipoId ||
      prevPlanPago.cuotas !== planPago.cuotas
    ) {
      const disabledCase =
        planPago.tipoId === TIPO_PLAN_LIBRO_VENTAS_ID ||
        (planPago.tipoId === TIPO_PLAN_CUOTAS_ID && planPago.cuotas === 12);
      if (disabledCase) {
        dispatch(
          handleChange('endoso.tipoProvisionId', TIPO_PROVISION_MENSUAL_ID),
        );
      }
    }
  }

  handleOptionChange = event => {
    const { dispatch, handleChange } = this.props;
    const tipoId = parseInt(event.target.value, 10);
    dispatch(handleChange('endoso.tipoProvisionId', tipoId));
  };

  render() {
    const { tipoProvisionId, style, planPago, disabled } = this.props;
    const disabledCase =
      planPago.tipoId === TIPO_PLAN_LIBRO_VENTAS_ID ||
      (planPago.tipoId === TIPO_PLAN_CUOTAS_ID && planPago.cuotas === 12);

    return (
      <FormSection title="Tipo de Provisión" style={style}>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              value={TIPO_PROVISION_MENSUAL_ID}
              checked={tipoProvisionId === TIPO_PROVISION_MENSUAL_ID}
              onChange={this.handleOptionChange}
              disabled={disabledCase || disabled}
            />
            Mensual
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              value={TIPO_PROVISION_CUOTAS_FORMA_PAGO_ID}
              checked={tipoProvisionId === TIPO_PROVISION_CUOTAS_FORMA_PAGO_ID}
              onChange={this.handleOptionChange}
              disabled={disabledCase || disabled}
            />
            Cuotas de la forma de pago
          </Label>
        </FormGroup>
      </FormSection>
    );
  }
}

TipoDeProvisionSection.propTypes = {
  tipoProvisionId: PropTypes.number.isRequired,
  planPago: PropTypes.shape({
    id: PropTypes.number,
    tipoId: PropTypes.number,
    cuotas: PropTypes.number,
    planPagosDocument: PropTypes.object,
    selectedPlanPagosDocumentName: PropTypes.string,
    planPagosDocumentUuid: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

TipoDeProvisionSection.defaultProps = {
  style: {},
  disabled: false,
};

export default TipoDeProvisionSection;
