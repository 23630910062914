import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import BaseModal from './BaseModal';


class DeprecatedWarningModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      awaitingResponse: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const { awaitingResponse } = this.state;

    if (!nextProps.modalOpen && awaitingResponse === true) {
      this.setState({
        awaitingResponse: false,
      });
    }
  }

  handleSubmit() {
    const { handleSubmit, objectId } = this.props;

    this.setState({ awaitingResponse: true });
    handleSubmit(
      objectId,
      () => this.setState({ awaitingResponse: false }),
    );
  }

  render() {
    const {
      handleModal, modalOpen, warningMessage, confirmText,
    } = this.props;
    const { awaitingResponse } = this.state;

    return (
      <div>
        <BaseModal
          handleModal={() => {}}
          modalOpen={modalOpen}
          type="modal-warning warning-container"
        >
          <div className="warning-section text">
            <div className="icon-container">
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
            <div>{warningMessage}</div>
          </div>
          <div className="button-container warning-section">
            <div>
              <Button
                className="warning-cancel"
                disabled={awaitingResponse}
                onClick={handleModal}
              >
                Cancelar
              </Button>
            </div>
            <div>
              <Button
                className="warning-publish"
                disabled={awaitingResponse}
                onClick={() => this.handleSubmit()}
              >
                {confirmText}
              </Button>
            </div>
          </div>
        </BaseModal>
      </div>
    );
  }
}

DeprecatedWarningModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  objectId: PropTypes.number,
  modalOpen: PropTypes.bool.isRequired,
  warningMessage: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
};

DeprecatedWarningModal.defaultProps = {
  objectId: -1,
};

export default DeprecatedWarningModal;
