import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import NumberFormat from 'react-number-format';
import FormSection from '../../../UI/FormSection';
import {
  TIPO_COSTO_AVISO_IMPAGO_MONTO_SINIESTRO_ID,
  TIPO_COSTO_AVISO_IMPAGO_SINIESTRO_ID,
} from '../../../../CONST';

class CostoDeAvisoDeImpagoSection extends Component {
  handleOptionChange = event => {
    const { dispatch, handleChange } = this.props;
    const tipoId = parseInt(event.target.value, 10);
    dispatch(handleChange('endoso.tipoCostoAvisoImpagoId', tipoId));
  };

  render() {
    const {
      tipoCostoAvisoImpagoId,
      costoAvisoImpago,
      moneda,
      dispatch,
      style,
      validate,
      handleChange,
      disabled,
    } = this.props;
    const isInvalid = validate && costoAvisoImpago === null;
    return (
      <FormSection title="Costo de aviso de impago" style={style}>
        <FormGroup check className="radio-with-input-in-the-middle">
          <Label check>
            <Input
              type="radio"
              value={TIPO_COSTO_AVISO_IMPAGO_SINIESTRO_ID}
              checked={
                tipoCostoAvisoImpagoId === TIPO_COSTO_AVISO_IMPAGO_SINIESTRO_ID
              }
              onChange={this.handleOptionChange}
              disabled={disabled}
            />
            Igual a
            <NumberFormat
              id="tipo-costo-aviso-impago"
              name="tipoCostoAvisoImpago"
              className={`form-control${
                tipoCostoAvisoImpagoId ===
                  TIPO_COSTO_AVISO_IMPAGO_SINIESTRO_ID && isInvalid
                  ? ' is-invalid'
                  : ''
              }`}
              onValueChange={values => {
                const newValue = values.floatValue;
                dispatch(handleChange('endoso.costoAvisoImpago', newValue));
              }}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              allowNegative={false}
              value={
                tipoCostoAvisoImpagoId === TIPO_COSTO_AVISO_IMPAGO_SINIESTRO_ID
                  ? costoAvisoImpago
                  : 0
              }
              disabled={
                tipoCostoAvisoImpagoId !==
                  TIPO_COSTO_AVISO_IMPAGO_SINIESTRO_ID || disabled
              }
              suffix={` ${moneda}`}
              style={{ width: '110px' }}
            />
            por cada siniestro
          </Label>
        </FormGroup>
        <FormGroup check className="radio-with-input-in-the-middle">
          <Label check>
            <Input
              type="radio"
              value={TIPO_COSTO_AVISO_IMPAGO_MONTO_SINIESTRO_ID}
              checked={
                tipoCostoAvisoImpagoId ===
                TIPO_COSTO_AVISO_IMPAGO_MONTO_SINIESTRO_ID
              }
              onChange={this.handleOptionChange}
              disabled={disabled}
            />
            Igual a
            <NumberFormat
              id="costo-aviso-impago"
              name="costoAvisoImpago"
              className={`form-control${
                tipoCostoAvisoImpagoId ===
                  TIPO_COSTO_AVISO_IMPAGO_MONTO_SINIESTRO_ID && isInvalid
                  ? ' is-invalid'
                  : ''
              }`}
              onValueChange={values => {
                const newValue = values.floatValue;
                dispatch(handleChange('endoso.costoAvisoImpago', newValue));
              }}
              decimalSeparator=","
              decimalScale="2"
              fixedDecimalScale
              allowNegative={false}
              isAllowed={values => !values.value || values.floatValue <= 100}
              value={
                tipoCostoAvisoImpagoId ===
                TIPO_COSTO_AVISO_IMPAGO_MONTO_SINIESTRO_ID
                  ? costoAvisoImpago
                  : 0
              }
              disabled={
                tipoCostoAvisoImpagoId !==
                  TIPO_COSTO_AVISO_IMPAGO_MONTO_SINIESTRO_ID || disabled
              }
              suffix="%"
              style={{ width: '110px' }}
            />
            del monto de cada siniestro
          </Label>
        </FormGroup>
      </FormSection>
    );
  }
}

CostoDeAvisoDeImpagoSection.propTypes = {
  tipoCostoAvisoImpagoId: PropTypes.number.isRequired,
  costoAvisoImpago: PropTypes.number,
  moneda: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  validate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CostoDeAvisoDeImpagoSection.defaultProps = {
  costoAvisoImpago: null,
  style: {},
  disabled: false,
};

export default CostoDeAvisoDeImpagoSection;
